@mixin txttrans() {
  &::-webkit-input-placeholder {
    text-transform: none;
  }
  &:-moz-placeholder {
    text-transform: none;
  }
  &:-ms-input-placeholder {
    text-transform: none;
  }
  &::-moz-placeholder {
    text-transform: none;
  }
}

#lpChat {
  input[type='text'] {
    @include txttrans();
  }
  input[type='email'] {
    @include txttrans();
  }
  textarea {
    @include txttrans();
  }
  select {
    text-transform: none;
  }
  option {
    text-transform: none;
  }
  .lp_buttons_area button {
    letter-spacing: 1px;
  }
}

#lpChat select.lp_select_field {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  -o-appearance: menulist;
}

.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}
