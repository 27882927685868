/* Loyalty Account settings page */
.account-page {
  &__content {
    .account-setting__section {
      .account-settings__container {
        .account-info {
          .label {
            width: auto;
          }
        }
      }
    }
  }
}
/* Loyalty Profile preferences page */
.profile-page--loyalty {
  &.social-info {
    margin-top: 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: 0;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0);
      @include swap_direction(margin, 0);
      clear: both;
    }
    .section-header {
      display: none;
    }
    .social-info__header {
      border: 0;
      font-size: 25px;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 5px 0 15px 0);
        font-size: 30px;
      }
    }
    .loyalty {
      &_join_membership_accept_terms {
        .label {
          &-content {
            width: 90%;
            display: inline-block;
            vertical-align: top;
            padding-#{$ldirection}: 4px;
          }
        }
      }
      &__panel__join__form-join__submit {
        @include swap_direction(margin, 25px 0 0 0);
        padding: 15px 50px;
        &:not(.button--disabled) {
          @include join-now-button-color;
        }
        &.button--disabled {
          background: $color-gray;
          color: $color-white;
          border-color: $color-gray;
          cursor: default;
          &:hover,
          &:active,
          &:focus {
            background: $color-gray;
            color: $color-white;
            border-color: $color-gray;
          }
        }
      }
    }
    .loyalty_join {
      &_learnmore {
        text-transform: uppercase;
        font-size: 13px;
      }
    }
    .profile-page--loyalty__terms {
      @include swap_direction(margin, 20px 0 0 0);
      font-style: italic;
      font-size: 12px;
      .terms_privacy {
        color: $color-green;
      }
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 25px 0 5px 0px);
      }
    }
    .loyalty_join_membership {
      &_header {
        @include swap_direction(margin, 20px 0);
      }
    }
  }
}
