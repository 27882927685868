.loyalty__panel {
  &__offers {
    @include swap_direction(padding, 30px 20px 0 20px);
    &__error {
      text-align: center;
      color: $color-error;
      font-family: $font--text;
    }
    &__list-container {
      @include swap_direction(padding, 25px 0 45px 0);
      border-bottom: 1px solid $color-light-gray;
      .loyalty__panel__offers {
        &__offer-image-container {
          height: auto;
          margin-bottom: 20px;
          @include breakpoint($landscape-up) {
            height: 315px;
            img {
              width: 275px;
              height: 315px;
            }
          }
        }
        &__offer-data-container {
          @include swap_direction(padding, 0 10px);
          @include breakpoint($landscape-up) {
            min-height: 125px;
            margin-bottom: 25px;
          }
        }
        &__offer-valid {
          margin-bottom: 15px;
        }
        &__slides {
          .slick {
            &-arrow {
              top: 34%;
            }
            &-slide {
              @include swap_direction(padding, 0 25px);
              text-align: center;
              @include breakpoint($landscape-up) {
                @include swap_direction(padding, 10px);
              }
            }
            &-list {
              @include breakpoint($landscape-up) {
                @include swap_direction(padding, 0 10px);
                @include swap_direction(margin, 0 auto);
                width: 90%;
              }
            }
          }
        }
        &__promo {
          &_title {
            font-family: $font--subheading;
            font-size: 19px;
            @include breakpoint($landscape-up) {
              @include swap_direction(margin, 0);
              min-height: 60px;
            }
          }
          &_desc {
            font-family: $font--subheading;
            font-size: 15px;
            font-style: italic;
            margin-bottom: 20px;
            min-height: 50px;
            @include breakpoint($landscape-up) {
              @include swap_direction(margin, 0);
            }
          }
          &_code {
            font-family: $font--text;
            font-size: 15px;
            color: $color-black;
          }
        }
      }
    }
  }
  &__offers__sku {
    @include swap_direction(padding, 30px 20px 0 20px);
    @include breakpoint($landscape-up) {
      padding-bottom: 30px;
    }
    &__list-container {
      @include swap_direction(padding, 25px 0 45px 0);
      border-bottom: 1px solid $color-light-gray;
      .loyalty__panel__offers__sku {
        &__product-image-container {
          margin-bottom: 20px;
          min-height: 266px;
          @include breakpoint($landscape-up) {
            margin-bottom: 80px;
            height: 266px;
          }
        }
        &__product__data__wrapper {
          @include swap_direction(margin, 0 0 20px 0);
        }
        &__product__header {
          font-family: $font--subheading;
          font-size: 19px;
          min-height: 45px;
        }
        &__product__sub-header {
          font-family: $font--subheading;
          font-size: 15px;
          font-style: italic;
          margin-bottom: 20px;
          min-height: 50px;
        }
        &__product__offer-code {
          font-family: $font--text;
          font-size: 15px;
          color: $color-black;
        }
      }
      .slides {
        li {
          @include breakpoint($landscape-up) {
            width: 32%;
            text-align: center;
            display: inline-block;
            margin-#{$rdirection}: 15px;
            margin-bottom: 20px;
            &:nth-child(3n) {
              margin-#{$rdirection}: 0;
            }
          }
        }
      }
      .slick {
        &-slide {
          @include swap_direction(margin, 0 20px);
          text-align: center;
        }
        &-arrow {
          top: 34%;
        }
      }
    }
  }
  &__title {
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    font-family: $font--heading;
    @include breakpoint($landscape-up) {
      font-size: 30px;
    }
  }
  &__subtitle {
    text-align: center;
    font-size: 14px;
    font-family: $font--text;
    a {
      @include swap_direction(margin, 15px 0 0 0);
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 11px;
      display: inline-block;
      text-decoration: none;
      border-bottom: 1px solid $color-light-gray;
      line-height: 1;
      color: $color-black;
    }
  }
  &__how-to-earn,
  &__benefits {
    display: none;
  }
}
/* Order history page */
.orders-page {
  &__statustext {
    color: $color-gray-darker;
    font-size: 12px;
    margin-top: 30px;
    font-family: $font--text;
  }
}
