.store-locator {
  position: relative;
  margin-bottom: 10px;
  @include breakpoint($medium-up) {
    margin-bottom: 0;
  }

  // Genie
  .hidden,
  .inactive,
  .hide {
    display: none;
  }
  &__header {
    background: none;
    text-align: center;
    @include breakpoint($landscape-up) {
      padding-top: 75px;
      padding-bottom: 100px;
      background: $gray;
    }
    .store-locator--results & {
      @include breakpoint($landscape-up) {
        padding-top: 40px;
        padding-bottom: 10px;
      }
    }
  }
  &__header-container {
    margin: 0 0 10px 0;
    @include breakpoint($landscape-up) {
      margin-bottom: 25px;
    }
  }
  &__paginator {
    padding: 10px 0;
    overflow: auto;
    text-transform: uppercase;
    border-top: solid 1px $color-lighter-gray;
    &-panel {
      &--left {
        font-size: 11px;
        float: left;
        text-transform: none;
      }
      &--right {
        font-size: 11px;
        float: right;
        color: $color-green;
        cursor: pointer;
        margin-right: 10px;
      }
      &--next {
        margin-right: 42px;
      }
    }
  }
  &__description {
    text-transform: uppercase;
    letter-spacing: 0.13em;
    font-size: 11px;
    width: 90%;
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      font-size: 12px;
    }
  }
  &__messages {
    color: $color-error;
  }
  &__form {
    @include clearfix;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5%;
    @include breakpoint($landscape-up) {
      max-width: $max-width;
      padding: 0;
    }
    select,
    input,
    a.selectBox {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
    button {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
    &-elements {
      max-width: 600px;
      margin: auto;
      position: relative;
    }
    &--inputs {
      select {
        width: 85%;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
    }
    &--inputs-filter {
      overflow: auto;
      font-size: 11px;
      margin-top: 20px;
    }
    &--filters {
      padding: 20px;
      display: none;
      .store-locator--results & {
        // @include breakpoint($medium-up) {
        display: block;
        // }
        overflow: auto;
      }
    }
    &-column--distance {
      width: 200px;
      text-align: left;
      @include breakpoint($landscape-up) {
        float: left;
      }
      select,
      a.selectBox {
        background: none;
      }
    }
    &--search-input[type='text'] {
      border: 0;
      width: 84%;
      font-size: 16px;
      text-align: center;
      padding: 0 32px 0 15px;
      line-height: 60px;
      height: 60px;
      color: $color-light-grey;
      @include breakpoint($medium-up) {
        width: 100%;
        text-align: left;
        padding-left: 65px;
        padding-right: 40px;
      }
    }
    &--intl {
      display: none;
      padding: 40px 0 0;
      .search-submit {
        vertical-align: top;
      }
    }
  }
  &__direction {
    color: $color-green;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    .icon {
      font-size: 10px;
      margin-top: -4px;
      margin-right: 5px;
    }
  }
  &__book-appointment-btn {
    margin-top: 5px;
    padding: 5px;
  }
  &__each-day {
    margin: 5px 0;
  }
  &__day--name {
    margin-right: 5px;
    text-transform: capitalize;
  }
  &__locations {
    &--intl {
      float: left;
      border-bottom: solid 1px $color-dark-gray;
      cursor: pointer;
      text-transform: uppercase;
      .icon {
        font-size: 10px;
      }
    }
    &--current {
      float: right;
      cursor: pointer;
      text-transform: uppercase;
      position: absolute;
      top: 28px;
      transform: translateY(-50%);
      right: 0;
      font-size: 15px;
      line-height: 40px;
      @include breakpoint($medium-up) {
        font-size: 11px;
        line-height: 1.5;
      }
      &-label {
        border-bottom: solid 1px $color-dark-gray;
        margin-right: 5px;
        display: none;
        @include breakpoint($medium-up) {
          display: block;
          float: left;
        }
      }
      @include breakpoint($medium-up) {
        position: static;
        transform: none;
      }
    }
  }
  &__form-column {
    margin: 0 auto;
    @include breakpoint($landscape-up) {
      display: inline-block;
      margin: 0 0 0 10px;
      position: relative;
      &--store-type,
      &:first-child {
        margin-left: 0;
      }
      .icon--search {
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.neither {
      // display: none;    // JGA
    }
    &--search {
      width: 100%;
      border-bottom: solid 1px $color-black;
      @include breakpoint($medium-up) {
        border-bottom: 0;
      }
      .icon--map-marker {
        float: left;
        font-size: 24px;
        line-height: 40px;
      }
      .icon--close {
        color: $color-light-gray;
        position: absolute;
        font-size: 12px;
        right: 22px;
        top: 25px;
        cursor: pointer;
      }
    }
    &--submit {
      @include breakpoint($landscape-up) {
        float: right;
      }
      .search-submit {
        &:hover {
          background: $color-green;
          color: $color-white;
          border-color: $color-green;
        }
      }
    }
    &--store-type {
      max-width: 200px;
      @include breakpoint($landscape-up) {
        float: left;
        max-width: auto;
      }
    }
    select {
      margin-bottom: 14px;
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
      }
    }
    select,
    option {
      color: $color-gray;
      font-size: 15px;
    }
    button {
      min-width: auto;
    }
    .store-type_container {
      .store,
      .spa {
        float: left;
        margin: 10px 30px 10px 0;
        text-transform: uppercase;
        font-size: 12px;
        .label:before {
          content: none;
        }
        .icon {
          font-size: 7px;
          width: 15px;
          height: 15px;
          border: 1px solid $color-green;
          border-radius: 50%;
          line-height: 15px;
          margin-right: 2px;
        }
        input[type='checkbox']:checked ~ .label {
          .icon {
            background: $color-green;
            color: $color-white;
          }
        }
      }
      .spa {
        .icon {
          border: 1px solid $color-black;
        }
        input[type='checkbox']:checked ~ .label {
          .icon {
            background: $color-black;
            color: $color-white;
          }
        }
      }
    }
  }
  &__dutyfree {
    padding: 20px 0 0 0;
  }
  &__results-container,
  &__map-canvas-container {
    position: absolute;
    left: -400%;
    height: 100%;
    width: 100%;
    min-height: 150px;
    @include breakpoint($landscape-up) {
      position: static;
    }
    &.active {
      position: static;
    }
    &.list-active {
      position: static;
      .store-locator {
        // Pagination content shows via JS. To override Pagination Style for Selected locator
        &__paginator,
        &__info,
        &-section__list-item {
          display: none !important;
          &.selected {
            padding: 20px 0;
            display: block !important;
          }
        }
      }
    }
  }
  &__results-container {
    padding: 0 10px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
    @include breakpoint($landscape-up) {
      clear: left;
      float: left;
      width: 30%;
    }
    .directions-form {
      &__errors {
        padding: 5px 0;
      }
      &__submit {
        @include breakpoint($medium-down) {
          margin: 0 0 5px 0;
          line-height: 17px;
        }
      }
    }
    .directions-form__results {
      .adp-directions {
        td.adp-substep {
          word-break: break-all;
          &:nth-child(1) {
            width: 7%;
          }
          &:nth-child(2) {
            width: 10%;
          }
          &:nth-child(3) {
            width: 70%;
          }
          &:nth-child(4) {
            width: 12%;
          }
        }
      }
      .adp-placemark {
        width: 100%;
      }
    }
  }
  &__map-canvas-container {
    @include breakpoint($landscape-up) {
      float: right;
      width: 70%;
    }
  }
  &__map-canvas-holder,
  &__directions-map-holder {
    height: 285px;
    @include breakpoint($landscape-up) {
      height: 545px;
    }
  }
  &__map-canvas,
  &__directions-map {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    // fix for a squashed image issue, with the map controls
    // (see: http://stackoverflow.com/questions/9904379/google-map-zoom-controls-not-displaying-correctly)
    .gmnoprint img {
      max-width: none;
    }
  }
  &__touts {
    position: relative;
    .store-locator--results & {
      display: none;
    }
    &--results {
      display: none;
      clear: right;
      padding: 20px 0 0 0;
      @include breakpoint($landscape-up) {
        padding-top: 40px;
      }
      .store-locator--results & {
        display: block;
      }
    }
  }
  &__body {
    display: none;
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 15px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    .store-locator--results & {
      display: block;
    }
  }
  &__info {
    padding: 10px 0;
    border-bottom: solid 1px $color-lighter-gray;
  }
  &__sections {
    margin: 0 0 30px 0;
    border-bottom: solid 1px $color-light-green;
    height: 45px;
    text-align: center;
    &--items {
      overflow: auto;
      display: inline-block;
    }
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__button {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.13em;
    text-align: center;
    height: 45px;
    line-height: 45px;
    float: left;
    width: 50%;
    border: 0;
    font-weight: 500;
    display: block;
    min-width: 120px;
    border-bottom: solid 1px $color-lighter-gray;
    font-family: $font--lamer-headline;
    &.active {
      background: $color-white;
      border-bottom: solid 1px $color-gray;
      color: $color-black;
    }
  }
  &__results-header {
    margin: 0 0 20px 0;
    @include breakpoint($landscape-up) {
      margin-bottom: 35px;
    }
  }
  &__stores {
    @include breakpoint($landscape-up) {
      height: 663px;
      overflow: auto;
      max-height: 465px;
      -webkit-overflow-scrolling: touch;
    }
  }
  &-section__list-item {
    padding: 10px 0;
    border-top: 1px solid $color-lighter-gray;
    &:first-child {
      border-top: 0;
    }
    &.first {
      border-top: 0;
    }
    &--left,
    &--middle,
    &--right {
      float: left;
    }
    &--left {
      width: 20%;
      padding-right: 5%;
      text-align: center;
      @include breakpoint($medium-up) {
        width: 10%;
        padding-right: 0;
        text-align: left;
      }
      .result-number {
        display: inline-block;
        width: 25px;
        height: 25px;
        text-align: center;
        text-indent: 2px;
        border-radius: 50%;
        color: $color-white;
        background-color: $color-black;
        margin-bottom: 10px;
        @include breakpoint($medium-up) {
          margin-bottom: 0;
        }
        &.STORE {
          background-color: $color-green;
        }
      }
    }
    &--mobile-direction {
      float: left;
      width: 35%;
      padding-right: 2%;
      word-wrap: break-word;
      @include breakpoint($medium-up) {
        width: 40%;
      }
      .store-name {
        display: block;
        margin-bottom: 15px;
        &-link {
          text-decoration: none;
          border-bottom: 0;
          color: $color-black;
          font-weight: bold;
          text-transform: uppercase;
          &.STORE {
            color: $color-green;
          }
        }
      }
    }
    &--middle {
      width: 70%;
    }
    &--right {
      width: 20%;
      text-align: left;
      color: $color-lighter-gray;
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
  &__tooltip {
    @include transition(background-color 0.1s ease-in-out);
    position: relative;
    cursor: pointer;
    &:hover,
    &.selected {
      background-color: $color-white;
    }
  }
  &__tooltip-left-col {
    float: left;
    width: 45%;
    margin-right: 7%;
    position: relative;
    padding-left: 26px;
    @include breakpoint($landscape-up) {
      float: none;
      width: 100%;
      margin: 0;
      padding-left: 42px;
    }
  }
  &__tooltip-right-col {
    float: right;
    width: 48%;
    line-height: 15px;
    @include breakpoint($landscape-up) {
      float: none;
      width: 100%;
      line-height: 17px;
      padding-left: 42px;
    }
  }
  &__tooltip-name {
    text-transform: uppercase;
    margin: 0 0 7px 0;
    a {
      text-decoration: none;
      border-bottom: 0;
      color: $color-black;
      font-weight: bold;
      &.STORE {
        color: $color-green;
      }
    }
    @include breakpoint($landscape-up) {
      font-size: 14px;
    }
    .icon {
      font-size: 24px;
      position: absolute;
      top: 1px;
      left: 0;
      @include breakpoint($landscape-up) {
        font-size: 27px;
        left: 8px;
      }
    }
  }
  &__result-pin {
    cursor: pointer;
    transform: translateY(100%);
    &,
    &-image {
      height: 30px;
      width: 20.4px;
    }
    &-label {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      background: $color-black;
      color: $color-white;
      &.STORE {
        background: $color-green;
      }
    }
  }
  &__tooltip.info_window {
    line-height: 15px;
    padding: 5px 0;
    color: $color-white;
    text-align: left;
    a,
    a:hover {
      color: $color-white;
    }
    &:hover {
      background: none;
    }
    .store-locator__tooltip-name {
      margin: 0 0 5px 0;
      font-size: 13px;
      font-weight: bold;
      @include breakpoint($landscape-up) {
        margin-bottom: 3px;
      }
    }
    .store-locator__tooltip-address {
      margin: 0 0 2px 0;
    }
    .store-locator__tooltip-directions {
      margin-top: 8px;
    }
    a.store-locator__tooltip-book-appt-btn {
      color: $color-dark-gray;
      &:hover {
        color: $color-white;
      }
    }
  }
  &__tooltip-distance {
    font-size: 11px;
    margin-top: 5px;
    @include breakpoint($landscape-up) {
      margin-top: 0;
      position: absolute;
      top: 1px;
      right: 0;
    }
  }
  &__tooltip-address,
  &__tooltip-phone {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  &__tooltip-address {
    margin: 0 0 2px 0;
    @include breakpoint($landscape-up) {
      margin-bottom: 3px;
    }
  }
  &__tooltip-phone {
    display: inline-block;
    margin: 0 0 3px 0;
    clear: both;
  }
  &__tooltip-hours {
    margin: 12px 0 0 0;
    div {
      margin-bottom: 2px;
      @include breakpoint($landscape-up) {
        margin-bottom: 3px;
      }
    }
  }
  &__tooltip-links {
    margin: 12px 0 0 0;
  }
  &__tooltip-events,
  &__tooltip-directions {
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      font-size: 12px;
      text-decoration: underline;
      a:hover {
        text-decoration: none;
      }
    }
  }
  &__tooltip-events {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__tooltip-directions {
    margin-top: 2px;
    @include breakpoint($landscape-up) {
      margin-top: 3px;
    }
  }
  &__tooltip-book-appt-btn {
    margin-top: 3px;
    padding: 5px;
  }
  .doors_results {
    width: 100%;
  }
  &__map-recentering-search {
    @include breakpoint($landscape-up) {
      float: right;
      margin: 10px 0 0 0;
    }
  }
  &__map-recentering-search-prompt-button {
    position: absolute;
    bottom: 1px;
    left: 1px;
    @include breakpoint($landscape-up) {
      position: static;
    }
  }
  &__loading-image {
    margin: 32px auto 0 auto;
    display: block;
    .store-locator__map-canvas & {
      @include vertical-horizontal-center;
      position: absolute;
      margin: 0 auto;
    }
  }
  &__directions-results {
    .adp-text {
      width: 93%;
      padding-left: 15px;
      vertical-align: middle;
    }
  }
  &__directions-destination {
    float: left;
    margin-right: 10px;
  }
  &__directions-location {
    .directions-form__transportation-modes {
      padding: 10px;
      .directions-form__transportation-mode-link.active,
      .directions-form__transportation-mode-link:hover {
        color: $color-error;
      }
    }
  }
  .gm-style {
    .gm-style-iw-t {
      &:after {
        background: $color-green;
      }
      .gm-style-iw {
        padding-#{$rdirection}: 20px !important;
        padding-bottom: 10px !important;
        background: $color-green;
        .gm-style-iw-d {
          overflow: auto !important;
        }
      }
    }
  }
  &__content {
    .store-locator--results & {
      display: none;
    }
  }
}

// This handles adding a marker icon to the left sidebar results
#store_results {
  .store-locator {
    // &__tooltip {
    //   @include breakpoint($landscape-up) {
    //     background-image: url(/media/images/locator/result-pin.png);
    //     background-repeat: no-repeat;
    //     background-size: 20px 29px;
    //     background-position: 16px 25px;
    //     .column {
    //        padding-left: 35px;
    //     }
    //   }
    // }
  }
}

// override selectbox
.distance-selectBox-dropdown-menu,
.country-selectBox-dropdown-menu {
  //background-color: $color-light-silver;
}

#search-feature {
  &-content {
    #store-locator {
      &-form {
        &-div {
          .label-field {
            width: 120px;
            padding-top: 8px;
          }
          .text-field {
            padding-top: 8px;
          }
          .field-width-168 {
            width: 168px;
          }
          .field-width-160 {
            width: 160px;
          }
          .or-separator {
            padding-top: 8px;
            padding-right: 8px;
            text-transform: uppercase;
          }
          #find_stores_intl {
            &.header {
              padding-top: 20px;
            }
          }
          #PERIMITER {
            width: 80px;
            display: inline;
          }
          .align-rgt {
            text-align: right;
          }
          #search-locator-button {
            cursor: pointer;
            padding-top: 15px;
          }
          #driving-button {
            cursor: pointer;
          }
          #sign-up-button {
            cursor: pointer;
            padding-top: 5px;
            padding-left: 2px;
          }
        }
      }
    }
    #show_map {
      width: 450px;
      height: 380px;
      #map {
        width: 450px;
        height: 340px;
      }
    }
    #show_driving_map {
      width: 450px;
      height: 420px;
      #driving_map {
        width: 450px;
        height: 340px;
      }
    }
    #special-events-detail {
      position: relative;
      height: 185px;
      padding: 0 0 50px;
    }
    #route {
      padding: 5px;
    }
  }
}

/**
 * typeahead drop-down
 */
.section-stores {
  .pac-container {
    border-radius: 0;
    box-shadow: none;
    border: 0;
    background: #f7f7f7;
    .pac {
      &-item {
        background: #f7f7f7;
        border: 0;
        line-height: 40px;
        font-size: inherit;
        &:hover,
        &-selected {
          background: $color-green;
          color: $color-white;
          cursor: pointer;
          .pac-item-query {
            color: $color-white;
          }
        }
      }
      &-icon {
        margin-right: 22px;
        opacity: 0;
      }
      &-item-query {
        font-size: inherit;
      }
      &-matched {
        font-weight: normal;
      }
    }
    &.pac-logo {
      &:after {
        background: none;
        display: none;
      }
    }
  }
}

// override selectbox style
.distance-selectBox-dropdown-menu {
  background-color: $gray;
}

// To override Google info Window Background color
.info {
  &-window,
  &-icon > div > div {
    background: $color-black !important;
    &.STORE {
      background: $color-green !important;
    }
  }
  &-icon.STORE > div > div {
    background: $color-green !important;
  }
}
