.cs-page {
  &__content-item {
    margin-top: 20px;
    .cs-contact-form__section {
      line-height: 1.375;
      margin: 25px 0 35px;
      max-width: 100%;
      text-align: justify;
      @include breakpoint($medium-up) {
        max-width: 90%;
      }
    }
    #contact_us {
      .fieldset {
        float: none;
        width: 100%;
        margin-bottom: 20px;
        @include breakpoint($medium-up) {
          float: left;
          width: 45%;
        }
        #form--contact_us--field--QUESTION {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 98%;
          }
        }
        &.my_order {
          p {
            margin: 0;
          }
        }
        &.my_message {
          h2 {
            margin-top: 0;
          }
          .label {
            display: none;
          }
        }
        .select-box__label {
          width: 100%;
          height: 48px;
          font-size: 16px;
          line-height: 48px;
        }
        .select-box__label:after,
        .select-box__label.open:after {
          width: 20px;
          top: 12px;
        }
        input[type='text'],
        input[type='email'],
        input[type='tel'],
        input[type='search'],
        input[type='password'],
        select,
        .select-box {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 96%;
          }
          .select-box__options.open {
            top: 47px;
          }
          &.error {
            border-color: $color-error;
          }
        }
        input[type='radio'].error + label {
          color: $color-error;
        }
        .field-label {
          display: none;
        }
        input.adpl--processed + label {
          margin-bottom: 0em;
        }
      }
      .cs-contact-form__errors {
        li {
          color: $color-error;
          margin: 20px 0;
        }
      }
      .fieldset.my_message {
        margin-bottom: 20px;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 90%;
        }
        textarea {
          background: none;
          padding-top: 10px;
          width: 100%;
          @include breakpoint($medium-up) {
            width: 98%;
          }
        }
      }
      .fieldset.my_emailaddress {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 45%;
        }
        input.field {
          width: 100%;
          @include breakpoint($medium-up) {
            width: 95%;
          }
        }
      }
      .fieldset.my_order {
        width: 100%;
        @include breakpoint($medium-up) {
          width: 90%;
        }
      }
      .field-input {
        .btn_submit {
          input,
          & + .button-loading {
            margin-top: 25px;
            width: 100%;
            @include breakpoint($medium-up) {
              margin-top: 5px;
              width: 65%;
            }
          }
        }
      }
      .registered_member {
        margin: 20px 0 10px;
        width: 90%;
        .cs-contact-form__label,
        .cs-contact-form__radio {
          display: inline-block;
          margin-right: 20px;
        }
      }
      .cs-contact-form {
        &__shipping {
          &--label,
          &--radio {
            display: inline-block;
            margin-#{$rdirection}: 20px;
          }
        }
      }
      .check_signup {
        width: 100%;
        margin: 10px 0px 20px;
        @include breakpoint($medium-up) {
          width: 90%;
        }
        .field-label {
          float: left;
          margin-right: 15px;
        }
        .field-input {
          input {
            float: left;
            margin-top: 3px;
          }
          label {
            width: 100%;
            @include breakpoint($medium-up) {
              width: 96.5%;
            }
          }
        }
      }
    }
  }
}

.cs-contact-form {
  .button-loading {
    cursor: not-allowed;
  }
}
