/* Mixin function for review star Background image */
@mixin pr-background-image ($cr22:false, $spp-grid-image: false) {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @if ($cr22 and $spp-grid-image) {
    $pr-height-value: 12px;
    $pr-width-value: 12px;
  }
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/export/images/global/rating/rating-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      @if ($cr22 and $spp-grid-image) {
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}

@mixin pr-solid-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-rating-width-value: 17px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/export/images/global/rating/rating-solidwhite-small-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-rating-width-value;
    }
  }
}
/* Mixin function for review large star Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 27px;
  $pr-width-value: 28px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/export/images/global/rating/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
      margin-right: 8px;
    }
  }
}
/* Mixin function for Submit button background */
@mixin pr-submit-btn {
  background: $color-dark-gray;
  color: $color-white;
  border-color: $color-dark-gray;
}
/* Global variables */

$color-base-text: #030b2b;
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
.review-snippet,
#pr-reviewsnippet,
.search-wrapper__results {
  .p-w-r {
    @include pr-background-image($cr22, $spp-grid-image);
    a {
      text-decoration: none;
      border-bottom: 1px solid $color-dark-gray;
      &:hover {
        color: $color-green;
        border-bottom: 1px solid $color-green;
        text-decoration: none;
      }
    }
    button,
    a {
      color: $color-dark-gray;
      font-family: $font--text;
      font-size: 14px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .pr-snippet-stars {
      background: transparent;
    }
    span,
    p,
    strong {
      color: $color-dark-gray;
      font-family: $font--text;
      font-size: 13px;
    }
    .pr {
      &-textinput,
      &-textarea {
        &::-webkit-input-placeholder {
          color: $color-light-gray;
          font-size: 12px;
          font-family: $font--text;
          text-transform: none;
        }
        &::-moz-placeholder {
          color: $color-light-gray;
          font-size: 12px;
          font-family: $font--text;
          text-transform: none;
        }
        &::-ms-placeholder {
          color: $color-light-gray;
          font-size: 12px;
          font-family: $font--text;
          text-transform: none;
        }
        &::placeholder {
          color: $color-light-gray;
          font-size: 12px;
          font-family: $font--text;
          text-transform: none;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal,
      &-snippet-reco-to-friend {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-dark-gray;
      }
      &-clear-all-radios {
        color: $color-black;
        letter-spacing: 0;
        &:hover {
          color: $color-green;
          text-decoration: none;
          border-bottom: 1px solid $color-green;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-header-table {
        .pr-header-title {
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            display: inline-block;
            a,
            span {
              color: $color-dark-gray;
              font-size: 14px;
              font-family: $font--text;
            }
            a:hover {
              color: $color-green;
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-dark-gray;
          font-family: $font--text;
          font-size: 14px;
          background: transparent;
        }
        .pr-review {
          .pr-rd-header {
            .pr-rd-review-headline {
              color: $color-black;
              font-family: $font--text;
              font-size: 13px;
            }
          }
        }
        .pr-rd-description-text {
          color: $color-dark-gray;
          font-family: $font--text;
          font-size: 16px;
        }
        .pr-rd-author-nickname {
          font-size: 13px;
          font-family: $font--text;
        }
        .pr-rd-author-submission-date {
          time {
            color: $color-dark-gray;
            font-family: $font--text;
            font-size: 13px;
          }
        }
        .pr-rd-description {
          color: $color-dark-gray;
          font-family: $font--text;
          font-size: 16px;
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline,
      h1.title {
        color: $color-dark-gray;
        font-family: $font--text;
        font-size: 16px;
        font-weight: normal;
      }
      .subtitle {
        color: $color-dark-gray;
        font-size: 16px;
      }
      .title {
        color: $color-dark-gray;
        font-size: 18px;
      }
      .pr-dark {
        color: $color-dark-gray;
        font-weight: bold;
      }
    }
  }
}
/* Home & MPP page */
.product-grid__content {
  .product-grid__item {
    .product-brief__rating {
      .review-snippet {
        .p-w-r {
          .pr-category-snippet__total {
            font-family: $font--text;
            font-size: 15px;
            color: $color-dark-gray;
            display: block;
          }
          @include pr-background-image($cr22, $spp-grid-image);
        }
      }
    }
  }
}
/* Content Block - Split Width Animations */
.content-block--split-width {
  margin-top: 0;
  .content-block-large__content-text {
    .product-rating {
      .review-snippet {
        .p-w-r {
          .pr-category-snippet__total {
            font-family: $font--text;
            font-size: 15px;
            color: $color-dark-gray;
            display: none;
          }
          @include pr-background-image($cr22, $spp-grid-image);
        }
      }
    }
  }
}
/* SPP page */
#pr-rating-tab {
  .pr-rating-stars {
    padding: 18px;
  }
}

.spp__reviews-block {
  .spp__reviews-header {
    @include heading--2;
    border-top: solid 1px $color-lighter-gray;
    margin: 0 25px;
    padding: 20px 0;
    text-align: center;
    @include breakpoint($medium-up) {
      margin: 0 auto;
      padding: 40px 0;
    }
  }
  .content-block__content {
    @include breakpoint($small-down) {
      padding: 0 25px;
    }
  }
}

.review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-container {
        cursor: pointer;
        @include pr-background-image($cr22, $spp-grid-image);
        display: inline-block;
        float: right;
        .pr-rating-stars {
          @if ($cr22 and $spp-grid-image) {
            height: 18px;
            border-radius: 0;
            padding-top: 0;
            padding-bottom: 0;
          } @else {
            vertical-align: text-bottom;
          }
        }
      }
      .pr-snippet-read-and-write {
        margin-top: 0;
        float: left;
        .pr-snippet-review-count {
          font-size: 15px !important;
          text-decoration: none;
          border: 0;
          padding-inline: 7.5px 5px;
          &:before {
            content: '(';
            visibility: visible;
          }
          &:after {
            content: ')';
            visibility: visible;
          }
        }
        .pr-snippet-write-review-link {
          display: none;
        }
      }
      &.pr-snippet-minimal,
      &.pr-snippet-compact {
        display: inline-block;
        .pr-snippet-read-and-write,
        .pr-snippet-stars-reco-reco,
        .pr-snippet-stars-container {
          margin-top: 0;
          display: inline-block;
        }
      }
    }
    .pr-no-reviews {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-review-count {
          display: none;
        }
        .pr-snippet-write-review-link {
          display: block;
          font-size: 14px !important;
          border: 0;
          margin-#{$rdirection}: 6px;
          padding-inline: 7.5px 0;
          @include breakpoint($medium-up) {
            font-size: 15px !important;
            margin-#{$rdirection}: 6px;
          }
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-snapshot {
      padding: 0 50px;
      @include breakpoint($small-down) {
        padding: 0;
      }
      .pr-review-snapshot-block-container {
        .pr-review-snapshot-block {
          border: 0;
          .pr-review-snapshot-block-headline {
            display: none;
          }
        }
        .pr-review-snapshot-block-histogram {
          .pr-ratings-histogram-barValue {
            background: $color-dark-gray;
          }
          .pr-histogram-label {
            text-transform: uppercase;
            font-size: 12px;
            color: $color-base-text;
          }
          .pr-histogram-count {
            color: $color-base-text;
            font-size: 12px;
            font-weight: normal;
          }
          .pr-histogram-list {
            li:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }
        .pr-review-snapshot-block-pros {
          .pr-review-snapshot-block-headline {
            display: block;
            padding: 0 30px;
            font-size: 12px;
            color: $color-base-text;
            font-family: $font--text;
            text-transform: uppercase;
            background: transparent;
            text-align: center;
            font-weight: normal;
          }
          dt {
            background: transparent;
          }
          .pr-snapshot-tag-def {
            text-align: center;
            line-height: 1;
            padding: 0;
            height: auto;
            .pr-snapshot-highlight {
              display: none;
            }
            &:first-of-type {
              padding-top: 0px;
              margin-top: 0;
            }
            span {
              font-size: 18px;
              color: $color-dark-gray;
              font-family: $font--subheading;
              text-transform: lowercase;
              line-height: 30px;
            }
            .pr-filter-btn {
              text-align: center;
            }
          }
        }
        .pr-review-snapshot-block-cons {
          text-align: center;
          @include breakpoint($small-down) {
            position: relative;
          }
          @include pr-large-background-image();
          .pr-review-snapshot-tags,
          .pr-rating-stars {
            display: none;
          }
          @include breakpoint($tablet-only) {
            position: relative;
            padding-bottom: 20px;
          }
          .pr-review-snapshot-snippets {
            .pr-snippet-stars-reco-stars {
              float: none;
              height: 84px;
              .pr-snippet-stars-container {
                display: block;
                margin-top: 10px;
                text-align: center;
                .pr-snippet-stars-png {
                  display: block;
                }
                .pr-snippet-rating-decimal {
                  display: none;
                  border: none;
                  font-family: $font--subheading;
                  font-size: 30px;
                  color: $color-dark-gray;
                  float: none;
                  padding: 0;
                  margin: 0;
                  &:after {
                    content: ' out of 5';
                    color: $color-lighter-gray;
                  }
                }
              }
              .pr-snippet-read-and-write {
                margin-top: 0;
                .pr-snippet-review-count {
                  display: block;
                  text-align: center;
                  font-family: $font--heading;
                  color: $color-dark-gray;
                  font-size: 36px !important;
                  text-transform: uppercase;
                  @include breakpoint($small-down) {
                    font-size: 25px !important;
                    margin-top: 10px;
                  }
                }
                .pr-snippet-write-review-link {
                  font-size: 11px !important;
                  @include button--secondary;
                  @include pr-submit-btn();
                  padding: 11px 50px 11px 50px;
                  margin: 0;
                  position: relative;
                  top: 110px;
                  @include breakpoint($small-down) {
                    position: absolute;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: 0;
                    display: block;
                    padding: 11px 25px 11px 25px;
                  }
                  @include breakpoint($tablet-only) {
                    position: absolute;
                    top: inherit;
                    bottom: 0;
                    #{$ldirection}: 0;
                    #{$rdirection}: 0;
                    margin: 0 auto;
                    width: 200px;
                  }
                }
              }
            }
            .pr-snippet-stars-reco-reco {
              float: none;
              .pr-snippet-reco-to-friend {
                @include breakpoint($small-down) {
                  margin-bottom: 20px;
                }
                display: block;
                margin: 0 auto;
                position: relative;
                bottom: 30px;
                .pr-checkbox-icon {
                  display: none !important;
                }
                .pr-reco-value {
                  font-size: 18px;
                  font-family: $font--subheading;
                  color: $color-dark-gray;
                  line-height: 1;
                }
                .pr-reco-to-friend-message {
                  font-size: 18px;
                  font-family: $font--subheading;
                  color: $color-dark-gray;
                  text-transform: lowercase;
                  max-width: 70%;
                }
                div,
                span {
                  display: block !important;
                  text-align: center;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
      .pr-review-snapshot-faceoff,
      .pr-review-snapshot-msq-container {
        display: none;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        background: transparent;
        text-align: center;
        border-bottom: 1px solid $color-lighter-gray;
        border-top: 1px solid $color-lighter-gray;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 55px;
        @include breakpoint($small-down) {
          padding-left: 0px;
          padding-right: 0px;
        }
        @include breakpoint($tablet-only) {
          margin-top: 15px;
        }
        .pr-rd-review-total {
          display: none;
        }
        .pr-rd-review-header-contents {
          &:before {
            content: 'filter by:';
            font-size: 13px;
            text-transform: uppercase;
            display: inline-block;
            color: $color-dark-gray;
            margin-#{$rdirection}: 10px;
            font-weight: bold;
            font-family: $font--text;
            letter-spacing: 0.05em;
          }
          background: transparent;
          .pr-multiselect {
            vertical-align: middle;
            .pr-multiselect-button {
              .pr-caret-icon {
                display: inline-block;
                vertical-align: middle;
              }
            }
            button {
              min-width: auto;
              border-radius: 4px;
              display: inline-block;
              span {
                span {
                  text-transform: uppercase;
                  letter-spacing: 0.05em;
                }
              }
              .pr-multiselect-count {
                color: $color-white;
                background: $color-dark-gray;
              }
            }
          }
          .pr-multiselect {
            display: none;
          }
          .pr-multiselect-button-age,
          .pr-multiselect-button-whatisyourskintype,
          .pr-multiselect-button-skinconcern {
            display: inline-block;
          }
          .pr-multiselect-button-skinconcern {
            .pr-multiselect-options {
              width: 178px;
              ul {
                width: 100%;
              }
              @include breakpoint($small-down) {
                width: 178px;
                position: absolute;
                left: -18px;
              }
            }
          }
          .pr-multiselect-button-whatisyourskintype {
            .pr-multiselect-options {
              @include breakpoint($small-down) {
                left: -18px;
              }
            }
          }
        }
        .pr-clearfix {
          display: none;
        }
        .pr-rd-review-header-contents,
        .pr-rd-main-header-search-sort {
          display: inline-block;
        }
        .pr-rd-review-header-contents {
          width: 60%;
          padding: 0 0 15px 0;
          padding-bottom: 0;
          .pr-multiselect {
            .pr-multiselect-options {
              overflow: auto;
              width: 178px;
            }
          }
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
        .pr-rd-main-header-search-sort {
          width: 40%;
          display: inline-block;
          padding-top: 0;
          display: flex;
          flex-flow: row wrap;
          @include breakpoint($tablet-only) {
            float: #{$rdirection};
          }
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
        .pr-rd-main-header-search-sort {
          .pr-rd-main-header-search {
            width: 100%;
            position: relative;
            order: 2;
            margin-top: 10px;
            .pr-rd-search-reviews-input {
              width: 100%;
              float: none;
              input {
                border: 1px solid $color-lighter-gray;
                border-radius: 4px;
                color: $color-dark-gray;
              }
              @include breakpoint($landscape-up) {
                width: 100%;
                float: #{$rdirection};
              }
              input:focus + button {
                span {
                  svg {
                    path {
                      fill: $color-white;
                    }
                  }
                }
              }
              .pr-search-icon {
                margin-bottom: 4px;
              }
              .pr-rd-search-reviews-icon-button {
                border: 1px solid $color-lighter-gray;
                border-radius: 0 4px 4px 0;
                background: $color-dark-gray;
                height: 35px;
              }
              .pr-cross-icon {
                .pr-cross-icon__line {
                  stroke: $color-dark-gray;
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
              .pr-rd-search-reviews-clear-button {
                .pr-cross-icon__line {
                  stroke: $color-dark-gray;
                }
                &:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
            }
          }
          .pr-rd-review-header-sorts {
            width: 100%;
            padding: 0;
            position: relative;
            order: 1;
            .pr-rd-sort-group {
              width: 100%;
              @include breakpoint($medium-up) {
                padding-top: 5px;
              }
              &:before {
                content: 'sort by: ';
                color: $color-dark-gray;
                font-family: $font--text;
                font-size: 13px;
                text-transform: uppercase;
                font-weight: bold;
                margin-right: 10px;
                letter-spacing: 0.05em;
              }
              select {
                &::-ms-expand {
                  display: none;
                }
              }
            }
            .pr-rd-sort {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              cursor: pointer;
              width: 75%;
              font-family: $font--text;
              color: $color-dark-gray;
              height: 40px;
              line-height: 40px;
              font-size: 11px;
              text-transform: uppercase;
              border-radius: 4px;
              padding: 0 30px 0 15px;
              letter-spacing: 0.05em;
              &:focus {
                outline: none;
                box-shadow: none;
              }
              @include breakpoint($landscape-down) {
                width: 70%;
              }
              @include breakpoint($medium-down) {
                width: 100%;
              }
            }
          }
        }
        .pr-rd-display-search-no-results {
          .pr-rd-review-total {
            display: block;
            font-size: 20px;
            font-family: $font--text;
            color: $color-dark-gray;
            letter-spacing: 0.05em;
          }
          @include breakpoint($small-down) {
            margin-top: 30px;
          }
        }
      }
      .pr-review {
        border-bottom: 1px solid $color-lighter-gray;
        padding: 10px 50px 0px 50px;
        @include breakpoint($small-down) {
          padding: 0;
        }
        .pr-rd-header {
          .pr-rd-star-rating {
            display: block;
            float: none;
            margin-bottom: 10px;
          }
          .pr-rd-review-headline {
            line-height: 1;
            font-family: $font--heading;
            font-size: 24px;
            color: $color-dark-gray;
            font-style: italic;
            display: block;
            margin: 0;
            word-wrap: break-word;
          }
        }
        .pr-rd-right {
          padding-left: 40px;
          @include breakpoint($small-down) {
            padding-left: 0px;
          }
          @include breakpoint($medium-portrait-only) {
            padding-left: 0px;
          }
          .pr-rd-details {
            .pr-rd-bold {
              font-weight: normal;
            }
          }
          .pr-rd-author-nickname,
          .pr-rd-author-location {
            .pr-rd-bold {
              display: none;
            }
          }
          .pr-rd-description-text {
            font-size: 16px;
          }
        }
        .pr-rd-description {
          .pr-verified_reviewer {
            display: none;
          }
        }
        .pr-accordion {
          .pr-caret-icon {
            display: inline-block;
            .pr-caret-icon__line {
              stroke: $color-dark-gray;
            }
          }
          .pr-accordion-btn {
            span {
              font-size: 16px;
              font-family: $font--text;
              color: $color-base-text;
            }
          }
        }
        .pr-accordion-content {
          .pr-rd-tags,
          .pr-rd-subratings {
            width: 95%;
            clear: both;
            @include breakpoint($small-down) {
              width: 100%;
            }
            dl {
              width: 100%;
            }
            dt {
              padding-left: 0px;
              width: 330px;
              letter-spacing: 1px;
              color: $color-base-text;
              font-size: 16px;
              line-height: 1;
              padding-bottom: 10px;
              @include breakpoint(($small-down) (orientation portrait)) {
                width: 65%;
              }
            }
            dd {
              display: table-row;
              color: $color-base-text;
              font-size: 16px;
            }
          }
          .pr-rd-subratings {
            margin-bottom: 15px;
          }
          .pr-rd-tags {
            margin-bottom: 3px;
            dt {
              float: left;
              padding-top: 0;
              padding-bottom: 0;
            }
            dd {
              text-transform: capitalize;
            }
          }
          .pr-rd-review-tag {
            margin-bottom: 0;
          }
        }
        .pr-rd-footer {
          padding-bottom: 20px;
          .pr-rd-bottomline {
            margin-top: -15px;
            .pr-rd-bold {
              display: none;
            }
            span {
              color: $color-base-text;
              font-size: 16px;
            }
          }
          .pr-rd-helpful-action {
            .pr-rd-helpful-text {
              font-weight: normal;
              color: $color-dark-gray;
              font-size: 16px;
              padding-top: 7px;
            }
            .pr-helpful-yes {
              @include breakpoint($medium-portrait-only) {
                padding-left: 0px;
              }
            }
          }
          .pr-rd-flag-review-container {
            vertical-align: baseline;
            .pr-flag-review-thankyou {
              vertical-align: middle;
              font-size: 16px;
            }
            .pr-flag-icon {
              position: relative;
              bottom: 4px;
            }
            @include breakpoint($small-down) {
              margin-top: 13px;
            }
            a {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .pr-helpful {
            &-count {
              font-size: 16px;
              color: $color-dark-gray;
              padding-right: 3px;
              &:hover {
                color: $color-dark-gray;
              }
            }
            &-btn {
              border: 0;
              padding: 7px 11px;
              background: none;
              vertical-align: top;
              text-transform: capitalize;
              .pr-thumbs-icon {
                display: none;
              }
              @include breakpoint($small-down) {
                float: left;
                margin: 6px 6px 0 0;
                padding-left: 0;
              }
              @include breakpoint(($small-down) (orientation portrait)) {
                float: none;
                margin: 6px 6px 0 0;
                padding-left: 0;
              }
            }
            &-active {
              background: $color-green !important;
            }
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: 'yes';
                  visibility: visible;
                  margin-right: 5px;
                  text-transform: capitalize;
                  font-size: 16px;
                  font-weight: bold;
                  border-bottom: 1px solid $color-dark-gray;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: 'no';
                  visibility: visible;
                  margin-right: 5px;
                  text-transform: capitalize;
                  font-size: 16px;
                  font-weight: bold;
                  border-bottom: 1px solid $color-dark-gray;
                }
              }
            }
          }
          .pr-modal-content {
            .pr-control-label,
            .pr-flag-review-label,
            .pr-flag-review-btn,
            input,
            textarea {
              font-family: $font--text;
              font-size: 16px;
              color: $color-dark-gray;
            }
            input,
            textarea {
              &:focus {
                outline: none;
                box-shadow: none;
                border-color: $color-green;
              }
            }
            .pr-form-radio {
              position: relative;
              left: 0;
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
            .pr-flag-review-btn {
              @include button--secondary;
              @include pr-submit-btn();
            }
          }
        }
      }
      .pr-rd-main-footer {
        border-bottom: 1px solid $color-gray;
        margin-bottom: 20px;
        padding-left: 50px;
        padding-right: 50px;
        .pr-rd-content-block {
          width: 100%;
          .pr-rd-to-top {
            margin-left: 31%;
            font-size: 16px;
            text-align: center;
            @include breakpoint($small-down) {
              margin-left: 0;
            }
          }
          .pr-rd-review-position {
            span {
              font-size: 16px;
            }
          }
          .pr-rd-pagination {
            border: 0;
          }
        }
      }
    }
  }
}
/* Write a review page */
#power_review_container {
  float: left;
  width: 100%;
  padding: 0 10px;
  .product-full {
    &__container {
      max-width: 1024px;
      margin: 0 auto;
      padding: 40px 0 20px;
    }
    &__image {
      width: 35%;
      margin-right: 5%;
      float: left;
      @include breakpoint($small-down) {
        width: 100%;
        text-align: center;
      }
    }
    &__details {
      float: left;
      width: 60%;
      @include breakpoint($small-down) {
        width: 100%;
      }
      .js-product-image-link {
        text-decoration: none;
      }
      .product-full__name,
      .product-full__subline {
        float: none;
        width: auto;
        margin: 0;
        padding: 0;
        padding-top: 10px;
        @include breakpoint($small-down) {
          text-align: left;
        }
      }
      .js-pr-sub-line {
        color: $color-dark-gray;
        font-size: 15px;
        font-family: $font--text;
      }
      .pr-war {
        .pr-header {
          .pr-header-title {
            color: $color-dark-gray;
          }
        }
        #pr-war-form {
          .btn-toggle {
            .pr-btn {
              color: $color-dark-gray;
              font-size: 14px;
              font-family: $font--text;
              &:focus {
                box-shadow: none;
                outline: none;
              }
              &.active {
                background: $color-green;
                color: $color-white;
                border: 1px solid $color-green;
              }
            }
            div {
              &:first-child {
                border-radius: 4px 4px 0 0;
              }
              &:last-child {
                border-radius: 0 0 4px 4px;
              }
            }
          }
          input,
          textarea,
          select {
            border: 1px solid $color-lighter-gray;
            border-radius: 4px;
            color: $color-dark-gray;
            &:focus {
              border: 1px solid $color-green;
              box-shadow: none;
              outline: none;
              color: $color-green;
            }
          }
          input {
            -webkit-appearance: none;
            appearance: none;
            font-size: 14px;
            height: 40px !important;
            line-height: 40px;
            padding: 0 40px 0 15px !important;
            text-transform: none;
            transition: border-color 0.3s, color 0.3s;
          }
          textarea {
            padding: 10px !important;
          }
          select {
            max-width: 100%;
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            min-width: 60px;
            font-size: 11px;
            height: 40px !important;
            line-height: 40px;
            padding: 0 30px 0 15px !important;
            letter-spacing: 0.05em;
            background-color: $color-white;
            background-repeat: no-repeat;
            background-position: 100% 50%;
            background-image: url('/sites/cremedelamer2/themes/cremedelamer2_base/img/select-box/caret--down.svg');
            option {
              &:first-child {
                text-transform: uppercase;
              }
            }
            &:focus {
              color: $color-dark-gray;
            }
          }
          .pr-btn-add-tag {
            a {
              @include button--secondary;
              @include pr-submit-btn();
              &:after {
                color: $color-white;
              }
              @include breakpoint($small-down) {
                min-width: 100%;
              }
            }
          }
          .pr-media_image-form-group,
          .pr-media_video-form-group,
          .pr-media_videourl-form-group {
            label {
              @include button--secondary;
              @include pr-submit-btn();
              span {
                color: $color-white;
                font-size: 11px;
                &:after {
                  color: $color-white;
                }
              }
              @include breakpoint($small-down) {
                min-width: 100%;
              }
            }
          }
          .pr-bestuses-form-group,
          .pr-cons-form-group,
          .pr-describeyourself-form-group,
          .pr-wasthisagift-form-group {
            display: none;
          }
          .pr-media-preview {
            .pr-loading-indicator {
              width: 50px;
              height: 50px;
              margin: 10px 0 10px 10px;
            }
            .pr-textinput {
              display: none;
            }
            .pr-btn-delete {
              background-color: $color-black;
              border: 1px solid $color-black;
              &:hover {
                background-color: $color-green;
                border: 1px solid $color-green;
              }
            }
          }
          .tag-container {
            .pr-label-control {
              &.checked {
                text-transform: uppercase;
                color: $color-white;
                background: $color-green;
                border-color: $color-green;
                span {
                  color: $color-white;
                }
              }
            }
            .input-add-tag-field {
              border-radius: 4px 0 0 4px;
            }
            .input-group-btn {
              .pr-btn-add-tag-submit {
                div {
                  font-size: 11px;
                  color: $color-white;
                  text-transform: uppercase;
                }
                padding: 12px 15px;
                line-height: 1;
                min-width: auto;
                background: $color-dark-gray;
                color: $color-white;
                border: 1px solid $color-dark-gray;
                border-radius: 0 4px 4px 0;
                text-align: center;
                height: 40px;
                &:hover {
                  text-decoration: none;
                  background: $color-green;
                  color: $color-white;
                  border-color: $color-green;
                }
              }
            }
          }
          .form-group {
            &.pr-has-error {
              .form-control {
                border-color: $color-error;
              }
              .pr-control-label {
                span {
                  color: $color-error;
                }
              }
            }
            .pr-helper-text {
              display: none;
            }
          }
        }
        .pr-submit {
          .pr-accessible-btn {
            @include button--secondary;
            @include pr-submit-btn();
            font-weight: normal;
            @include breakpoint($small-down) {
              margin-bottom: 20px;
              width: 100%;
            }
          }
          .pr-subscript {
            line-height: 20px;
          }
        }
      }
      /* Thank you page */
      #pr-write {
        .p-w-r {
          .thank-you-page {
            .review-preview {
              @include pr-background-image($cr22, $spp-grid-image);
              .col-sm-12 {
                .pr-rating {
                  &-number,
                  &-date {
                    border: 0;
                    padding-top: 0;
                  }
                }
              }
              .headline {
                margin-bottom: 0;
              }
              .pr-text {
                .pr-dark {
                  width: 70%;
                  display: inline-block;
                  padding-right: 20px;
                }
              }
              #war-ty-pros,
              #war-ty-cons {
                display: none;
              }
              #war-ty-best-uses {
                .pr-attribute-header {
                  background: transparent;
                  padding-left: 0;
                }
                .pr-attribute-list {
                  li {
                    color: $color-dark-gray;
                    font-size: 15px;
                    padding-left: 0;
                  }
                }
              }
            }
          }
          .pr-alert-container {
            display: block;
            p {
              line-height: 18px;
            }
          }
          #pr-warning {
            display: none;
          }
        }
      }
    }
  }
}

.spp_reviews {
  width: 100%;
  text-align: center;
  .pr-review-heading {
    text-align: center;
    font-family: $font--text;
    color: $color-dark-gray;
    font-size: 13px;
    text-transform: uppercase;
  }
  .pr-review-summary {
    text-align: center;
    font-family: $font--heading;
    color: $color-dark-gray;
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 10px;
    line-height: 1;
    display: none;
    @include breakpoint($small-down) {
      margin-top: 0px;
    }
    span {
      font-family: $font--heading;
      color: $color-dark-gray;
      font-size: 36px;
    }
  }
  .js-mpp-summary-snippet {
    .p-w-r {
      .pr-snippet {
        margin-top: -28px;
        @include breakpoint($small-down) {
          margin-top: 0px;
        }
        .pr-snippet-stars-png {
          @include pr-large-background-image();
          .pr-snippet-rating-decimal {
            display: block;
            border: none;
            font-family: $font--subheading;
            font-size: 30px;
            color: $color-dark-gray;
            float: none;
            padding: 0;
            margin-top: 10px;
            &:after {
              content: ' out of 5';
              color: $color-lighter-gray;
            }
          }
        }
      }
      .pr-category-snippet__total {
        display: none;
      }
      .pr-no-reviews {
        margin-bottom: 20px;
        .pr-snippet-stars-png {
          .pr-snippet-rating-decimal {
            display: none;
          }
        }
      }
    }
  }
}
/* Quick shop */
.product-quickshop__container {
  .review-snippet {
    .p-w-r {
      .pr-no-reviews {
        .pr-category-snippet__total {
          display: none;
        }
      }
      .pr-snippet {
        .pr-category-snippet__rating {
          float: right;
          line-height: 36px;
          @include breakpoint($small-down) {
            line-height: normal;
          }
        }
        .pr-category-snippet__total {
          float: left;
          padding-right: 10px;
          font-family: $font--text;
          color: $color-dark-gray;
          font-size: 15px;
          @include breakpoint($small-down) {
            line-height: 20px;
          }
        }
        .pr-star-v4 {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}
/* Search results page */
.search-wrapper__results {
  .product-grid__content {
    .product-grid__item {
      .product-brief__rating {
        .review-snippet {
          .p-w-r {
            .pr-category-snippet__total {
              line-height: 18px;
            }
            .pr-category-snippet__rating {
              @include breakpoint($small-down) {
                padding-bottom: 10px;
              }
            }
            .pr-no-reviews {
              .pr-category-snippet__total {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.product-full--black,
.product-grid--theme-black {
  .review-snippet {
    .p-w-r {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-container {
          @include pr-solid-background-image();
          .pr-star-v4 {
            height: 10px;
            width: 12px;
          }
        }
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            color: $color-white;
          }
          a.pr-snippet-write-review-link,
          a.pr-snippet-write-review-link:hover {
            color: $color-white;
          }
        }
      }
    }
  }
  .product-grid__content {
    .product-grid__item {
      .product-brief__rating {
        .review-snippet {
          .p-w-r {
            @include pr-solid-background-image();
            .pr-star-v4 {
              height: 12px;
              width: 14px;
            }
            .pr-category-snippet__total {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
