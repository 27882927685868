.product-brief,
.product-grid__inline-content {
  position: relative;
  .mpp-container & {
    height: 100%;
  }
  @include breakpoint($medium-up) {
    border: 5px solid $color-white;
    .product-grid--theme-white & {
      background-color: $color-white;
    }
    .product-grid--theme-light-gray & {
      background-color: $color-mpp-light-gray;
    }
    .product-grid--theme-light-sand & {
      background-color: $color-light-sand;
    }
    .product-grid--theme-sand & {
      background-color: $color-sand;
    }
    .product-grid--theme-light-green & {
      background-color: $color-light-green;
    }
    .product-grid--theme-light-blue & {
      background-color: $color-light-blue;
    }
    .product-grid--theme-black & {
      @include font-smoothing(true);
      background-color: $color-black;
      color: $color-white;
      .button {
        @include button--secondary;
      }
      select,
      a.selectBox,
      a {
        color: $color-white;
      }
    }
    .product-carousel & {
      background-color: transparent;
    }
    .landing-page-bg-color & {
      border: 0;
    }
    .product-grid--theme-black .product-carousel & {
      color: $color-text;
      a {
        color: $color-link;
      }
      .button {
        @include button;
      }
    }
  }
}

// mpp_tout_v1 uses 'position: absolute' so that we can span 100% height. However this means it has no dimensions hence we need to give this parent height so equalHeightsGrids() can attach itself.
.product-grid__inline-content {
  min-height: 350px;
  width: 100%;
  @include breakpoint($small-down) {
    border: 0;
  }
  @include breakpoint($medium-up) {
    min-height: 450px;
    background-color: transparent;
  }
  // need to override this selector rule that drupal admin generates when logged in.
  .contextual-links-region {
    position: static;
  }
}

.product-brief {
  text-align: center;
  line-height: 1;
  padding: 0 0 40px 0;
  @include breakpoint($medium-up) {
    padding-bottom: 60px;
  }
  .product-carousel & {
    @include breakpoint($small-down) {
      padding-bottom: 0;
    }
  }
  &__info {
    position: relative;
    z-index: 11;
  }
}

.product-brief__container {
  // on touch device we show cta buttons so lets align them at the bottom
  .touchevents & {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  .touchevents .product-grid--small-cols-1 & {
    @include breakpoint($small-down) {
      height: auto;
    }
  }
}

.product {
  &-brief__header,
  &__detail {
    padding: 0 14%;
  }
  &-price-installment {
    font-weight: normal;
    font-size: small;
    padding-top: 5px;
  }
}

.product-brief__image {
  position: relative;
  margin: 0 auto;
  max-width: 280px;
  @include breakpoint($medium-up) {
    max-width: 100%;
    padding: 0 11px;
  }
  .product-image--default,
  .product-image--hover {
    @include centerer(true, false);
    top: 0;
  }
  .product-image--hover {
    opacity: 0;
    transition: opacity 0.3s;
    background-color: $color-white;
    .product-grid--theme-white & {
      background-color: $color-white;
    }
    .product-grid--theme-light-gray & {
      background-color: $color-mpp-light-gray;
    }
    .product-grid--theme-light-sand & {
      background-color: $color-light-sand;
    }
    .product-grid--theme-sand & {
      background-color: $color-sand;
    }
    .product-grid--theme-light-green & {
      background-color: $color-light-green;
    }
    .product-grid--theme-light-blue & {
      background-color: $color-light-blue;
    }
    .product-grid--theme-black & {
      background-color: $color-black;
    }
  }
  .product-carousel & {
    margin-bottom: 20px;
    max-width: 100%;
    padding: 0;
    .product-grid--theme-white & {
      background-color: $color-white;
    }
    .product-grid--theme-light-gray & {
      background-color: $color-mpp-light-gray;
    }
    .product-grid--theme-light-sand & {
      background-color: $color-light-sand;
    }
    .product-grid--theme-sand & {
      background-color: $color-sand;
    }
    .product-grid--theme-light-green & {
      background-color: $color-light-green;
    }
    .product-grid--theme-light-blue & {
      background-color: $color-light-blue;
    }
    .product-grid--theme-black & {
      background-color: $color-black;
    }
    .landing-page-bg-color & {
      background-color: transparent;
    }
  }
  &:hover {
    .product-image--hover {
      opacity: 1;
    }
  }
}

.product-brief__inventory-status {
  // only show inventory status on quickshop and spp
  display: none;
}

.product-brief__footer {
  @include breakpoint($medium-up) {
    padding: 13px 0;
    .no-touchevents & {
      display: none;
    }
  }
}

.product-brief__cta {
  margin: 17px 0 0 0;
  @include breakpoint($medium-up) {
    margin-top: 7px;
  }
}

//
// Quickshop Hover Panel
//
// We show if:
// - non touch device
// - at least one cta button (e.g. shop now, quickshop, add to cart) exists
//
.product-brief__extras {
  display: none; // hide on mobile
}

.no-touchevents .content-block .product-brief--show-extras-panel-on-hover .product-brief__extras:after {
  box-shadow: none;
}
.no-touchevents .landing-page-bg-color .product-brief--show-extras-panel-on-hover .product-brief__extras:after {
  border: 0;
}

.no-touchevents .product-brief--show-extras-panel-on-hover {
  @include breakpoint($medium-up) {
    .product-brief__extras {
      display: block;
      visibility: hidden; // jquery cannot get height of hidden element i.e. display: none
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0;
      transition: opacity 0.3s;
      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        border: 10px solid $color-white;
        box-shadow: 0 0 0 1px $color-border;
      }
      &--exposed {
        visibility: visible;
        opacity: 1;
      }
    }
    &:hover {
      .product-brief__extras {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// only on MPP at large size on non touch device
.product-brief--show-extras-panel-on-hover {
  @include breakpoint($medium-up) {
    .product-brief__extras {
      min-height: 100%;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 6px;
        .no-touchevents .mpp-container .product-grid--theme-white & {
          background-color: $color-white;
        }
        .no-touchevents .mpp-container .product-grid--theme-light-gray & {
          background-color: $color-mpp-light-gray;
        }
        .no-touchevents .mpp-container .product-grid--theme-light-sand & {
          background-color: $color-light-sand;
        }
        .no-touchevents .mpp-container .product-grid--theme-sand & {
          background-color: $color-sand;
        }
        .no-touchevents .mpp-container .product-grid--theme-light-green & {
          background-color: $color-light-green;
        }
        .no-touchevents .mpp-container .product-grid--theme-light-blue & {
          background-color: $color-light-blue;
        }
        .no-touchevents .mpp-container .product-grid--theme-black & {
          background-color: $color-black;
        }
      }
    }
  }
}

.product-brief__extras-link {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.product-brief__extras-container {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 10px;
  min-height: 40px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .product-carousel & {
    padding-bottom: 0;
  }
  @include breakpoint($medium-up) {
    background-color: $color-white;
    .product-grid--theme-white & {
      background-color: $color-white;
    }
    .product-grid--theme-light-gray & {
      background-color: $color-mpp-light-gray;
    }
    .product-grid--theme-light-sand & {
      background-color: $color-light-sand;
    }
    .product-grid--theme-sand & {
      background-color: $color-sand;
    }
    .product-grid--theme-light-green & {
      background-color: $color-light-green;
    }
    .product-grid--theme-light-blue & {
      background-color: $color-light-blue;
    }
    .product-grid--theme-black & {
      background-color: $color-black;
    }
    .product-carousel & {
      background-color: transparent;
    }
  }
}

//
// Product grid carousel overrides
//
// hide copy if not in current slide
.product-brief__extras,
.product-brief__header,
.product-brief__footer,
.product-brief__flag,
.product-image.lazyloaded {
  @include breakpoint($medium-up) {
    .slick-slide & {
      opacity: 0;
      transition: opacity 1s;
    }
    .slick-active & {
      opacity: 1;
    }
  }
}

.product-image--hover.lazyloaded {
  @include breakpoint($medium-up) {
    .slick-slide & {
      transition: opacity 0.3s;
    }
    .slick-active & {
      opacity: 0;
    }
  }
}

.product-grid--theme-black .slick-slide .product-brief,
.product-grid--theme-black .slick-slide .product-grid__inline-content {
  @include breakpoint($medium-up) {
    @include font-smoothing(false);
  }
}
