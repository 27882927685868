@mixin marketing-button {
  margin: 30px 0;
  padding-#{$ldirection}: 50px;
  padding-#{$rdirection}: 50px;
  a {
    text-decoration: none;
    font-size: 11px;
    color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

.loyalty {
  &__market {
    &__container {
      @include swap_direction(margin, 0 auto);
      max-width: 1280px;
    }
    &__header {
      background-image: url(/media/export/cms/loyalty/loyalty_marketing_moile_banner.png);
      height: 250px;
      background-size: 100% 100%;
      text-align: center;
      position: relative;
      @include breakpoint($landscape-up) {
        background-image: url(/media/export/cms/loyalty/loyalty_marketing_pc_banner.png);
        background-repeat: no-repeat;
        height: 455px;
      }
      &__text {
        position: absolute;
        bottom: 30px;
        width: 100%;
        font-style: italic;
        font-size: 18px;
        color: $color-white;
        font-family: $font--subheading;
        @include breakpoint($landscape-up) {
          font-size: 24px;
          bottom: 60px;
        }
      }
    }
    &__status {
      text-align: center;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 50px 0 0 0);
      }
      &__tier-1,
      &__tier-2,
      &__tier-3 {
        .loyalty__market__status {
          &__header {
            @include swap_direction(padding, 30px 20px);
            border-top: 1px solid $color-light-gray;
            text-align: #{$ldirection};
            &__logo {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              display: inline-block;
              background-size: 100% 100%;
            }
            &__title {
              @include swap_direction(padding, 15px 0 0 2px);
              display: inline-block;
              vertical-align: top;
              font-size: 16px;
              font-family: $font--subheading;
              text-transform: uppercase;
            }
            &__points {
              @include swap_direction(padding, 15px 0 0 0);
              display: inline-block;
              float: #{$rdirection};
              font-size: 14px;
              .icon {
                @include swap_direction(padding, 0 0 0 5px);
              }
            }
          }
          &__subheader {
            @include swap_direction(padding, 0px 0 25px 0);
            text-align: center;
            font-size: 16px;
            font-family: $font--heading;
            text-transform: uppercase;
          }
          &__content {
            &__field {
              @include swap_direction(padding, 20px);
              background: $color-very-light-gray;
              margin-bottom: 5px;
              min-height: 70px;
              text-align: #{$ldirection};
              &.replenishment {
                padding-top: 12px;
              }
            }
            &__subfield {
              @include swap_direction(margin, 0);
            }
          }
        }
        .panel {
          margin-bottom: 30px;
        }
      }
      &__tier-1 {
        margin-top: 30px;
        .loyalty__market__status {
          &__header__logo {
            background-image: url(/media/export/cms/loyalty/marine_mobile_benefits_logo.png);
          }
          &__content__subfield {
            color: $color-marine;
          }
        }
      }
      &__tier-2 {
        .loyalty__market__status {
          &__header__logo {
            background-image: url(/media/export/cms/loyalty/aqua_marine_mobile_benefits_logo.png);
          }
          &__content__subfield {
            color: $color-aquamarine;
          }
        }
      }
      &__tier-3 {
        border-bottom: 1px solid $color-light-gray;
        .loyalty__market__status {
          &__header__logo {
            background-image: url(/media/export/cms/loyalty/ultra_marine_mobile_benefits_logo.png);
          }
          &__content__subfield {
            color: $color-ultramarine;
          }
        }
      }
      // for PC
      &__section {
        width: 20%;
        display: inline-block;
      }
      &__header {
        &__title {
          font-size: 25px;
          font-family: $font--heading;
          text-transform: uppercase;
          text-align: #{$ldirection};
          vertical-align: bottom;
          position: relative;
          &__text {
            padding-#{$ldirection}: 20px;
            position: absolute;
            bottom: 25px;
          }
        }
        &__tier-1,
        &__tier-2,
        &__tier-3 {
          .loyalty__market__status__header {
            &__logo {
              @include swap_direction(margin, 0 auto);
              width: 143px;
              height: 143px;
              background-size: 100% 100%;
              margin-bottom: 30px;
            }
            &__points {
              font-size: 16px;
              font-family: $font--subheading;
              font-style: italic;
              margin-bottom: 25px;
            }
            &__title {
              font-size: 25px;
              font-family: $font--heading;
              text-transform: uppercase;
              text-align: center;
            }
          }
        }
        &__tier-1 {
          .loyalty__market__status__header {
            &__logo {
              background-image: url(/media/export/cms/loyalty/marine_pc_benefits_logo.png);
            }
            &__title,
            &__points {
              color: $color-marine;
            }
          }
        }
        &__tier-2 {
          .loyalty__market__status__header {
            &__logo {
              background-image: url(/media/export/cms/loyalty/aqua_marine_pc_benefits_logo.png);
            }
            &__title,
            &__points {
              color: $color-aquamarine;
            }
          }
        }
        &__tier-3 {
          .loyalty__market__status__header {
            &__logo {
              background-image: url(/media/export/cms/loyalty/ultra_marine_pc_benefits_logo.png);
            }
            &__title,
            &__points {
              color: $color-ultramarine;
            }
          }
        }
      }
      &__content {
        text-align: center;
        .loyalty__market__status__section {
          @include swap_direction(padding, 20px);
          height: 60px;
          vertical-align: bottom;
          line-height: 1;
          font-size: 14px;
        }
        &__title {
          text-align: #{$ldirection};
        }
        &__empty {
          @include swap_direction(margin, 0 auto);
          width: 15px;
          height: 15px;
          border-radius: 50%;
        }
        .replenishment {
          @include swap_direction(padding, 10px 10px 10px 20px);
        }
        &__field {
          &:nth-child(odd) {
            background: $color-very-light-gray;
          }
        }
        .tier-1 {
          .loyalty__market__status__content__empty {
            background: $color-marine;
          }
          .replenishment__text {
            color: $color-marine;
          }
        }
        .tier-2 {
          .loyalty__market__status__content__empty {
            background: $color-aquamarine;
          }
          .replenishment__text {
            color: $color-aquamarine;
          }
        }
        .tier-3 {
          .loyalty__market__status__content__empty {
            background: $color-ultramarine;
          }
          .replenishment__text {
            color: $color-ultramarine;
          }
        }
      }
    }
    &__footer {
      @include swap_direction(padding, 40px 20px);
      text-align: center;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 4% 0);
      }
      &__question {
        font-size: 20px;
        margin-bottom: 10px;
        font-family: $font--subheading;
        @include breakpoint($landscape-up) {
          font-size: 25px;
          font-weight: bold;
        }
      }
      &__info {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: $font--text;
        @include breakpoint($landscape-up) {
          font-family: $font--subheading;
          font-size: 16px;
        }
      }
      &__visit {
        @include swap_direction(padding, 0 5px 2px 0);
        border-bottom: 1px solid $color-black;
        text-decoration: none;
      }
    }
  }
  &_join_signin {
    &_wrapper {
      @include swap_direction(padding, 45px 22px);
      background: $color-aliceblue;
      text-align: center;
      .loyalty_member__button {
        @include marketing-button;
      }
    }
    &__subheader {
      @include swap_direction(padding, 0 0 10px 0);
      font-size: 17px;
      @include breakpoint($landscape-up) {
        @include swap_direction(padding, 0 20% 10px 20%);
      }
    }
    &__button {
      @include marketing-button;
    }
    &__footer {
      font-family: $font--text;
      font-size: 13px;
    }
  }
}
