.social_login_v1 {
  &__delimeter {
    position: relative;
    clear: both;
    @include breakpoint($medium-up) {
      display: block;
    }
  }
  &__delimeter-horizontal {
    margin: 15px auto 10px;
    position: relative;
    line-height: 15px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    &:before {
      content: '';
      background: $color-light-gray;
      width: 100%;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: 6px;
      #{$ldirection}: 0;
    }
  }
  &__delimeter-label {
    padding: 0 7px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2em;
    text-align: center;
    text-transform: uppercase;
    background: $white;
    position: relative;
    color: $color-light-gray;
  }
  &__social-login-container {
    text-align: center;
  }
}

.social-login-container {
  &__social-network {
    margin: 10px 22px 0;
    width: 60px;
    height: 60px;
    display: inline-block;
    position: relative;
    @include breakpoint($medium-up) {
      width: 264px;
      height: 40px;
    }
  }
  &__social-network-blocker {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    #{$ldirection}: 0;
    cursor: pointer;
    &_hidden {
      display: none;
    }
  }
  &__social-network-button {
    width: 100%;
    height: 100%;
    letter-spacing: 0.2px;
    border: none;
    line-height: 2;
    text-decoration: none;
    color: $white;
    font-size: 14px;
    background: $color-vkontakte url(/media/images/social_login/vk_icon.svg) 10px center no-repeat;
    display: block;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    @include breakpoint($medium-up) {
      background-size: 40px 40px;
      background-position-x: 10px;
    }
    &-label {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
  &__social-network-button:visited {
    color: $white;
  }
  &__social-network-button_facebook {
    background: $color-facebook url(/media/images/social_login/fb_icon.svg) 4px no-repeat;
    background-size: 49px 60px;
    @include breakpoint($medium-up) {
      background-size: 40px 40px;
    }
  }
  &__social-network-button_vkontakte {
    background-size: 40px 27px;
  }
}

.sign-in-component_ru {
  &__legal-container {
    text-align: #{$ldirection};
    padding-#{$ldirection}: 10px;
    margin: 25px 0 30px;
    .legal-container {
      &__acceptance-block {
        margin-top: 22px;
        font-size: 13px;
        @include breakpoint($medium-up) {
          margin-top: 12px;
          font-size: 14px;
          a {
            text-decoration: underline;
          }
        }
      }
      &__acceptance-block_error {
        color: $color-red;
        label,
        a {
          color: $color-red;
          :hover {
            color: $color-red;
          }
        }
      }
    }
  }
}

.sign-in-page {
  .social_login_v1 {
    &__delimeter {
      display: block;
      @include breakpoint($large-up) {
        display: none;
      }
    }
  }
}

.sign-in {
  .social-login-container {
    &__social-network {
      @include breakpoint($medium-up) {
        width: 60px;
        height: 60px;
      }
      &-label {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  .sign-in-component_ru {
    &__legal-container {
      text-align: $ldirection;
      .legal-container {
        &__acceptance-block {
          font-size: 11px;
          text-align: center;
        }
      }
    }
  }
}

.social-info__vk {
  margin-top: 20px;
  .vkontakte-login {
    @include swap_direction(padding, 0 9px 0 30px);
    display: inline-block;
    background: $color-vkontakte url(/media/images/social_login/vk_icon_21x21.png) 5px no-repeat;
    background-size: 18px;
    height: 21px;
    font-size: 8px;
    letter-spacing: 0.5px;
    border-radius: 4px;
    border: none;
    line-height: 3px;
    width: 170px;
    margin-#{$ldirection}: 20px;
  }
  #vkontakte_disconnect {
    display: inline-block;
    vertical-align: middle;
    margin-#{$ldirection}: 20px;
    .form-submit {
      margin: 0;
    }
  }
}
