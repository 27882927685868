.foundation_finder_results_wrapper {
  display: none;
}

.ff-results {
  text-align: center;
}

#foreground-node.overlay-container {
  overflow: visible;
}

.ff-results {
  #pg_wrapper .ff-results h1,
  #pg_wrapper .ff-results h2,
  #pg_wrapper .ff-results h3,
  #pg_wrapper .ff-results h4,
  #pg_wrapper .ff-results h5,
  #pg_wrapper .ff-results h6 {
    font-family: LinotypeDidotRoman;
  }
  #sidebar_account {
    float: left;
    width: 180px;
    margin-right: 25px;
  }
  .ff-results--container {
    margin: 0 auto;
  }
  .ff-results--container {
    width: 85%;
    @include breakpoint($medium-up) {
      width: 90%;
    }
  }
  .ff-results-buttons,
  #pg_wrapper .ff-results-buttons {
    margin-top: 40px;
    display: block;
  }
  .ff-button,
  #pg_wrapper .ff-button {
    width: 30%;
    height: 3em;
    margin-right: 3%;
    float: left;
    clear: none;
    border: solid black 1px;
    text-transform: uppercase;
    line-height: 2.7em;
    font-size: 10px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .ff-button--start-over {
    display: none;
  }
  #pg_wrapper .ff-button--start-over {
    display: block;
  }
  #pg_wrapper .ff-button {
    width: 47%;
    margin-right: 2%;
  }
  @media only screen and (min-width: 641px) {
    .ff-button,
    #pg_wrapper .ff-button {
      font-size: 18px;
      letter-spacing: 0;
    }
  }
  .ff-results__headline,
  .ff-results__primary_headline,
  #pg_wrapper .ff-results__headline,
  #pg_wrapper .ff-results__primary_headline {
    padding-top: 60px;
    margin-bottom: 0.2em;
    font-size: 30px;
    border: none;
  }
  @media only screen and (min-width: 641px) {
    .ff-results__headline,
    .ff-results__primary_headline,
    #pg_wrapper .ff-results__headline,
    #pg_wrapper .ff-results__primary_headline {
      font-size: 45px;
    }
  }
  .ff-results__secondary_headline,
  #pg_wrapper .ff-results__secondary_headline {
    font-size: 30px;
    padding-top: 60px;
    margin-bottom: 0.2em;
    border: none;
  }
  .ff-results__subheadline,
  .ff-results__primary_subheadline,
  .ff-results__secondary_subheadline {
    font-size: 22px;
    font-style: italic;
    text-transform: none;
  }
  .ff-results__quiz_results_text,
  #pg_wrapper .ff-results__quiz_results_text {
    font-size: 16px;
    text-transform: none;
    padding-top: 10px;
  }
  .ff-results__subheadline,
  .ff-results__secondary_subheadline,
  .ff-results__quiz_results_text {
    margin-bottom: 50px;
  }
  .foundation-finder__results-product--image,
  #pg_wrapper .foundation-finder__results-product--image {
    margin-bottom: 30px;
  }
  .foundation-finder__results-product--shade {
    font-size: 18px;
  }
  .foundation-finder__results-product--price,
  #pg_wrapper .foundation-finder__results-product--price {
    font-size: 18px;
    margin: 10px 0;
  }
  .foundation-finder__results-product--unitprice,
  #pg_wrapper .foundation-finder__results-product--unitprice {
    display: none;
  }
  .foundation-finder__results-product--quick-shop {
    position: absolute;
  }
  .ff-results__secondary-products {
    margin: 0 auto;
    padding-bottom: 150px;
    width: 70%;
  }
  .device-mobile .ff-results__secondary-products {
    padding-bottom: 73px;
  }
  #pg_wrapper .ff-results__secondary-products {
    width: 100%;
  }
  @media only screen and (max-width: 641px) {
    .ff-results__secondary-products {
      width: 100%;
    }
  }
  @media only screen and (max-width: 641px) {
    .ff-results__primary-shade .foundation-finder__results-product--image img {
      width: 100%;
      margin-left: 10px;
    }
    .ff-results__secondary-products .foundation-finder__results-product--image img {
      width: 100%;
      left: 7px;
      position: relative;
    }
  }
  .ff-results__recomended-shade {
    position: relative;
  }
  .foundation-finder__results-product--quick-shop {
    position: relative;
    top: -200px;
    visibility: hidden;
    display: inline-block;
  }
  #pg_wrapper .foundation-finder__results-product--quick-shop {
    line-height: 1;
    padding: 0.6em 1.538em 0.4em;
  }
  .ff-results__recomended-shade:hover .foundation-finder__results-product--quick-shop {
    visibility: visible;
  }
  .device-mobile .foundation-finder__results-product--quick-shop,
  .device-mobile .ff-results__recomended-shade:hover .foundation-finder__results-product--quick-shop {
    visibility: hidden;
    display: none;
  }
  .ff-results__secondary-products .ff-results__recomended-shade {
    float: left;
    clear: none;
    width: 42%;
  }
  .ff-results__recomended-shade .foundation-finder__results-product--add-to-bag,
  .ff-results__recomended-shade .foundation-finder__results-product--waitlist-btn,
  .ff-results__recomended-shade .foundation-finder__results-product--sold_out-btn,
  .ff-results__recomended-shade .foundation-finder__results-product--out_of_stock-btn,
  .ff-results__recomended-shade .foundation-finder__results-product--coming_soon_stock-btn {
    width: 100%;
  }
  @media only screen and (min-width: 641px) {
    .ff-results__recomended-shade .foundation-finder__results-product--add-to-bag,
    .ff-results__recomended-shade .foundation-finder__results-product--waitlist-btn,
    .ff-results__recomended-shade .foundation-finder__results-product--sold_out-btn,
    .ff-results__recomended-shade .foundation-finder__results-product--out_of_stock-btn,
    .ff-results__recomended-shade .foundation-finder__results-product--coming_soon_stock-btn {
      width: 110px;
      display: block;
      margin: 0 auto;
    }
  }
  .ff-results__spacer--wrapper {
    width: 8%;
    float: left;
    clear: none;
    position: relative;
    top: 50px;
  }
  .ff-results__spacer {
    width: 1px;
    height: 119px;
  }
  @media only screen and (min-width: 641px) {
    .ff-results__secondary-products .ff-results__recomended-shade {
      width: 48%;
    }
    .ff-results__spacer--wrapper {
      width: 2%;
    }
    .ff-results__spacer {
      background-color: $color-black;
    }
  }
  /* share overlay */

  .ff-results-share {
    background: white;
    text-align: center;
    height: 275px;
  }
  .foundation_finder_results_wrapper .product-4-up,
  div.product-4-up.basic-block,
  .foundation_finder_results_wrapper .product-4-up.basic-block {
    background: none;
  }
  .device-mobile .foundation_finder_results_wrapper .product-4-up .products-container {
    width: 80%;
  }
  .device-mobile .product_carousel_1up .prod-shot-wrapper .btn-wrapper {
    bottom: -22px;
  }
  .device-mobile .product_carousel_1up .prod-shot-wrapper .btn-wrapper a.btn-reg {
    font-size: 1.1em;
  }
  .looks-to-love .carousel-stage .product-details .add-all-to-bag {
    width: 178px;
    height: 2em;
  }
  /* beauty by lamer 4up */
  .product-4-up.basic-block .mod-header {
    font-size: 2em;
  }
  .product-4-up .product:hover .prod-shot-wrapper .btn-wrapper .quick-shop-button {
    font-size: 0.5em;
  }
  .foundation_finder_results_wrapper .product-4-up .prod-name {
    font-size: 0.3em;
  }
  .foundation_finder_results_wrapper .product-4-up .prod-heads {
    padding: 0;
  }
  @media only screen and (min-width: 641px) {
    .product-4-up.basic-block .mod-header {
      font-size: 4.45em;
    }
    .product-4-up .product:hover .prod-shot-wrapper .btn-wrapper .quick-shop-button {
      font-size: 1.231em;
    }
    .foundation_finder_results_wrapper .product-4-up .prod-name {
      font-size: 1em;
    }
    .product-4-up .products-container {
      min-height: 160px;
    }
  }
  .page-products.device-mobile.foundation-finder-page #banner,
  .page-product.device-mobile.foundation-finder-page #banner {
    display: none;
  }
}

.ff-results-share__cta-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 365px;
  @include breakpoint($medium-up) {
    display: block;
  }
}

h2.ff-results-share__title {
  margin-top: 25px;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
  @include breakpoint($medium-up) {
    margin-top: 50px;
    font-size: 30px;
  }
}

h5.ff-results-share__cta-title {
  font-size: 20px;
  margin-bottom: 1em;
  text-transform: none;
  @include breakpoint($medium-up) {
    font-size: 16px;
  }
}

.ff-results-share__cta-email-error {
  color: $color-error;
  display: none;
  padding-bottom: 0.5em;
}

input.ff-results-share__cta-email-address {
  width: 80%;
  margin-bottom: 25px;
  text-align: center;
  padding: 3px 0;
  font-size: 16px;
  @include breakpoint($medium-up) {
    width: 314px;
  }
}

.ff-results-share__cta-email-submit {
  margin-top: 1em;
  width: 80%;
  @include breakpoint($medium-up) {
    width: 110px;
  }
}

.ff-results-share__cta {
  text-align: center;
  @include breakpoint($medium-up) {
    float: left;
    clear: none;
    width: 49%;
  }
}

.ff-results-share__cta-email {
  position: relative;
  top: 10px;
  order: 3;
  @include breakpoint($medium-up) {
    top: 0;
  }
}

.ff-results-share__cta-email-sent {
  position: relative;
  top: 0;
}

.ff-results-share__cta-social-media {
  position: relative;
  order: 1;
  @include breakpoint($medium-up) {
    top: 0;
  }
}

.ff-results-share__cta-spacer {
  height: 1px;
  border-top: solid black 1px;
  width: 60%;
  margin: 0 auto;
  position: relative;
  order: 2;
  @include breakpoint($medium-up) {
    width: 1px;
    border-left: solid black 1px;
    height: 150px;
    border-top: none;
    top: 0;
  }
}

.ff-results-share__cta-email-sent {
  display: none;
}

.ff-results-share__social-icons {
  width: 104px;
  margin: 0 auto;
  cursor: pointer;
}

.ff-results-share__icon {
  width: 45px;
  height: 44px;
  float: left;
  clear: none;
  @include breakpoint($medium-up) {
    width: auto;
  }
}

.ff-results-share__icon img {
  width: 65%;
}

.ff-results-share__facebook_icon {
  margin-right: 9px;
}

.ff-results-save-error {
  padding: 50px;
  text-align: center;
  text-transform: none;
}

.ff-results-save-alert {
  padding: 50px;
  text-transform: none;
  text-align: center;
}

//PG specific css
#pg_wrapper {
  .ff-button--save-to-profile {
    display: none;
  }
  .ff-results {
    display: block;
    padding-bottom: 50px;
    background: $color-sand;
    .ff-button {
      width: 47%;
    }
  }
  .content {
    float: left;
    width: 100%;
    margin-top: 10px;
    @include breakpoint($medium-up) {
      width: 900px;
    }
  }
  .ff-quiz__inner {
    display: none;
  }
  .ff-results--container {
    margin: 0 auto;
  }
}
