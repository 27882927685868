.sign-in-page {
  .new-account {
    .form-item {
      &.email-promo {
        .label-content {
          display: inline-block;
          width: 90%;
          vertical-align: top;
          font-size: 13px;
        }
      }
    }
    .new-account {
      &__item {
        &__brand-logo {
          height: 50px;
          width: 100%;
          display: inline-block;
          background: url(/media/export/cms/loyalty/signin_waves_de_lamer_logo_mobile.png) no-repeat
            center center;
          background-size: 100px;
          @include breakpoint($landscape-up) {
            background: url(/media/export/cms/loyalty/signin_waves_de_lamer_logo_pc.png) no-repeat
              center center;
          }
        }
      }
    }
  }
  .social-login {
    &__email-opt-in {
      display: none;
    }
    &__terms {
      display: none;
    }
  }
}
//Gnav overlay Changes
.site-header__utility {
  .gnav-util__content--account {
    height: auto;
    overflow-y: hidden;
    top: auto;
  }
  &-right {
    background: $color-white;
  }
}
.profile-page {
  .sms-promotions {
    display: none;
  }
}
