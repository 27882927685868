body > .wrapper {
  padding: 0;
}

.ff-quiz__inner {
  padding: 25px 0 0 0;
  max-width: 1064px;
  margin: 0 auto;
  position: relative;
  font-family: didot;
  padding: 20px 20px 0px 20px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner {
    min-height: 508px;
  }
}

.ff-quiz__inner a .ff-quiz__headline--tertiary,
.no-touch .ff-quiz__inner a .ff-quiz__headline--tertiary:hover {
  color: black;
  text-decoration: none;
  filter: alpha(opacity=100);
  opacity: 1;
}

.ff-quiz__inner h2.ff-quiz__edit-header {
  text-align: center;
  padding: 0 0 20px 0;
  position: relative;
  z-index: 2;
}

.ff-quiz__inner .ff-quiz__carousel-wrapper {
  position: relative;
}

.ff-quiz__inner .ff-quiz__question-title,
.ff-quiz__inner .ff-quiz__answer-wrapper {
  position: absolute;
  width: 100%;
}

.ff-quiz__inner .ff-quiz__answer-wrapper {
  top: 0;
}

.ff-quiz__inner .ff-question__question-title {
  position: relative;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__question-title,
  .ff-quiz__inner .ff-question__question-title,
  .ff-quiz__inner .ff-quiz__answer-wrapper {
    position: relative;
  }
}

.ff-question__question-title {
  text-align: center;
  margin-bottom: 40px;
}

.ff-question__headline {
  margin-bottom: 0;
  font-size: 16px;
}

.ff-question__headline--secondary {
  text-transform: none;
  font-size: 10px;
}

@media only screen and (min-width: 641px) {
  .ff-question__headline {
    font-size: 28px;
  }
  .ff-question__headline--secondary {
    font-size: 14px;
  }
}

.ff-quiz__inner h2.ff-quiz__edit-headline {
  font-size: 16px;
  text-align: center;
  font-style: italic;
  margin: 0;
}

.ff-quiz__inner .ff-quiz__edit-header .ff-quiz__headline--secondary {
  font-size: 22px;
}

.ff-quiz__inner .ff-quiz__edit-header .ff-quiz__headline {
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 30px;
  margin: 0 0 4px 0;
}

.ff-quiz__inner .ff-quiz__carousel {
  margin: 0 !important;
  position: relative;
}

.ff-quiz__inner .ff-quiz__slide {
  position: relative;
  padding: 25px 0 0 0;
  width: 100% !important;
  overflow: hidden;
  min-height: 300px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide {
    min-height: 450px;
  }
}

.ff-quiz__inner .ff-quiz__slide a {
  font-style: normal;
  text-decoration: none;
}

.ff-quiz__inner .ff-quiz__headline {
  font-size: 18px;
  line-height: 1em;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 0 0 0.5em 0;
  padding: 0 8%;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__headline {
    font-size: 28px;
    margin: 0 0 0.7em 0;
  }
}

.ff-quiz__inner .ff-quiz__headline--secondary {
  letter-spacing: -0.02em;
  text-align: center;
  font-size: 12px;
  line-height: 1em;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__headline--secondary {
    font-size: 20px;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 641px) and (min-height: 800px) {
  .ff-quiz__inner .ff-quiz__headline--secondary {
    font-size: 24px;
  }
}

.ff-quiz__inner .ff-quiz__description {
  line-height: 22px;
}

.ff-quiz__inner .ff-quiz__question {
  padding: 0 1%;
  margin: 0;
}

.ff-quiz__inner .ff-quiz__question > li {
  float: left;
  width: 16%;
  text-align: center;
  line-height: 1.5em;
}

.ff-quiz__inner .ff-quiz__answer.active {
  filter: alpha(opacity=100);
  opacity: 1;
}

.ff-quiz__inner .ff-quiz__answer.active .ff-quiz__checkbox {
  background-position: 0 0;
}

.ff-quiz__inner .ff-quiz__button--results .ff-quiz__button-text--results {
  display: inline;
}

.ff-quiz__inner .ff-quiz__edit-close {
  position: absolute;
  top: 20px;
  right: 0;
  cursor: pointer;
  display: none;
}

.ff-quiz--edit .ff-quiz__inner .ff-quiz__edit-close {
  display: block;
}

.ff-quiz__inner .ff-quiz__filter-button-wrapper {
  position: absolute;
  left: 0;
  bottom: 20px;
  text-align: center;
  width: 100%;
  display: none;
}

.ff-quiz--edit .ff-quiz__inner .ff-quiz__filter-button-wrapper {
  display: block;
}

.ff-quiz__inner .ff-quiz__button--next,
.ff-quiz__inner .ff-quiz__button--prev {
  display: inline-block;
}

.ff-quiz__footer-container a.inactive {
  filter: alpha(opacity=30);
  opacity: 0.3;
  cursor: default;
}

.no-touch .ff-quiz__footer-container a:hover {
  color: black;
  text-decoration: underline;
}

.ff-quiz__inner .ff-quiz__button-text--spacer {
  display: inline-block;
  padding: 0 20px;
}

.ff-quiz__inner .ff-quiz__footer-container {
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

.ff-quiz__inner .ff-quiz__link--help {
  position: absolute;
  bottom: 30px;
}

.ff-quiz__inner .ff-quiz__start-over-link,
.ff-quiz__inner .ff-quiz__link--help {
  letter-spacing: 0.1em;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid $color-black;
  padding-bottom: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-quiz__inner .ff-quiz__slider {
  margin: 0 auto 30px auto;
  width: 83%;
  position: relative;
  z-index: 100;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer {
  cursor: pointer;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-question__question-title {
  top: 100px;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer_dot {
  width: 10px;
  height: 10px;
  z-index: 1000;
  margin: 0 auto 10px auto;
  border-radius: 100%;
  visibility: hidden;
  z-index: 10000;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer_dot {
    margin-bottom: 19px;
  }
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__answer-wrapper,
  .ff-quiz__inner .ff-quiz__slide--1 .ff-question__question-title {
    top: 10px;
  }
}

@media only screen and (min-width: 641px) {
  .no-touch .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer:hover .ff-quiz__answer_dot {
    visibility: visible;
    background: $color-black;
  }
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer--active .ff-quiz__answer_dot,
.no-touch .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer--active:hover .ff-quiz__answer_dot {
  visibility: visible;
  background: $color-black;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__coverage-stage {
  /* margin-top: -10px; */
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__coverage-stage {
    margin-top: -10px;
  }
}

.ff-quiz__inner .ff-quiz__coverage-image {
  top: -40px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__coverage-image {
    top: 0;
  }
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question > li {
  background: url('/media/export/cms/foundation-finder/sliderui-marker.png') no-repeat 50% 0;
  font-style: italic;
  padding: 0;
  margin: 0 5px;
  text-align: center;
  width: 45px;
  float: none;
  display: inline-block;
  vertical-align: top;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question > li:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question > li {
    margin: 0 2.6%;
    width: auto;
  }
}

.ff-quiz__slide--2 .ff-quiz__question {
  top: -130px;
  position: relative;
}

.ff-quiz__slide--2 .ff-question__question-title {
  position: relative;
  top: 120px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__slide--2 .ff-quiz__question {
    top: 0;
  }
  .ff-quiz__slide--2 .ff-question__question-title {
    top: 0;
  }
}

.ff-quiz__slide--2 .ff-question__question-title {
  margin-bottom: 0;
}

.ff-quiz__inner .ff-quiz__slide--2 {
  padding-top: 0;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide--2 {
    padding-top: 25px;
  }
}

.ff-quiz__inner .ff-quiz__slide--2 ul {
  height: 220px;
  margin-left: 3%;
}

.ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__question > li {
  position: relative;
  top: 60px;
  width: 33%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
}

.ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__headline--secondary {
  color: white;
  text-decoration: none;
  font-style: normal;
  position: relative;
  top: 55%;
  margin-top: -1em;
}

.ff-quiz__inner .ff-quiz__slide--2 ul {
  height: 170px;
  width: 300px;
  margin: 0 auto;
}

.ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__question > li {
  top: 75px;
  width: 85px;
  margin: 5px;
}

.ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__shadebox {
  display: block;
  filter: alpha(opacity=30);
  opacity: 0.3;
  height: 75px;
  border: #f7efec 2px solid;
  outline: none;
  transition: transform 0.25s;
}

.no-touch .ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__shadebox:hover,
.ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__answer--active {
  filter: alpha(opacity=100);
  opacity: 1;
  padding: 0;
  transform: scale(1.1);
  border-color: white;
}

.ff-quiz__inner .ff-quiz__coverage-stage,
.ff-quiz__inner .ff-quiz__intensity-stage {
  position: relative;
  height: 200px;
  margin: 0 auto 5px auto;
  text-align: center;
}

.ff-quiz__inner .ff-quiz__coverage-stage {
  height: 120px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__coverage-stage {
    height: 200px;
  }
}

.ff-quiz__inner .ff-quiz__coverage-image,
.ff-quiz__inner .ff-quiz__intensity-image {
  position: absolute;
  width: 100%;
  font-size: 180px;
  line-height: 170px;
  letter-spacing: -0.05em;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ff-quiz__coverage-image--slide img {
  width: 98%;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__slider {
  margin: 0 auto 30px auto;
  position: relative;
  z-index: 100;
  border-top: #a68e72 1px solid;
  top: -145px;
}

.ff-quiz__inner .ff-quiz__headline--tertiary {
  font-size: 9px;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2em;
  height: 2em;
}

.ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question {
  padding: 0 5%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 1000;
  top: -105px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question {
    top: -120px;
  }
}

.ff-quiz__inner .ff-quiz__footer {
  padding: 13px 0 0;
  position: relative;
  width: 100%;
  left: 0;
  top: -70px;
  font-family: times;
  text-transform: uppercase;
  display: block;
  font-size: 12px;
  text-align: center;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__coverage-image--slide img {
    width: 512px;
  }
  .ff-quiz__inner h2.ff-quiz__edit-headline {
    font-size: 20px;
  }
  .ff-quiz__inner .ff-quiz__edit-header .ff-quiz__headline--secondary {
    font-size: 36px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__slider {
    width: 400px;
    top: -193px;
    left: -1.3%;
  }
  .ff-quiz__inner .ff-quiz__headline--tertiary {
    font-size: 10px;
  }
  .ff-quiz__inner .ff-quiz__slide--2 ul {
    height: 170px;
    width: 651px;
    margin: 0 auto;
  }
  .ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__question > li {
    top: 70px;
    width: 170px;
    margin: 20px;
  }
  .ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__shadebox {
    width: 150px;
    height: 150px;
    border-width: 10px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer_dot {
    width: 15px;
    height: 15px;
    margin-bottom: 15px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question {
    top: -147px;
    width: 520px;
  }
  .ff-quiz__inner .ff-quiz__footer {
    top: -150px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 832px) and (min-height: 710px) {
  .ff-quiz__coverage-image--slide img {
    width: 770px;
  }
  .ff-quiz__inner h2.ff-quiz__edit-headline {
    font-size: 26px;
  }
  .ff-quiz__inner .ff-quiz__edit-header .ff-quiz__headline--secondary {
    font-size: 42px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__slider {
    width: 600px;
    top: -177px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__answer_dot {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
  }
  .ff-quiz__inner .ff-quiz__headline--tertiary {
    font-size: 11px;
  }
  .ff-quiz__inner .ff-quiz__slide--2 ul {
    height: 220px;
    width: 805px;
    margin: 0 auto;
  }
  .ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__question > li {
    top: 110px;
    width: 220px;
    margin: 20px;
  }
  .ff-quiz__inner .ff-quiz__slide--2 .ff-quiz__shadebox {
    width: 200px;
    height: 200px;
    border-width: 10px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question {
    top: -116px;
    width: 770px;
  }
  .ff-quiz__inner .ff-quiz__footer {
    top: -30px;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1010px) and (min-height: 800px) {
  .ff-quiz__inner h2.ff-quiz__edit-headline {
    font-size: 30px;
  }
  .ff-quiz__inner .ff-quiz__edit-header .ff-quiz__headline--secondary {
    font-size: 55px;
  }
  .ff-quiz__coverage-image--slide img {
    width: auto;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__slider {
    width: 770px;
    top: -151px;
  }
  .ff-quiz__inner .ff-quiz__headline--tertiary {
    font-size: 15px;
  }
  .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__question {
    top: -82px;
    width: 1018px;
  }
  .ff-quiz__inner .ff-quiz__footer {
    top: -30px;
  }
}

.ff-quiz--edit .ff-quiz__inner .ff-quiz__nav-value {
  padding-right: 13px;
  left: 7px;
  background: url('/media/export/cms/foundation-finder/arrow-small_down.png') no-repeat right 4px;
}

.ff-quiz--edit .ff-quiz__inner .ff-quiz__nav-item.active .ff-quiz__nav-value {
  background: url('/media/export/cms/foundation-finder/arrow-small_up.png') no-repeat right 3px;
}

.ff-quiz__inner .ff-quiz__carousel-wrapper {
  position: relative;
  overflow: hidden;
  height: 300px;
}

@media only screen and (min-width: 641px) {
  .ff-quiz__inner .ff-quiz__carousel-wrapper {
    height: 450px;
  }
}

.ff-quiz-matchmaking-footer--wrapper {
  position: relative;
  width: 100%;
  top: -40px;
}

.ff-quiz-matchmaking-footer--box {
  width: 100%;
  background-color: $color-white;
  margin: 0 auto;
  background-image: url('/media/export/cms/foundation_finder/matchmaking_on_us_heart.png');
  background-size: 18px;
  letter-spacing: -0.2px;
  background-position: center 1em;
  background-repeat: no-repeat;
  position: relative;
  transition: height 0.5s;
  overflow: hidden;
  height: 120px;
  padding-top: 30px;
}

.ff-quiz-matchmaking-footer {
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  width: 80%;
  margin: 0 auto;
  line-height: 1.2em;
  padding: 0.7em;
  padding-bottom: 0;
}

.ff-quiz-matchmaking-footer--close {
  background: url('/sites/lamer/modules/custom/lm_product/libraries/products/spp-help-close.png');
  width: 9px;
  height: 9px;
  right: 0;
  position: absolute;
  margin: -9px 9px;
  background-repeat: no-repeat;
  cursor: pointer;
  display: none; /* just for mobile */
  z-index: 10000;
}

.ff-quiz-matchmaking-footer--copy {
  font-size: 10px;
  font-family: 'LinotypeDidotRoman';
  margin-top: 0.8em;
  padding: 0 10px;
  text-align: center;
}
/* footer PC specific */
@media only screen and (min-width: 641px) {
  .ff-quiz-matchmaking-footer--wrapper {
    top: auto;
    bottom: 0;
    position: absolute;
  }
  .ff-quiz-matchmaking-footer--box {
    width: 320px;
    height: 88px;
    padding: 0;
    background-position: 1em 1.35em;
    background-size: auto;
    cursor: pointer;
    left: -2%;
  }
  .ff-quiz-matchmaking-footer--box.active {
    height: 185px;
  }
  .ff-quiz-matchmaking-footer {
    font-size: 24px;
  }
  .ff-quiz-matchmaking-footer--close {
    display: block;
  }
  .ff-quiz-matchmaking-footer--copy {
    font-size: 16px;
  }
}

.page-products.device-mobile.foundation-finder-page #banner,
.page-product.device-mobile.foundation-finder-page #banner {
  display: none;
}
