@mixin opc_update__checkout-progress-bar {
  .checkout-progress-bar {
    margin: 0;
    overflow: hidden;
    margin-bottom: 16px;
    margin-top: 16px;
    border-bottom: 1px solid $color-light-gray;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
    &__list {
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 100%;
      list-style-type: none;
      text-align: center;
    } // &__list

    &__list-item {
      position: relative;
      color: $color-light-gray;
      display: inline-block;
      padding: 0 20px;
      @include breakpoint($landscape-up) {
        margin: 0 20px;
      }
      a {
        color: $color-light-gray;
        text-decoration: none;
        line-height: 27px;
        cursor: default;
        text-transform: uppercase;
      }
      &--complete {
        a {
          cursor: pointer;
        }
      }
      &--current {
        border-bottom: 1px solid $color-black;
        a {
          color: $color-black;
          cursor: pointer;
        }
      }
    } // &__list-item
  } // .checkout-progress-bar
} // @mixin opc_update__checkout-progress-bar

@include opc_update__checkout-progress-bar;

header.checkout-index-header,
section.collapsed,
section.disabled,
section.checkout-panel--shipping,
section.checkout-panel--payment,
section.checkout-panel--registration {
  display: none;
  &.active {
    display: block;
  }
}

.checkout-single-page {
  .checkout-panel .continue-button-wrapper {
    text-align: center;
    @include breakpoint($landscape-up) {
      text-align: right;
    }
  }
}

.active-panel-review {
  section.checkout-panel--shipping,
  section.checkout-panel--payment,
  section.checkout-panel--registration {
    display: block;
  }
}
