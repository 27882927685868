.product-full {
  margin: 0 7px;
  @include breakpoint($landscape-up) {
    margin: 0 15px;
  }
  &__container {
    max-width: $max-width;
    position: relative;
    margin: 0 auto 10px auto;
    padding: 25px 0 0px 0;
    @include breakpoint($medium-up) {
      margin: 0 auto 40px auto;
      padding: 25px 0 10px 0;
    }
    @include breakpoint($landscape-up) {
      @include pie-clearfix;
      padding: 0;
    }
    .product-full--black & {
      .product-flag__badge {
        border: 1px solid $color-white;
      }
    }
  }
  // accordion
  &__accordion {
    &__container {
      margin: 0px 0 5px 0;
      @include breakpoint($landscape-up) {
        margin: 0px 0 15px 0;
      }
    }
  }
  &.product-bg-image {
    background: no-repeat center;
    background-size: cover;
  }
  &__ndd_timer {
    background-color: $color-lightest-grey;
    .product-ndd-countdown {
      @include swap_direction(padding, 10px);
      @include swap_direction(margin-bottom, 10px);
      display: none;
      p {
        display: inline;
      }
      .hours,
      .minutes {
        font-weight: bold;
      }
    }
  }
  &__iln-disclaimer {
    padding-top: 10px;
    font-style: italic;
    display: none;
    &.has-ingredients {
      &.has-disclaimer {
        display: block;
      }
    }

  }
}

.product-full__meta {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
    padding: 23px 30px 0 30px;
  }
}

.product-breadcrumb {
  opacity: 0.4;
  letter-spacing: 0.05em;
  font-size: 12px;
  text-transform: uppercase;
  .product-full--black & {
    color: $color-white;
  }
  a.product-breadcrumb__link {
    text-decoration: none;
    &:hover {
      color: $color-black;
    }
    .product-full--black & {
      color: $color-white;
    }
  }
}

.product-full__content {
  position: relative;
  &-wrapper {
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  @include breakpoint($landscape-up) {
    float: left;
    width: 36.29032%;
    margin: 0 4.43548% 0 0;
    padding: 0 0 40px 0;
  }
}

.product-full__content-wrapper {
  @include breakpoint($landscape-up) {
    max-width: 410px;
  }
}

.product-full__name,
.product-full__subline {
  @include font--subheading();
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  @include breakpoint($landscape-up) {
    font-size: 30px;
    line-height: 34px;
    text-align: left;
    float: right;
    width: 36.29032%;
    margin: 0 4.43548% 0 0;
    padding: 5px 0 0 0;
  }
}

.product-full__desc {
  @include font--subheading();
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  width: 80%;
  margin: 0 auto 15px auto;
  @include breakpoint($landscape-up) {
    text-align: left;
    width: 100%;
    margin-top: 5px;
  }
}

.product-full__price-wrapper {
  @include pie-clearfix;
  border-top: 1px solid $color-border;
  height: 20px;
  line-height: 43px;
  @include breakpoint($landscape-up) {
    border-color: #e2e1e1; // @TODO: check with design if this is intentional
  }
}

.product-full__price {
  float: left;
}

.product-full__rating {
  float: right;
  .pr-review-snippet-container {
    line-height: 0px;
  }
  .pr-snippet-read-and-write {
    line-height: 20px;
  }
}

.product-full__cta {
  display: none;
  @include breakpoint($landscape-up) {
    text-align: left;
    margin: 0 0 5px 0;
    display: inline-block;
    .button {
      width: auto;
    }
  }
}

.product-full__social {
  position: absolute;
  bottom: 30px;
  right: 20px;
  @include breakpoint($landscape-up) {
    right: 40px;
    bottom: 80px;
  }
}

// bg colors
.product-full {
  &--white {
    background: $color-white;
  }
  &--black {
    @include font-smoothing(true);
    background: $color-black;
    color: $color-white;
    a {
      color: $color-white;
      .icon--facebook {
        fill: $color-white;
        border: 1px $color-white solid;
        &:hover {
          fill: $color-black;
          border: 1px $color-green solid;
        }
      }
    }
    .button--active {
      color: $color-black;
    }
    .product-replenishment-select {
      // on AR select
      .button {
        @if $cr22 {
          background: $color-light-black;
          border: none;
          color: $color-white;
        } @else {
          background: $color-black;
          color: $color-white;
        }
        &--active {
          @if $cr22 {
            background: $color-light-black;
            color: $color-white;
          } @else {
            background: $color-white;
            color: $color-black;
          }
        }
        &:hover {
          @if $cr22 {
            border: none;
          } @else {
            border: 1px solid $color-white;
          }
        }
      }
      &__overlay-link:hover {
        color: $color-white;
      }

      // select box
      .selectBox.product-replenishment-select__select {
        // onload
        color: $color-white;
        @if $cr22 {
          background: $color-light-black;
          border: none;
        } @else {
          background: $color-black;
        }
        .selectBox-arrow:after {
          color: $color-white;
        }
        // open and selected
        &.selectBox-menuShowing,
        &.selectBox-menuActive {
          @if $cr22 {
            background: $color-light-black;
            border: none;
            color: $color-white;
          } @else {
            background: $color-white;
            color: $color-black;
          }
          .selectBox-arrow:after {
            @if $cr22 {
              color: $color-white;
            } @else {
              color: $color-black;
            }
          }
        }
      }
    }
  }
  &--light-green {
    background: $color-light-green;
  }
  &--light-sand {
    background: $color-light-sand;
  }
  &--light-blue {
    background: $color-light-blue;
  }
}

.product-full__accordion__title,
.product-full__mobile-panel__title {
  @include accordion-title;
  border-top: 1px solid $color-extra-light-gray;
}

.product-full__accordion__panel {
  display: none;
  margin: 0 0 20px 0;
  clear: both;
  @include breakpoint($landscape-up) {
    margin: 0 0 10px 0;
  }
  &.init--open {
    display: block;
  }
  .product-full__engraving-button {
    &.disabled {
      cursor: no-drop;
      border: 1px solid $color-gray-light-apt;
      color: $color-gray-light-apt;
    }
  }
}

.product-full__mobile-panel__title {
  &::after {
    display: none;
    content: '';
  }
}

.product-full__mobile-panel__panel {
  margin: 0 0 10px 0;
}

.product-full__replenishment {
  @include breakpoint($landscape-up) {
    padding-bottom: 0;
  }
}

.product-full__offer {
  @include clearfix;
  border-top: 1px solid $color-border;
  padding: 10px 0;
  margin-bottom: 8px;
}

.product-full__offer__url {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.product-full__offer__image {
  width: 36px;
  margin: 0 6px 4px 0;
  position: relative;
  top: 3px;
  @include breakpoint($landscape-up) {
    width: 38px;
  }
}

.product-full__offer__content {
  display: inline;
  font-size: 14px;
  line-height: 18px;
  @include breakpoint($landscape-up) {
    font-size: 15px;
    line-height: 19px;
  }
}

.product-full__offer__text--mobile {
  display: block;
  font-size: 14px;
  text-align: center;
  clear: both;
  margin: 20px 0 0 40px;
  font-weight: normal;
  @include breakpoint($landscape-up) {
    display: none;
  }
}

.product-full__inventory-status {
  @include clearfix;
}

.product-full__mobile-panels {
  .product-inventory-status__item {
    border-top: 1px solid $color-border;
    padding: 10px 0 0 0;
  }
}

//product images
.product-full__media {
  position: relative;
  height: 220px;
  margin-bottom: 20px;
  @include breakpoint($landscape-up) {
    float: left;
    width: 52.82258%;
    margin: 0 4.43548% 0 2.01613%;
    height: auto;
    min-height: 663px;
    margin-bottom: 0;
  }
}

.product-full__image {
  margin: 0 auto;
  text-align: center;
  position: relative;
  @include breakpoint($landscape-up) {
    max-width: 100%;
  }
}

.product-full__image-carousel {
  max-width: 100%;
  margin: 0 auto;
  .slick-slider {
    padding-left: 0;
  }
  .slick-list {
    padding-right: 0;
  }
  .carousel-controls {
    text-align: left;
    top: 45%;
    @include breakpoint($landscape-up) {
      top: 40%;
    }
  }
  .carousel-controls .slick-prev {
    left: 0;
    @include breakpoint($landscape-up) {
      left: -10px;
    }
  }
  .carousel-controls .slick-next {
    right: 0;
    @include breakpoint($landscape-up) {
      right: -10px;
    }
  }
  .carousel-dots {
    margin-top: -15px;
    margin-bottom: 15px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}

// Prevent FOUC as slick carousel inits
.product-full__carousel__slide {
  display: none;
  position: relative;
  margin: 0;
  .slick-initialized & {
    display: block;
  }
}

.product-full__image-img {
  max-height: 220px;
  .product-full__video-image & {
    padding: 20px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  @include breakpoint($landscape-up) {
    max-height: none;
    .slick-slide & {
      opacity: 0;
      transition: opacity 2s;
    }
    .slick-active & {
      opacity: 1;
    }
  }
}

.product-full__image-thumbnails {
  display: none;
  @include breakpoint($landscape-up) {
    @include clearfix;
    @include centerer(false, true);
    left: 5px;
    display: block;
    padding: 20px 0;
    .product-quickshop & {
      left: 15px;
    }
  }
}

.product-full__carousel__thumbnail {
  display: block;
  width: 60px;
  cursor: pointer;
  margin: 0 0 15px 0;
  &.active {
    cursor: default;
  }
  .product-full__carousel__thumbnail-img {
    border-bottom: 1px solid transparent;
    transition: border 0.3s;
  }
  &:hover,
  &.active {
    .product-full__carousel__thumbnail-img {
      border-color: $color-dark-gray;
    }
  }
}

.product-full__carousel__thumbnail-img--video {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  width: 60px;
  height: 40px;
  .icon {
    color: $color-white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    @include centerer(true, true);
  }
}

.product-full__video {
  @include breakpoint($landscape-up) {
    padding: 140px 0 0 90px;
  }
}

.product-full__video-image {
  cursor: pointer;
  position: relative;
  .icon {
    @include vertical-horizontal-center;
    position: absolute;
    font-size: 40px;
    z-index: 1;
    color: $color-white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    @include breakpoint($landscape-up) {
      font-size: 50px;
      margin-top: -25px;
      margin-left: -25px;
      @include transform(none);
    }
  }
}

.product-full .slick-dots li.slick-dot-video {
  position: relative;
  margin: 0;
  &:after {
    top: -5px;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-left-color: $color-gray;
    border-width: 5px;
    margin-left: -5px;
    opacity: 0.3;
    transition: opacity 0.3s;
  }
  button {
    display: none;
  }
  &.slick-active {
    &:after {
      border-left-color: $color-black;
      opacity: 1;
    }
  }
}

// Auto Replenish Overlay
.product-auto-replenishment {
  text-align: center;
  &__heading {
    @include heading--3;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}

.product-full__image-spp-link {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .product-quickshop & {
    display: block;
  }
}

.product-full__footer {
  .product-full__cta {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}
// Afterpay styles in SPP
.afterpay-paragraph {
  .afterpay-link {
    background: $color-black;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    line-height: 1;
    text-align: center;
    padding-#{$rdirection}: 1px;
  }
}

.ugc-headings {
  margin: 0 auto;
  text-align: center;
  &__eyebrow {
    text-align: center;
    margin: 0 25px;
    padding: 20px 0;
    border-top: 1px solid $color-border;
    @include breakpoint($landscape-up) {
      margin: 0 72px;
      padding: 40px 0;
    }
  }
  &__heading {
    @include text--2;
    margin: 0 0 15px;
    opacity: 0.8;
    @include breakpoint($medium-up) {
      opacity: 1;
      margin-bottom: 18px;
    }
  }
  &__subheading {
    @include heading--2;
    padding: 0 6%;
    @include breakpoint($landscape-up) {
      padding: 0 12%;
    }
  }
}

