///
/// Checkout pages styling
///

//colors
$checkout-border-header: $color-light-gray;

// Local variables
$checkout-spacing: 20px; // common shared margin/paddings
$cart-spacing: 10px;
$checkout-pushdown: 35px; // used to nudge content down from top
$viewcart-pushdown: 35px; // used to nudge content down from top
$cart-container-width: 1024px; // comps
$checkout-sidebar-width: percentage(356px / $cart-container-width);
$cart-sidebar-width: percentage(256px / $cart-container-width); // this will override
$cart-sidebar-width: $checkout-sidebar-width; //same as onepage
$cart-line: 1px solid $color-light-gray; // line used everywhere

/// Cart item values
// product
$product-width-mobile: 25%;
$product-width-desktop: 25%;
// description
$desc-width-mobile: 60%;
$desc-width-desktop: 35%;
// price
$price-width: 15%;
// replenishment
// $replenishment-width-desktop: 20%;
// qty
$qty-width: 15%;
// total
$total-width-desktop: 10%;

// Common styles
@import 'checkout-shared';
@import 'checkout-account';
@import 'checkout-confirmation';
@import 'checkout-favourites-past-purchases';
@import 'checkout-gift-card';
@import 'checkout-helpful-links';
@import 'checkout-need-help';
@import 'checkout-offers-promos';
@import 'checkout-order-summary';
@import 'checkout-payment';
@import 'checkout-recommended-products';
@import 'checkout-samples';
@import 'checkout-shipping';
@import 'checkout-viewcart';
@import 'checkout-progress-bar';
@import 'terms_tooltip';
