.terms-tooltip {
    #{$ldirection}: 5px;
    position: relative;
    cursor: pointer;
    .icon--information {
      width: 16px;
      height: 15px;
    }
    .tooltiptext-under,
    .tooltiptext-over {
      visibility: hidden;
      width: 280px;
      background-color: $color-white;
      border: 1px solid $color-dark-gray;
      padding: 10px;
      position: absolute;
      z-index: 1;
      #{$ldirection}: -123px;
      opacity: 0;
      transition: opacity 0.3s;
      height: auto;
      font-size: 14px;
      font-weight: normal;
      text-align: $ldirection;
      text-transform: initial;
      font-family: $font--neue-haas-unica-pro;
      letter-spacing: normal;
      line-height: 1.4;
    }
    .tooltiptext-under {
      top: 135%;
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        #{$ldirection}: 45%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $color_black transparent;
      }
    }
    .tooltiptext-over {
      bottom: 110%;
      &::before {
        content: '';
        position: absolute;
        top: 100%;
        #{$ldirection}: 45%;
        border-width: 5px;
        border-style: solid;
        border-color: $color_black transparent transparent transparent;
      }
    }
    &:hover {
      .tooltiptext-under,
      .tooltiptext-over {
        visibility: visible;
        opacity: 1;
      }
    }
  }
