/* Loyalty next-tier */
.loyalty__panel__next-tier__title {
  display: none;
}
/* Loyalty landing page */
.account-section {
  &.account-section--loyalty {
    .loyalty__content {
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 20%;
      }
    }
    .loyalty__panel {
      &__notification__welcome {
        display: none;
      }
      &__landing {
        &__logo {
          margin-bottom: 25px;
          img {
            @include swap_direction(margin, 0 auto);
          }
        }
        &__content {
          @include swap_direction(padding, 20px 20px 0 20px);
          @include breakpoint($landscape-up) {
            @include swap_direction(padding, 20px 20px 50px 20px);
            border-bottom: 1px solid $color-light-gray;
          }
        }
        &__user {
          @include swap_direction(padding, 30px 0 30px 0);
          display: block;
          width: 100%;
          border: 0;
          @include breakpoint($landscape-up) {
            @include swap_direction(padding, 0);
            width: 30%;
            display: inline-block;
            border-#{$rdirection}: 1px solid $color-light-gray;
          }
          &__title {
            font-size: 24px;
            font-family: $font--heading;
            margin-bottom: 10px;
            text-align: center;
            text-transform: uppercase;
            &__tier-1 {
              color: $color-marine;
            }
            &__tier-2 {
              color: $color-aquamarine;
            }
            &__tier-3 {
              color: $color-ultramarine;
            }
          }
          &__logo {
            @include swap_direction(margin, 0 auto);
            background-repeat: no-repeat;
            height: 160px;
            width: 160px;
            background-size: 100% 100%;
            text-align: center;
            &__tier-1 {
              background-image: url(/media/export/cms/loyalty/marine_pc_benefits_logo.png);
            }
            &__tier-2 {
              background-image: url(/media/export/cms/loyalty/aqua_marine_pc_benefits_logo.png);
            }
            &__tier-3 {
              background-image: url(/media/export/cms/loyalty/ultra_marine_pc_benefits_logo.png);
            }
          }
          &__earned {
            @include swap_direction(margin, 0);
            font-size: 40px;
            padding-top: 35px;
            line-height: 1;
            font-family: $font--subheading;
            color: $color-white;
          }
          &__text {
            font-size: 16px;
            font-family: $font--subheading;
            color: $color-white;
            text-transform: uppercase;
            line-height: 1.2;
          }
          &__next {
            &__tier-2 {
              color: $color-aquamarine;
              text-transform: uppercase;
            }
            &__tier-3 {
              color: $color-ultramarine;
              text-transform: uppercase;
            }
          }
        }
        &__points {
          @include swap_direction(padding, 0);
          width: 100%;
          display: block;
          text-align: center;
          @include breakpoint($landscape-up) {
            @include swap_direction(padding, 1% 0% 0 3%);
            width: 64%;
            display: inline-block;
            vertical-align: top;
          }
          &__spend {
            font-size: 20px;
            font-family: $font--subheading;
            margin-bottom: 20px;
            color: $color-black;
            &__text {
              display: inline-block;
              @include breakpoint($landscape-up) {
                display: block;
              }
            }
          }
          &__new {
            font-size: 14px;
            font-family: $font--text;
            margin-bottom: 20px;
            &__text {
              @include breakpoint($landscape-up) {
                display: block;
              }
            }
          }
          &__links {
            border-bottom: 1px solid $color-light-gray;
            padding-bottom: 40px;
            @include breakpoint($landscape-up) {
              border-bottom: 0;
              padding-bottom: 0;
            }
            .panel__links {
              text-transform: uppercase;
              font-size: 12px;
              letter-spacing: 1px;
              color: $color-black;
              line-height: 1;
              text-decoration: underline;
              display: inline-block;
              cursor: pointer;
              font-weight: bold;
            }
            .about {
              border-#{$rdirection}: 1px solid $color-black;
              padding-#{$rdirection}: 7px;
            }
            .faqs {
              padding-#{$ldirection}: 5px;
            }
          }
        }
      }
    }
    .loyalty-loading {
      text-align: center;
      &-image {
        width: 32px;
        height: 32px;
        background-image: url(/media/images/global/loading.gif);
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
      }
    }
  }
}
/* Non loylaty page */
.non__loyalty__panel {
  padding-bottom: 25px;
  margin-bottom: 15px;
  @include breakpoint($landscape-up) {
    border-bottom: 1px solid $color-light-gray;
  }
  &__logo {
    margin-bottom: 25px;
    img {
      @include swap_direction(margin, 0 auto);
    }
  }
  &__rewards-program {
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    &__text {
      margin-top: 20px;
      @include breakpoint($landscape-up) {
        margin-top: 0;
      }
    }
  }
  &__join-now {
    padding: 0 20px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    .button {
      @include swap_direction(margin, 30px 0 15px 0);
      font-family: $font--text;
    }
    .label {
      &-content {
        width: 90%;
        display: inline-block;
        vertical-align: top;
        text-align: #{$ldirection};
        padding-#{$ldirection}: 4px;
        @include breakpoint($landscape-up) {
          width: 94%;
        }
      }
    }
    &-button {
      text-align: center;
      .button {
        &:not(.button--disabled) {
          @include join-now-button-color;
        }
      }
    }
  }
  &__learnmore {
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: bold;
    display: block;
    text-align: center;
  }
  &__terms {
    @include swap_direction(padding, 0 20px);
    @include swap_direction(margin, 15px 0 0 0);
    font-size: 11px;
    font-style: italic;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 75px);
      @include swap_direction(margin, 15px 15px 0 0);
    }
    &__conditions {
      color: $color-green;
      text-decoration: underline;
    }
  }
}
