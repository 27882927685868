.checkout-panel--loyalty {
  .checkout-panel {
    &__content {
      &__logo {
        height: auto;
      }
      &__title {
        margin: 25px 0;
        text-transform: uppercase;
        font-size: 38px;
        line-height: 1;
      }
      &__label {
        margin-bottom: 25px;
      }
      &__learn-more {
        margin-top: 15px;
        font-size: 12px;
      }
      .loyalty-join-panel {
        &__accept-terms {
          margin-top: 15px;
          font-size: 12px;
          font-style: italic;
        }
        &__accept-loyalty-terms {
          @include swap_direction(margin, 0 0 20px 0);
          .label {
            display: block;
            &-content {
              width: 90%;
              display: inline-block;
              vertical-align: top;
              text-align: #{$ldirection};
              padding-#{$ldirection}: 4px;
            }
          }
        }
        &__join-now {
          .button {
            &:not(.button--disabled) {
              @include join-now-button-color;
            }
          }
          .button--disabled {
            background: $color-gray;
            color: $color-white;
            border-color: $color-gray;
            cursor: default;
            &:hover,
            &:active,
            &:focus {
              background: $color-gray;
              color: $color-white;
              border-color: $color-gray;
            }
          }
        }
      }
    }
  }
}

.checkout--confirmation-page {
  .checkout-panel--loyalty {
    .registration_header {
      border: none;
      .checkout-panel {
        &__heading {
          margin: 25px 0;
          text-transform: uppercase;
          font-size: 38px;
          line-height: 1;
          @include breakpoint($landscape-up) {
            width: 70%;
          }
        }
      }
    }
    .registration-content {
      &__create-account {
        width: 100%;
      }
      &__heading {
        font-family: $font--text;
      }
      .checkout-registration {
        &__firstname,
        &__lastname,
        &__password {
          .field {
            width: 100%;
            @include breakpoint($landscape-up) {
              width: 50%;
            }
          }
          .note {
            color: $color-gray;
            display: block;
            margin-top: 10px;
          }
          .label {
            text-transform: uppercase;
          }
        }
        .checkbox {
          .field {
            &[type='checkbox'] ~ label::before {
              font-size: 20px;
            }
          }
          .label-content {
            font-size: 14px;
            display: inline-block;
            width: 75%;
            vertical-align: top;
          }
        }
        &__content__learn-more {
          margin-top: 15px;
          font-size: 12px;
        }
      }
    }
  }
}

.checkout {
  &__sidebar {
    .loyalty-points-content {
      &__summary {
        padding: 0 20px;
        text-align: center;
        font-size: 15px;
        font-family: $font--text;
        letter-spacing: 1px;
        position: relative;
        .loyalty-info-icon {
          font-size: 9px;
          border: 1px solid $color-gray;
          width: 15px;
          height: 15px;
          display: inline-block;
          border-radius: 50%;
          text-align: center;
          position: absolute;
          top: 5px;
          #{$rdirection}: 0;
          cursor: pointer;
        }
        .loyalty-tooltip-content {
          display: none;
        }
      }
    }
    .checkout_offer_flexslider {
      .slider_list {
        padding: 25px;
        .offer-code-wallet {
          &__item {
            .offer_desc {
              display: block;
              text-align: center;
            }
            .offer_apply {
              display: block;
              text-align: center;
              margin-top: 20px;
            }
          }
          &__code-info--applied {
            margin: auto;
            display: table;
            img {
              min-height: 313px;
            }
          }
        }
      }
    }
  }
}

.checkout-panel--promo {
  .offer-banner-item__offercode {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.tooltipster {
  &-default,
  &-base {
    border-width: 1px;
    background: $color-white;
    border-radius: 10px;
  }
  &-content {
    color: $color-black;
    background: $color-white;
    border-radius: 10px;
  }
}
