/* SHIPPING PANEL */

html.no-js .panel--shipping .select-menu {
  margin-left: 0;
}

.panel--shipping {
  &__header {
    @include breakpoint($medium-down) {
      padding: 10px !important;
      h2 {
        margin: 0;
      }
    }
    .button-mini {
      border-radius: 0;
      float: right;
      margin: 2px 1em 0 0;
      padding: 0 6px;
      text-transform: uppercase;
    }
  }
  &__edit-address {
    &__content {
      .checkout {
        &__panel {
          &__sub-section {
            margin-left: 2em;
            .address-form {
              &__ship-type {
                display: none;
              }
            }
          }
        }
      }
      .update-address-note {
        width: auto !important;
      }
      .phones .phone-required {
        display: block;
      }
      .default-shipping {
        input {
          margin: 0 10px 3px 0;
          vertical-align: middle;
          width: auto !important;
        }
      }
    }
  }
  .select-address input[type='radio'] {
    margin: 0 0.5em 0 1em;
    vertical-align: baseline;
    &:first-child {
      margin-left: 0;
    }
  }
  .select-address .show_more_address,
  .select-address .show_less_address {
    color: #5c88e1;
    cursor: pointer;
    text-decoration: underline;
    float: right;
    margin: 20px 40px 0 0;
  }
  section {
    margin-bottom: 1em;
    h3 {
      float: left;
    }
    #shipping_add_new_address,
    #shipping_select_existing_address {
      float: left;
      margin-left: 30px;
    }
  }
  #qas {
    margin-top: 1em;
  }
  .default-shipping,
  .form-item.giftwrap {
    label {
      display: inline;
    }
  }
  .content > div {
    margin-bottom: 1em;
  }
  .giftwrap {
    display: block;
    margin: 10px 0 20px;
  }
  .card-message {
    display: block;
    textarea {
      height: 4em;
    }
  }
  .gift-options textarea {
    width: 12em;
    height: 6em;
  }
  #continue-button {
    text-align: center;
    margin-bottom: 20px;
  }
  & {
    &.substate-manual {
      .manual-address {
        display: none;
      }
      #manual-address-fields {
        display: block !important;
      }
    }
    &.edit header h2 .edit {
      color: $color-white;
      text-decoration: none;
      cursor: default;
    }
  }
  .select-menu {
    margin-left: 0;
    .address-options {
      vertical-align: top;
      width: 167px;
      .edit-this-address {
        margin-right: 1em;
      }
      .ship-this-address {
        display: block !important;
        &.selected {
          background: none #333;
        }
      }
      .bill-this-address {
        display: none;
      }
      .default-shipping-check {
        input {
          float: left;
          margin-right: 3px;
        }
        label {
          float: left;
          margin-top: 5px;
          width: 80%;
        }
      }
      .select-this-address {
        background: none #ccc;
        border: 0 none;
        color: $color-white;
        font-size: 12px;
        font-weight: bold;
        margin-top: 10px;
        display: block !important;
        &.selected {
          background: none #333;
          .tick_symbol {
            display: inline;
          }
        }
        &.button {
          padding: 4px 7px;
        }
        .tick_symbol {
          display: none;
        }
      }
    }
  }
  .country-id label {
    margin-bottom: 5px;
  }
  #shipping-address-display,
  #gift-options-display {
    header {
      background: none;
    }
  } /* override default hidden state of sub-panel */
  .email-and-sms-promotions {
    margin-bottom: 20px;
    .section-email-promotions {
      float: none;
      width: auto;
      fieldset.email_promotions {
        input {
          display: block;
          float: left;
          margin: 0 10px 20px 0;
        }
      }
    }
  }
  .checkout {
    &__button {
      background: none repeat scroll 0 0 #666666 !important;
      color: $color-white !important;
      display: inline-block !important;
      font-size: 14px !important;
      line-height: 25px !important;
      padding: 4px 10px !important;
      text-transform: uppercase !important;
    }
    &__button:hover {
      color: $color-black !important;
    }
  }
}

.shipping-address-updated {
  margin: 1em 0 2em;
}

.shipping-also-billing {
  margin: 2em 0 1em;
}

.shipping-same-as-billing-overlay {
  .shipping-buttons {
    text-align: center;
  }
  .answer-yes,
  .answer-no {
    text-transform: capitalize;
  }
}

.overlay-container.shipping-same-as-billing-overlay {
  height: 245px;
}

.checkout-panel--shipping {
  .gift-options-content {
    .giftwrap__is-gift {
      margin-bottom: 25px;
    }
  }
  &-edit-address {
    input.checkedEmpty {
      border-color: $color-error;
    }
    .address-form {
      input {
        width: 100%;
      }
      input.error {
        border-color: $color-error;
      }
      .invalid_marker,
      .valid_marker {
        display: none;
      }
      .section-sms-promotions {
        margin-bottom: 10px;
      }
      &__name-fields {
        .first-name,
        .last-name {
          width: 49%;
          float: left;
        }
        .last-name {
          float: right;
        }
      }
      &__phones {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 49%;
        }
      }
      &__city-state-zip {
        &--ca {
          .city {
            width: 49%;
            float: right;
          }
          .state {
            width: 100%;
            clear: both;
            float: none;
          }
          .postal-code {
            width: 49%;
            float: left;
          }
        }
        .postal-code {
          width: 49%;
          float: left;
        }
        .city {
          width: 100%;
          display: inline-block;
        }
        .state {
          float: right;
          width: 49%;
          select {
            width: 100%;
          }
          a {
            min-width: 100%;
            height: 47px;
            line-height: 45px;
            .selectBox-label {
              padding: 0 25px 0 10px;
              @include breakpoint($landscape-up) {
                padding: 0 40px 0 20px;
              }
            }
          }
        }
      }
    }
  }
  .disable-dropdown,
  .selectBox-disabled {
    cursor: not-allowed;
  }
  .select-address {
    input[type='radio'] {
      & + label {
        display: inline-block;
      }
    }
    .address-to-use {
      margin: 10px 5px 20px 0;
      @include breakpoint($landscape-up) {
        margin-right: 50px;
      }
    }
    .form-item.select-menu {
      margin-bottom: 20px;
      .selectbox {
        max-width: 100%;
      }
    }
    .section-sms-promotions {
      label.sms_promo_label {
        display: block;
      }
    }
    .default-shipping {
      label {
        display: block;
      }
    }
  }
}

.shipping-address {
  &-display-content__addresses {
    text-transform: capitalize;
  }
}

.delete-confirm-overlay {
  .address-delete__info {
    text-transform: capitalize;
  }
}

.checkout-panel {
  .messages {
    padding: 0;
    padding-bottom: 8px;
  }
  .single-message {
    &:first-child {
      padding-top: 5px;
    }
  }
  &--delivery-method {
    .selectBox-dropdown {
      min-width: 295px;
    }
  }
}

.gift-options-content {
  border-top: 1px solid $color-lightest-grey;
  border-bottom: 1px solid $color-lightest-grey;
  padding-top: 8px;
  &--header {
    cursor: pointer;
    width: 95%;
    .toggle-icon {
      float: right;
      margin-top: 5px;
    }
    .icon {
      right: 20px;
      top: 5px;
      position: absolute;
    }
    .icon--plus.icon--minus {
      @include icon('minus');
      margin-top: 0px;
    }
  }
}

.checkout-panel__content {
  .section-sms-promotions {
    .sms-mobile {
      margin-top: 15px;
    }
  }
}
