.need-help-panel {
  margin-bottom: 1em;
  h3 {
    margin-bottom: 1em;
  }
  &__content {
    h4 {
      font-weight: bold;
      span.text {
        font-weight: normal;
      }
    }
  }
  &__phone {
    padding: 5px 0;
    padding-left: 24px;
    background: transparent url(/images/checkout/icon_phone.png) no-repeat left center;
    @include breakpoint($medium-down) {
      padding: 5px 0 5px 5px;
    }
  }
  &__chat {
    padding: 5px 0;
    padding-left: 24px;
    background: transparent url(/images/checkout/icon_chat.png) no-repeat left center;
    @include breakpoint($medium-down) {
      padding: 5px 0 5px 5px;
    }
  }
  &__email {
    padding: 5px 0;
    padding-left: 24px;
    background: transparent url(/images/checkout/icon_email.png) no-repeat left center;
    @include breakpoint($medium-down) {
      padding: 5px 0 5px 5px;
    }
  }
}

body#index #need-help-panel {
  display: none;
}
