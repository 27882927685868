///
/// @file components/prouducts/_spp.scss
///
/// \brief Styles for multi-product pages
///
///

.spp__container__inner {
  @include content-container;
}
