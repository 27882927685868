.offerspick {
  margin: 0 auto;
  padding: 20px 6.25%;
  @include breakpoint($medium-up) {
    width: 800px;
    padding: 60px 0 20px 0;
  }
  .offerspick__offers {
    @include clearfix;
  }
  .offerspick__offer {
    @include clearfix;
    text-align: center;
    padding: 12px 0 0 0;
    height: 260px;
    @include breakpoint($medium-up) {
      width: 25%;
      float: left;
      padding: 28px 1px 0 1px;
    }
    &.is_selected {
      border-bottom: 0;
      .offerspick__button--remove {
        display: inline-block;
      }
      .offerspick__button--add {
        display: none;
      }
    }
  }
  .offerspick__offer__header {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.2em;
    margin-bottom: 18px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      letter-spacing: 0.1em;
    }
  }
  .offerspick__offer__subheader {
    font-size: 11px;
    letter-spacing: 0.2em;
    margin-bottom: 18px;
    @include breakpoint($medium-up) {
      font-size: 16px;
      letter-spacing: 0.1em;
    }
  }
  .offerspick__header__title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .offerspick__header__choices,
  .offerspick__header__remove {
    display: none;
  }
  .offerspick__button__container {
    text-align: center;
  }
  .offerspick__button--add {
  }
  .offerspick__button--remove {
    display: none;
  }
  .offerspick__shades {
    @include clearfix;
    margin: 26px 0;
  }
  .offerspick__shade {
  }
  .offerspick__shade__image {
    padding-bottom: 20px;
  }
  .offerspick__shade__dot {
    background: #999;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
  }
  .offerspick__shade__name {
    //font: $brandon-text;
    font-size: 11px; //9px
    line-height: 12px;
    margin: 4px 0;
  }
}
