.section-afterpay {
  .content-block-collapsible {
    @include breakpoint($medium-up) {
      max-width: 100%;
    }
    &__header {
      display: none;
    }
    &__content {
      @include breakpoint($medium-up) {
        max-width: 100%;
        margin: 0;
      }
    }
  }
  .afterpay-working-header {
    font-family: $font--heading;
    font-size: 25px;
    line-height: 1;
    text-align: center;
    margin: 30px 0;
    @include breakpoint($medium-up) {
      font-size: 36px;
    }
  }
  .content-block-tout {
    &__media {
      height: 80px;
    }
    &__image {
      width: 25%;
    }
    &__text-headline {
      font-family: $font--subheading;
      font-size: 18px;
      line-height: 1.5;
      @include breakpoint($medium-up) {
        font-size: 21px;
      }
    }
    &__text-body {
      font-family: $font--text;
      font-size: $base-mobile-font-size;
      line-height: $base-line-height;
      @include breakpoint($medium-up) {
        font-size: $base-font-size;
      }
    }
  }
  .basic-grid-formatter__details {
    display: none;
  }
  .requirements-header,
  .payments-header {
    font-family: $font--subheading;
    font-size: 18px;
    line-height: 1.5;
    @include breakpoint($medium-up) {
      font-size: 24px;
    }
  }
  .requirements-description,
  .payments-description {
    font-family: $font--text;
    font-size: $base-mobile-font-size;
    line-height: $base-line-height;
    @include breakpoint($medium-up) {
      font-size: $base-font-size;
    }
  }
  .afterpay-faq-section {
    .customer-service__content {
      width: 100%;
      padding: 0 15px;
    }
  }
}
