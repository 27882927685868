.content-block-formatter {
  &:not(.container-vertical-bottom-margin--*) {
    margin-bottom: 20px;
    @include breakpoint($landscape-up) {
      margin-bottom: 40px;
    }
  }
  &.align-#{$ldirection}, &.align-#{$rdirection} {
    .content-block-formatter__headline {
      padding: unset;
    }
  }
  &.align-#{$ldirection} {
    .content-block-formatter__header,
    .content-block-formatter__body {
      text-align: $ldirection;
    }
  }
  &.align-#{$rdirection} {
    .content-block-formatter__header,
    .content-block-formatter__body {
      text-align: $rdirection;
    }
  }
}

.content-block-formatter__header {
  text-align: center;
  padding: 0 20px;
  margin: 25px auto;
  @include breakpoint($medium-up) {
    margin: 15px auto 35px auto;
  }
}

.content-block-formatter__eyebrow {
  @include text--2;
  margin: 0 0 15px 0;
  opacity: 0.8;
  @include breakpoint($medium-up) {
    margin-bottom: 18px;
    opacity: 1;
  }
}

.content-block-formatter__headline {
  @include heading--2;
  position: relative;
  padding: 0 10%;
}

.content-block-formatter__body {
  @include text--1;
  text-align: center;
  margin: 10px 0 0 0;
  @include breakpoint($medium-up) {
    margin-top: 15px;
    padding: 0 10%;
  }
}

.content-block-formatter__cta {
  text-align: center;
  margin: 20px 0 0 0;
  @include breakpoint($medium-up) {
    margin-top: 40px;
    .button {
      margin-#{$ldirection}: 19%;
    }
    &.button {
      &--center {
        margin-top: 0;
        .button {
          margin-#{$ldirection}: 0;
        }
      }
    }
  }
}

.content-block-formatter.content-container {
  .content-block-formatter__item {
    position: relative;
    text-align: center;
    @if $cr22 {
      padding: 0 20px;
    } @else {
      padding: 0 25px;
    }
    @include breakpoint($medium-up) {
      max-width: $content-block-max-width;
      margin: 0 auto;
      padding: 0;
      .continue-shopping {
        float: #{$ldirection};
        margin-top: 36px;
        margin-#{$ldirection}: 90px;
      }
    }
  }
}

.content-block-formatter__item {
  .content-block-large__picture {
    &.horizontal-align-#{$ldirection} {
      margin-#{$ldirection}: 0;
    }
    &.horizontal-align-#{$rdirection} {
      margin-#{$rdirection}: 0;
    }
  }
  .content-block-large__content {
    &.horizontal-align-#{$ldirection}, 
    &.horizontal-align-#{$rdirection} {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
      .content-block-large__content-text {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
  }
}

// Template Overrides
.content-block-formatter {
  .basic-carousel-formatter {
    margin-bottom: 0;
  }
  .basic-carousel .content-block--split-width {
    padding: 0;
  }
}
