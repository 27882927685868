/* PAYMENT PANEL */

#payment-panel {
  .select-address .show_more_address,
  .select-address .show_less_address {
    color: #5c88e1;
    cursor: pointer;
    text-decoration: underline;
    float: right;
    margin: 20px 40px 0 0;
  }
  form {
    margin-top: 1em;
  }
  .content {
    position: relative;
  }
  input[type='radio'] {
    margin: 0 0.5em 0 1em;
  }
  input[type='checbox'] {
    margin: 0 0.5em 0 0;
  }
  .payment-option {
    display: inline-block;
    .related-media {
      display: block;
      padding: 5px 15px;
    }
  }
  .payment-control {
    display: inline-block;
  }
  .cc-info {
    width: 10em;
    margin: 1em 0 1.5em;
  }
  #checkout_saved_payment {
    h3 {
      margin-bottom: 1em;
    }
    #default-payment-method {
      margin: 1em 0;
      input[type='checkbox'] {
        float: left;
      }
    }
  }
  #complete-button {
    text-align: center;
    margin-bottom: 2em;
  }
  .sub-section {
    margin-left: 0em;
    margin-top: 10px;
    .billing-edit-address-content__title {
      float: left;
    }
    .billing_add_new_address {
      float: left;
      margin-left: 30px;
    }
    .billing_select_existing_address {
      float: left;
      margin-left: 30px;
    }
    .billing-edit-address-header .change-address {
      margin-left: 10px;
    }
  }
  .edit-this-address {
    display: none;
  }
  .delete-this-address {
    display: none;
  }
  section {
    h3 {
      float: left;
    }
    .billing_add_new_address {
      float: left;
      margin-left: 30px;
    }
    .billing_select_existing_address {
      float: left;
      margin-left: 30px;
    }
  }
  .security-info {
    position: absolute;
    right: 1.5em;
    text-align: center;
    top: 1.5em;
  }
  .field-are-required {
    position: absolute;
    top: 0;
    right: 1em;
  }
  input[type='radio']:first-child {
    margin-left: 0;
  }
  .form-item.block {
    display: block;
  }
  #checkout_payment_select .related-media {
    display: block;
    margin-top: 0.2em;
  }
  #checkout_payment_card_select label {
    float: left;
    margin-right: 1em;
  }
  #checkout_saved_payments h3 {
    margin-bottom: 1em;
  }
  .select-menu .address-options {
    vertical-align: top;
    width: 167px;
    .select-this-address {
      background: none $color-gray;
      border: 0 none;
      border-radius: 0;
      color: $color-white;
      font-size: 12px;
      font-weight: bold;
      margin-top: 0;
      display: block !important;
      &.selected {
        background: none $color-green;
        .tick_symbol {
          display: inline;
        }
      }
      .tick_symbol {
        display: none;
      }
    }
    .bill-this-address {
      display: block;
    }
    .ship-this-address {
      display: none;
    }
    .message {
      display: none;
    }
    .default-shipping-check {
      display: none;
    }
    .same-as-shipping.selected {
      .bill-this-address {
        display: none;
      }
      .message {
        display: block;
        line-height: 12px;
      }
    }
  }
}

#checkout_review {
  .msg-order-email {
    display: none;
  }
  div.submit {
    text-align: center;
    @include breakpoint($landscape-up) {
      text-align: right;
    }
  }
}

.privacy-policy-overlay {
  width: 650px;
  height: 350px;
}

#security-code-panel {
  margin: -20px;
  .content {
    width: 100%;
    max-width: 540px;
    overflow: hidden;
    .left {
      float: left;
      width: 40.740741%;
    }
    .right {
      float: right;
      width: 59.259259%;
      text-align: right;
    }
  }
  .center {
    text-align: center;
  }
  header a {
    float: right;
    margin-top: 5px;
  }
}

#security-info-panel {
  margin: -20px;
}

.checkout-panel--review {
  &.active {
    border-bottom: 0;
  }
  .pc-place-order {
    margin-bottom: 25px;
    color: $color-error;
    div {
      margin-top: 10px;
    }
  }
}
/* PAYMENT PANEL */
#payment-panel,
.checkout-panel--payment {
  .column {
    width: 50%;
  }
  ul.block {
    margin-bottom: 1em;
  }
  // Panel Substates
  &.panel-substate-saved-single-address,
  &.panel-substate-saved {
    .payment-form,
    #paypal-info {
      display: none;
    }
  }
  &.panel-substate-add {
    #paypal-info,
    .saved-payments,
    .cc-info {
      display: none;
    }
  }
  &.panel-substate-paypal {
    div.payment-container {
      display: none;
    }
  }
  .address-to-use__toggle-link {
    display: none;
  }
  &.panel-substate-saved-none-selected {
    .save-options,
    .payment-form,
    #paypal-info,
    .cc-info,
    .continue-button-wrapper input,
    .giftcard-apply,
    .sub-section.address {
      display: none;
    }
  }
  // Payment Options
  &.panel-payment-option-hide {
    .do-not-save-payment,
    .default-payment {
      display: none;
    }
  }
  &.panel-payment-default-only {
    .do-not-save-payment {
      display: none;
    }
  }
  &.panel-payment-save-card-only {
    .default-payment {
      display: none;
    }
  }
  &.panel-payment-show-all {
  }
  .payment-info {
    margin: 0.5em 0;
    .additional-info {
      float: right;
      width: 20%;
      .required-notice {
        font-style: italic;
      }
      .related-media {
        margin: 0.3em 0;
      }
      a.security-information {
        font-weight: bold;
      }
    }
  }
  .form-item {
    label {
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
    }
    .related-media {
      clear: left;
      display: inline-block;
      img {
        margin-right: 0.125em;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .credit-card-options {
    padding: 0 1.5em;
    select {
      width: 100%;
      font-size: 1em;
    }
  }
  .saved-credit-card-info {
    padding: 0 1.5em;
    p {
      margin: 1em 0;
    }
    .expiration-date {
      display: block;
    }
  }
  .button-primary {
    display: block;
    margin: 1.5em auto 0;
  }
  .form-item-group.expiration-date {
    float: left;
    margin-right: 0.5em;
    .form-item {
      float: left;
      margin-top: 0;
      margin-right: 0.5em;
    }
  }
  .cvv {
    input {
      width: 5em;
    }
    .valid_marker {
      left: 36px;
    }
    .invalid_marker {
      left: 32px;
    }
  }
  .sub-section {
    .billing-edit-address-content__title {
      float: left;
    }
    .billing_add_new_address {
      float: left;
      margin-left: 30px;
    }
    .billing_select_existing_address {
      float: left;
      margin-left: 30px;
    }
    .billing-edit-address-header .change-address {
      margin-left: 10px;
    }
  }
  .edit-this-address {
    display: none;
  }
  .delete-this-address {
    display: none;
  }
}

.security-overlay {
  width: 450px;
  height: 230px;
}

.checkout-panel--payment {
  .payment-display-content__heading {
    margin-bottom: 20px;
  }
  .pre-order-message-display {
    margin-top: 1em;
  }
}

.payment-edit-content {
  .payment-type {
    margin-bottom: 20px;
  }
  &__payment-type {
    display: none;
  }
  .payment-container {
    margin: 15px 0;
    padding-bottom: 15px;
    .address-container {
      .edit {
        float: right;
      }
    }
    input.checkedEmpty {
      border-color: $color-error;
    }
    .payment-control {
      margin-right: 15px;
    }
    &-card-number,
    &-cvv,
    &-expires-month,
    &-expires-year {
      margin-bottom: 20px;
      a {
        width: 100%;
        min-width: 100%;
        &.error {
          border-color: $color-error;
        }
        @include breakpoint($landscape-up) {
          margin-right: 10px;
          height: 46px;
          line-height: 42px;
          min-width: 110px;
        }
      }
      .security-code {
        margin-right: 0;
      }
    }
    .invalid_marker,
    .valid_marker {
      display: none;
    }
    .cc_image_container {
      position: absolute;
      width: auto;
      right: 10px;
      top: 8px;
      @include breakpoint($medium-portrait-only) {
        left: 235px;
      }
      ul {
        .related-media {
          width: auto;
        }
      }
    }
    .paypal-container {
      margin-bottom: 20px;
    }
    .address-container {
      .address-to-use {
        label {
          display: inline-block;
          width: 60%;
          margin: 5px 0;
        }
      }
      .billing_add_new_address {
        float: right;
        font-size: 14px;
      }
    }
    &-expiration-cvv {
      width: 100%;
      @include breakpoint($medium-portrait-only) {
        width: 70%;
      }
    }
    &-card-number {
      input {
        width: 100%;
        @include breakpoint($medium-portrait-only) {
          width: 70%;
        }
      }
    }
    &-cvv {
      float: left;
      width: 32%;
      input {
        width: 100%;
      }
      @include breakpoint($landscape-up) {
        width: 32%;
      }
      @include breakpoint($medium-only) {
        width: 33%;
        float: right;
      }
    }
    &-expires-month {
      float: left;
      width: 32%;
      margin-right: 5px;
      @include breakpoint($landscape-up) {
        width: 33%;
        margin-right: 7px;
      }
      @include breakpoint($medium-only) {
        width: 33%;
      }
    }
    &-expires-year {
      width: 32%;
      float: left;
      margin-right: 5px;
      @include breakpoint($medium-only) {
        width: 31%;
        margin-right: 0;
      }
      @include breakpoint($landscape-up) {
        width: 33%;
        margin-right: 8px;
      }
      @include breakpoint($medium-only) {
        width: 31%;
        margin-right: 0;
      }
    }
    .address-container {
      .address-form {
        .first-name,
        .last-name {
          width: 49%;
          input {
            width: 100%;
          }
        }
        .first-name {
          float: left;
        }
        .last-name {
          float: right;
        }
        &__addresses {
          .form-item {
            .field {
              @include breakpoint($medium-up) {
                width: 100%;
              }
            }
          }
        }
        &__city-state-zip {
          .city {
            input {
              width: 100%;
            }
          }
          .postal-code {
            width: 49%;
            float: left;
            input {
              width: 100%;
            }
            @include breakpoint($medium-up) {
              width: 49%;
            }
          }
          .state {
            width: 49%;
            float: right;
            a {
              width: 100%;
              float: right;
              height: 44px;
              @include breakpoint($landscape-up) {
                width: 100%;
                line-height: 45px;
                float: right;
              }
              &.error {
                border-color: $color-error;
              }
            }
            a.disable-select {
              cursor: not-allowed;
            }
            @include breakpoint($medium-portrait-only) {
              width: 49%;
              float: right;
              select {
                width: 100%;
              }
            }
          }
          .city {
            @include breakpoint($medium-portrait-only) {
              margin-right: 0;
            }
          }
        }
        &__country {
          .country-id {
            select {
              @include breakpoint($medium-up) {
                width: 100%;
              }
            }
          }
        }
        &__phones {
          .phone-1 {
            input {
              width: 100%;
              @include breakpoint($medium-up) {
                width: 49%;
              }
            }
          }
        }
      }
    }
    .afterpay-option-checkbox .afterpay-learnmore {
      padding-#{$rdirection}: 5px;
    }
    .afterpay-option-checkbox .afterpay-learnmore span {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 50%;
      background: $color-black;
      color: $color-white;
      font-size: 10px;
      line-height: 1.4;
      text-transform: lowercase;
      text-align: center;
    }
  }
}

// Alipay
@if $alipay-enabled {
  .payment-container {
    .payment_method-container {
      padding-bottom: 15px;
      .credit-card-select {
        height: 25px;
        padding-left: 12px;
        .cc-payment-checkbox {
          display: inline-block;
          .label-content {
            font-weight: bold;
            font-size: 14px;
            height: 28px;
            text-transform: uppercase;
          }
          .label-logos {
            float: #{$rdirection};
            padding-#{$ldirection}: 15px;
            @include breakpoint($medium-up) {
              width: 428px;
            }
            .label-logo {
              height: 45%;
              position: relative;
              top: -5px;
              width: 45%;
              &--small {
                width: 11%;
                #{$rdirection}: 3px;
              }
            }
          }
        }
      }
      .payment-method-form-container {
        margin-left: 40px;
        padding-top: 30px;
      }
    }
    .checkbox--paypal {
      border-top: 1px solid $color-gray-light-apt;
      margin-bottom: 10px;
      padding-left: 12px;
      padding-bottom: 11px;
      padding-top: 29px;
      .label-content {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
      }
      .label-logos {
        display: inline;
        padding-left: 10px;
        position: relative;
        top: -3px;
        .label-logo {
          height: auto;
          width: 85px;
        }
      }
    }
    .checkbox--alipay {
      border-top: 1px solid $color-gray-light-apt;
      padding: 25px 12px 0;
      height: 80px;
      .alipay-payment-checkbox {
        display: inline-block;
        vertical-align: middle;
        .label-content {
          font-weight: bold;
          font-size: 14px;
          margin-right: 20px;
          text-transform: uppercase;
        }
        .label-logos {
          position: relative;
          top: -2px;
          left: -6px;
          display: inline;
          .label-logo {
            height: 30px;
          }
        }
      }
    }
    .checkbox--afterpay {
      border-width: 1px 0;
      border-style: solid;
      border-color: $color-gray-light-apt;
      padding: 25px 12px 0;
      height: 115px;
      @include breakpoint($medium-up) {
        height: 90px;
      }
      .afterpay-option-checkbox {
        display: inline-block;
        vertical-align: middle;
        .label-content {
          font-weight: bold;
          font-size: 14px;
          margin-right: 10px;
          text-transform: uppercase;
        }
        .label-logo {
          height: 20px;
          position: relative;
          top: -2px;
          #{$ldirection}: -6px;
          display: inline;
          @include breakpoint($medium-up) {
            height: 30px;
          }
        }
      }
    }
    .paypal_terms,
    .alipay_terms {
      margin-top: 30px;
    }
    .address-container {
      border-top: 1px solid $color-gray-light-apt;
      padding-left: 12px;
      padding-bottom: 10px;
      padding-top: 25px;
    }
  }
  .payment-edit-content {
    .mobile-sticky-bottom {
      .button {
        min-width: 220px;
      }
    }
  }
}
