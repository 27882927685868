/* Dropdown control */
@mixin selectBox {
  // @todo remove after roll-out (ASMBLY7-529)
  @if $cr22 {
    border-radius: 0;
    border: 1px solid $color-white;
  } @else {
    border-radius: 4px;
    border: 1px solid $color-lighter-gray;
  }
  font-size: 11px;
  letter-spacing: 0.05em;
  text-decoration: none;
  text-align: left;
  min-width: 150px;
  width: auto;
  position: relative;
  color: $color-dark-gray;
  outline: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  height: 40px;
  line-height: 37px;
  background: $color-white;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
}

%selectBox {
  @include selectBox;
}

a.selectBox {
  @extend %selectBox;
  .selectBox-label {
    padding: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    min-width: fit-content;
  }
  .selectBox-arrow {
    @include icon('caret--down', after); /* Down Arrow */
    top: 0;
    position: absolute;
    right: 8px;
    height: 100%;
    min-width: 30px;
    background-color: transparent;
    color: $color-dark-gray;
    font-size: 9px;
    &:after {
      position: absolute;
      top: 36%;
      left: 50%;
      font-weight: 700;
    }
  }
  &.selectBox-menuShowing {
    .selectBox-arrow {
      @include icon('caret--up', after); /* Up Arrow */
      &:after {
        font-weight: 700;
      }
    }
  }
  &.selectBox-menuShowing-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &.selectBox-menuShowing-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
/* Dropdown menu */
.selectBox-dropdown-menu {
  // @todo remove after roll-out (ASMBLY7-529)
  @if $cr22 {
    &.selectBox-options-top {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &.selectBox-options-bottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  } @else {
    &.selectBox-options-top {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &.selectBox-options-bottom {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  position: absolute;
  z-index: 99999;
  min-height: 1em;
  max-height: 200px;
  border: 1px solid $color-lighter-gray;
  background: $color-white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin selectBox-options {
  font-size: 12px;
  text-decoration: none;
  text-align: left;
  list-style: none;
  display: block;
  margin: 0;
  cursor: pointer;
  overflow: auto;
}

select.selectBox {
  @include icon('caret--up', after);
  &:active,
  &:focus,
  &:focus-visible,
  &:focus-within {
    @include icon('caret--down',after);
  }
}

%selectBox-options {
  @include selectBox-options;
  li {
    line-height: 30px;
    &:first-child {
      padding-top: 0;
    }
    a {
      display: block;
      color: $color-dark-gray;
      padding: 0 15px;
      white-space: nowrap;
      overflow: hidden;
      border: 0;
      text-decoration: none;
      transition: none;
      &:hover {
        background: $color-black;
        color: $color-white;
      }
    }
    &.selectBox-selected a {
      background: $color-black;
      color: $color-white;
    }
    &.selectBox-disabled a {
      color: $color-lighter-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
    &.selectBox-selected.selectBox-disabled a {
      background: $color-white;
      color: $color-lighter-gray;
      &:hover {
        background: $color-white;
        cursor: default;
      }
    }
  }
}

.selectBox-options {
  @extend %selectBox-options;
}
