.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        font-weight: normal;
        font-size: 15px;
        text-decoration: underline;
        color: $color-dark-gray !important;
        font-family: $font--text;
        &:hover {
          color: $color-green !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          #onetrust-policy-text {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        float: #{$rdirection};
      }
      #onetrust-button-group {
        button {
          text-align: #{$rdirection};
          padding-#{$rdirection}: 17%;
          text-decoration: underline;
          opacity: 1;
          @include breakpoint($medium-up) {
            padding-#{$rdirection}: 0;
          }
        }
        .onetrust-pc-btn-handler {
          margin-bottom: 0;
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          top: auto;
          #{$ldirection}: 95%;
          bottom: 20px;
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none;
      }
    }
  }
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    text-decoration: underline;
    text-transform: capitalize;
    &:hover {
      text-decoration: none;
      color: $color-green !important;
    }
    &:focus {
      outline: 0;
    }
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        #pc-title {
          font-size: 1.4rem;
          &:before,
          &:after {
            display: none;
          }
        }
        .pc-close-btn-container {
          .pc-close-button {
            &:focus,
            &:hover {
              outline: 0;
            }
          }
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFloatingRoundedCorner {
      @include swap_direction(padding, 25px 24px 25px 22px);
      border: 10px solid $color-black;
      width: 100%;
      #{$rdirection}: 0;
      #{$ldirection}: auto;
      bottom: 0 !important;
      font-size: 13px;
      @include breakpoint($medium-up) {
        width: 390px;
      }
      p {
        display: inline !important;
        font-size: 13px;
      }
      #onetrust-group-container {
        margin-#{$ldirection}: 0;
        float: none;
        #onetrust-policy {
          margin-top: 0;
          .banner-header {
            margin: 0;
          }
          #onetrust-policy-text {
            padding: 0;
            line-height: normal;
            color: $color-dark-gray;
            float: none;
          }
          #onetrust-close-btn-container {
            margin-#{$rdirection}: -5%;
            margin-top: -5%;
          }
        }
      }
      #onetrust-button-group-parent {
        padding: 0;
        float: none;
        #onetrust-pc-btn-handler {
          padding: 0;
          margin: 0;
          width: auto;
          color: $color-dark-gray;
          letter-spacing: normal;
          font-size: 13px;
          line-height: normal;
          &:hover {
            color: $color-dark-gray;
          }
        }
      }
    }
    #onetrust-button-group {
      display: inline;
    }
  }
}
