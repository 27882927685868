///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

.section-esearch {
  .site-content {
    &__offer-banner {
      display: none;
      @include breakpoint($large-up) {
        display: block;
      }
    }
  }
}

.search-wrapper {
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  &__title {
    @include heading--2;
    text-align: center;
    margin-bottom: 20px;
    @include breakpoint($large-up) {
      margin-bottom: 40px;
    }
  }
  .summary {
    padding: 0;
    text-align: center;
    &-results {
      &__result {
        @include breakpoint($large-up) {
          position: absolute;
          top: 86px;
          left: 27px;
        }
      }
      &__auto-correct {
        @include breakpoint($large-up) {
          position: absolute;
          width: 100%;
          text-align: center;
          top: 86px;
        }
      }
      p {
        margin: 0 0 4px;
      }
    }
    .search-no-results {
      padding: 0 5% 20px;
      @include breakpoint($large-up) {
        padding: 0;
      }
    }
  }
  &__results {
    @include breakpoint($large-up) {
      padding-top: 40px;
      position: relative;
    }
    .results {
      &__products {
        .products {
          &__header {
            // temp hide sort
            display: none;
          }
        }
      }
      &__content {
        .results-summary {
          // temp hide sort
          display: none;
        }
      }
    }
  }
  &__recommendations {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0;
    @include breakpoint($large-up) {
      padding: 60px;
    }
  }
  &__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
}

.search-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 12px 0 60px;
  position: relative;
  @include breakpoint($large-up) {
    padding: 24px 20px 60px;
  }
  .search-form__fields {
    @include breakpoint($tablet-only) {
      input[type='text'] {
        width: 80%;
      }
      input[type='submit'] {
        width: 15%;
      }
    }
  }
  .results {
    &__sort {
      @include breakpoint($large-up) {
        position: absolute;
        right: 0;
        top: -40px;
        z-index: 1;
      }
    }
    &__products {
      .search-filter {
        border-top: 1px solid $color-white-smoke;
        float: none;
        padding: 0;
        width: 100%;
        @include breakpoint($large-up) {
          border-top: 1px solid $color-border;
          clear: right;
          float: left;
          margin: 10px 0;
          padding: 12px 0;
          width: 11.71875%;
        }
        .breadcrumbs {
          @include breakpoint($large-up) {
            margin-bottom: 18px;
            border-bottom: 1px solid $color-border;
          }
          &__results {
            background-color: $color-white-smoke;
            padding-bottom: 2px;
            @include breakpoint($large-up) {
              background-color: transparent;
              padding-bottom: inherit;
            }
          }
        }
        .results__header {
          font-size: 14px;
          &--column {
            text-align: center;
            @include breakpoint($large-up) {
              height: 100%;
              position: relative;
              display: block;
              text-align: left;
            }
          }
          &--breadcrumbs,
          &--dimensions {
            .results-header {
              cursor: pointer;
              margin: 8px 0 8px 20px;
              text-align: left;
              @include breakpoint($large-up) {
                border-bottom: 1px solid $color-border;
                padding: 0 0 8px 10px;
                margin: 0 0 8px;
              }
            }
            .breadcrumb {
              &__header {
                text-align: left;
                margin: 0 0 0 20px;
                padding: 5px 0;
                @include breakpoint($large-up) {
                  border-bottom: none;
                  padding: 0 0 6px;
                }
              }
              &__results {
                @include breakpoint($large-up) {
                  margin-left: 10px;
                }
              }
            }
            .dimension {
              text-transform: uppercase;
              background-color: $color-white-smoke;
              padding-bottom: 5px;
              @include breakpoint($large-up) {
                border-bottom: 1px solid $color-border;
                margin-bottom: 8px;
                padding: 0 0 8px 10px;
                background-color: transparent;
              }
              &.active {
                .dimension__header {
                  @include icon('minus');
                }
              }
              &__header {
                @include icon('plus');
                font-weight: bold;
                letter-spacing: 0.05em;
                padding: 10px 0 10px 20px;
                background: transparent;
                text-align: left;
                font-size: 12px;
                position: relative;
                cursor: pointer;
                @include breakpoint($large-up) {
                  padding: 10px 0;
                }
                &:before {
                  position: absolute;
                  top: 16px;
                  right: 5%;
                  font-size: 7px;
                  @include breakpoint($large-up) {
                    right: 8px;
                  }
                }
              }
              &__results {
                &--link {
                  text-align: left;
                  padding-left: 30px;
                  background-color: $color-white;
                  border-left: 10px solid $color-white-smoke;
                  border-right: 10px solid $color-white-smoke;
                  border-bottom: 1px solid $color-white-smoke;
                  @include breakpoint($large-up) {
                    border: none;
                    padding-left: inherit;
                  }
                }
              }
            }
          }
        }
        .breadcrumbs__link,
        .omnifilter {
          display: block;
          padding: 14px 0;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.035em;
          border-bottom: 1px solid $color-white-smoke;
          text-decoration: none;
          cursor: pointer;
          text-transform: uppercase;
          &:last-child {
            border-bottom: 0;
          }
          @include breakpoint($large-up) {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 0;
            margin: 0 0 8px 0;
            border: 0;
            word-break: break-word;
            transition: border 0.2s;
            &:hover {
              color: $color-green;
              opacity: 1;
            }
          }
        }
        .breadcrumbs__link {
          @include icon('close');
          text-align: left;
          padding: 7px 20px 8px 30px;
          background-color: $color-white;
          margin-bottom: 8px;
          position: relative;
          border-left: 10px solid $color-white-smoke;
          border-right: 10px solid $color-white-smoke;
          border-bottom: 1px solid $color-white-smoke;
          @include breakpoint($large-up) {
            border: 1px solid #c2c2c2;
            padding: 7px 20px 8px 8px;
            border-radius: 4px;
          }
          &:last-child {
            @include breakpoint($large-up) {
              border: 1px solid $color-lighter-gray;
            }
          }
          &:before {
            position: absolute;
            top: 9px;
            right: 20px;
            color: $color-green;
            font-size: 12px;
            @include breakpoint($large-up) {
              color: $color-text;
              right: 6px;
              top: 11px;
              font-size: 9px;
            }
          }
        }
      }
      .product-grid-wrapper {
        padding: 30px 0;
        @include breakpoint($large-up) {
          clear: none;
          float: right;
          margin-left: 0.78125%;
          padding: 0;
          width: 85.15625%;
        }
        @include breakpoint($tablet-only) {
          padding: 30px 20px;
        }
      }
    }
  }
}

.search-results {
  &__header {
    padding: 30px 20px 0;
    display: none;
    @include breakpoint($large-up) {
      display: block;
    }
    .search-form {
      &__fields {
        margin: 20px 0;
      }
    }
  }
}
