///
/// Common styles shared between product_brief_v1, product_quickshop_v1, spp_product_layout_v1. Defaults are set for product_brief_v1
///
///

.product {
  &-name,
  &__name,
  &__subline,
  &-item__name,
  &-brief__title {
    @include font--subheading();
    font-size: 17px;
    line-height: 19px;
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: 22px;
    }
    .product-quickshop & {
      @include breakpoint($medium-up) {
        font-size: 22px;
        line-height: 26px;
      }
    }
  }
}

.product {
  &-subline,
  &__description-short {
    @include font--subheading();
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    opacity: 0.8;
    margin: 7px 0 0 0;
    @include breakpoint($medium-up) {
      opacity: 0.8;
      font-size: 14px;
      line-height: 18px;
    }
  }
  &__description-short {
    width: 100%;
    overflow: auto;
  }
}

.product {
  &-name,
  &-subline,
  &__subline,
  &__name,
  &-item__name {
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.product {
  &-price,
  &-sku-price,
  &__price {
    font-size: 14px;
    line-height: 1;
    margin: 8px 0 0 0;
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
    .product-quickshop & {
      @include breakpoint($medium-up) {
        font-size: 15px;
        font-weight: 400;
        line-height: inherit;
        margin-top: 0;
      }
    }
    .product-full & {
      margin: 0;
      line-height: inherit;
    }
    &__label,
    &__prefix {
      .product-brief &,
      .product-quickshop & {
        color: $color-medium-gray;
        text-transform: lowercase;
      }
    }
  }
  &-price-installment {
    font-weight: normal;
  }
}

.product-brief .product-price__label--shade {
  text-transform: none;
}

.product-sku-unit-price {
  color: $color-medium-gray;
  font-style: italic;
  margin-#{$ldirection}: 10px;
}

.product-flag {
  position: absolute;
  top: 30px;
  #{$rdirection}: 20px;
  z-index: 1;
  @include breakpoint($small-down) {
    .product-grid--small-cols-2 &,
    .product-grid--small-cols-3 &,
    .product-grid--small-cols-4 & {
      top: 20px;
      #{$rdirection}: 10px;
    }
  }
  @include breakpoint($medium-up) {
    top: 10px;
    #{$rdirection}: 10px;
  }
  .product-quickshop & {
    top: 50px;
    #{$rdirection}: 50px;
    @include breakpoint($medium-up) {
      top: 35px;
      #{$rdirection}: 10px;
    }
  }
  .product-full & {
    top: 30px;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-up) {
      top: 60px;
      #{$rdirection}: 40px;
    }
  }
  &__badge {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    border: 1px solid $color-green;
    color: $color-green;
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      opacity: 1;
    }
    @include breakpoint($medium-up) {
      width: 50px;
      height: 50px;
      font-size: 11px;
      line-height: 11px;
    }
    &--best-sellers,
    &--best-seller,
    &--bestsellers,
    &--bestseller {
      @include font-smoothing();
      background: $color-green;
      color: $color-white;
      border: 0;
    }
    &--new,
    &--newshade,
    &--new-shade,
    &--preorder,
    &--pre-order {
      @include font-smoothing();
      color: $color-white;
      background: $color-black;
      border: 0;
    }
    // drop graphic if word is too long. see js
    &--no-graphic {
      border: 0;
      background: transparent;
      color: $color-text;
    }
  }
}

.product-add-to-cart,
.product-add-to-waitlist {
  .button {
    .product-full & {
      min-width: 190px;
    }
  }
}

.product-add-to-favorites {
  .link {
    @include link--secondary;
    font-size: 10px;
    text-transform: uppercase;
    div {
      display: inline;
    }
  }
  .product-quickshop &,
  .product-full & {
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 10px;
    }
  }
}

.product-image {
  display: block;
  margin: auto;
  &-link {
    position: relative;
    display: block;
  }
  &--med {
    width: 100%;
    max-width: 340px;
  }
  &--custom {
    @include fluid-image;
  }
}

@if $cr22 {
  .product-full-v2 {
    .product-image--med {
      max-width: unset;
    }
  }
}

.product {
  &-rating,
  &__ratings {
    margin: 8px 0 0 0;
    @include breakpoint($medium-up) {
      margin-top: 12px;
    }
    .review-snippet {
      .pr-category-snippet__total,
      .pr-snippet-rating-decimal {
        display: none;
      }
    }
    &--no-stars {
      display: none;
    }
    .product-quickshop & {
      @include breakpoint($medium-up) {
        margin: 0;
      }
    }
    .product-full & {
      margin: 0;
    }
  }
}

.product-inventory-status {
  .product-quickshop & {
    margin: 10px 0;
  }
  &__item {
    margin: 10px 0 0 0;
    color: $color-light-gray;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.035em;
    @include breakpoint($medium-up) {
      font-size: 13px;
    }
    .product-quickshop & {
      margin: 0;
    }
    .product-full & {
      margin: 0 0 10px 0;
      font-size: 15px;
      @include breakpoint($medium-up) {
        margin: 0 0 10px 3px;
      }
    }
  }
}
//Preorder functionality
.preorder-button-disabled {
  background-color: $color-gray;
  pointer-events: none;
  border: 1px solid $color-gray;
}

.pre-order-container,
.preorder-status-display {
  .pre-order {
    &_active_msg,
    &_sold_msg,
    &_tos_msg {
      font-style: normal;
      font-size: 14px;
      color: inherit;
      .product-full & {
        @include breakpoint($medium-up) {
          font-size: 15px;
        }
      }
    }
  }
}

.pre-order-container {
  display: block;
}

.preorder-status-display {
  font-size: 13px;
}

.preorder {
  &_msg_disp,
  &_date_disp {
    font-weight: bold;
  }
}

.product-sku-size {
  display: none;
}

.product-size {
  margin: 0 0 15px 0;
  .product-quickshop & {
    display: none;
  }
}

.product-shadename {
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 15px;
  text-transform: none;
  padding: 0 0 4px 6px;
  .product-full__mobile-panels & {
    padding: 0 0 5px 5px;
  }
}

.product-section-title {
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 10px 0;
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
  &__label {
    letter-spacing: 0;
    text-transform: none;
    font-weight: normal;
    padding-#{$ldirection}: 3px;
    // hide sku selection text on quickshop
    .product-quickshop__sku & {
      display: none;
    }
  }
  .product-full & {
    display: none;
  }
}

.sku-list {
  margin: 0 0 5px 0;
  @include breakpoint($medium-up) {
    margin-bottom: 12px;
    padding: 0 0 5px 0;
    border-bottom: 1px solid $color-extra-light-gray;
  }
  &__list {
    @include pie-clearfix;
  }
  &__item {
    float: $ldirection;
    margin: 0 6px 10px 0;
    @include breakpoint($medium-up) {
      margin: 0 10px 10px 0;
    }
    .button {
      @include swap_direction(padding, 13px 20px 14px);
    }
  }
  &__button {
    text-transform: none;
    @include breakpoint($medium-up) {
      min-width: 0;
    }
  }
  .product-full & {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

.product-social {
  &__list {
    position: relative;
    @include breakpoint($medium-up) {
      @include pie-clearfix;
    }
  }
  &__item {
    margin: 10px 0 0 0;
    padding: 0;
    @include breakpoint($medium-up) {
      float: $ldirection;
      margin: 0 0 0 10px;
    }
  }
  &__link {
    text-decoration: none;
    display: block;
    position: relative;
    padding: 0 1px;
    .icon {
      font-size: 28px;
      cursor: pointer;
      @include breakpoint($medium-up) {
        font-size: 36px;
      }
      &--svg {
        fill: $color-black;
        width: 30px;
        height: 28px;
        transition: 0.3s all ease;
        border-radius: 4px;
        &:hover {
          fill: $color-white;
          background: $color-green;
        }
        @include breakpoint($medium-up) {
          width: 38px;
          height: 36px;
        }
      }
    }
  }
  &__item--pinterest {
    .icon--pinterest--square--filled {
      opacity: 0;
      position: absolute;
      top: 0;
      #{$ldirection}: 1px;
      color: $color-green;
      transition: opacity 0.3s;
    }
    .product-social__link:hover {
      .icon--pinterest--square {
        opacity: 0;
      }
      .icon--pinterest--square--filled {
        opacity: 1;
      }
    }
  }
  &__item--facebook {
    .icon--facebook {
      padding: 4px;
      border: 1px $color-gray solid;
      border-radius: 3px;
      height: 30px;
      width: 30px;
      transition: background 0.3s;
      @include breakpoint($medium-up) {
        padding: 6px;
        border-radius: 4px;
        height: 36px;
        width: 40px;
      }
      &:hover {
        background-color: $color-green;
        border: 1px $color-green solid;
        fill: $color-white;
      }
    }
  }
  &__qrcontent {
    position: absolute;
    #{$rdirection}: 50px;
    top: 0;
    width: 100px;
    @include breakpoint($medium-up) {
      bottom: 50px;
      #{$rdirection}: 0;
      top: auto;
      width: 150px;
    }
  }
}

.product-full--black {
  &.product-full {
    .product-social__link {
      .icon--svg {
        fill: $color-white;
        &:hover {
          fill: $color-black;
        }
      }
    }
  }
}

// Tooltip used for the "add to wishlist" CTA
.tooltipster-cremedelamer2 {
  background: $color-mpp-light-gray;
  color: $color-black;
  max-width: 300px;
  a {
    color: $color-black;
    text-decoration: underline;
  }
  .tooltipster-content {
    @include font--text;
    font-size: 13px;
    line-height: normal;
    overflow: hidden;
    padding: 10px;
    text-transform: uppercase;
    // White and grey border
    &::after {
      content: '';
      position: absolute;
      top: -10px;
      #{$rdirection}: -10px;
      bottom: -10px;
      #{$ldirection}: -10px;
      z-index: -1;
      border: 10px solid $color-white;
      box-shadow: 0px 0px 0px 1px $color-border;
    }
  }
  // Message shown when there's no registered user
  .anon-message {
    display: block;
    text-transform: none;
    body.signed-in & {
      display: none;
    }
  }
}

.add_cart_response {
  #cboxLoadedContent {
    padding: 0;
  }
  .cart-error-container {
    margin: 25px 10px 20px;
    text-align: center;
  }
}

.product-replenishment-select {
  margin-bottom: 15px;
  display: grid;
  column-gap: 10px;
  grid-template-rows: [header] 40px [buttons] auto;
  grid-template-columns: 1fr 3fr;
  @include breakpoint($medium-up) {
    margin-bottom: 15px;
    padding: 0 0 15px 0;
    grid-template-columns: 1fr 220px;
  }
  .product-full & {
    grid-template-rows: [header] 0 [buttons] auto;
  }
  .product-section-title {
    grid-column: 1 / span 2;
    align-self: center;
  }
  &__one-time,
  &__auto-delivery {
    grid-row: 2;
  }
  &__one-time {
    &--btn {
      @include typestyle--b1;
      padding: 20px 22px;
      text-transform: none;
      justify-content: center;
      display: flex;
      height: auto;
      cursor: pointer;
    }
  }
  &__auto-delivery {
    display: grid;
    grid-template-columns: 1fr 15px;
  }
  select,
  &__select.selectBox {
    @include typestyle--b1;
    @if cr22 {
      @include swap_direction(padding, 20px 22px 20px 15px);
    } @else {
      padding: 20px 22px;
    }
    width: auto !important; // overriding js set inline width in favor of filling grid area
    max-width: 100%;
    border: 1px solid $color-black;
    background-color: $color-white;
    height: auto;
    position: relative;
    overflow: hidden;
    &:focus {
      color: $color-dark-gray;
    }
    &.selectBox-menuActive {
      span.selectBox-arrow {
        @include transition(opacity .25s ease-in-out);
        opacity: 0;
        @include breakpoint($med-small-up) {
          opacity: 1;
        }
      }
      &:hover {
        span.selectBox-arrow {
          @include transition(opacity .25s ease-in-out);
          opacity: 1;
        }
      }
    }
    span.selectBox-arrow {
      background-color: $color-white;
      padding: 0 15px 2px 20px;
      height: 100%;
      top: 0;
      right: 0;
      &:after {
        top: calc(50% - 4.5px);
        #{$ldirection}: calc(50% - 4.5px);
      }
      @include breakpoint($med-small-up) {
        background-color: transparent;
      }
    }
    &.selectBox-menuShowing {
      background: $color-white;
    }
    &.selectBox-menuActive {
      font-weight: bold;
      background-color: $color-white;
      border-color: $color-black;
    }
    .selectBox-label {
      padding-#{$ldirection}: 0;
    }
  }
  &__select-selectBox-dropdown-menu {
    max-height: 230px;
    &.selectBox-options {
      @include typestyle--b1;
      li a:hover {
        color: $color-white;
        @if $cr22 {
          background: $color-light-black;
        } @else {
          background: $color-neutral-dark-gray;
        }
      }
      .selectBox-selected a {
        @if $cr22 {
          background: $color-light-black;
        } @else {
          background: $color-neutral-dark-gray;
        }
      }
    }
  }
  &__overlay-link {
    cursor: pointer;
    text-decoration: none;
    margin-#{$ldirection}: 5px;
    align-self: center;
    position: relative;
    &:hover {
      color: $color-black;
    }
  }
}
