@mixin h13 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 10px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
  @include breakpoint($medium-up) {
    font-size: 14px;
    padding: 0 55px;
  }
}

@mixin facebook_button_divider {
  font-weight: normal;
  font-size: 17px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 43%;
    left: 0;
  }
  .inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
}

.social-login-section {
  text-align: center;
  .social-declare-text {
    display: none;
  }
  .social-login {
    .social-login-buttons {
      position: relative;
      margin-bottom: 10px;
      &__blocker {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        opacity: 0.5;
        &-hidden {
          display: none;
        }
      }
    }
    .facebook-logo {
      position: absolute;
      margin-top: 9px;
      left: 0;
      right: 0;
      cursor: pointer;
      span {
        padding-left: 10px;
        color: $color-white;
        font-size: 17px;
      }
    }
    position: relative;
    margin: 20px 0;
    .facebook-button {
      color: $color-white;
      background-color: $color-fb-blue;
      border: none;
      height: 47px;
      width: 100%;
      letter-spacing: 0.25px;
      border-radius: 4px;
    }
    &__container {
      text-align: center;
      padding: 0 0 20px;
      margin-top: 20px;
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      width: 254px;
      margin: auto;
    }
    @media (min-width: 768px) {
      &__container {
        margin-top: 0;
      }
      padding: 0;
    }
    &__divider {
      @include facebook_button_divider;
      width: 100%;
      color: $color-light-gray;
      font-family: $font--neue-haas-unica-pro;
      margin: 0 auto 26px auto;
      &:before {
        background: $color-light-gray;
        height: 1px;
      }
    }
    &__terms {
      p {
        margin: 0;
      }
    }
    &__title,
    &__info {
      display: none;
    }
  }
}

.new-account {
  .social-login-section {
    .social-declare-text {
      display: block;
    }
  }
}
/* FB checkout signin page design */
.checkout-panel {
  &--sign-in-main {
    .social-login {
      &__divider {
        @include facebook_button_divider;
        width: 100%;
        margin: 0;
        color: $color-light-gray;
        font-family: $font--neue-haas-unica-pro;
        &:before {
          background: $color-light-gray;
          height: 1px;
        }
      }
    }
  }
  .social-login-section {
    margin-top: 30px;
    .social-login {
      .facebook-button {
        width: 100%;
      }
      &__divider {
        width: 100%;
      }
      &__terms {
        padding-bottom: 20px;
        border-bottom: 1px solid $color-light-gray;
        text-align: left;
      }
      &__email-opt-in {
        text-align: left;
        padding-bottom: 10px;
      }
    }
  }
}
/* GNAV styling */
.gnav-util__content--account {
  @include breakpoint($medium-up) {
    height: 550px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .gnav-util__content-inner {
    .social-login {
      .social-login-buttons {
        position: relative;
        &__blocker {
          position: absolute;
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: $color-white;
          opacity: 0.5;
          &-hidden {
            display: none;
          }
        }
      }
      &__container {
        display: block;
      }
      &__terms {
        @include breakpoint($medium-up) {
          padding-top: 70px;
        }
        a {
          text-decoration: underline;
        }
      }
      &__email-opt-in {
        padding-top: 10px;
      }
    }
  }
}

.site-header__utility {
  .gnav-util__content--account {
    .social-login {
      .fb-login-button {
        @include breakpoint($medium-up) {
          position: inherit !important;
        }
      }
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  @include breakpoint($landscape-up) {
    .new-account__container {
      .new-account__social {
        vertical-align: top;
      }
      .social-login__divider {
        &:before {
          height: 425px;
        }
      }
    }
    .social-login {
      &__container {
        top: 3px;
      }
      &__divider {
        text-transform: uppercase;
        &:before {
          content: '';
          background: $color-light-gray;
          width: 1px;
          top: 3%;
          #{$ldirection}: 50%;
          display: inline-block;
          position: absolute;
          height: calc(100% - 12%);
        }
        .inner {
          background: $color-white;
          margin-#{$rdirection}: 0;
          top: 37%;
          #{$ldirection}: 49%;
          position: absolute;
          color: $color-light-gray;
        }
      }
    }
    .return-user__social {
      vertical-align: top;
    }
    .social-login-section {
      .social-login {
        &__divider {
          display: none;
        }
      }
    }
  }
  .social-login {
    &__email-opt-in {
      text-align: left;
      padding-bottom: 10px;
      padding-right: 3px;
      @include breakpoint($landscape-up) {
        padding-right: 30px;
      }
    }
    &__terms {
      text-align: left;
      padding-bottom: 10px;
    }
  }
}
/* FB Disconnect button styling */
#facebook_disconnect {
  display: inline;
  input.form-submit {
    margin-bottom: 4px;
    margin-left: 10px;
    padding: 4px;
    line-height: 30px;
    min-width: 100px;
    background-color: $white;
    color: $color-dark-gray;
    @include breakpoint($landscape-up) {
      margin-left: 120px;
      min-width: 140px;
    }
  }
}
/* FB Order confirmation page */
.checkout--confirmation-page {
  .social-login-section {
    padding: 1em;
    text-align: center;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
    .social-login {
      padding-bottom: 0;
      &__title {
        text-transform: uppercase;
        font-size: 18px;
        display: block;
        text-align: center;
      }
      &__info {
        display: block;
        text-align: left;
      }
      &__divider {
        display: none;
      }
      &__terms {
        min-width: 330px;
        display: none;
        @include breakpoint($landscape-up) {
          margin-left: 28%;
          width: 44%;
        }
        @include breakpoint($medium-up) {
          width: auto;
          margin-left: 0;
        }
      }
      &__email-opt-in {
        padding-bottom: 15px;
        text-align: left;
        @include breakpoint($landscape-up) {
          margin-left: 28%;
          width: 44%;
        }
        @include breakpoint($medium-up) {
          width: auto;
          margin-left: 0;
        }
      }
      .facebook-button {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 43%;
        }
      }
    }
  }
}
/* FB Order confirmation guest user */
.checkout-guest-registration {
  .checkout-panel--registration {
    .checkout-registration {
      .social-login__divider {
        @include facebook_button_divider;
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
        margin: 0 0 20px 0;
        display: block;
        color: $color-light-gray;
        font-family: $font--neue-haas-unica-pro;
        &:before {
          background: $color-light-gray;
          height: 1px;
        }
      }
    }
  }
  .social-login-section {
    padding: 0;
    width: 0;
    text-align: center;
    border-bottom: 0;
    .social-login {
      &__title,
      &__info {
        display: none;
        text-align: left;
      }
      &__divider {
        display: none;
        width: 350px;
      }
      &__container {
        right: 0;
        left: 50px;
      }
      .facebook-button {
        width: 352px;
      }
    }
  }
}
/* FB profile preference page */
.profile-page {
  &__content {
    .social-info {
      border-bottom: 1px solid $color-light-gray;
      &__content {
        padding: 0;
      }
      &__connect {
        padding-bottom: 10px;
      }
      &__header {
        border-top: 1px solid $color-light-gray;
      }
      &__lstatus {
        img {
          height: 20px;
          width: 20px;
          margin-right: 0;
          @include breakpoint($medium-only) {
            height: 38px;
            width: 38px;
          }
        }
      }
      &__rstatus {
        position: relative;
        @include breakpoint($medium-up) {
          margin-left: 0;
          float: none;
        }
        .form-submit {
          float: none;
          margin-top: 0;
        }
        input.connect-facebook-button {
          margin: 0 auto;
          background: $color-dark-gray;
          color: $white;
          border: 1px solid $color-dark-gray;
          border-radius: 4px;
          padding: 5px 10px 5px 5px;
          width: 61px;
          font-size: 10px;
          letter-spacing: 0.05em;
          cursor: pointer;
          text-transform: uppercase;
          height: 30px;
          @include breakpoint($medium-only) {
            width: 84px;
            height: 40px;
            padding: 9px;
            font-size: 11px;
          }
          @include breakpoint($med-small-up) {
            display: inline-block;
            margin-left: 20px;
          }
          @include breakpoint($landscape-up) {
            margin-left: 50px;
            width: 140px;
          }
          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
            background: $color-green;
            color: $white;
            border-color: $color-green;
          }
        }
        .fb-login-button {
          position: absolute;
          left: 0;
          right: 0;
          height: 35px;
          display: none;
          @include breakpoint($landscape-up) {
            left: 6px;
          }
        }
      }
    }
  }
}

.login__container,
.registration {
  .social-login {
    &__container {
      text-align: center;
      margin-top: 10px;
      .social-login {
        &__divider {
          @include h13;
          width: 100%;
          color: $color-light-gray;
          font-family: $font--neue-haas-unica-pro;
          &:before {
            background: $color-light-gray;
            height: 1px;
          }
        }
        &__terms {
          padding-top: 10px;
        }
      }
    }
  }
}

.checkout {
  &__content {
    .checkout-panel--confirmation {
      border-bottom: none;
    }
  }
}

.account-profile__connected-accounts {
  text-transform: uppercase;
}

.social-login {
  &__container {
    display: none;
  }
}
/* Account Signin Page */
.new-account__fieldset {
  .new-account__social {
    .social-login-section {
      @include breakpoint($medium-up) {
        margin-top: 27%;
      }
    }
  }
}
// signin page redesign
@if $signin-redesigned {
  .social-login-section {
    .social-login {
      .facebook-logo {
        text-align: #{$ldirection};
        img {
          #{$ldirection}: 4%;
          position: absolute;
        }
        span {
          margin-#{$ldirection}: 32%;
        }
      }
    }
  }
  .social-login {
    &__section {
      text-align: center;
      &__divider {
        .horizontal-separator {
          border-top: 1px solid $color-light-gray;
          margin-top: 0;
          height: 2px;
          .inner {
            #{$ldirection}: 50%;
            position: absolute;
            color: $color-light-gray;
            background: $color-white;
            width: 40px;
            height: 40px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .new-account__fieldset {
    width: 490px;
    float: #{$rdirection};
    .new-account__social {
      .social-login-section {
        @include breakpoint($medium-up) {
          margin-top: 16%;
        }
      }
    }
  }
}
