.generic-helplinks-overlay {
  width: 630px;
  height: 550px;
  .customer-service {
    padding: 0;
    margin: 0;
    &_anchor,
    &__navigation {
      display: none;
    }
    &__content {
      width: 100%;
    }
    &__title,
    &__contact_info,
    &__menu-mob {
      display: none;
    }
  }
  #cboxLoadedContent {
    @include breakpoint($medium-up) {
      max-height: 500px;
      overflow: auto;
    }
  }
}

.links-panel {
  &__link {
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
