.content-block--columns {
  padding-bottom: 30px;
  @include breakpoint($landscape-up) {
    padding-bottom: 0;
  }
  .content-block__content {
    @include breakpoint($landscape-up) {
      display: flex;
      width: 100%;
    }
  }
  .basic-carousel-formatter {
    margin-bottom: 0;
  }
  .slick-prev {
    @include breakpoint($landscape-up) {
      left: -40px;
    }
  }
  .slick-next {
    @include breakpoint($landscape-up) {
      right: -40px;
    }
  }
  .content-block__column {
    position: relative;
    padding: 30px 0;
    .content-block__column-image {
      display: block;
      margin: auto;
      position: relative;
      z-index: 2;
    }
  }
  .content-block__column-images {
    position: relative;
    max-width: 70%;
    margin: auto;
    @include breakpoint($landscape-up) {
      max-width: 100%;
    }
  }
  .content-block__column-content {
    position: relative;
    z-index: 2;
  }
  .content-block__column--1 {
    @include breakpoint($landscape-up) {
      max-width: 25%;
    }
    .content-block__column-content {
      @include breakpoint($landscape-up) {
        display: flex;
        flex-direction: column;
        padding-top: 32px;
      }
    }
    .content-block__column-text {
      @include heading--1;
      line-height: 1;
      text-align: center;
      padding: 0 30px 50px 30px;
      @include breakpoint($landscape-up) {
        padding: 0;
        font-size: 24px;
        order: 2;
        margin-top: 50px;
      }
    }
    .content-block__bg-image {
      top: -16px;
      right: -30px;
      transition: all 0.35s ease-in-out;
      @include breakpoint($landscape-up) {
        top: 127px;
        right: -60px;
      }
    }
  }
  .content-block__column--2 {
    .content-block__column-content {
      max-width: 70%;
      margin: auto;
      text-align: center;
      @include breakpoint($landscape-up) {
        max-width: 100%;
        margin-left: 103px;
        margin-right: 74px;
      }
    }
    .content-block__column-text {
      margin: 15px auto 20px;
    }
    @include breakpoint($landscape-up) {
      flex-grow: 1;
      max-width: 53%;
    }
  }
  .content-block__column--3 {
    @include breakpoint($landscape-up) {
      max-width: 25%;
      padding-right: 37px;
    }
    .content-block__column-content {
      @include breakpoint($landscape-up) {
        padding-top: 80px;
        padding-bottom: 0;
      }
    }
    .content-block__bg-image {
      top: -18px;
      right: -35px;
      width: 125px;
      @include breakpoint($landscape-up) {
        width: 150px;
        top: -60px;
        right: -37px;
      }
    }
  }
  .content-block__bg-image {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
  }
}
