// Scroll fade into
.scroll-animation {
  transition: all 0.5s ease-in-out;
  transform: translateY(120px);
  opacity: 0;
  &.transition {
    transform: translateY(0);
    opacity: 1;
  }
}

// Content block reveal / Expanders
.animate {
  transition: all 0.5s ease-in-out;
  transform: translateY(-40px) scale(0.8);
  opacity: 0;
  max-height: 0;
  visibility: hidden;
  &.animating {
    transform: translateY(0px) scale(1);
    opacity: 1;
    max-height: 100vh;
    visibility: visible;
    overflow: hidden;
    &.done {
      max-height: none;
      overflow: visible;
    }
  }
}

.animate--no-scale {
  transform: translateY(0px) scale(1);
  transition: all 0.3s ease;
}
