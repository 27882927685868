.kit-formatter {
  .product-kit {
    &__content {
      .product-full__name {
        text-transform: capitalize;
        margin-bottom: 5px;
        letter-spacing: -0.4px;
        @include breakpoint($medium-up) {
          font-size: 34px;
          float: none;
          width: auto;
          letter-spacing: -1.5px;
        }
      }
      .product-full__desc {
        margin: 10px auto 12px;
        width: 90%;
        @include breakpoint($medium-up) {
          margin: 0 auto 14px;
          width: 100%;
        }
      }
      .collapsible-menu__parent {
        border-top: 1px solid $border-color-normal;
        font-weight: bold;
      }
      .collapsible-menu,
      .collapsible-menu__parent {
        position: relative;
        list-style-type: decimal;
        list-style-position: inside;
        &.sold-out-header {
          background: $kit-gradient-bg;
        }
      }
      .collapsible-menu__parent--expanded .collapsible-sub-menu {
        max-height: 600px;
      }
      .collapsible-sub-menu {
        transition: max-height 0.7s;
        max-height: 0;
        overflow: hidden;
        display: block;
        text-align: center;
        @include breakpoint($medium-up) {
          text-align: $ldirection;
        }
        .accordion-content {
          text-align: center;
          display: inline-block;
          width: 100%;
        }
      }
      .collapsible-menu__parent:last-of-type {
        border-bottom: 1px solid $border-color-normal;
        margin-bottom: 20px;
      }
      .sku-count,
      .selected-sku-count {
        display: none;
      }
      .dynamic-pricing {
        &__active {
          font-size: 13px;
          font-weight: normal;
          &-per-ml {
            color: #{$color-light-grey};
            font-style: italic;
          }
        }
        .product-inactive {
          opacity: 0.5;
        }
        .product-kit {
          &__out-of-stock {
            @extend .product-inactive;
            display: block;
          }
          &__products {
            text-align: #{$ldirection};
            &-list {
              text-align: center;
              width: 32%;
              @include breakpoint($medium-up) {
                width: 23%;
              }
            }
          }
          &__button {
            @extend .button;
            width: 314px;
            @include breakpoint($medium-up) {
              float: #{$rdirection};
              width: 46.5%;
            }
            &.in-active {
              @extend .button--disabled;
              .add-to-set {
                display: block;
              }
            }
          }
          &__image-container {
            &.selected {
              .product-kit__image {
                border: none;
                border-bottom: 1.5px solid #{$color-black};
                border-radius: 0;
              }
            }
          }
          &-menu__item {
            color: #{$color-black};
            .button {
              display: inline-block;
            }
          }
        }
        .collapsible-menu {
          &__parent--expanded {
            .sku-count {
              display: block;
            }
            .collapsible-sub-menu {
              max-height: 940px;
            }
          }
        }
        .selected-sku-count {
          display: inline-block;
          font-family: $font--text;
          font-size: 14px;
          font-style: italic;
          font-weight: normal;
          margin-bottom: 15px;
          @include breakpoint($medium-up) {
            margin: 10px 0;
          }
        }
        .product-checked {
          background: url(/media/images/icons/tick_unselected.svg) no-repeat;
          background-size: cover;
          height: 24px;
          margin: auto;
          width: 24px;
        }
        .active-product {
          .product-kit {
            &__image {
              border-bottom: 1.5px solid #{$color-black};
            }
          }
          .product-checked {
            background: url(/media/images/icons/tick_selected.svg) no-repeat;
            background-size: cover;
          }
        }
      }
    }
    &__title {
      font-family: $font--neue-haas-unica-pro;
      text-transform: uppercase;
      display: inline-block;
      width: 93%;
      position: static;
      padding: 12px 0;
      font-weight: 600;
      font-size: 14px;
      &-last {
        display: none;
      }
      @include breakpoint($medium-up) {
        width: 95%;
      }
      @include breakpoint($sticky-breakpoint) {
        width: 93%;
      }
    }
    &__transparent {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $kit-gradient-bg;
      z-index: 1;
      #{$ldirection}: 0;
      display: none;
      &.active {
        display: block;
      }
    }
    &__title:after {
      top: 15px;
      #{$rdirection}: 0;
      position: absolute;
    }
    &__selected-list {
      display: none;
      text-align: center;
      margin: 0 auto;
      clear: both;
      width: 300px;
      @include breakpoint($medium-up) {
        width: 500px;
      }
      .product-full__name {
        text-transform: capitalize;
        float: none;
        width: auto;
        text-align: center;
        margin: 24px auto 2px;
      }
      .product-full__price {
        float: none;
      }
      &.active {
        display: block;
        @include breakpoint($sticky-breakpoint) {
          display: none;
        }
      }
    }
    &__edit-selected-products {
      width: 40%;
      display: inline-block;
    }
    &__details-small {
      width: 80%;
      margin: 0 auto 20px;
    }
    &__products {
      text-align: center;
      padding: 0 0 23px;
      overflow: hidden;
    }
    &__products-list {
      width: 23%;
      display: inline-block;
    }
    &__edit-product {
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 11px;
    }
    &__image-container {
      &.selected {
        .product-kit__image {
          border: 1px solid $color-black;
          border-radius: 10px;
        }
      }
      &.active-product {
        .product-kit__image {
          border-bottom: 1px solid $color-black;
        }
      }
      &.in-active {
        display: none;
      }
    }
    &__add-to-bag-small {
      &.button--disabled {
        pointer-events: none;
      }
    }
    &__cta-container {
      .product-kit__add-to-bag {
        margin: 0 0 15px;
        &.button--disabled {
          pointer-events: none;
        }
      }
    }
    &__button {
      font-weight: 400;
      width: 72%;
      background-color: $color-light-sand;
      color: $color-black;
      margin: 0 0 30px;
      .add-to-set {
        display: block;
      }
      .sold-out {
        display: none;
      }
      &.in-active {
        border-color: $color-lightest-grey;
        color: $color-lightest-grey;
        cursor: default;
        &:hover {
          border-color: $color-lightest-grey;
        }
        .add-to-set {
          display: none;
        }
        .sold-out {
          display: block;
        }
      }
      @include breakpoint($medium-up) {
        width: 46.5%;
        margin: 0 0 21px;
      }
      &:hover {
        border-color: $color-dark-gray;
      }
    }
    &__sticky {
      .sticky-add-to-bag__container {
        .sticky-add-to-bag__description__container {
          width: 40%;
          .product-full__name {
            font-size: 25px;
            margin: 0;
            text-transform: capitalize;
            letter-spacing: -1.5px;
          }
        }
        .sticky-add-to-bag__image-single {
          display: none;
          width: 80px;
          &.active {
            display: block;
          }
          .sticky-add-to-bag__image {
            height: auto;
          }
        }
      }
      .sticky-msg-container {
        position: absolute;
        #{$rdirection}: 30px;
        bottom: 10px;
      }
      &.product-multi-select__sticky {
        .sticky-add-to-bag__container {
          .sticky-add-to-bag {
            &__description-container {
              width: 25%;
            }
            &__description {
              width: 100%;
            }
          }
        }
      }
    }
    &__items-container {
      position: relative;
    }
    &__add-to-bag {
      width: 190px;
      margin: 10px;
      .sold-out {
        display: none;
      }
      &.button-inactive {
        background-color: $color-gray;
        cursor: default;
        .add-to-bag {
          display: none;
        }
        .sold-out {
          display: block;
        }
      }
      &-small {
        width: 87%;
        z-index: 99;
        margin: 10px 0;
        position: fixed;
        bottom: 0;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
        @include breakpoint($sticky-breakpoint) {
          display: none;
        }
        .sold-out {
          display: none;
        }
        &.button-inactive {
          background-color: $color-gray;
          cursor: default;
          .add-to-bag {
            display: none;
          }
          .sold-out {
            display: block;
          }
        }
      }
    }
    &__image-block {
      text-align: center;
      .product-full__carousel__slide {
        display: block;
        max-width: 485px;
        margin: 0 auto;
        .product-full__carousel__slide-img {
          height: 220px;
          @include breakpoint($medium-up) {
            height: 485px;
          }
        }
      }
    }
    &__product {
      &-name {
        margin-bottom: 10px;
        font-weight: normal;
        @include breakpoint($medium-up) {
          font-size: 25px;
          border-bottom: 0;
        }
      }
      &-description {
        font-weight: normal;
        line-height: 19px;
        @include breakpoint($medium-up) {
          font-size: 15px;
        }
      }
    }
    &__default-image {
      display: none;
      &.active {
        display: block;
      }
    }
    &-menu__item {
      &.active-cat {
        color: $color-green;
        .product-kit__title {
          .product-kit__title-last {
            display: inline-block;
          }
          .product-kit__title-start {
            display: none;
          }
        }
        .product-kit__button {
          display: none;
        }
      }
    }
  }
  .product-upsell-default__price-a,
  .product-upsell-default__price-b,
  .product-default__price {
    display: none;
    padding-#{$ldirection}: 4px;
    &.active {
      display: block;
    }
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 1px;
    }
  }
  @include breakpoint($landscape-up) {
    .product-full__media {
      min-height: 550px;
    }
  }
  .product-full__image-thumbnails {
    #{$ldirection}: auto;
    transform: none;
    top: 60px;
    .product-full-kit__thumbnail {
      display: none;
      width: 100px;
      margin: 0;
      &.active {
        display: block;
        .product-full__carousel__thumbnail-img {
          border: none;
        }
      }
    }
  }
}

.custom-kit-page {
  #lamer_sticky_pc {
    display: none;
  }
}
