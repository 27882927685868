///
/// customer service internal page
///
.customer-service {
  margin: 44px 0 0;
  max-width: none;
  padding: 0;
  width: 100%;
  @include pie-clearfix;
  @include breakpoint($medium-up) {
    margin: 44px 0;
    padding: 20px 20px 0;
  }
  @include breakpoint($tablet-only) {
    margin: 44px 0 0;
    padding: 20px 0 0;
  }
  &__title {
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
  }
  &__subtitle {
    font-family: 'La Mer Text', serif;
    font-size: 16px;
    font-weight: normal;
    margin: 25px 0 40px;
    text-align: center;
  }
  &__footer {
    position: relative;
  }
  &__navigation {
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;
    @include breakpoint($medium-up) {
      float: left;
      margin: 0 95px 0 0;
      padding: 0;
      width: 23%;
    }
    @include breakpoint($medium-portrait-only) {
      margin: 0 2% 0 0;
    }
    @include breakpoint($medium-landscape-only) {
      margin: 0 30px 0 0;
      width: 30%;
    }
  }
  &__tout {
    padding: 10px 0 0 0;
  }
  &__content {
    float: none;
    margin: 50px 0 10px;
    padding: 0 25px;
    width: 100%;
    @include breakpoint($medium-up) {
      float: left;
      margin: 0;
      padding: 0;
      width: calc(74% - 64px);
    }
    @include breakpoint($tablet-only) {
      float: none;
      margin: 0 30px;
      width: calc(100% - 60px);
    }
  }
  &_columns_container {
    @include pie-clearfix;
  }
  &__contact_info {
    display: block;
    text-align: center;
  }
  &__collapsible-block {
    border-bottom: 1px solid $color-light-green;
    font-family: 'La Mer Text', serif;
    font-size: 16px;
    padding: 0;
    position: relative;
    &:first-child {
      border-top: 1px solid $color-light-green;
    }
    .collapsible-block__title {
      cursor: pointer;
      padding: 25px 0;
      position: relative;
      span {
        display: inline-block;
        width: 93%;
        @include breakpoint($medium-up) {
          width: 96%;
        }
      }
      .collapsible-block__icon {
        @include icon('plus', after);
        &:after {
          float: right;
          font-size: 16px;
          margin-top: 0;
          right: 0;
        }
      }
    }
    .collapsible-block__content {
      font-family: 'Neue Haas Unica Pro', sans-serif;
      font-size: 15px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      &-item {
        font-size: 14px;
        margin-bottom: 20px;
        text-align: justify;
        @include breakpoint($medium-up) {
          font-size: 15px;
        }
      }
      ul {
        margin: 1em 20px;
        padding: 0;
        list-style-type: disc;
      }
      ol {
        counter-reset: li;
        li {
          list-style: none;
          &:before {
            content: counter(li);
            counter-increment: li;
            padding: 0 12px 0 0px;
          }
        }
      }
      .back_to_top {
        margin-bottom: 30px;
        text-decoration: underline;
      }
    }
  }
  .collapsible-block--alt {
    .collapsible-block__icon {
      @include icon('minus', after);
    }
    .collapsible-block__content {
      height: auto;
    }
  }
  &-privacy-page,
  &-terms-page {
    .customer-service__content {
      float: none;
      margin: 0 0 40px;
      text-align: justify;
      width: 100%;
    }
  }
}

.cs-contact-info {
  padding: 35px 0;
  position: relative;
  text-align: center;
  width: 100%;
  @include breakpoint($medium-up) {
    display: inline-table;
    width: 33%;
  }
  @include breakpoint($tablet-only) {
    width: 20%;
    &__call,
    &__livechat {
      margin-right: 25px;
    }
  }
  &__left {
    .cs-contact-info__image {
      float: left;
      margin-top: 6%;
      padding-left: 5%;
    }
    .cs-contact-info__title {
      font-size: 21px;
      line-height: 40px;
    }
    .cs-contact-info__description {
      a.link {
        font-size: 15px;
        line-height: 40px;
      }
    }
  }
  a {
    text-decoration: none;
  }
  span.icon {
    font-size: 3em;
    display: inline-block;
  }
  &__image {
    display: inline-block;
    margin: 0 auto;
  }
  h2 {
    display: inline-block;
    margin: 15px 0 0 10px;
    vertical-align: middle;
  }
  &__description {
    display: block;
    p {
      margin: 0;
    }
    a {
      &.link,
      &.link_phone {
        line-height: 50px;
        color: $color-green;
      }
    }
  }
  &__title {
    font-size: 24px;
    line-height: 60px;
  }
}

.cs-content-block {
  margin-bottom: 25px;
  &__title--mobile {
    display: none;
  }
  h3 {
    margin-bottom: 15px;
  }
  &:first-child {
    margin-top: 15px;
  }
  &__anchor {
    &:before {
      @media only screen and (min-width: 768px) {
        content: ' ';
        display: block;
        height: $layout_height;
        margin-top: -$layout_height;
        visibility: hidden;
      }
    }
  }
}

.video-container {
  .video-block {
    &.content-container {
      margin-top: 45px;
      max-width: none;
    }
  }
}

.cs-contact-info {
  &__left {
    border-right: 1px solid $color-light-green;
    border-top: 1px solid $color-light-green;
    @include breakpoint($medium-up) {
      padding-left: 70px;
    }
    &:last-child {
      border-right: none;
    }
  }
}

#colorbox .customer-service__content {
  padding: 0;
  @include breakpoint($medium-up) {
    padding: 0 25px;
  }
}

.customer-privacy-policy-wrapper {
  margin: 0 auto;
  max-width: 960px;
  overflow: hidden;
  .customer-service__content {
    width: 100%;
  }
  .cs-content-block {
    &__content {
      font-size: 13px;
      h1 {
        @include heading--1;
      }
      h2 {
        @include heading--2;
      }
      h3 {
        @include heading--3;
      }
    }
    p {
      padding: 10px 0 5px;
      margin: 0;
      color: $color-medium-gray;
    }
    ul {
      list-style-type: disc;
      padding-top: 10px;
      padding-#{$ldirection}: 30px;
      color: $color-medium-gray;
      a {
        color: $color-medium-gray;
        text-decoration: none;
        &:hover {
          color: $color-black;
        }
      }
    }
  }
  .left-list {
    @include breakpoint($medium-up) {
      width: 32%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .right-list {
    @include breakpoint($medium-up) {
      width: 66%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .back_to_top {
    text-align: #{$rdirection};
    a {
      color: $color-medium-gray;
      text-decoration: none;
    }
  }
}
