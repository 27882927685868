.filterable-products {
  &__result-shade-type {
    color: $color-white;
    #{$ldirection}: 0;
    top: 0;
    position: absolute;
    font-size: 13px;
    @include breakpoint($medium-up) {
      #{$ldirection}: 0;
      font-size: 17px;
    }
    &.perfect {
      color: $color-black;
    }
    &.darker {
      height: 100%;
      #{$ldirection}: 66.8%;
      top: 0;
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        top: 6%;
        #{$ldirection}: 12%;
        height: 81.1%;
      }
    }

    span {
      width: 100%;
      height: 100%;
      text-align: center;
      color: $black;
      font-family: $font--text;
      writing-mode: vertical-rl;
      padding-#{$rdirection}: 50%;
      text-orientation: mixed;
      transform: rotate(180deg);
      font-weight: 900;
      letter-spacing: 1px;
      @include breakpoint($medium-up) {
        font-size: 16px;
      }
      &.icon {
        transform: none;
        position: absolute;
        bottom: 0;
        #{$ldirection}: 12px;
        font-size: 12px;
      }
    }
    &--prev {
      height: 300px;
      #{$ldirection}: 5px;
      @include breakpoint($medium-up) {
        display: block;
        height: 100%;
        top: -3%;
        #{$ldirection}: 16%;
        letter-spacing: 1px;
      }
      .icon {
        display: none;
      }
    }
    &--next {
      #{$ldirection}: unset;
      height: 100%;
    }
  }
  &__results-eyebrow {
    margin: 30px 11% 0;
    font-size: 11px;
    letter-spacing: 0.3px;
    height: 20px;
    line-height: 0.81;
    color: $color-light-grey;
    width: 90%;
    @include breakpoint($medium-up) {
      font-size: 13px;
      margin: 4% auto 0;
      padding: 0 21%;
    }
  }
  &__results-header {
    margin: 20px 11% 30px;
    line-height: 1;
    padding-#{$rdirection}: 15%;
    color: $color-black;
    font-family: $font--heading;
    letter-spacing: 0.5px;
    font-size: 24px;
    font-weight: bold;
    width: 90%;
    @include breakpoint($medium-up) {
      letter-spacing: -0.04px;
      padding: 0 21%;
      line-height: 0.68;
      margin: 20px auto 40px;
    }
  }
  &__results-grid {
    width: 100%;
    margin: 0 auto 60px;
    position: relative;
    @include breakpoint($extra-large-up) {
      width: 90%;
    }
    &-slider {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 23.5%;
      }
    }
    .slick-track {
      margin: 0 auto;
      display: flex;
    }
    .slick-prev {
      @include breakpoint($medium-up) {
        top: 30%;
      }
    }
    .slick-next {
      @include breakpoint($medium-up) {
        top: 30%;
      }
    }
    .slick-list {
      @include swap_direction(padding, 0 10% 0 2%);
      @include breakpoint($extra-large-up) {
        padding: 0;
      }
    }
  }
  &__flag {
    position: absolute;
    top: 30px;
    #{$rdirection}: 20px;
    z-index: 1;
    @include breakpoint($small-down) {
      .product-grid--small-cols-2 &,
      .product-grid--small-cols-3 &,
      .product-grid--small-cols-4 & {
        top: 20px;
        #{$rdirection}: 10px;
      }
    }
    @include breakpoint($medium-up) {
      top: 10px;
      #{$rdirection}: 10px;
    }
    .product-quickshop & {
      top: 50px;
      #{$rdirection}: 50px;
      @include breakpoint($medium-up) {
        top: 35px;
        #{$rdirection}: 10px;
      }
    }
    .product-full & {
      top: 30px;
      #{$rdirection}: 20px;
      @include breakpoint($landscape-up) {
        top: 60px;
        #{$rdirection}: 40px;
      }
    }
  }
  &__rating {
    position: absolute;
    top: 32px;
    #{$ldirection}: 17px;
  }
  &__result-model,
  &__result-item {
    @include breakpoint($medium-up) {
      margin: 0;
      position: relative;
    }
  }
  &__results-model {
    &-slider {
      max-height: 700px;
      overflow: hidden;
      padding: 0;
      .model-image {
        background-color: $color-white;
        opacity: 0.15;
        @include breakpoint($medium-up) {
          max-width: 100%;
        }
      }
      .slick-slide {
        margin: 0;
      }
      .slick-center,
      .slick-active {
        margin: 0;
        .model-image {
          background-color: none;
          opacity: 1;
        }
      }
      .filterable-products__result-shade-type {
        &.perfect {
          height: 310px;
          #{$ldirection}: 33%;
          top: -8px;
          letter-spacing: 1px;
          @include breakpoint($medium-up) {
            height: 81.1%;
            top: 6%;
            #{$ldirection}: 12%;
          }
        }
        &.perfect-prev-slide {
          #{$ldirection}: 60.3%;
        }
        &.lighter {
          #{$ldirection}: 27%;
        }
        &.lighter-prev-slide,
        &.first-perfect-prev-slide {
          @include breakpoint($medium-down) {
            #{$ldirection}: 41.3%;
          }
        }
        &.perfect-next-slide,
        &.darker-next-slide {
          @include breakpoint($medium-down) {
            #{$ldirection}: 50.5%;
          }
        }
        &.perfect-prev-slide,
        &.lighter {
          width: 47px;
          height: 100%;
          top: 0;
          #{$rdirection}: 0;
          @include breakpoint($medium-up) {
            #{$rdirection}: 0;
            #{$ldirection}: 62%;
            height: 100%;
            width: auto;
            top: -3%;
          }
        }
      }
      .filterable-products__result-model--lighter:not(.slick-active),
      .filterable-products__result-model--darker:not(.slick-active),
      .filterable-products__result-model--perfect:not(.slick-active) {
        &:hover {
          cursor: pointer;
        }
      }
      @include breakpoint($medium-up) {
        width: 80%;
        margin: 0 auto;
      }
    }
    &-slider--arrows {
      .slick-prev,
      .slick-next {
        height: 100%;
        &.slick-disabled {
          cursor: default;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
        top: 0;
      }
      .slick-next {
        #{$rdirection}: 0;
        top: 0;
      }
    }
    position: relative;
    .slick-list {
      padding: 0 47px;
      @include breakpoint($medium-up) {
        padding: 0 20%;
      }
    }
    .slick-track {
      display: flex;
      background: $color-white;
    }
  }
  &__result-item {
    &.slick-slide {
      .slick-slider & {
        margin: 0 10px;
        display: flex;
        height: auto;
      }
    }
    &--content {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 275px;
      height: auto;
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
    &--coverage {
      height: 13px;
      width: 308px;
      color: $color-dark-grey;
      font-size: 13px;
      letter-spacing: 0.01px;
      line-height: 0.81;
      text-transform: uppercase;
    }
    &--image {
      position: relative;
      max-height: 253px;
      max-width: 275px;
      overflow: hidden;
      @include breakpoint($medium-up) {
        max-width: 100%;
        max-height: 400px;
      }
      img {
        background-color: $color-light-sand;
        width: 275px;
        height: 253px;
        margin: 0 auto;
        &.filterable-products__result-item--image-smoosh {
          margin-#{$ldirection}: -140px;
        }
        @include breakpoint($medium-up) {
          width: auto;
          height: auto;
          max-width: 400px;
        }
      }
      &-smoosh {
        position: absolute;
        top: 0;
        #{$ldirection}: 50%;
        opacity: 0;
      }
    }
    &--details {
      max-width: 340px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 290px;
        padding-#{$rdirection}: 10px;
        li {
          &.details-key {
            font-size: 13px;
            width: 45%;
            font-weight: 700;
            letter-spacing: -0.1px;
            line-height: 0.81;
            margin-top: 25px;
            text-transform: uppercase;
          }
          &.details-delimiter {
            width: 100%;
            flex-grow: 1;
            height: 26px;
            margin: 0 5px;
            border-bottom: 1px solid $color-lighter-gray;
          }
          &.details-value {
            min-height: 18px;
            font-family: $font--lamer-text;
            font-style: italic;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1;
            width: 55%;
            padding-#{$ldirection}: 5px;
            margin-top: 25px;
          }
          &.line-break {
            width: 100%;
          }
        }
        @include breakpoint($medium-up) {
          width: 100%;
          padding-#{$rdirection}: 0;
        }
      }
    }
    &--buzzwords {
      max-width: 250px;
      font-size: 13px;
      letter-spacing: -0.1px;
      line-height: 0.72;
      flex-grow: 1;
    }
    &--name,
    &--shadename {
      font-size: 16px;
      letter-spacing: 0.02px;
      line-height: 0.84;
      text-transform: uppercase;
    }
    &--name {
      text-align: center;
      text-decoration: none;
      font-family: $font--lamer-text;
      width: 308px;
      max-width: 75%;
      margin: 30px auto 20px;
      letter-spacing: 2px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 1;
      a {
        border-bottom: unset;
        text-decoration: none;
        &:hover {
          color: $color-black;
        }
      }
      @include breakpoint($medium-up) {
        margin: 40px auto 0;
        font-size: 18px;
        letter-spacing: 1px;
      }
    }
    &--shadename {
      margin: 0 auto;
      width: 75%;
      font-size: 11px;
      text-align: center;
      font-weight: 900;
      padding-top: 0;
      @include breakpoint($medium-up) {
        padding-top: 30px;
      }
    }
    &--your-perfect-shade {
      color: $color-light-grey;
    }
    &--price {
      margin: 20px auto;
      height: 23px;
      width: 75%;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0.02px;
      line-height: 0.81;
      padding-top: 17px;
      @include breakpoint($medium-up) {
        margin: 15px auto;
      }
    }

    .product-rating {
      margin: 10px auto 20px;
      .p-w-r {
        .pr-snippet {
          .pr-snippet-stars-png {
            .pr-star-v4,
            .pr-star-v4-0-filled {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    &--finish {
      text-transform: capitalize;
    }
  }
}
