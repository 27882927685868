/* GIFTCARD */
.payment-giftcard {
  margin-bottom: 20px;
  padding-top: 12px;
  border-top: 1px solid $color-lightest-grey;
  border-bottom: 1px solid $color-lightest-grey;
  &__pin,
  &__number,
  &__balance,
  &__apply {
    width: 100%;
    @include breakpoint($landscape-up) {
      padding-right: 15px;
      width: 50%;
      float: left;
    }
    input,
    a {
      width: 100%;
    }
  }
  .invalid_marker,
  .valid_marker {
    display: none;
  }
  .has-giftcard {
    display: none;
  }
}

.giftcard-balance-overlay {
  height: auto;
  margin: auto;
  @include breakpoint($landscape-up) {
    width: 425px;
  }
  h3 {
    margin-bottom: 10px;
  }
}

.has-giftcard-text {
  margin-left: 0.5em;
}

.giftcart-balance {
  margin-top: 10px;
  font-weight: bold;
}

fieldset.fs.checkout_giftcard {
  margin-top: 0;
  max-width: none;
}
