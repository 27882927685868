.loyalty__panel__transactions {
  text-align: center;
  padding-bottom: 30px;
  &__title {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px;
    font-family: $font--heading;
  }
  &__subtitle {
    @include swap_direction(padding, 0 20px);
    font-family: $font--text;
    font-size: 14px;
    color: $color-medium-dark-gray;
    &__text {
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
  &__status {
    display: none;
  }
  &__list {
    @include swap_direction(margin, 0 auto);
    width: 90%;
    margin-top: 20px;
    &__column-date,
    &__column-activity,
    &__column-points,
    &__column-total {
      @include swap_direction(padding, 10px);
      font-size: 11px;
      color: $color-dark-gray;
      font-weight: normal;
      font-family: $font--text;
      @include breakpoint($landscape-up) {
        width: 10%;
      }
    }
    &__column-activity {
      @include breakpoint($landscape-up) {
        width: 70%;
      }
    }
  }
  &__paginate {
    .transactions-footer__view-more-link {
      text-transform: uppercase;
      font-size: 11px;
      margin-top: 15px;
      display: block;
    }
  }
  &__table {
    width: 100%;
    &__head {
      .loyalty__panel__transactions__list {
        &__column-date,
        &__column-activity,
        &__column-points,
        &__column-total {
          text-transform: uppercase;
          padding: 20px;
          font-weight: bold;
        }
      }
    }
    &__body {
      .points_content {
        font-size: 16px;
      }
      .set-border {
        border-bottom: 1px solid $color-light-gray;
      }
      .set-bold {
        font-weight: bold;
      }
      .loyalty__panel__transactions__table__row:last-child {
        border: 0;
      }
      .loyalty__panel__transactions__list {
        &__column-date,
        &__column-activity,
        &__column-points,
        &__column-total {
          padding: 20px;
        }
        &__column-activity {
          &__transaction-link__order-id {
            font-weight: bold;
          }
          &__transactions {
            display: inline-block;
          }
        }
        &__column-date,
        &__column-activity {
          font-size: 14px;
        }
        &__column-minus,
        &__column-plus {
          margin-top: 20px;
        }
      }
    }
  }
}
/* Loylaty landing page offer limts text */
.loyalty__page__index__instructions {
  @include swap_direction(margin, 0 auto);
  width: 90%;
  margin-top: 30px;
  &__heading {
    @include swap_direction(margin, 0);
    font-weight: bold;
    font-size: 11px;
    font-family: $font--text;
  }
  &__subheading {
    font-size: 11px;
    font-family: $font--text;
  }
  &__link {
    font-size: 11px;
    font-family: $font--text;
    text-decoration: underline;
  }
}
