.mpp-filter-set__mobile-cta {
  @include selectBox;
  min-width: 120px;
  padding: 0 30px 0 15px;
  @if $cr22 {
    .mpp-container--dark-background & {
      background: $color-neutral-black;
      border: 1px solid  $color-border;
      color: $color-white-80;
    }
  }
  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
  }
  .mpp-filter-set__mobile-cta-text--edit {
    display: none;
    .mpp-filter-set--filtered & {
      display: inline;
    }
  }
  .mpp-filter-set__mobile-cta-text--default {
    .mpp-filter-set--filtered & {
      display: none;
    }
  }
  @include breakpoint($large-up) {
    display: none;
  }
}

.mpp-filter-set__container {
  display: none;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-white;
  border-left: 15px solid $color-white-smoke;
  border-right: 15px solid $color-white-smoke;
  z-index: 9999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  @include breakpoint($large-up) {
    display: block !important;
    width: auto;
    height: auto;
    background: none;
    border: 0;
    position: static;
    z-index: 1;
    overflow: visible;
    background: $color-white;
  }
  @if $cr22 {
    .mpp-container--dark-background & {
      @include breakpoint($large-up) {
        background: $color-neutral-black;
      }
    }
  }
}

.mpp-filter-set__header {
  @include pie-clearfix;
  background: $color-white-smoke;
  padding: 20px 30px 10px 20px;
  line-height: 1;
  @include breakpoint($large-up) {
    background: transparent;
    line-height: normal;
    padding: 0 10px 15px 10px;
    position: relative;
    z-index: 1;
  }
}

.mpp-filter-set__label {
  @include text--2;
  opacity: 0.6;
  float: left;
  @include breakpoint($large-up) {
    display: none;
  }
}

.mpp-filter-set__count {
  font-size: 11px;
  opacity: 0.6;
  float: right;
  @include breakpoint($large-up) {
    opacity: 1;
    float: none;
    font-size: 14px;
  }
  @if $cr22 {
    .mpp-container--dark-background & {
      color: $color-white-80;
    }
  }
}

.mpp-filter-set__body {
  background: $color-white;
  padding-bottom: 60px;
  @include breakpoint($large-up) {
    background: none;
    border-top: 1px solid $color-border;
    padding: 0;
  }
}

.mpp-filter-set__section {
  @include breakpoint($large-up) {
    border-bottom: 1px solid $color-border;
    padding: 10px 0 0 0;
    &.active {
      padding: 10px 0;
    }
  }
}

.mpp-filter-set__title {
  font-size: 11px;
  font-weight: bold;
  background: $color-white-smoke;
  padding: 12px 20px 7px 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  @include breakpoint($large-up) {
    @include icon('plus');
    .mpp-filter-set__section.active & {
      @include icon('minus');
    }
    padding: 10px 20px 15px 10px;
    background: none;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    &:before {
      position: absolute;
      top: 16px;
      right: 8px;
      font-size: 7px;
    }
  }
  @if $cr22 {
    .mpp-container--dark-background & {
      @include breakpoint($large-up) {
        color: $color-white-80;
      }
    }
  }
}

.mpp-filter-set__button {
  display: block;
  padding: 14px 30px;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.035em;
  border-bottom: 1px solid $color-white-smoke;
  position: relative;
  cursor: pointer;
  &:last-child {
    border-bottom: 0;
  }
  @include breakpoint($large-up) {
    padding: 0;
    font-size: 12px;
    line-height: 15px;
    padding: 8px 20px 9px 8px;
    margin: 0 0 8px 0;
    border: 0;
    word-break: break-word;
    border: 1px solid $color-white;
    transition: border 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  &.active {
    font-weight: bold;
    @include icon('check');
    @include breakpoint($large-up) {
      @include icon('close');
      font-weight: normal;
      border: 1px solid $color-border;
      border-radius: 4px;
      padding-right: 20px;
      &:hover {
        color: $color-text;
      }
    }
    &:before {
      position: absolute;
      top: 13px;
      right: 20px;
      color: $color-green;
      font-size: 12px;
      @include breakpoint($large-up) {
        color: $color-text;
        right: 6px;
        top: 11px;
        font-size: 9px;
      }
    }
  }
}

.mpp-filter-set__footer {
  @include pie-clearfix;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background: $color-white;
  border-top: 1px solid $color-white-smoke;
  border-bottom: 15px solid $color-white-smoke;
  border-left: 15px solid $color-white-smoke;
  border-right: 15px solid $color-white-smoke;
  @include breakpoint($large-up) {
    display: none;
  }
}

.mpp-filter-set__reset,
.mpp-filter-set__submit {
  width: 50%;
  float: left;
  border-radius: 0;
  border: 0;
  &:hover,
  &:active {
    background: inherit;
    color: inherit;
  }
}

.mpp-filter-set__close {
  position: fixed;
  top: 13px;
  right: 20px;
  z-index: 1;
  @include breakpoint($large-up) {
    display: none;
  }
}

.mpp-filter-set__selections {
  display: none;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.035em;
  padding: 5px 30px 10px 30px;
  clear: both;
  &.active {
    display: block;
    @include breakpoint($large-up) {
      display: none;
    }
  }
  &-reset {
    color: $color-gray;
    text-decoration: underline;
    white-space: nowrap;
    padding-left: 5px;
    &--desktop {
      @include link--secondary;
      text-decoration: none;
      line-height: 15px;
      padding-left: 0;
      margin-top: 5px;
      display: none;
      @include breakpoint($large-up) {
        display: block;
        opacity: 0;
        transition: opacity 0.2s;
        .mpp-filter-set--filtered & {
          opacity: 1;
        }
        @if $cr22 {
          .mpp-container--dark-background & {
            border-bottom: 1px solid $color-border;
            color: $color-white-80;
          }
        }
      }
    }
  }
  &-tags {
    text-transform: uppercase;
  }
  &-count {
    padding: 5px 0 0 0;
  }
}

// no results found
.filter-no-matches {
  display: none;
  text-align: center;
  padding: 60px 0;
  &__cta {
    padding: 10px;
  }
}
// Override for the dark background option.
@if $cr22 {
  .mpp-container--dark-background {
    .mpp-container--custom {
      .mpp-filter-set__button {
        @include breakpoint($large-up) {
          background: $color-neutral-black;
          border: 1px solid $color-neutral-black;
          color: $color-white-80;
        }
        &.active {
          border: 1px solid $color-border;
          &:before {
            color: $color-white-80;
          }
        }
      }
    }
  }
}
