/* global changes */
a:focus {
  outline: none;
}
/* Navigation-loyalty landing page */
.account-section--loyalty {
  &.account-section {
    .sidebar-menu {
      .loyalty {
        a {
          font-weight: bold;
        }
      }
    }
  }
}
