/* ORDER SUMMARY PANEL */

.checkout-panel--order-summary {
  .label {
    float: left;
    width: 65%;
    clear: both;
    margin-bottom: 0.5em;
    .checkout__content & {
      text-align: right;
    }
  }
  .total {
    margin-top: 0.5em;
    font-weight: bold;
  }
  .breakdown {
    background-color: $color-gray;
    padding: 1em;
    margin-top: 1em;
    clear: left;
  }
  .value {
    float: right;
    width: 35%;
    text-align: right;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  .checkout__content & .checkout__panel--content {
    @include breakpoint($small-up) {
      padding-left: 50%;
    }
  }
  .ship-method {
    select {
      &.selectBox-attached {
        width: 100%;
      }
    }
    .selectBox-dropdown {
      min-width: 295px;
    }
  }
  .disable-dropdown,
  .selectBox-disabled {
    cursor: not-allowed;
  }
  .label.afterpay {
    width: 100%;
  }
  .afterpay-learnmore span {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    background: $color-black;
    color: $color-white;
    font-size: 10px;
    line-height: 1.4;
    text-transform: lowercase;
    text-align: center;
  }
  .checkout-panel__content {
    overflow: visible;
  }
  .order-summary__signature {
    &-container {
      display: none;
    }
    &-display {
      display: block;
      width: 100%;
    }
    &-icon {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 50%;
      background: $color-black;
      color: $color-white;
      font-size: 10px;
      line-height: 1.4;
      text-transform: lowercase;
      text-align: center;
      cursor: pointer;
    }
    &-popup {
      position: relative;
      top: 0;
      #{$rdirection}: 0;
      width: 100%;
      box-shadow: none;
      border: 1px solid $color-black;
      z-index: 999;
      background: $color-white;
      @include breakpoint($medium-up) {
        position: absolute;
        width: 100%;
        top: 7%;
        #{$rdirection}: 90%;
        border-radius: 4px;
      }
      &-label {
        @include swap_direction(padding, 20px 30px 20px 28px);
        font-size: 14px;
        line-height: get-line-height(14px, 20px);
      }
      &-close {
        @include icon('close');
        position: absolute;
        top: 0;
        #{$rdirection}: 0;
        padding: 15px;
      }
    }
  }
}
// Styles for the AfterPay overlay in checkout page
#colorbox.afterpay-overlay {
  #cboxLoadedContent {
    width: auto !important;
    height: 660px !important;
    @include breakpoint($medium-up) {
      width: 770px !important;
    }
  }
}
