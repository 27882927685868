///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here

$mobile_search_header_height: 20px;

.search-form {
  &__fields {
    @include pie-clearfix;
    &--close {
      @include icon(close);
      @include transition(all 0.75s cubic-bezier(0.23, 1, 0.32, 1));
      color: $color-gray;
      font-size: 12px;
      right: 5%;
      bottom: 10px;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      @include breakpoint($large-up) {
        top: 12px;
        font-size: 18px;
        right: 7%;
        cursor: pointer;
      }
      &.active {
        display: block;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  input[type='text'].search-form__field,
  input[type='submit'].search-form__submit {
    display: block;
  }
  input[type='text'].search-form__field {
    @include font--text();
    padding: 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    line-height: 2.5;
    height: auto;
    width: 100%;
    &:focus {
      border-color: transparent;
    }
    &:focus-visible {
      border-color: $color-black;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      &:focus-visible {
        border-color: $color-white;
        color: $color-gray;
        @include breakpoint($large-up) {
          color: $color-white;
        }
      }
    }
    @include input-placeholder {
      color: $color-gray;
      font-size: 14px;
      line-height: 2.5;
    }
    @include breakpoint($large-up) {
      border: none;
      border-top: 1px solid $color-lighter-gray;
      border-bottom: 1px solid $color-lighter-gray;
      padding: 0 40px 0 15px;
      font-size: 20px;
      text-align: left;
      height: 59px;
      width: 100%;
      background: transparent;
      &:focus {
        border-color: transparent;
      }
      &:focus-visible {
        border-color: $color-black;
      }
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        border-color: $color-neutral-gray;
        &:focus,
        &:focus-visible {
          border-color: $color-white;
        }
      }
      @include input-placeholder {
        color: $color-gray;
        font-size: 20px;
        line-height: 2.85;
        margin-left: 5px;
      }
    }
  }
  input[type='submit'].search-form__submit {
    display: block;
    float: left;
    margin: 10px 0 0 0;
    @include breakpoint($large-up) {
      margin: 0 0 0 5px;
    }
  }
  input[type='text'] {
    border: none;
    border-bottom: 1px solid $black;
    border-radius: 0;
    height: 30px;
    @include breakpoint($large-up) {
      height: auto;
      top: auto;
    }
  }
  .typeahead-wrapper {
    text-align: left;
    width: 100%;
    max-height: 500px;
    padding-top: 20px;
    @include breakpoint($large-up) {
      max-height: inherit;
    }
    .see-results--upper {
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background-color: $color-medium-dark-gray;
        @include breakpoint($large-up) {
          background-color: $color-neutral-black;
        }
      }
    }
  }
  .search-suggestions__link {
    font-size: 12px;
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
    }
  }
  .result {
    padding: 1em 0;
    a,
    a:active {
      text-decoration: none;
      text-align: left;
    }
  }
  &--results {
    margin: 0 auto 12px;
    position: relative;
    width: 100%;
    text-align: center;
    @include breakpoint($large-up) {
      padding: 0 60px;
      min-height: 60px;
      max-width: 1080px;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: auto;
      width: 100%;
      text-align: center;
      padding: 0 12px;
      @include breakpoint($large-up) {
        text-align: left;
        width: auto;
      }
    }
    &.active {
      .esearch__recommendation-wrapper {
        @include transition(all 0.75s cubic-bezier(0.23, 1, 0.32, 1));
        height: auto;
      }
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($large-up) {
    padding: 0 20px;
  }
  &__header {
    // Used for typeahead
    &.mpp-container {
      overflow: visible;
      padding: 0;
    }
  }
}
// @todo check styles
.search-results__header--mobile {
  background: #ebebeb;
  text-align: center;
  height: $mobile_search_header_height;
  line-height: $mobile_search_header_height;
  .product-full--genaissance &,
  .mpp-container--dark-background & {
    background: $color-neutral-black;
  }
}

@include breakpoint($large-up) {
  .search-results__header--mobile {
    display: none;
  }
}

// Changes positioning on PC based on the sticky nav.
.sticky .esearch-nav {
  @include breakpoint($large-up) {
    position: fixed;
  }
}

.esearch-nav {
  background-color: $color-white;
  @include breakpoint($large-up) {
    @include transition(all 0.75s cubic-bezier(0.23, 1, 0.32, 1));
    position: absolute;
    height: 0;
    z-index: 50;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    display: none;
  }
  .product-full--genaissance &,
  .mpp-container--dark-background & {
    background-color: $color-neutral-black;
  }
  &.active {
    @include breakpoint($large-up) {
      height: 1500px;
      opacity: 1;
      z-index: 100;
      display: block;
    }
  }
  &__form {
    &-fields {
      @include icon(search);
      top: 0;
      z-index: 1;
      width: 100%;
      padding: 0 5%;
      @include breakpoint($large-up) {
        height: auto;
        padding: 0 60px;
        position: absolute;
        margin: 0 auto;
        left: 0;
        z-index: 50;
      }
      &:before {
        bottom: 10px;
        left: 5%;
        position: absolute;
        z-index: 1;
        @include breakpoint($large-up) {
          display: none;
        }
      }
      &:after {
        color: $color-gray;
        font-size: 24px;
        line-height: 2em;
        right: 10px;
        top: 8px;
        position: absolute;
        display: none;
        @include breakpoint($large-up) {
          display: block;
        }
      }
    }
    &-field {
      padding: 0 30px;
      @include breakpoint($large-up) {
        width: 880px;
      }
      &:before {
        color: $black;
        font-size: 30px;
        text-align: left;
      }
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      background: $color-neutral-black;
    }
  }
  &__animation-wrapper {
    @include transition(all 0.75s cubic-bezier(0.23, 1, 0.32, 1));
    height: 0;
    opacity: 0;
    position: absolute;
    overflow: scroll;
    background-color: $color-white;
    top: 45px;
    width: 100%;
    z-index: 40;
    display: none;
    @include breakpoint($large-up) {
      padding: 60px 0 0;
      position: relative;
      top: 0;
      overflow: auto;
    }
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      background-color: $color-neutral-black;
    }
    &.active {
      opacity: 1;
      display: block;
    }
  }
  &__recommendation-wrapper {
    padding-top: 15px;
    @include breakpoint($large-up) {
      padding-top: 30px;
    }
  }
  &__suggestions-wrapper {
    @include breakpoint($large-up) {
      padding: 0;
      padding-block-end: 62px;
    }
    .typeahead-wrapper {
      padding: 0;
      .no-results {
        @include breakpoint($large-up) {
          padding: 30px 0 0;
        }
        .product-full--genaissance &,
        .mpp-container--dark-background & {
          color: $color-white;
        }
      }
    }
  }
  &__suggestions {
    opacity: 0; // hide for mobile for now
    @include font--text();
    line-height: 2.65;
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
    text-align: center;
    @include breakpoint($large-up) {
      opacity: 1;
      line-height: 2.95;
      height: 60px;
      text-align: left;
      margin-left: 15px;
      top: 0;
    }
    .search-suggestions {
      &__item {
        padding: 0;
      }
      &__link {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0.4;
        letter-spacing: 2px;
        @include breakpoint($large-up) {
          font-size: 20px;
        }
      }
    }
  }
  // Search result product overrides
  .product-brief {
    text-transform: none;
    padding: 0;
    border: none;
    &__image {
      img.product-image {
        height: auto;
        padding: 0;
      }
    }
  }
}

// Search elements both in nav and results page.
.esearch {
  &__recommendation-wrapper {
    padding: 0 5%;
    margin: 18px auto 0;
    height: 0;
    @include breakpoint($large-up) {
      padding: 0 4% 0 0;
    }
    &--col {
      width: 100%;
      display: inline-block;
      vertical-align: top;
      &:last-child {
        @include breakpoint($large-up) {
          padding-right: 0;
        }
      }
      @include breakpoint($large-up) {
        width: 33%;
        padding-right: 4%;
      }
    }
  }
  &__recommendation-list {
    text-align: left;
    color: $color-gray;
    .product-full--genaissance &,
    .mpp-container--dark-background & {
      color: $color-white;
      @include breakpoint($landscape-up) {
        &:focus,
        &:hover {
          color: $color-neutral-gray;
        }
      }
    }
    &__hdr {
      @include text--2;
      text-transform: uppercase;
      padding: 13px 5%;
      letter-spacing: 1px;
      font-size: 13px;
      font-weight: bold;
      background-color: $color-mpp-light-gray;
      @include breakpoint($large-up) {
        background-color: $color-white;
        font-weight: normal;
      }
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background-color: $color-neutral-black;
        @include breakpoint($landscape-up) {
          background-color: $color-neutral-black;
        }
      }
    }
    &__list {
      background-color: $color-white;
      .product-full--genaissance &,
      .mpp-container--dark-background & {
        background-color: $color-neutral-black;
      }
      @include breakpoint($large-up) {
        background-color: $color-mpp-light-gray;
      }
      .recommendation-list-item {
        @include heading--4;
        font-size: 18px;
        border-bottom: 1px solid $color-lightest-grey;
        padding-bottom: 8px;
        @include breakpoint($large-up) {
          border-bottom: 2px solid $color-white;
          padding-bottom: 0;
        }
        .product-full--genaissance &,
        .mpp-container--dark-background & {
          border-bottom: $color-neutral-gray;
        }
        &__img {
          width: 60px;
          display: inline-block;
          vertical-align: middle;
        }
        &__title {
          display: inline-block;
          vertical-align: middle;
          max-width: 70%;
        }
        &__link {
          text-decoration: none;
          .product-full--genaissance &,
          .mpp-container--dark-background & {
            color: $color-white;
            @include breakpoint($landscape-up) {
              &:focus,
              &:hover {
                color: $color-neutral-gray;
              }
            }
          }
          img {
            height: auto;
            padding: 0;
          }
        }
      }
    }
  }
}
