/**
 * Pagination
 */
.pagination-container {
  @include breakpoint($large-up) {
    position: absolute;
    top: -10px;
    width: 100%;
  }
  .pagination {
    text-align: center;
    padding: 0 0 1em;
    margin: 0 auto;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 12px;
    cursor: pointer;
    span {
      display: inline-block;
      &:after {
        content: '\00b7';
      }
      &.last:after {
        content: '';
      }
      a,
      strong {
        display: inline-block;
        padding: 0 1em;
        text-decoration: none;
      }
      &.first a {
        padding-left: 0;
      }
      &.last a {
        padding-right: 0;
      }
    }
  }
}
