
/// Buttons
.button {
  @include button;
  &--secondary {
    @include button--secondary;
  }
  &--green {
    @include button--green;
  }
  &--transparent {
    @include button--transparent;
  }
  &--transparent--white {
    border-color: $color-white;
    &:hover,
    &:active,
    &:focus {
      background: $color-black;
      border-color: $color-white;
      color: $color-white;
    }
    &:focus-visible {
      @include outline-none;
    }
  }
  &--transparent--black {
    border-color: $color-black;
    &:hover,
    &:active,
    &:focus {
      background: $color-black;
      border-color: $color-white;
      color: $color-white;
    }
    &:focus-visible {
      @include outline-none;
      border-color: $color-black;
    }
  }
  &--large-white {
    @include button--large-white;
  }
  &--active {
    background: $color-white;
    border-color: $color-black;
    color: $color-dark-gray;
    font-weight: bold;
    &:hover,
    &:active,
    &:focus {
      background: $color-white;
      color: $color-dark-gray;
      cursor: default;
    }
  }
  &--disabled {
    background: $color-gray;
    color: $color-white;
    border-color: $color-gray;
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      background: $color-gray;
      color: $color-white;
      border-color: $color-gray;
    }
    &:focus-visible {
      @include outline-none;
    }
  }
  &--wide {
    width: 100%;
  }
}

input[type='submit'] {
  @include button;
}

///
/// Links
///

.link {
  @include link;
  @include to-sentence-case;
  &--inverted {
    @include font-smoothing;
    color: $color-white;
    border-color: $color-white;
  }
  &--secondary {
    @include link--secondary;
    @include to-sentence-case;
  }
}
