$ab--color--gray--lighter: #c7c7c7;
$ab--color--gray--black: #1a1a1a;
$ab--color--white: #ffffff;

.appt-book {
  position: relative;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  input.error,
  select.error {
    border: 1px solid $color-error;
  }
  a {
    &.selectBox.error,
    &.selectbox.error {
      border: 1px solid $color-error;
    }
  }
  p {
    font-family: $font--neue-haas-unica-pro;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 34px auto 11px;
  }
  .button.disabled,
  input[type='submit'] {
    font-family: $font--neue-haas-unica-pro;
  }
  select::-ms-expand {
    display: none;
  }
  select {
    font-family: $font--neue-haas-unica-pro;
  }
  input[type='submit'].disabled {
    background: $color-gray;
    color: $color-white;
    border-color: $color-gray;
    cursor: default;
    &:hover,
    &:active,
    &:focus {
      background: $color-gray;
      color: $color-white;
      border-color: $color-gray;
    }
  }
  .basic-responsive {
    position: relative;
    display: block;
    font-size: 18px;
  }
  .ui-select {
    background: url(/media/export/cms/appointment_booking/common/select_location_arrow.png) no-repeat;
    background-position: 97% 50%;
    border: 1px solid $color-gray-light;
    border-radius: 4px;
    float: none;
    height: 45px;
    margin-right: 50px;
    overflow: hidden;
    width: 450px;
    z-index: 0;
    position: relative;
    .select-location-arrow {
      position: absolute;
      z-index: -1;
      right: 0;
      width: 8%;
      height: 45px;
      background: transparent;
      border-left: none;
    }
    select {
      &::-ms-expand {
        display: none;
      }
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 120%;
      background: transparent;
      /* To allow visibility to the parent's background arrow */
      border: 0;
      /* hides rounded border */
      font-family: $font--neue-haas-unica-pro;
      height: 45px;
      color: $color-black;
      display: block;
      font-size: 14px;
      letter-spacing: normal;
      line-height: 14px;
      padding: 0 8% 0 20px;
      text-decoration: none;
      text-transform: none;
      white-space: nowrap;
      z-index: 1;
    }
  }
  .location-submit {
    @include button;
    margin: 20px 0 0;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 250px;
      margin: 20px 0;
    }
  }
  .appt-book-location__signin-text {
    font-size: 14px;
    line-height: 1;
    width: 100%;
    @include breakpoint($medium-up) {
      margin: 10px 0 20px;
      line-height: 26px;
      width: 40%;
      float: right;
    }
  }
  &.counters {
    max-width: $max-width;
    .appt-book-location__signin-text {
      p {
        margin: 25% 0;
        width: 65%;
      }
    }
  }
  &.services {
    .appt-book-content-header .total_text {
      float: left;
    }
    background-color: $color-white-smoke;
    .appt-book-page-header-content__title,
    .appt-book-page-header-content__subhead {
      color: $color-black;
    }
    .appt-book-page-header-content__dots li.active {
      background: url('/media/export/cms/appointment_booking/common/dot_white.png') no-repeat 0 0;
    }
    .appt-book-page-header {
      background-color: $color-white;
    }
  }
  &.services .appt-book-content-header {
    display: block;
    max-width: 1202px;
    border-bottom: 2px solid $color-gray-light;
    padding-bottom: 24px;
    @include breakpoint($medium-up) {
      border: none;
    }
  }
  .appt-book-content-header {
    .total_cost {
      font-family: $font--neue-haas-unica-pro;
      font-size: 17px;
      height: 30px;
      line-height: 30px;
      overflow: hidden;
      display: none;
      float: left;
      margin-top: 11px;
      border-right: 1px solid $color-gray-light;
      padding-right: 10px;
      &_text {
        display: inline-block;
        float: left;
        font-weight: bold;
        padding: 0px 5px 0 0;
      }
      .price {
        display: inline-block;
        float: left;
        padding: 0px 5px 0 0;
      }
      .total_cost_val {
        display: inline-block;
        float: left;
        padding: 16px 5px 0 0;
      }
      &_line {
        border-right: 2px solid $color-gray-light;
        display: inline-block;
        height: 29px;
        margin-top: 10px;
        padding: 0 7px 0 0;
      }
    }
    .total_text {
      .total_time_text {
        font-family: $font--neue-haas-unica-pro;
        display: inline-block;
        float: left;
        font-weight: bold;
        padding: 16px 5px 0 0;
      }
    }
  }
  .book-now-bar {
    .minutes {
      background: url('/media/export/cms/appointment_booking/common/clock_icon_total.png') 0 18px no-repeat;
      text-indent: 20px;
      display: inline-block;
      float: left;
      font-weight: normal;
      padding: 16px 0 0;
      text-align: left;
      width: auto;
      font-family: $font--neue-haas-unica-pro;
    }
    .button {
      font-family: $font--neue-haas-unica-pro;
      font-size: 13px;
      background-color: $color-black;
      border: 1px solid $color-gray-medium;
      color: $color-white;
      display: inline-block;
      float: left;
      height: 45px;
      letter-spacing: 2.77px;
      line-height: 15px;
      margin-left: 23px;
      padding: 13px;
      text-transform: uppercase;
      width: 200px;
      &:hover {
        background: $color-gray-mdark none repeat scroll 0 0;
        border-color: $color-gray-mdark;
        color: $color-white;
        cursor: pointer;
        font-size: 13px;
        text-decoration: none;
      }
      &.disabled {
        background: $color-gray;
        color: $color-white;
        border-color: $color-gray;
        cursor: default;
        &:hover,
        &:active,
        &:focus {
          background: $color-gray;
          color: $color-white;
          border-color: $color-gray;
        }
      }
    }
  }
  .service-select {
    width: auto;
    margin: 28px auto 0;
    overflow: hidden;
    .services-container {
      position: relative;
      width: 100%;
      max-width: 1090px;
      overflow: hidden;
      margin: 14px auto;
      padding-top: 0;
    }
    .service {
      display: block;
      float: left;
      min-height: 240px;
      overflow: hidden;
      position: relative;
      width: 50%;
      margin: 15px 0;
      @include breakpoint($medium-up) {
        min-height: 350px;
      }
      .service_tile {
        background-color: $color-white;
        margin: 0 15px;
        height: 100%;
      }
      .service-image {
        display: block;
        float: left;
        height: 100%;
        margin: 0;
        max-width: 350px;
        width: 50.5%;
      }
      .service_tile-content {
        float: left;
        width: 49.5%;
        height: 100%;
        padding: 27px 30px 60px;
        @include breakpoint($medium-up) {
          border: 1px solid $color-gray-lighter;
          border-left: none;
        }
      }
      .service-details {
        display: block;
        overflow: hidden;
        text-align: left;
        h3 {
          margin: 0;
          text-transform: uppercase;
          line-height: 1.1;
          @include breakpoint($medium-up) {
            text-transform: none;
          }
          &.service-title {
            font-family: $font--lamer-text;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
            text-align: left;
          }
        }
        .lesson_description p {
          font-family: $font--neue-haas-unica-pro;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0;
          margin-top: 20px;
        }
      }
      .selection-bar {
        font-family: $font--neue-haas-unica-pro;
        display: block;
        overflow: hidden;
        position: absolute;
        bottom: 15px;
        width: 38%;
        > div {
          display: block;
          float: left;
          text-transform: uppercase;
          text-align: left;
          padding: 15px 0 0 0;
          margin: 0;
          border-top: 1px solid $color-gray-light;
          font-size: 16px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 26px;
        }
        .selection-time {
          text-transform: none;
          background: url('/media/export/cms/appointment_booking/common/clock_icon.png') 0 21px no-repeat;
          text-indent: 20px;
          width: 50%;
        }
      }
      &.selected {
        .selection-bar {
          .selection-time {
            background: url('/media/export/cms/appointment_booking/common/clock_icon_white.png') 0 21px no-repeat;
          }
        }
      }
      .selection-bar {
        .selection-selector {
          cursor: pointer;
          display: inline;
          float: right;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 2.5px;
          line-height: 28px;
          text-transform: uppercase;
          text-align: right;
          width: 50%;
          .icon {
            background: url('/media/export/cms/appointment_booking/common/check_off.png') no-repeat;
            display: inline-block;
            position: relative;
            top: 0;
            margin-right: 5px;
            width: 18px;
            height: 18px;
            visibility: visible;
          }
        }
      }
      &.selected {
        .service_tile-content {
          color: $color-white;
          background: $color-black;
          border: $color-black;
          border-left: none;
        }
        .selection-time {
          color: $color-white;
        }
      }
      .selection-bar {
        .selection-selector.selected {
          color: $color-white;
          .icon {
            background: url('/media/export/cms/appointment_booking/common/check_selected.png') no-repeat;
            display: inline-block;
            position: relative;
            top: 0;
            margin-right: 5px;
            width: 18px;
            height: 18px;
            visibility: visible;
          }
        }
      }
    }
    .disclaimer_text {
      font-size: 12px;
      height: auto;
      max-width: 1060px;
      margin: 20px auto 10px;
      text-align: left;
      border-top: 1px solid $color-gray-lighter;
      padding: 40px 0 30px 0;
    }
  }
  #confirmation {
    margin: 0 auto;
    .confirmation-container {
      .your-lessons {
        font-weight: bold;
        padding-top: 17px;
        padding-bottom: 26px;
        h4 {
          font-family: $font--neue-haas-unica-pro;
          font-size: 10px;
          color: $color-gray-medium-dark;
          letter-spacing: 2.5px;
          line-height: 10px;
          text-transform: uppercase;
        }
        span.lesson {
          font-family: $font--neue-haas-unica-pro;
          font-size: 32px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 36px;
          padding-top: 20px;
          text-transform: uppercase;
        }
      }
      .appointment-info-content {
        width: 100%;
        max-width: 1060px;
        margin: 0 auto;
        border-top: 1px solid $color-gray-light;
        border-bottom: 1px solid $color-gray-light;
        font-weight: bold;
        text-transform: uppercase;
        padding: 0;
        height: auto;
        @include breakpoint($medium-up) {
          height: 142px;
        }
        .date-time {
          width: 39%;
        }
        .start-time,
        .total-time,
        .total-cost {
          width: 20%;
        }
        &.total-cost-added .total-time {
          border-right: 1px solid $color-gray-light;
        }
        .appointment-info-detail {
          display: inline-table;
          border-right: 1px solid $color-gray-light;
          height: 141px;
          padding: 0px 4px;
          vertical-align: top;
          &:nth-last-child(1) {
            border-right: none;
          }
          h4 {
            font-family: $font--neue-haas-unica-pro;
            font-size: 10px;
            color: $color-gray-medium-dark;
            letter-spacing: 2.5px;
            line-height: 10px;
            text-transform: uppercase;
            padding-top: 35px;
          }
          span {
            font-family: $font--lamer-headline;
            font-size: 24px;
            color: $color-black;
            letter-spacing: 0px;
            line-height: 36px;
            padding-top: 20px;
            &.appt-total-time {
              text-transform: none;
            }
            &.appt-date {
              line-height: 30px;
            }
          }
        }
      }
      .location {
        padding-top: 35px;
        max-width: 1060px;
        margin: 0 auto;
        h4 {
          font-family: $font--neue-haas-unica-pro;
          font-size: 10px;
          color: $color-gray-medium-dark;
          letter-spacing: 2.5px;
          line-height: 10px;
          font-weight: bold;
          text-transform: uppercase;
        }
        span {
          &.location-name {
            font-family: $font--lamer-headline;
            font-size: 24px;
            color: $color-black;
            letter-spacing: 0px;
            line-height: 28px;
            font-weight: bold;
            text-transform: uppercase;
            padding-top: 22px;
            padding-bottom: 10px;
          }
          font-family: $font--lamer-headline;
          font-size: 14px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 20px;
        }
        .location-link {
          width: 100%;
          border-bottom: 1px solid $color-gray-light;
          margin: 0 auto;
          height: 76px;
          line-height: 76px;
          margin-top: 35px;
          margin-bottom: 27px;
        }
        a.location-direction-links {
          @include button;
        }
      }
      .schedulle-copy span.schedulle-text {
        font-family: $font--neue-haas-unica-pro;
        font-size: 13px;
        color: $color-black;
        letter-spacing: 0px;
        line-height: 21px;
      }
      .redeemable-appointment {
        background: $color-white;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
        padding-top: 42px;
        span.redeemable-appointment {
          font-size: 12px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 18px;
          border-top: none;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
  }
  #appointment-cancellation {
    .button-container {
      margin-top: 30px;
    }
    #appt-book-btn {
      font-family: $font--arial-times;
      background-color: $color-black;
      font-size: 13px;
      float: none;
      letter-spacing: 0.1em;
      line-height: 21px;
      height: 43px;
      padding: 11px 45px;
      border-color: $color-black;
      color: $color-white;
      text-transform: uppercase;
    }
    .cancel-content,
    .error-content {
      margin-top: 10px;
    }
    .cancel-content {
      p {
        span {
          font-family: $font--neue-haas-unica-pro;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
    .error-content p {
      font-family: $font--neue-haas-unica-pro;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
    }
  }
  &.appointments {
    .appt-book-page-header-content__title,
    .appt-book-page-header-content__subhead {
      color: $color-black;
    }
    .appt-book-page-header-content__dots {
      li {
        background: url('/media/export/cms/appointment_booking/common/dot_transparent_border_white.png') no-repeat 0 0;
        &.active {
          background: url('/media/export/cms/appointment_booking/common/dot_white.png') no-repeat 0 0;
        }
      }
    }
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
  }
  .appointment-select {
    margin: 0 auto;
    max-width: 1060px;
    overflow: hidden;
    width: auto;
    .appointments-container {
      .booking-step {
        text-align: left;
        &.step1 {
          margin-bottom: 44px;
          margin-top: 11px;
        }
        &.step2 {
          padding: 0;
          margin-bottom: 46px;
        }
        &.step3 {
          margin-bottom: 0;
          padding: 0;
        }
      }
      .step-header {
        border-bottom: 2px solid $color-gray-dark;
        font-family: $font--neue-haas-unica-pro;
        font-size: 16px;
        color: $color-black;
        letter-spacing: 0px;
        line-height: 26px;
        padding: 0 0 2px;
        margin: 0;
        text-transform: none;
        .step_head {
          font-family: $font--lamer-text;
          font-size: 18px;
          font-weight: bold;
        }
      }
      .step1 {
        .step-header {
          margin: 0 0 30px;
        }
      }
      .services {
        display: inline-block;
        margin: 0px;
        overflow: hidden;
        width: 79%;
        h4 {
          text-transform: uppercase;
          font-size: 16px;
          padding-bottom: 18px;
          letter-spacing: 0.2em;
        }
      }
      .service {
        border: 1px solid $color-gray-light;
        display: inline-block;
        float: left;
        margin-bottom: 20px;
        margin-right: 5%;
        overflow: hidden;
        padding: 27px 29px 23px;
        width: 45%;
        .service-head {
          border-bottom: 1px solid $color-gray-light;
          color: $color-black;
          font-family: $font--lamer-text;
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 17px;
          margin: 0;
          padding-bottom: 18px;
          text-transform: none;
        }
        .selection-bar {
          display: block;
          clear: both;
          padding-top: 13px;
          overflow: hidden;
          > div {
            display: block;
            float: left;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            padding: 11px 0;
            margin: 0;
          }
          .selection-time {
            background: url('/media/export/cms/appointment_booking/common/clock_icon_date.png') 0 center no-repeat;
            font-family: $font--neue-haas-unica-pro;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 26px;
            padding: 0;
            text-indent: 20px;
            text-transform: none;
          }
          .selection-selector {
            display: none;
          }
        }
      }
      #change-services-link,
      #read-all-artist-link {
        border-bottom: 1px solid $color-black;
        font-size: 14px;
        letter-spacing: 0.23em;
        padding-bottom: 3px;
        margin-left: 11px;
        text-decoration: none;
        text-transform: uppercase;
      }
      #change-services-link {
        letter-spacing: 0;
        line-height: 15px;
        margin: 0 0 1%;
        overflow: hidden;
        padding: 0;
        text-align: center;
        vertical-align: top;
        float: right;
        &:hover {
          background: $color-gray-mdark none repeat scroll 0 0;
          border-color: $color-gray-mdark;
          color: $color-white;
          text-decoration: none;
        }
      }
      #read-all-artist-link {
        margin-left: 51.8%;
      }
      .selects-container {
        border-bottom: 1px solid $color-gray-light;
        margin: 0;
        overflow: hidden;
        .select-container {
          float: left;
          text-align: left;
          padding: 0 0 46px;
          width: 35.5%;
          .ui-select {
            width: 100%;
            select {
              width: 100%;
              padding: 0 13% 0 20px;
            }
            .select-location-arrow {
              width: 13%;
            }
            background-position: 95% 50%;
          }
          .artist-info {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .artist-select-container {
          display: none;
          margin-left: 4%;
        }
        .select-container h4 {
          font-family: $font--neue-haas-unica-pro;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
          margin-bottom: 4px;
          text-transform: none;
        }
        .artist-select-container h4 {
          text-transform: none;
        }
      }
      .appointment-select {
        .calendar-head {
          font-family: $font--lamer-headline;
          border-bottom: 1px solid $color-gray-light;
          height: 79px;
          margin: 0;
          position: relative;
          text-align: center;
          .cal-head-content {
            position: relative;
            display: inline-block;
            overflow: hidden;
            margin: 22px 0 21px;
            > * {
              float: left;
            }
            .cal-copy {
              font-family: $font--lamer-headline;
              font-size: 24px;
              font-weight: bold;
              color: $color-black;
              letter-spacing: 0px;
              line-height: 26px;
              padding: 7px 33px;
              position: relative;
              text-transform: none;
              .week {
                text-transform: uppercase;
              }
              .of {
                font-style: italic;
                font-weight: normal;
              }
            }
            .cal-controls {
              cursor: pointer;
              height: 11px;
              margin-top: 3%;
              overflow: hidden;
              text-indent: 100%;
              white-space: nowrap;
              width: 8px;
              &.next {
                background: transparent url('/media/export/cms/appointment_booking/common/cal_arrow_right.png')
                  no-repeat;
              }
              &.previous {
                background: transparent url('/media/export/cms/appointment_booking/common/cal_arrow_right.png')
                  no-repeat;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }
          .date-picker {
            background: transparent url('/media/export/cms/appointment_booking/common/cal_date_picker_icon.png')
              no-repeat;
            height: 31px;
            cursor: pointer;
            overflow: hidden;
            position: absolute;
            right: 0;
            text-indent: 100%;
            top: 30%;
            white-space: nowrap;
            width: 30px;
          }
        }
        .calendar-wrapper {
          position: relative;
          &__no-slot {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            z-index: 1;
            @include breakpoint($small-down) {
              position: relative;
            }
          }
          table {
            border: 1px solid $color-gray-light;
            margin: 0;
            text-align: center;
            width: 100%;
            &.calendar-view {
              border-top: 0;
              .col-heads {
                display: block;
                text-align: left;
                tr {
                  display: table;
                  width: 100%;
                }
              }
              tbody {
                overflow-x: auto;
                height: 400px;
                position: relative;
                display: block;
                @include breakpoint($small-down) {
                  display: table-row-group;
                }
              }
              th {
                width: 148px; //(100% / 7);
              }
              tr {
                td {
                  &.enabled {
                    border: 0;
                  }
                }
              }
            }
            .col-heads {
              background-color: transparent;
              border: 1px solid $color-white;
            }
            th {
              border: medium none;
              color: $color-black;
              font-family: $font--lamer-text;
              font-size: 18px;
              letter-spacing: 0px;
              line-height: 26px;
              padding: 23px 0 13px;
              text-align: center;
              &.hilite {
                border-bottom: 2px solid $color-black;
                font-weight: bold;
              }
            }
            td {
              border: 1px solid $color-gray-light;
              font-family: $font--neue-haas-unica-pro;
              font-size: 14px;
              color: $gray-medium;
              cursor: default;
              letter-spacing: 0px;
              line-height: 29px;
              text-align: center;
              padding: 11px 0 8px;
              &.enabled {
                background-color: $color-white;
                color: $color-black;
                cursor: pointer;
                &:hover {
                  color: $color-white;
                  background-color: $color-gray-lighter;
                }
              }
              &.selected {
                background-color: $color-black;
                color: $color-white;
                &:hover {
                  background-color: $color-black;
                  color: $color-gray-lighter;
                }
              }
            }
          }
        }
      }
      .error-messages {
        display: block;
        margin: 20px 20px 0;
        color: $color-red;
        font-family: $font--neue-haas-unica-pro;
        font-size: 14px;
        font-variant: normal;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 18px;
        .error-message {
          display: block;
        }
      }
      .sign-in-container {
        border-bottom: 1px solid $color-gray-light;
        display: block;
        height: auto;
        letter-spacing: 0.1em;
        margin: 22px 0 0;
        overflow: hidden;
        width: auto;
        h4 {
          color: $color-black;
          font-family: $font--neue-haas-unica-pro;
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 26px;
          margin-bottom: 21px;
          text-transform: none;
        }
      }
      .booking-step.step3 {
        .sign-in-container h4 {
          margin-bottom: 26px;
        }
        .book-appt-container {
          margin-bottom: 38px;
          h4 {
            margin-bottom: 25px;
          }
        }
      }
      .sign-in-container {
        .sign-in-form-container {
          position: relative;
          overflow: hidden;
          input {
            margin-right: 11px;
            width: 35%;
            &.login__email {
              width: 49%;
            }
            &.login__password {
              width: 29%;
            }
            &.login__submit {
              background: $color-black none repeat scroll 0 0;
              border: medium none;
              color: $color-white;
              cursor: default;
              float: right;
              font-family: $font--arial-times;
              font-size: 13px;
              height: 45px;
              letter-spacing: 0;
              margin-left: 1%;
              width: 170px;
              border-radius: 0;
            }
            &.error {
              border: 1px solid $color-red;
            }
          }
          .tooltip--appointment {
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0;
            top: 0;
            margin-bottom: 29px;
            .tooltiptext-over {
              bottom: 105%;
            }
            @include breakpoint($medium-up) {
              margin-bottom: 10px;
            }
          }
        }
        a.login_forgot {
          background: transparent url('/media/export/cms/appointment_booking/common/forgot_password_arrow.png')
            no-repeat scroll 0 0;
          display: block;
          font-family: $font--arial-times;
          font-size: 12px;
          letter-spacing: 0px;
          line-height: 10px;
          padding-bottom: 38px;
          padding-left: 12px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
      .sign-in-container input,
      .book-appt-container input {
        border: 1px solid $color-gray-light;
        border-radius: 4px;
        font-family: $font--neue-haas-unica-pro;
        font-size: 13px;
        height: 44px;
        line-height: 15px;
        margin-bottom: 29px;
        margin-right: 26px;
        max-width: 485px;
        padding: 0 20px;
        width: 100%;
      }
      .sign-in-container input.error,
      .book-appt-container input.error {
        border: 1px solid $color-error;
        color: $color-error;
      }
      .sign-in-container {
        input.error.active {
          color: $color-black;
        }
      }
      .book-appt-container {
        display: block;
        overflow: hidden;
        width: auto;
        height: auto;
        margin: 32px 0 21px;
        letter-spacing: 0.1em;
        h4 {
          color: $color-black;
          font-family: $font--neue-haas-unica-pro;
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 26px;
          margin-bottom: 17px;
          text-transform: none;
        }
        input {
          &.appt-book-last-name {
            margin-right: 0;
          }
          &.appt-placeholder {
            color: $color-gray-darker;
          }
        }
        fieldset {
          display: inline-block;
          font-family: $font--neue-haas-unica-pro;
          font-size: 18px;
          height: 25px;
          margin-bottom: 20px;
          max-width: 485px;
          width: 100%;
          &.appt-book-first-name,
          &.appt-book-email {
            float: left;
          }
          &.appt-book-mobile-prefix,
          &.appt-book-mobile {
            float: left;
            margin: 0;
            width: 53.2%;
          }
        }
        input.mobile-phone-fieldset {
          border: none;
          padding: 0;
          margin: 0;
        }
        fieldset.mobile-phone-fieldset {
          border: none;
          padding: 0;
          margin: 0;
          .appt-book-mobile {
            margin-right: 0px;
            width: 84%;
          }
        }
        input.appt-book-mobile-prefix {
          float: left;
          margin: 0;
          width: 53.2%;
          padding: 0 20px 0 15px;
          background: transparent;
        }
        input.appt-book-mobile-prefix,
        fieldset.appt-book-mobile-prefix {
          width: 16%;
          padding: 0 20px 0 15px;
          background: transparent;
        }
        fieldset.appt-book-mobile {
          width: 80%;
        }
        .registration__email-list,
        .registration__sms-list,
        .registration__sms_notifications-list {
          width: 48.2%;
          float: left;
        }
        .registration__email-list,
        .registration__sms_notifications-list {
          input {
            height: auto;
            margin-bottom: 0;
            margin-right: 10px;
            width: auto;
          }
          span {
            font-family: $font--neue-haas-unica-pro;
            font-size: 12px;
            font-variant: normal;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 18px;
          }
        }
        .registration__sms-list {
          span {
            font-family: $font--neue-haas-unica-pro;
            font-size: 12px;
            font-variant: normal;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 18px;
          }
          float: right;
          input {
            height: auto;
            margin-bottom: 0;
            margin-right: 10px;
            width: auto;
          }
        }
        .tooltip--appointment {
          font-size: 12px;
          line-height: 1.5;
          letter-spacing: 0;
          top: 0;
          .tooltiptext-over {
            bottom: 105%;
          }
          @include breakpoint($medium-up) {
            top: -15px;
          }
        }
      }
      .confirm-container {
        border-bottom: 1px solid $color-gray-light;
        border-top: 1px solid $color-gray-light;
        display: block;
        height: auto;
        padding: 31px 0 24px;
        overflow: hidden;
        width: 100%;
        .total-time {
          font-family: $font--neue-haas-unica-pro;
          font-size: 16px;
          font-weight: normal;
          float: right;
          letter-spacing: 0.1em;
          line-height: 26px;
          padding: 0 2.5%;
          margin: 12px 0;
        }
        .total-cost {
          font-family: $font--neue-haas-unica-pro;
          font-size: 16px;
          font-weight: normal;
          float: right;
          letter-spacing: 0.1em;
          line-height: 26px;
          padding: 0 2.5%;
          margin: 12px 0;
          border-right: 1px solid $color-gray-light;
        }
        .total-time {
          span.clock-icon {
            background: url(/media/export/cms/appointment_booking/common/clock_icon_total.png) no-repeat;
            text-indent: 23px;
            display: inline-block;
            background-position: 6% 42%;
          }
        }
        .appt-book-book-submit {
          background: $color-black;
          border: medium none;
          color: $color-white;
          cursor: default;
          font-family: $font--neue-haas-unica-pro;
          font-size: 13px;
          float: right;
          height: 45px;
          letter-spacing: 0px;
          margin-left: 5%;
          width: 170px;
          &.disabled {
            margin-left: 5%;
            background: $color-gray;
            color: $color-white;
            border: 1px solid $color-gray;
            cursor: default;
            &:hover,
            &:active,
            &:focus {
              background: $color-gray;
              color: $color-white;
              border-color: $color-gray;
            }
          }
        }
        .start-over {
          border-color: $color-black;
          color: $color-black;
          display: block;
          float: right;
          font-size: 12px;
          letter-spacing: 0.1em;
          margin-top: 10px;
          text-align: right;
          text-transform: uppercase;
        }
      }
      .cancel-container {
        display: block;
        height: auto;
        margin: 0 0 21px;
        overflow: hidden;
        width: 100%;
        .start-over {
          color: $color-black;
          display: inline-block;
          letter-spacing: 0;
          margin-top: 0;
          padding-left: 0;
          text-align: center;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 14px;
          padding-bottom: 0;
          .icon {
            padding-right: 10px;
            font-size: 11px;
            font-weight: bold;
            line-height: 1.5;
          }
          @include breakpoint($medium-up) {
            float: right;
            border-bottom: 1px solid $color-dark-gray;
            text-align: right;
            margin-top: 32px;
          }
        }
        .redeemable-appointment {
          float: left;
          font-variant: normal;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 18px;
          margin-top: 30px;
          width: 100%;
          border-top: 1px solid $color-gray-light;
          padding: 30px 0 10px 0;
          @include breakpoint($medium-up) {
            width: 75%;
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
  .page-header__title {
    font-size: 39px;
    font-weight: bold;
    letter-spacing: 7px;
    padding-left: 90px;
    text-align: left;
    text-transform: uppercase;
  }
  .page-header__subtitle {
    padding-left: 90px;
    text-align: left;
    p {
      font-size: 24px;
      line-height: 1.3;
    }
  }
  .confirmation {
    width: 100%;
    position: relative;
    .appt-book-content-header {
      margin: 40px 0;
      display: none;
      p {
        width: 56%;
        margin: 15px auto;
      }
    }
    &.appt-book-section-content {
      padding: 0;
    }
    .redeemable-appointment {
      padding: 25px;
      max-width: 1060px;
      margin: 0 auto;
    }
    .confirmation-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      background-color: $color-white-smoke;
      > div {
        padding: 11px 0 0;
        text-align: center;
        h4 {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 2px;
          margin: 5px 5px 1px;
          text-align: center;
          text-transform: none;
        }
        span {
          font-size: 12px;
          display: block;
          margin: 5px;
          text-align: center;
          &.location-phone {
            margin-bottom: 12px;
          }
        }
      }
      .date-time {
        border-right: 1px solid $color--gray-lightest;
      }
      .artist {
        display: none;
      }
      .schedulle-copy {
        span {
          letter-spacing: 0.105em;
          line-height: 18px;
          margin: 10px auto;
        }
      }
      .location {
        a {
          border-bottom: 1px solid $color-black;
          padding-bottom: 5px;
          font-size: 14px;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 0.23em;
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    margin-top: 30px;
  }
}

@include breakpoint($small-down) {
  .appt-book p {
    margin-bottom: 20px;
    margin-top: 16px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }
}

.appt-book-link {
  text-transform: uppercase;
  padding-bottom: 5px;
  text-decoration: none;
  border-bottom: 1px solid $color-light-red;
  font-size: 14px;
  letter-spacing: 0.23em;
  &:hover {
    text-decoration: none;
  }
}

.appointments-buttons {
  .appt-book-link {
    border-bottom: none;
  }
}

@include breakpoint($small-down) {
  .appt-book-link {
    font-size: 12px;
    padding-bottom: 0;
  }
}

.touch .appt-book select {
  padding-right: 35px;
}

.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        .error {
          &.active {
            color: $color-black;
          }
          color: $color-error;
        }
      }
    }
  }
}

.appt-book-page-header {
  position: relative;
  color: $color-white;
  text-decoration: none;
  display: block;
  margin: 0 auto 30px;
  text-align: center;
  .counters & {
    max-width: 1002px;
  }
}

@include breakpoint($small-down) {
  .appt-book-page-header {
    margin: 0 auto;
    text-align: left;
  }
}

.appt-book-page-header-bg {
  img {
    display: none;
    width: 100%;
    height: auto;
  }
  &.bg-img-mobile {
    display: none;
  }
}

@include breakpoint($small-down) {
  .appt-book-page-header-bg.bg-img-mobile {
    display: block;
  }
}

.appt-book-page-header-content {
  border: 0 none;
  height: auto;
  margin: 0 auto;
  padding: 50px 24px 0 24px;
  position: relative;
  text-align: center;
  top: 0;
  width: 100%;
  .inner {
    border-bottom: 1px solid $color-gray-lighter;
    padding: 0 0 50px 0;
    .counters & {
      border-bottom: none;
      padding: 0;
    }
    .confirmation & {
      border-bottom: none;
      padding: 0 0 20px 0;
    }
  }
  @include breakpoint($medium-up) {
    padding: 50px 0 50px 0;
    .inner {
      border-bottom: none;
      padding: 0;
    }
  }
  h2,
  h3,
  h4 {
    text-align: center;
    margin-bottom: 0;
    font-size: 20px;
    color: $color-black;
  }
  h2 {
    font-family: $font--lamer-headline;
    font-size: 56px;
    line-height: 54px;
    letter-spacing: -0.3px;
    padding-bottom: 0;
  }
  h4 {
    font-family: $font--neue-haas-unica-pro;
    text-transform: none;
    font-size: 16px;
    line-height: 26px;
    margin-top: 18px;
    letter-spacing: 0;
  }
  h3 {
    font-family: $font--lamer-headline;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0;
  }
  .counters & {
    padding: 50px 0 50px 0;
    @include breakpoint($medium-up) {
      position: absolute;
      width: auto;
    }
  }
}

@include breakpoint($small-down) {
  #content {
    .appt-book-page-header-content {
      h2 {
        font-family: $font--lamer-headline;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0;
        padding-bottom: 0;
        border: none;
      }
    }
  }
  .section-cancel-appointment {
    .appt-book-page-header-content {
      h2 {
        border-bottom: 0;
        font-size: 13px;
        padding-top: 5px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-page-header-content h4 {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
  }
}

.appt-book-page-header-content__dots {
  position: absolute;
  bottom: 17px;
  left: 50%;
  margin-left: -67px;
  display: none;
}

.main {
  .appt-book-page-header-content__dots {
    li {
      text-indent: -9999px;
      background: url('/media/export/cms/appointment_booking/common/dot_transparent.png') no-repeat 0 0;
      float: left;
      padding: 0;
      width: 12px;
      height: auto;
      margin-left: 17px;
    }
  }
}

.appt-book-page-header-content__dots {
  li.active {
    background: url('/media/export/cms/appointment_booking/common/dot_black.png') no-repeat 0 0;
  }
}

@include breakpoint($small-down) {
  .appt-book-page-header-content__dots {
    margin-left: -52px;
  }
  .main {
    .appt-book-page-header-content__dots {
      li {
        background-size: 10px;
        margin-left: 14px;
        padding: 0;
        width: 10px;
      }
    }
  }
}
/* MAIN CONTENT
*****************************/

.appt-book-sections-container {
  position: relative;
  .counters & {
    max-width: $max-width;
  }
}

@include breakpoint($small-down) {
  .appt-book-sections-container {
    padding: 0;
    text-align: left;
  }
}

.appt-book-section,
.appt-book-section-content {
  position: relative;
  width: 100%;
}

@include breakpoint($small-down) {
  .appt-book-section-content {
    margin-bottom: 20px;
    padding-top: 5px;
  }
}

@include breakpoint($small-down) {
  .appt-book .basic-responsive {
    font-size: 16px;
  }
}

@include breakpoint($small-down) {
  .appt-book .basic-responsive {
    a {
      margin-top: 0;
    }
  }
}

.appt-book-content-header {
  display: inline-block;
  margin: 0 auto;
}

@include breakpoint($small-down) {
  .appt-book-content-header {
    display: inline;
    .book-now-bar {
      display: none;
    }
  }
  #content {
    .appt-book-content-header {
      p {
        font-family: $font--neue-haas-unica-pro;
        text-align: center;
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
  .service-select {
    .appt-book-content-header {
      display: none;
      margin-bottom: 0;
      padding: 0 24px;
    }
  }
}

.appt-book {
  .appt-book-content-header {
    .total_text {
      font-family: $font--neue-haas-unica-pro;
      font-size: 17px;
      display: inline-block;
      height: 46px;
      overflow: hidden;
      padding: 0 0 0 10px;
    }
  }
}

.appt-book-content-header-button {
  display: block;
  max-width: 1202px;
  border-bottom: 2px solid $color-gray-light;
  border-top: 2px solid $color-gray-light;
  padding-bottom: 24px;
}

.total_text_book_now_bar_button {
  margin-top: 29px;
}

.appt-book {
  .appt-book-content-header {
    .book-now-bar {
      font-family: $font--neue-haas-unica-pro;
      font-size: 17px;
      display: inline-block;
      height: 46px;
      overflow: hidden;
      margin: 0 auto;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book .book-now-bar {
    display: block;
    border-top: 1px solid $color-gray-light;
    margin-top: 30px;
    .button {
      margin-left: 6%;
      margin-top: 24px;
      text-align: center;
      width: 88%;
    }
  }
}
/* APPT LANDING
*****************************/

.appt-book-location-selector {
  font-family: $font--lamer-headline;
  font-size: 14px;
  margin: 0 0 27px;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  @include breakpoint($medium-up) {
    margin: 0 auto 40px;
    max-width: 1002px;
    width: 60%;
    border-right: 1px solid $color-gray-light;
    border-bottom: none;
    float: left;
  }
  .form-container {
    display: inline-block;
    margin-bottom: 26px;
    overflow: hidden;
    position: relative;
    width: 100%;
    .location-select {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 70%;
      }
    }
    @include breakpoint($medium-up) {
      margin-bottom: 24px;
      width: auto;
    }
  }
  .counters & {
    @include breakpoint($medium-up) {
      padding-top: 180px;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book .ui-select {
    .select-location-arrow {
      height: 44px;
    }
    float: none;
    margin-bottom: 22px;
    overflow: visible;
    width: 100%;
  }
}
/* This is to remove the arrow of select element in IE */

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .ui-select {
      width: 345px;
      select {
        width: 345px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .ui-select {
      select {
        font-size: 13px;
        padding: 0 8% 0 19px;
        width: 100%;
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .location-submit {
      width: 230px;
      &:hover {
        width: 230px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appt-book-location__signin-text {
      text-align: center;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book .appt-book-section {
    .appt-book-location__book-service {
      font-size: 13px;
      margin: 19px 20px 0;
      text-align: center;
    }
  }
}
/* PICK SERVICE
*****************************/

@include breakpoint($small-down) {
  .appt-book.services {
    .appt-book-content-header {
      .total_text {
        float: none;
      }
    }
  }
}

@include breakpoint($small-down) {
  #content {
    .appt-book.services {
      .appt-book-page-header-content {
        top: 30%;
      }
      .appt-book-page-header-content__title {
        margin-bottom: 0px;
      }
    }
  }
  .appt-book.services {
    .appt-book-page-header-content__subhead {
      font-size: 20px;
      letter-spacing: 0;
      padding-bottom: 0;
      padding-top: 25px;
      border: none;
      display: block;
      margin-top: 0;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book.services {
    .appt-book-page-header-content__dots {
      li.active {
        background-size: 10px auto;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book.services {
    background-color: $color-white;
    .appt-book-sections-container {
      padding: 0;
      margin-left: -24px;
      margin-right: -24px;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .service-select {
      margin: 25px auto 0;
      padding-top: 0;
      .section-header__inner {
        font-size: 16px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book.services {
    .appt-book-content-header {
      border-bottom: none;
      padding-bottom: 0;
      p {
        border-bottom: 1px solid $color-gray-light;
        padding-bottom: 15px;
        margin-bottom: 18px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .step-header {
          .step_head {
            font-size: 16px;
          }
        }
      }
    }
    .appt-book-section-content.appointment-select {
      .appointments-container {
        .step-header {
          text-align: left;
          line-height: 26px;
        }
      }
    }
    .appointment-select {
      .appointments-container {
        .booking-step.step3 {
          padding: 0 24px;
        }
      }
      .step3 {
        .step-header {
          text-align: left;
        }
      }
      #change-services-link,
      #read-all-artist-link,
      #appt-book-sign-in-link,
      .start-over {
        font-size: 12px;
        margin-left: 0;
        padding-bottom: 0;
      }
      #appt-book-sign-in-link,
      .start-over {
        border-bottom: none;
        text-decoration: underline;
      }
      .selects-container {
        .select-container {
          h4 {
            letter-spacing: 1px;
            margin: 20px 0;
            padding: 0;
            text-transform: none;
            text-align: center;
          }
        }
      }
      .step1 {
        .selects-container {
          .counter-select-container {
            h4 {
              margin: 22px 0 0;
              text-align: left;
            }
          }
        }
        .artist-select-container h4 {
          text-align: left;
          margin: 0;
        }
      }
      .cancel-container {
        display: block;
        float: none;
        height: auto;
        margin: 0px;
        overflow: hidden;
        width: 100%;
        text-align: center;
        .redeemable-appointment {
          font-size: 13px;
          width: 100%;
          padding: 0 9%;
        }
      }
      .confirm-container {
        .start-over {
          float: left;
          margin-left: 20%;
          text-align: center;
          width: auto;
        }
      }
    }
    .appt-book-content-header {
      .total_cost {
        border-right: 1px solid $color-gray-light;
        height: auto;
        padding-right: 16px;
        margin-top: 0px;
        .total_cost_text {
          padding: 10px 5px 10px 0;
        }
        .price {
          display: inline-block;
          float: left;
          padding: 10px 5px 10px 0;
        }
      }
    }
    &.services {
      .appt-book-content-header {
        .total_cost {
          padding-right: 3px;
          width: 50%;
          height: 73px;
          line-height: 50px;
        }
      }
    }
    .button.disabled,
    input[type='submit'].disabled {
      background: $color-gray;
      color: $color-white;
      border-color: $color-gray;
      cursor: default;
      &:hover,
      &:active,
      &:focus {
        background: $color-gray;
        color: $color-white;
        border-color: $color-gray;
      }
    }
    .appt-book-content-header {
      .book-now-bar {
        border: medium none;
        display: inline-block;
        margin-top: 10px;
        overflow: visible;
        width: 100%;
        .button {
          margin-left: 0;
          margin-top: 0;
          width: 100%;
        }
      }
    }
    .book-now-bar .minutes {
      display: none;
    }
  }
  #content {
    .appt-book.services {
      .appt-book-content-header {
        .total_text {
          padding: 0 0 0 5px;
          width: 50%;
          line-height: 50px;
          height: 50px;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appt-book-content-header {
      .total_container {
        font-family: $font--arial;
        font-size: 16px;
        letter-spacing: 0px;
        margin: 10px 0;
        text-align: center;
        border-top: 1px solid $color-gray-light;
        border-bottom: 1px solid $color-gray-light;
        height: 75px;
        .total_text {
          height: auto;
          .total_time_text {
            font-family: $font--neue-haas-unica-pro;
            font-weight: bold;
          }
        }
        .total_cost_text {
          font-weight: bold;
        }
      }
      .total_text {
        .total_time_text {
          padding: 10px 3px 10px 0;
        }
      }
      .total_container .minutes {
        background: url('/media/export/cms/appointment_booking/common/clock_icon.png') 0 center no-repeat;
        background-position: 0 50%;
        display: inline-block;
        float: left;
        padding: 10px 0 10px 17px;
      }
    }
  }
  &.services {
    .appt-book-content-header {
      .total_container {
        border-top: none;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .book-now-bar {
      .button:hover {
        font-size: 13px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .service-select {
      .services-container {
        background: none;
        border: none;
        margin-bottom: 0;
        margin-top: 25px;
        padding: 0;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .service-select {
      .service {
        border-top: 1px solid $color-gray-lighter;
        border-bottom: none;
        float: none;
        width: 100%;
        min-height: 0;
        padding: 0;
        margin: 0;
        .service-head {
          cursor: pointer;
          overflow: hidden;
          position: relative;
          text-align: center;
          width: 100%;
          background: $color-white-smoke;
          .icon {
            display: block;
            width: 31px;
            height: 50px;
            float: right;
            padding: 0;
            margin: 0;
            visibility: visible;
            background: url('/media/export/cms/appointment_booking/common/arrow_close.png') no-repeat 4px center;
          }
        }
        &.expanded .service-head {
          background: $color-white;
          .icon {
            background: url('/media/export/cms/appointment_booking/common/arrow_open.png') no-repeat 0 19px;
          }
        }
        .service-head .service-title {
          font-family: $font--lamer-text;
          font-weight: bold;
          display: block;
          float: left;
          font-size: 16px;
          margin-left: 6%;
          padding: 16px 0;
          text-transform: none;
          width: 82%;
          line-height: 18px;
          text-align: left;
        }
        &.selected .selection-bar .selection-time {
          background: url('/media/export/cms/appointment_booking/common/clock_icon.png') 0 center no-repeat;
          color: $color-black;
          background-position: 22% center;
        }
        .selection-bar .selection-time {
          background-position: 22% center;
          padding-left: 9%;
          font-size: 13px;
          text-align: left;
          width: 45%;
        }
        .service-content-container {
          padding: 0;
        }
      }
      #content {
        .service-select {
          .service {
            .service-head {
              h3.service-title {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .service-select {
      .service {
        .service_tile {
          margin: 0 5px;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .service-select {
      .service {
        .service-image {
          width: 47%;
          margin: 0;
          float: left;
        }
        .service-details {
          width: 53%;
          float: left;
          margin-top: 10px;
          margin-left: 0;
          h3.service-title {
            font-size: 18px;
            margin-top: -2px;
          }
          .lesson_description {
            padding: 0;
            p {
              font-size: 12px;
              line-height: 21px;
              margin: 1px 24px 10px 16px;
              text-align: left;
            }
          }
        }
        .selection-bar {
          bottom: auto;
          float: none;
          position: relative;
          width: 100%;
          clear: both;
          height: 48px;
          & > div {
            font-size: 13px;
            letter-spacing: 0;
            padding: 10px 0;
          }
          .selection-selector {
            font-size: 12px;
            width: 55%;
            padding: 10px 0;
            display: inline-block;
            text-align: center;
            .icon {
              top: 0;
              float: none;
            }
            &.selected {
              color: $color-black;
              div {
                border-top: 1px solid $color-black;
              }
              .icon {
                background: url('/media/export/cms/appointment_booking/common/check_on.png') no-repeat;
                top: 0;
                float: none;
              }
            }
          }
        }
      }
      .disclaimer_text {
        font-size: 13px;
        height: auto;
        padding: 0 24px 24px 24px;
        text-align: center;
        border: none;
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .service-select {
      .service {
        .service_tile-content {
          padding: 30px 10px 90px;
        }
        .service-details {
          h3.service-title {
            font-family: $font--lamer-text;
            font-size: 21px;
          }
        }
        .selection-bar {
          width: 44%;
          .selection-time {
            font-size: 15px;
            width: 45%;
          }
          .selection-selector {
            font-size: 9px;
            width: 55%;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay {
    &.appt-book-services-unavailabe-overlay,
    &.appt-book-services-overlay,
    &.appt-book-book-conflict-overlay {
      .overlay-content-container {
        .overlay-content {
          p {
            width: 100%;
          }
        }
      }
    }
    .overlay-content-container {
      .overlay-content {
        height: 220px;
      }
    }
    &.appt-book-book-conflict-overlay {
      .overlay-content-container {
        .overlay-content {
          padding: 40px 45px 0 45px;
        }
      }
    }
  }
}
/* DATE TIME
*****************************/

@include breakpoint($small-down) {
  .appt-book.appointments {
    .appt-book-page-header-content__dots {
      li {
        background-size: 10px auto;
        &.active {
          background-size: 10px auto;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appt-book-content-header {
        margin: 0;
      }
    }
  }
  #content {
    .appt-book {
      .appt-book-section-content.appointment-select {
        .appt-book-content-header {
          p {
            margin-bottom: 21px;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .booking-step.step1 {
          margin: 17px 0 25px;
          padding: 0 24px;
          text-align: center;
          .services {
            .service-head {
              border-bottom: none;
              margin-bottom: 0;
              margin-top: 13px;
              text-align: left;
              padding-bottom: 15px;
            }
          }
          .ui-select {
            margin: 0;
          }
          .step-header {
            text-align: left;
            margin-bottom: 19px;
          }
        }
        .booking-step.step2 {
          margin-bottom: 30px;
          padding: 0;
          .step-header {
            margin: 0px 24px 20px;
          }
          .appointment-select .date-picker {
            padding: 0px 24px;
            thead {
              background-color: transparent;
              tr {
                border-bottom: 1px solid $color-gray-light;
                border-top: 1px solid $color-gray-light;
                th {
                  padding: 17px 0 10px;
                }
              }
            }
            tbody tr {
              td {
                padding: 15px 0 0;
              }
              &:nth-child(1) td {
                padding: 18px 0 0;
              }
              &:last-child td {
                padding-bottom: 17px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .step-header {
          font-size: 14px;
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .services {
          width: 72%;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .services {
          margin: 0;
          width: 100%;
          h4 {
            letter-spacing: 1px;
          }
        }
        .service {
          float: none;
          padding: 1.8% 7%;
          width: 100%;
          &:nth-child(2) {
            margin-top: 18px;
          }
          .service-head {
            font-size: 14px;
            letter-spacing: 1px;
            text-align: center;
            text-transform: none;
            width: 100%;
          }
          .selection-bar {
            border-top: 1px solid $color-gray-light;
            padding-top: 12px;
            padding-bottom: 4px;
            .selection-time {
              font-size: 14px;
            }
          }
        }
        #change-services-link {
          float: none;
          margin: 17px 0 0;
          width: 100%;
        }
        .selects-container {
          margin: 0;
          overflow: initial;
          .select-container {
            float: none;
            display: block;
            padding: 0 0 28px;
            width: auto;
            select {
              margin: 0;
              width: 100%;
            }
          }
          .artist-select-container {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .service {
          .selection-bar > div {
            letter-spacing: 0.25em;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .selects-container {
          .select-container {
            width: 32.5%;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .appointment-select {
          .calendar-head {
            border-bottom: medium none;
            display: block;
            font-size: 14px;
            height: auto;
            line-height: 18px;
            margin: 24px 0 27px;
            text-transform: uppercase;
            .cal-head-content {
              .cal-copy {
                letter-spacing: 1px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book .appointment-select .appointments-container .appointment-select .date-picker {
    width: 100%;
    margin-bottom: 13px;
    .pikaday-container {
      padding: 0;
      .pika-single {
        border: 1px solid $color-gray-light;
        padding: 0;
        z-index: 0;
        .pika-lendar {
          float: none;
          margin: 0;
          width: 100%;
          .pika-title {
            font-family: $font--arial-times-lino;
            font-size: 16px;
            line-height: 26px;
            margin-top: 22px;
            margin-bottom: 11px;
            .pika-label {
              font-size: 15px;
              font-weight: normal;
              letter-spacing: 0;
              z-index: 0;
              &:last-child {
                display: none;
              }
              .pika-select {
                z-index: 0;
                display: none;
              }
            }
            .pika-prev,
            .pika-next {
              width: 25px;
              height: 25px;
              cursor: pointer;
              overflow: hidden;
              text-indent: 100%;
              white-space: nowrap;
            }
            .pika-prev {
              background: transparent url('/media/export/cms/appointment_booking/common/date_arrow_left_mob.png')
                no-repeat;
              margin-left: 20px;
            }
            .pika-next {
              background: transparent url('/media/export/cms/appointment_booking/common/date_arrow_right_mob.png')
                no-repeat;
            }
          }
          table.pika-table {
            border-collapse: collapse;
            border-spacing: 0;
            margin: 0;
            th {
              border: none;
              background-color: transparent;
              color: $color-black;
              height: 33px;
              padding: 0;
              width: 36px;
              abbr {
                border: none;
                cursor: default;
                &[title] {
                  border: none;
                  cursor: default;
                }
              }
            }
            td {
              border: none;
              background-color: transparent;
              color: $color-black;
              height: 33px;
              padding: 0;
              width: 36px;
              .pika-button {
                background-color: $color-white;
                border-radius: 0;
                box-shadow: none;
                border: none;
                color: $color-black;
                float: none;
                font-size: 11px;
                height: 33px;
                padding: 0;
                text-align: center;
                width: 35px;
              }
              &.is-selected .pika-button {
                background-color: $color-black;
                color: $color-white;
                padding: 0;
                text-shadow: 0 0 0 $color-white;
                &:hover {
                  background-color: $color-black;
                  color: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .appointment-select {
          table {
            border: medium none;
            border-spacing: 0;
            margin: 0;
            th {
              font-size: 14px;
              line-height: 16px;
            }
            td {
              font-weight: normal;
              padding: 0.9em 0;
              font-size: 13px;
              line-height: 48px;
            }
            &.day td {
              font-size: 12px;
              line-height: 27px;
              width: 25%;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .error-messages {
          .error-message {
            color: $color-error;
          }
        }
        .sign-in-container {
          margin: 27px 0 23px;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .booking-step.step3 {
          .sign-in-container {
            h4 {
              font-size: 13px;
              margin-bottom: 20px;
              a.sign-in-link {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .booking-step.step3 {
          .book-appt-container {
            margin: 0 0 20px;
            h4 {
              font-size: 13px;
              letter-spacing: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($medium-landscape-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .sign-in-container {
          .sign-in-form-container {
            input.login__email {
              width: 48%;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .sign-in-container {
          .sign-in-form-container {
            input.login__email {
              width: 47%;
            }
            input.login__submit {
              width: 125px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .sign-in-container {
          .sign-in-form-container {
            input {
              font-size: 11px;
              &.login__email {
                width: 100%;
                float: none;
              }
              &.login__password {
                width: 100%;
                float: none;
              }
              &.login__submit {
                float: none;
                font-size: 11px;
                line-height: 0;
                margin: 0;
                padding: 5px;
                width: 100%;
              }
            }
            a.login_forgot {
              display: block;
              font-family: $font--arial-times;
              font-size: 12px;
              letter-spacing: 0px;
              line-height: 10px;
              padding-bottom: 0px;
              padding-left: 12px;
              text-decoration: none;
              text-transform: uppercase;
              margin: 30px 0;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          h4 {
            font-size: 12px;
            letter-spacing: 1px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-landscape-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          input {
            max-width: 475px;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          input {
            max-width: 347px;
            padding: 0 10px;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-landscape-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          fieldset {
            max-width: 475px;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          fieldset {
            max-width: 347px;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          letter-spacing: 1px;
          margin: 20px 20px 0;
          .registration__email-list,
          .registration__sms-list,
          .registration__sms_notifications-list {
            width: auto;
            float: none;
            display: block;
            margin-bottom: 10px;
            margin-top: 7px;
          }
          .registration__email-list {
            margin-bottom: 20px;
            span {
              text-transform: none;
            }
            input {
              opacity: 1;
            }
          }
          .registration__sms-list,
          .registration__sms_notifications-list {
            margin-bottom: 5px;
            width: auto;
            float: none;
            span {
              text-transform: none;
            }
            input {
              opacity: 1;
            }
          }
          input,
          fieldset {
            float: none;
            font-size: 13px;
            margin: 10px 0;
            width: 100%;
          }
          input {
            &.appt-book-first-name,
            &.appt-book-email {
              font-size: 13px;
              padding: 0 18px;
              width: 100%;
            }
            &.mobile-phone-fieldset {
              font-size: 13px;
              width: 100%;
            }
            &.appt-book-mobile {
              width: 82%;
            }
            &.appt-book-mobile-prefix {
              padding: 0;
              text-align: center;
              width: 18%;
              background-color: transparent;
            }
          }
          fieldset {
            &.appt-book-first-name,
            &.appt-book-email {
              font-size: 13px;
              padding: 0 18px;
              width: 100%;
            }
            &.mobile-phone-fieldset {
              font-size: 13px;
              width: 100%;
            }
            &.appt-book-mobile-prefix {
              padding: 0;
              text-align: center;
              width: 18%;
              background-color: transparent;
            }
          }
          fieldset.mobile-phone {
            height: auto;
            margin: 0;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          input.appt-book-mobile-prefix {
            padding: 0 8px 0;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .book-appt-container {
          input.appt-book-mobile-prefix {
            margin: 10px 0;
          }
        }
        .booking-step.step3 {
          .confirm-container {
            margin: 18px 0 32px;
            padding: 0px 0 24px;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .confirm-container {
          width: auto;
          float: none;
          text-align: left;
          margin: 18px 20px;
          .total-time,
          .total-cost {
            padding: 25px 0 24px;
          }
          .total-time {
            letter-spacing: 0;
            margin: 0 auto 0px;
            text-align: center;
            width: 100%;
          }
          &.cost .total-time {
            width: 50%;
            margin-bottom: 20px;
            border-bottom: 1px solid $color-gray-light;
          }
          .total-cost {
            border-right: 1px solid $color-gray-light;
            border-bottom: 1px solid $color-gray-light;
            float: left;
            margin-left: 0;
            width: 50%;
            margin-bottom: 20px;
            margin-top: 0;
            letter-spacing: 0;
          }
          .total-time.total-cost-added {
            width: 50%;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .confirm-container {
          .appt-book-book-submit {
            margin-left: 0;
            width: 135px;
            &.disabled {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .appointment-select {
      .appointments-container {
        .confirm-container {
          .appt-book-book-submit {
            width: 100%;
            &.disabled {
              margin: 0px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    .confirmation {
      .appt-book-content-header {
        display: block;
        margin: 20px 0 5px;
        text-align: center;
        p {
          width: 100%;
          text-align: left;
        }
      }
      .confirmation-container {
        border-top: none;
        padding: 5px 25px 0;
        .your-lessons,
        .date-time {
          border-right: none;
        }
        .date-time,
        .artist {
          padding-top: 0;
        }
        & > div {
          float: none;
          text-align: left;
          width: 100%;
          border: none;
          min-height: 0;
          padding: 0;
          margin: 0 0 35px;
          h4 {
            text-align: center;
          }
          span {
            text-align: center;
          }
        }
        .schedulle-copy {
          span {
            letter-spacing: 1px;
            line-height: 1.5em;
          }
        }
        .location {
          a {
            border-bottom: none;
            display: block;
            font-size: 12px;
            padding-bottom: 0;
            text-align: center;
            &.virtual-appt-link {
              line-height: 1;
              margin-top: 18px;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

.appt-book-overlay {
  &#appt-book-my-appointments-overlay .overlay-content-container .overlay_container .overlay-close {
    top: 30px;
  }
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(38, 38, 38, 0.81);
  z-index: 100;
  &.appt-book-services-unavailabe-overlay,
  &.appt-book-services-overlay,
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      .overlay-close {
        background: transparent url('/media/export/cms/appointment_booking/common/overlay_close_btn.png') no-repeat;
        cursor: pointer;
        display: block;
        height: 16px;
        overflow: hidden;
        position: absolute;
        right: 5%;
        text-indent: 100%;
        top: 7%;
        white-space: nowrap;
        width: 16px;
        z-index: 3;
        @include breakpoint($medium-up) {
          right: 3.5%;
          top: 12%;
        }
      }
      .overlay-content {
        border: 1px solid $color-gray-light;
        display: block;
        height: 557px;
        margin: 10px;
        padding: 25px 10px 0;
        position: relative;
        text-align: center;
        @include breakpoint($medium-up) {
          padding: 15px 45px 0;
        }
        h4 {
          font-family: $font--neue-haas-unica-pro;
          font-size: 21px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 25px;
          font-weight: bold;
        }
        p {
          font-family: $font--neue-haas-unica-pro;
          font-size: 14px;
          color: $color-black;
          letter-spacing: 0px;
          line-height: 28px;
          border-top: 1px solid $color-gray-light;
          padding-top: 20px;
        }
      }
    }
  }
  .overlay-content-container {
    position: absolute;
    display: block;
    text-align: center;
    background-color: $color-white;
    z-index: 1;
    width: 428px;
    top: 50%;
    left: 50%;
    margin-top: -95px;
    margin-left: -214px;
    height: 192px;
    margin: 3px;
    .overlay-close {
      background: transparent url('/media/export/cms/appointment_booking/common/overlay_close_btn.png') no-repeat;
      cursor: pointer;
      display: block;
      height: 16px;
      overflow: hidden;
      position: absolute;
      right: 2%;
      text-indent: 100%;
      top: 3%;
      white-space: nowrap;
      width: 16px;
      z-index: 3;
    }
  }
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      .overlay-close {
        right: 3%;
        top: 11%;
      }
    }
  }
  .overlay-content-container {
    .overlay-content {
      border: 1px solid $color-gray-light;
      display: block;
      height: 557px;
      margin: 10px;
      padding: 45px;
      position: relative;
      text-align: center;
      &#confirm-cancel {
        height: auto;
      }
      h4 {
        font-family: Arial, sans-serif;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.205em;
        margin-bottom: 20px;
        margin-top: 4px;
        padding: 0;
        text-transform: none;
      }
      p {
        font-size: 12px;
        line-height: 1.75;
        margin-top: 0;
      }
    }
  }
  &.appt-book-services-unavailabe-overlay,
  &.appt-book-services-overlay,
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      height: 256px;
      left: 50%;
      margin-left: -151px;
      width: 300px;
      top: 50%;
      margin-top: -95px;
      @include breakpoint($medium-up) {
        width: 600px;
        height: 166px;
        left: 44%;
        margin-left: -214px;
      }
    }
  }
  &.appt-book-services-unavailabe-overlay {
    .overlay-content-container {
      height: 180px;
    }
  }
  &.appt-book-services-overlay,
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      .overlay-content {
        height: auto;
        @include breakpoint($medium-up) {
          height: 140px;
        }
      }
    }
  }
  &.appt-book-services-unavailabe-overlay {
    .overlay-content-container {
      .overlay-content {
        height: 160px;
        h4 {
          margin-top: 14px;
        }
      }
    }
  }
  &.appt-book-services-overlay,
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      .overlay-content {
        h4 {
          margin-top: 14px;
        }
      }
    }
  }
  &.appt-book-services-unavailabe-overlay,
  &.appt-book-services-overlay,
  &.appt-book-book-conflict-overlay {
    .overlay-content-container {
      .overlay-content {
        p {
          line-height: 20px;
          padding-top: 10px;
        }
      }
    }
  }
  &.appt-book-datepicker-overlay {
    display: block;
    z-index: 1000;
    .overlay-content-container {
      height: auto;
      left: 50%;
      margin-left: -475px;
      max-width: 966px;
      top: 5%;
      width: 100%;
      .pika-single {
        color: $color-black;
        border: none;
      }
      .pika-title {
        font-family: $font--neue-haas-unica-pro;
        font-size: 18px;
        font-weight: bold;
        color: $color-black;
        letter-spacing: 0px;
        line-height: 26px;
        padding: 0 10px;
        button:hover,
        .offers-vip__button:hover {
          background-color: transparent;
        }
      }
      .pika-label {
        font-family: $font--arial-times-lino;
        font-size: 18px;
        font-weight: bold;
        color: $color-black;
        letter-spacing: 0px;
        line-height: 15px;
        .pika-select {
          top: 0;
        }
      }
      .pika-table {
        th {
          color: $color-black;
        }
        abbr {
          &[title] {
            border: none;
            text-decoration: none;
            cursor: default;
          }
          border: none;
          text-decoration: none;
          cursor: default;
        }
        thead {
          background-color: transparent;
          tr {
            border-bottom: 1px solid $color-gray-light;
            border-top: 1px solid $color-gray-light;
          }
        }
        td {
          color: $color-black;
          border-bottom: none;
        }
      }
      .pika-button {
        background: $color-white;
        border-radius: 0;
        color: $color-black;
        filter: none;
        font-size: 18px;
        &:hover {
          background-color: $color-black;
          border-radius: 0;
          color: $color-white;
          font-size: 18px;
        }
      }
      .pika-prev,
      .is-rtl .pika-next {
        background: transparent url('/media/export/cms/appointment_booking/common/date_arrow_left.png') no-repeat;
        filter: none;
      }
      .pika-next,
      .is-rtl .pika-prev {
        background: transparent url('/media/export/cms/appointment_booking/common/date_arrow_right.png') no-repeat;
        filter: none;
      }
    }
  }
  &.my-appointments-overlay .overlay-content-container {
    width: 431px;
    min-height: 184px;
    top: 50%;
    left: 50%;
    margin-top: -95px;
    margin-left: -214px;
    #confirm-cancel {
      p {
        font-family: $font--neue-haas-unica-pro;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.2em;
        line-height: 1.55;
        margin-bottom: 20px;
        margin-top: 1px;
        text-transform: none;
      }
      .yes-btn {
        height: 23px;
        margin-right: 13px;
        width: 119px;
      }
      .no-btn {
        height: 23px;
        width: 119px;
      }
      .button,
      .viewcart-buttons .go-shopping {
        width: 152px;
        height: 38px;
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay {
    .overlay-content-container {
      width: 300px;
      height: 232px;
      margin-left: -150px;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay {
    .overlay-content-container {
      .overlay-content {
        h4 {
          font-size: 24px;
          letter-spacing: 0.11em;
          line-height: 1.1em;
          margin-bottom: 8px;
          margin-top: 9px;
        }
        p {
          margin: 17px auto;
          line-height: 1.5em;
          font-size: 12px;
          width: 90%;
        }
      }
    }
  }
}

@include breakpoint($medium-landscape-only) {
  .appt-book-overlay {
    &.appt-book-services-unavailabe-overlay,
    &.appt-book-services-overlay,
    &.appt-book-book-conflict-overlay {
      .overlay-content-container {
        left: 42%;
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book-overlay {
    &.appt-book-services-unavailabe-overlay,
    &.appt-book-services-overlay,
    &.appt-book-book-conflict-overlay {
      .overlay-content-container {
        left: 40%;
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      left: 60%;
      margin-left: -451px;
      width: 97%;
    }
  }
}

@include breakpoint($medium-up) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      top: 60px;
      padding-top: 65px;
      padding-bottom: 38px;
    }
  }
}

@include breakpoint($medium-up) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-single {
        .pika-lendar {
          border: medium none;
          margin: 0;
          padding-right: 3%;
          padding-left: 0.5%;
          width: 50%;
          &:last-child {
            border-left: 1px solid $color-gray-light;
            padding-right: 0.5%;
            padding-left: 3%;
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-label {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }
}

@include breakpoint($medium-up) {
  .appt-book-overlay.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-table {
        margin-top: 27px;
        th {
          border: medium none;
          font-family: $font--lamer-text;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 26px;
          padding: 10px 0 7px;
        }
        td {
          border-right: medium none;
          font-family: $font--neue-haas-unica-pro;
          font-size: 18px;
          letter-spacing: 0px;
          line-height: 26px;
          padding: 19px 11px 20px 0;
        }
        tr:last-child td {
          padding-bottom: 0;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      #confirm-cancel {
        p {
          br {
            display: none;
          }
        }
      }
    }
  }
}

.viewcart-buttons {
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      #confirm-cancel {
        .go-shopping {
          width: 152px;
          height: 38px;
        }
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    #confirm-cancel {
      .recommended-item__button {
        width: 152px;
        height: 38px;
      }
      .button.yes-btn,
      .viewcart-buttons .yes-btn.go-shopping {
        margin-right: 36px;
      }
    }
  }
}

.viewcart-buttons {
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      #confirm-cancel {
        .yes-btn.go-shopping {
          margin-right: 36px;
        }
      }
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-content-container {
    #confirm-cancel {
      .yes-btn.recommended-item__button {
        margin-right: 36px;
      }
      .canceled {
        h4 {
          margin: 1px auto 12px;
          line-height: 24px;
          width: 80%;
        }
        p {
          margin-top: 17px;
          margin-bottom: 23px;
          span {
            display: block;
            text-align: center;
            margin-bottom: 5px;
          }
        }
        .book-new-btn {
          padding: 0.86em 1.1em 0.86em 1.4em;
          letter-spacing: 0.25em;
          margin-bottom: 7px;
          background-color: $color-light-red;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      #confirm-cancel {
        .button.yes-btn,
        .viewcart-buttons .yes-btn.go-shopping {
          margin-right: auto;
        }
        .button,
        .viewcart-buttons .go-shopping {
          display: block;
          margin: 10px auto;
        }
      }
    }
  }
  .viewcart-buttons {
    .appt-book-overlay.my-appointments-overlay {
      .overlay-content-container {
        #confirm-cancel {
          .yes-btn.go-shopping {
            margin-right: auto;
          }
          .go-shopping {
            display: block;
            margin: 10px auto;
          }
        }
      }
    }
  }
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      #confirm-cancel {
        .yes-btn.recommended-item__button {
          margin-right: auto;
        }
        .recommended-item__button {
          display: block;
          margin: 10px auto;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      width: 300px;
      height: auto;
      min-height: 0;
      margin-left: -150px;
      p {
        letter-spacing: 1px;
      }
      .canceled {
        h4 {
          width: auto;
          font-size: 14px;
        }
        .book-new-btn {
          letter-spacing: 1px;
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book.appointments {
    .appt-book-sections-container {
      margin-left: -24px;
      margin-right: -24px;
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    #confirmation {
      .confirmation-container {
        .your-lessons {
          padding-bottom: 0px;
          margin: 0 0 24px;
          span.lesson {
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 26px;
            padding-top: 20px;
            text-transform: uppercase;
          }
        }
        .appointment-info-content {
          .appointment-info-detail {
            height: 97px;
          }
          .date-time {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid $color-gray-light;
            height: auto;
            padding-bottom: 23px;
            &.total-cost-added {
              height: 292px;
              border-bottom: 1px solid $color-gray-light;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    #confirmation {
      .confirmation-container {
        .appointment-info-content {
          &.total-cost-added .total-time {
            border-right: none;
          }
          .start-time,
          .total-time {
            padding: 0;
            width: 50%;
            float: left;
            border-bottom: 1px solid $color-gray-light;
          }
          .total-cost {
            width: 100%;
          }
          .total-time {
            border-right: none;
          }
          .appointment-info-detail {
            h4 {
              padding-top: 23px;
            }
            span {
              font-family: $font--lamer-headline;
              font-size: 18px;
              color: $color-black;
              line-height: 36px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .appt-book {
    #confirmation {
      .confirmation-container {
        .appointment-info-content {
          .appointment-info-detail {
            span.appt-date {
              line-height: 27px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    #confirmation {
      .confirmation-container {
        .location {
          span {
            font-size: 13px;
            line-height: 21px;
            &.location-name {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($small-down) {
  .appt-book {
    #confirmation {
      .confirmation-container {
        .location {
          .location-link {
            border-top: 1px solid $color-gray-light;
          }
          a.location-direction-links {
            width: 100%;
          }
        }
        .schedulle-copy {
          span.schedulle-text {
            b {
              font-weight: bold;
            }
          }
        }
        .redeemable-appointment {
          span.redeemable-appointment {
            font-size: 13px;
          }
        }
      }
    }
  }
}
/* CANCEL APPOINMENT FROM LINK
*****************************/

@include breakpoint($small-down) {
  .appt-book {
    #appointment-cancellation {
      margin-top: 25px;
      text-align: center;
      #appt-book-btn {
        width: 100%;
        padding: 11px 0px;
      }
      .cancel-content {
        p {
          span {
            text-align: center;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      .error-content {
        p {
          text-align: center;
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}

.appt-artist-overlay {
  #cboxContent {
    height: 360px !important;
    #cboxLoadedContent {
      height: 360px !important;
    }
  }
  .icon--remove {
    font-size: 15px;
    #{$rdirection}: 0;
    &::before {
      font-weight: bold;
    }
  }
  #cboxClose {
    @include breakpoint($medium-down) {
      #{$rdirection}: 0;
      top: 5px;
    }
  }
}

.app-artist-info {
  [tabindex='0']:focus,
  button:focus {
    outline: 0 !important;
  }
  .slick-slide {
    width: 295px;
  }
  .slick-list {
    margin-top: 25px;
    margin-#{$rdirection}: 50px;
    @include breakpoint($medium-down) {
      margin: 10px 20px 0 -50px;
    }
  }
  .slick-track {
    @include breakpoint($medium-down) {
      #{$ldirection}: 7px;
    }
  }
  .slick-prev {
    #{$ldirection}: -30px;
    outline: 0;
    opacity: 1;
    &::before {
      content: '\2039';
      color: $ab--color--gray--black;
      font-size: 50px;
    }
    &.slick-disabled {
      opacity: 0.3;
    }
  }
  .slick-next {
    #{$rdirection}: 25px;
    outline: 0;
    opacity: 1;
    &::before {
      content: '\203A';
      color: $ab--color--gray--black;
      font-size: 50px;
    }
    &.slick-disabled {
      opacity: 0.3;
    }
  }
  .slick-dots {
    @include breakpoint($medium-down) {
      margin-top: 0;
    }
    li {
      @include breakpoint($medium-down) {
        display: inline-block;
      }
      button::before {
        @include breakpoint($medium-down) {
          font-size: 14px;
          line-height: 20px;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.artist-bio {
  margin-#{$ldirection}: 15px;
  margin-#{$rdirection}: 5px;
  @include breakpoint($medium-down) {
    margin: 0;
    margin-#{$rdirection}: 20px;
  }
  border-radius: 4px;
  border: 1px solid $ab--color--gray--lighter;
  overflow: hidden;
  .artist-image {
    padding: 0 0 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  .artist-details {
    padding-bottom: 10px;
    padding-#{$ldirection}: 10px;
    text-align: left;
    background-color: $ab--color--white;
    .artist_pre_header {
      text-transform: uppercase;
      font-size: 10px;
    }
    .artist-title {
      word-wrap: break-word;
      .artist-header {
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    .artist-description {
      font-size: 12px;
      line-height: 16px;
      .read-more {
        text-decoration: none;
      }
    }
  }
}
