//colors
$checkout-border-header: $color-light-gray;
$color-light-coral: #dcbcad;
$color-aliceblue: #f0f8ff;
$color-dark-blue: #142843;
$color-marine: #4c94a9;
$color-aquamarine: #207fbd;
$color-ultramarine: #003e68;

//mobile mode submit button color change
@mixin join-now-button-color {
  background: $color-green;
  border-color: $color-green;
  @include breakpoint($landscape-up) {
    background: $color-dark-gray;
    border: 1px solid $color-dark-gray;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      background: $color-green;
      color: $color-white;
      border-color: $color-green;
    }
  }
}

// Common styles
@import 'loyalty-points';
@import 'loyalty-transactions';
@import 'loyalty-checkout-confirmation';
@import 'loyalty-offers';
@import 'loyalty-leave';
@import 'loyalty-shared';
@import 'loyalty-sample-tout';
@import 'loyalty-join';
