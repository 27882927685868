#gift_card {
  @include clearfix;
  margin-top: 0;
  margin-bottom: 60px;
  @include breakpoint($landscape-up) {
    margin: 40px 15px 60px 15px;
  }
  @include breakpoint($large-up) {
    margin: 40px auto 60px auto;
  }
  .gift-card {
    &__name {
      font-family: $font--subheading;
      font-size: 19px;
      line-height: 23px;
      text-align: center;
      margin: 0 auto;
      position: relative;
      z-index: 10;
      @include breakpoint($landscape-up) {
        font-size: 30px;
        line-height: 34px;
        text-align: left;
        //width: 36.29032%;
        //margin: 0 4.43548% 0 0;
        padding: 5px 0 0 0;
      }
    }
    &__subline {
      text-align: center;
      font-family: $font--subheading;
      font-size: 14px;
      line-height: 18px;
      margin: 0 auto 15px auto;
      font-style: italic;
      @include breakpoint($landscape-up) {
        text-align: left;
        margin-top: 5px;
      }
      &_balance {
        font-family: $font--sans;
        font-weight: bold;
        font-style: normal;
      }
    }
    &-form__elements {
      padding-top: 15px;
    }
    .gc_instructions {
      margin-bottom: 20px;
    }
  }
  .gift-card-content {
    &__title {
      position: relative;
      //cursor: pointer;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: bold;
      height: 45px;
      line-height: 45px;
      @include breakpoint($medium-up) {
        font-size: 14px;
      }
    }
    &__panel {
      margin: 0 0 20px 0;
      clear: both;
      @include breakpoint($large-up) {
        margin: 0 0 10px 0;
      }
    }
  }
  fieldset.giftcard {
    @include breakpoint($large-up) {
      max-width: 410px;
    }
  }
  #sidebar {
    margin-right: 0;
    @include breakpoint($landscape-up) {
      float: left;
      width: 21%;
      margin-right: 25px;
    }
    @include breakpoint($medium-landscape-only) {
      width: 25%;
    }
  }
  #content {
    padding: 0 7px;
    @include breakpoint($medium-up) {
      padding: 0 20px;
    }
    @include breakpoint($landscape-up) {
      float: right;
      width: 76%;
      padding: 0;
    }
    @include breakpoint($medium-landscape-only) {
      width: 72%;
    }
    h2 {
      text-align: center;
      font-size: 20px;
      padding-bottom: 15px;
      @include breakpoint($medium-up) {
        text-align: left;
        font-size: 20px;
      }
    }
    ul.spacer {
      margin-bottom: 20px;
    }
  }
  #sidebar a,
  .gc_balance_head {
    text-transform: uppercase;
  }
  .left_container {
    @include breakpoint($landscape-up) {
      float: left;
      width: 42%;
      padding-right: 20px;
    }
  }
  .right_container {
    @include breakpoint($landscape-up) {
      float: left;
      width: 58%;
      padding-left: 40px;
    }
    ul {
      li.bold {
        font-weight: bold;
      }
      &.error_messages {
        margin-bottom: 0;
        color: $color-error;
      }
    }
  }
  .section {
    margin: 40px 0 40px 0;
  }
  fieldset.giftcard {
    padding: 0 20px;
    margin-bottom: 0px;
    @include breakpoint($landscape-up) {
      padding: 0;
    }
    div.form_element {
      margin: 0 0 25px 0;
      input {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
      select {
        width: 100%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
      textarea {
        margin-top: 6px;
        height: 120px;
        width: 100%;
        padding: 2px 8px;
      }
    }
    .qty_container {
      select {
        @include breakpoint($medium-up) {
          width: 55px;
          display: block;
        }
      }
    }
    .value_container {
      float: none;
      input {
        .GC_GIFTCARD_PIN {
          width: 18%;
        }
        .GC_GIFTCARD_NUMBER {
          width: 41%;
        }
      }
      select {
        @include breakpoint($medium-up) {
          width: 130px;
          display: block;
        }
      }
      label {
        text-transform: uppercase;
      }
    }
    .email_address_containers {
      @include clearfix;
    }
    .to_address_container {
      @include breakpoint($medium-up) {
        width: 49%;
        float: left;
      }
    }
    .from_address_container {
      @include breakpoint($medium-up) {
        width: 49%;
        float: right;
      }
    }
    .egiftcard & {
      div.form_element {
        input {
          width: 100%;
          display: block;
        }
      }
    }
    .terms_element {
      padding: 15px 0 35px 0;
    }
  }
  .gift_img {
    text-align: center;
    img {
      height: 220px;
      @include breakpoint($landscape-up) {
        height: auto;
      }
    }
  }

  // Gift cards Navigation
  .nav-gift-card {
    border-bottom: 1px solid $color-light-green;
    border-top: 1px solid $color-light-green;
    margin-top: 45px;
    @include breakpoint($landscape-up) {
      border-top: none;
      margin-top: 0;
      padding: 0 20px 25px;
    }
    &__title {
      font-family: $font--text;
      font-size: 11px;
      font-weight: bold;
      padding: 0;
      letter-spacing: 0.05em;
      display: none;
      text-transform: uppercase;
      margin-bottom: 6px;
      @include breakpoint($large-up) {
        font-size: 12px;
        display: block;
      }
    }
    &__content {
      padding-top: 10px;
      li {
        display: block;
        margin: 0;
        padding: 8px 20px;
        @include breakpoint($large-up) {
          padding: 0 0 10px 0;
        }
        a {
          cursor: pointer;
          font-family: $font--text;
          display: block;
          padding: 8px 0 8px 0;
          font-size: 11px;
          line-height: 14px;
          letter-spacing: 0.035em;
          text-decoration: none;
          text-transform: uppercase;
          opacity: 0.8;
          @include breakpoint($large-up) {
            font-size: 12px;
            line-height: 15px;
          }
          &:hover {
            color: $color-green;
            opacity: 1;
          }
        }
      }
    }
  }
  .giftcard-balance li.nav_balance,
  .egiftcard li.nav_buy_egift,
  .pgiftcard li.nav_buy_pgift,
  .giftcard-about li.nav_about,
  .giftcard-terms li.nav_terms {
    a {
      color: #aaaaaa;
    }
  }
}

#gift_card_overlay_container {
  @include breakpoint($medium-up) {
    .left_container {
      float: $ldirection;
      width: 270px;
    }
    .right_container {
      float: $rdirection;
      width: calc(100% - 300px);
    }
  }
  .section {
    margin: 20px 0;
    @include breakpoint($medium-up) {
      margin: 40px 0;
    }
  }
  .content_container {
    h2 {
      border: 0 none;
      margin: 0;
    }
    ul li {
      margin-bottom: 5px;
    }
  }
  .gift_img {
    margin: 10px 0 30px;
  }
}

.section-gift-cards {
  .product-grid__item {
    .product-add-to-favorites {
      display: none;
    }
  }
}
