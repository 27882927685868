.invis-search {
  background: none;
  text-align: center;
  padding: 20px 0;
  height: inherit;
  @include breakpoint($landscape-up) {
    background: $gray;
  }
  &__overlay {
    #cboxLoadedContent {
      padding: 0;
    }
    .invis-results {
      @include breakpoint($landscape-up) {
        float: #{$ldirection};
        width: 30%;
      }
      &__container {
        padding: 0 20px;
        background: $color-white;
      }
      &__header {
        padding: 10px 0;
      }
      &__map {
        height: 420px;
        @include breakpoint($landscape-up) {
          float: #{$rdirection};
          width: 70%;
          position: relative;
          overflow: hidden;
          display: inline-block;
        }
        &.active {
          display: block;
        }
        &.list-icon {
          display: none;
          @include breakpoint($landscape-up) {
            display: inline-block;
          }
        }
      }
      &__list-wrapper {
        height: 420px;
        position: relative;
        overflow: hidden;
      }
      &__list {
        height: 420px;
        overflow-y: scroll;
        &.list-active {
          .invis-result {
            display: none;
            @include breakpoint($landscape-up) {
              display: block;
            }
            &.selected {
              display: block;
            }
          }
        }
      }
      &__info {
        padding: 10px 0;
        border-bottom: solid 1px $color-lighter-gray;
      }
      .invis-result {
        padding: 10px 0;
        border-top: 1px solid $color-lighter-gray;
        &__marker {
          float: #{$ldirection};
          width: 20%;
          padding-#{$rdirection}: 5%;
          text-align: center;
          @include breakpoint($landscape-up) {
            width: 25%;
          }
          @include breakpoint($medium-up) {
            width: 10%;
            padding-#{$rdirection}: 0;
            text-align: #{$ldirection};
          }
          &-store {
            display: inline-block;
            width: 25px;
            height: 25px;
            text-align: center;
            border-radius: 50%;
            color: $color-white;
            background-color: $color-green;
            margin-bottom: 10px;
            @include breakpoint($medium-up) {
              margin-bottom: 0;
            }
          }
        }
        &__store-name {
          @include swap_direction(margin, 0 0 7px 0);
          text-transform: uppercase;
          text-decoration: none;
          border-bottom: 0;
          font-weight: bold;
          color: $color-green;
          @include breakpoint($landscape-up) {
            font-size: 14px;
          }
        }
        &__address {
          width: 75%;
          float: #{$ldirection};
          text-align: #{$ldirection};
          padding-#{$ldirection}: 10px;
          @include breakpoint($landscape-up) {
            width: 70%;
          }
        }
        &__address-phone {
          @include swap_direction(margin, 0 0 2px 0);
          @include breakpoint($landscape-up) {
            margin-bottom: 3px;
          }
        }
        &__directions {
          color: $color-green;
          text-transform: uppercase;
          font-size: 12px;
          text-decoration: none;
          a {
            color: $color-green;
          }
        }
        &__inv-status {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          color: $color-green;
          letter-spacing: 0.5px;
          margin-top: 10px;
        }
        &__distance {
          width: 20%;
          text-align: #{$ldirection};
          color: $color-lighter-gray;
          float: #{$ldirection};
        }
      }
      &__sections {
        margin-bottom: 5px;
        border-bottom: solid 1px $color-light-green;
        height: 45px;
        text-align: center;
        &--items {
          overflow: auto;
          display: inline-block;
        }
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &__button {
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.13em;
        text-align: center;
        height: 45px;
        line-height: 3;
        float: #{$ldirection};
        width: 50%;
        border: 0;
        font-weight: 500;
        display: block;
        min-width: 120px;
        border-bottom: solid 1px $color-lighter-gray;
        font-family: $font--lamer-headline;
        &.active {
          background: $color-white;
          border-bottom: solid 1px $color-gray;
          color: $color-black;
        }
      }
    }
    .invis-form {
      &__select {
        width: 49%;
        margin: 1%;
      }
      &__btn--submit {
        width: auto;
        margin: 1%;
      }
      &__loader {
        padding: 0;
      }
    }
  }
  &__header {
    font-family: $font--heading;
    text-transform: uppercase;
    text-align: center;
    font-size: 25px;
  }
  &__sku-container {
    padding: 5px 0;
    text-align: center;
    min-height: 100px;
    .invis-sku {
      &__image {
        vertical-align: middle;
        display: inline-block;
        max-width: 29%;
      }
      &__name {
        display: inline-block;
        vertical-align: middle;
        text-align: #{$ldirection};
        line-height: normal;
        max-width: 70%;
      }
      &__name--title {
        font-size: 14px;
        font-family: $font--heading;
      }
      &__product--size,
      &__shadename {
        display: block;
        text-align: #{$ldirection};
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  &__messages {
    color: $color-red;
    padding-top: 10px;
    @include breakpoint($landscape-up) {
      padding: 10px 0;
    }
  }
  &__row {
    padding: 0 5px;
  }
}

.invis-start {
  &__available {
    font-size: 14px;
    text-transform: uppercase;
  }
  &__container {
    padding: 10px 0;
    border-top: 1px solid $color-border;
  }
}

body.ajax-wait *,
body.ajax-wait {
  cursor: progress !important;
}

.invis-form {
  &__container {
    text-align: center;
  }
}

.invis-search__container {
  &.invis-result__success {
    .invis-search {
      &__row {
        @include breakpoint($landscape-up) {
          padding: 0 20px 10px;
        }
      }
      &__sku-container {
        @include breakpoint($landscape-up) {
          width: 52%;
          float: #{$ldirection};
          text-align: #{$ldirection};
          position: relative;
          .invis-sku {
            &__image {
              position: absolute;
              #{$rdirection}: 0;
              top: 0;
            }
          }
        }
      }
    }
    .invis-form {
      &__container {
        @include breakpoint($landscape-up) {
          width: 48%;
          float: #{$rdirection};
          padding: 10px 0;
          text-align: #{$rdirection};
        }
      }
    }
  }
}

html:lang(en-HK) {
  .invis-sku__subline {
    display: none;
  }
}
