/******* VIEWCART *********/
/* ------------------------ */
@mixin viewcart_overlay {
  position: fixed;
  z-index: 1003;
  width: 100%;
  height: 100%;
  background: $white;
  overflow: scroll;
  top: 0;
}

#viewcart .panel footer {
  float: right;
  margin: 5px;
}

html.js div.qty input.update_button {
  display: none;
}

html.js body#viewcart .tab-pane {
  opacity: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: opacity 1s ease;
  -moz-transition: opacity 1s ease;
  -o-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  transition: opacity 1s ease;
  &.active {
    display: block;
    opacity: 1;
    height: auto;
  }
  header {
    display: none;
  }
}
/* VIEWCART PAGE VIEWCART PANEL */
.checkout-panel--viewcart,
.panel--viewcart {
  // TODO: legacy selector, should be removed perhaps
  /* VIEWCART BUTTONS PANEL */
  padding: 0;
  &__buttons.panel {
    border-top-width: 0;
    @include breakpoint($medium-down) {
      border-width: 0;
    }
    .continue-shopping {
      display: none;
    }
    .go-shopping {
      display: none;
    }
    a {
      display: inline-block;
    }
    .continue-buttons {
      float: right;
    }
    .edit-cart {
      float: left;
    }
    .choose-samples-checkout {
      display: none;
    }
    .choose-samples-and {
      display: none;
    }
  }
  &__title.edit {
    float: none;
    margin: 0;
    text-transform: none;
  }
  /* VIEWCART CART ITEMS */
  .go-shopping {
    margin: 20px 0;
    padding: 0 0.5em;
    @include breakpoint($medium-up) {
      padding-left: 1%;
    }
    &--btn {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 0;
      }
    }
  }
  .cart-items {
    &__header {
      display: none;
      @include breakpoint($landscape-up) {
        display: block;
      }
      &--item {
        padding: 0.8em 0;
        float: left;
        box-sizing: border-box;
        text-transform: uppercase;
        font-weight: bold;
        &__product-subname {
          display: none;
        }
      }
      &--thumb {
        display: none;
      }
      &--products {
        width: 41%;
        padding-left: 1.5em;
        @include breakpoint($medium-landscape-only) {
          width: 37%;
        }
      }
      &--price {
        width: 14%;
        text-align: left;
        padding-left: 0;
        @include breakpoint($medium-landscape-only) {
          width: 12%;
        }
      }
      &--qty {
        width: 13%;
        text-align: left;
        padding-left: 0;
        @include breakpoint($medium-landscape-only) {
          width: 15%;
        }
      }
      &--frequency {
        width: 21%;
        @include breakpoint($medium-landscape-only) {
          width: 24%;
        }
      }
      &--total {
        width: 10%;
        float: right;
        text-align: center;
        padding-left: 0;
      }
    }
    &__item {
      padding: 15px 0;
      position: relative;
      margin: 0;
      box-sizing: border-box;
      border-top: 1px solid $color-lightest-grey;
      &:first-child {
        border-top: 1px solid $color-lightest-grey;
      }
      @include breakpoint($landscape-up) {
        padding: 28px 0;
      }
      &--group-replenish {
        width: 72%;
        float: left;
        @include breakpoint($landscape-up) {
          width: 21%;
        }
        @include breakpoint($medium-landscape-only) {
          width: 26%;
        }
      }
      &--group-desc-remove {
        width: 100%;
        float: left;
        margin-bottom: 10px;
        position: relative;
        @include breakpoint($landscape-up) {
          width: 25%;
          margin-bottom: 0;
          padding-right: 1%;
        }
        @include breakpoint($medium-landscape-only) {
          width: 21%;
        }
      }
      &--color {
        width: 25%;
      }
      &--thumb {
        width: 22%;
        padding: 0;
        float: left;
        margin-right: 2%;
        @include breakpoint($small-down) {
          height: 130px;
        }
        @include breakpoint($landscape-up) {
          width: 15%;
          margin-right: 1%;
        }
        .final-sale {
          color: $color-alert-msg;
          text-transform: uppercase;
        }
      }
      &--info {
        @include breakpoint($landscape-down) {
          width: 75%;
          float: right;
        }
      }
      &--desc {
        margin-bottom: 10px;
        margin-right: 20px;
        @include breakpoint($landscape-up) {
          margin-right: 0;
        }
        .product_subname {
          display: none;
        }
        .cart--item {
          &__product-name {
            a {
              text-decoration: none;
            }
          }
        }
      }
      &--remove {
        .remove_link {
          float: left;
          position: absolute;
          top: 0;
          right: 0;
          text-decoration: none;
          @include breakpoint($landscape-up) {
            position: relative;
          }
        }
        .mobile_hidden,
        .mobile-hidden {
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
      &--price {
        width: 45%;
        text-align: left;
        float: left;
        &__label {
          display: none;
        }
        @include breakpoint($landscape-up) {
          width: 14%;
        }
        @include breakpoint($medium-landscape-only) {
          width: 12%;
        }
      }
      &--qty {
        width: 27%;
        text-align: left;
        float: left;
        margin-bottom: 10px;
        margin-top: 8px;
        @include breakpoint($landscape-up) {
          width: 13%;
          margin: 0;
        }
        @include breakpoint($medium-landscape-only) {
          width: 15%;
        }
        &__label {
          display: block;
          float: left;
          padding-left: 10px;
        }
        & .donation {
          margin-top: 0;
        }
        .selectBox-dropdown {
          min-width: 70px;
        }
        select {
          width: auto;
        }
        &-sample,
        &-donation {
          padding-left: 15px;
        }
      }
      &--total {
        width: 45%;
        float: right;
        text-align: right;
        font-weight: bold;
        @include breakpoint($landscape-up) {
          width: 10%;
          text-align: center;
        }
      }
      &--replenishment,
      &--short-desc {
        width: 76%;
        text-align: left;
        clear: both;
        margin-left: 24%;
        margin-bottom: 20px;
        @include breakpoint($tablet-only) {
          margin-left: 16%;
          margin-right: 20px;
        }
        @include breakpoint($landscape-up) {
          width: 21%;
          margin-left: 0;
          float: right;
          margin-bottom: 0;
          clear: none;
          margin-right: 0;
        }
        select {
          width: 100%;
          @include breakpoint($large-up) {
            width: auto;
          }
        }
        &__notice {
          height: auto;
          overflow: hidden;
          color: $color-error;
        }
      }
      &--replenishment {
        width: 100%;
        float: left;
        margin-top: 8px;
        margin-bottom: 0;
        clear: none;
        margin-left: 0;
        @include breakpoint($landscape-up) {
          margin-top: 0;
        }
        a.selectBox {
          width: 100%;
          @include breakpoint($landscape-up) {
            width: auto;
          }
          @include breakpoint($medium-landscape-only) {
            width: 100%;
          }
        }
      }
      &--swatch {
        float: left;
        width: 16px;
        height: 16px;
        background-color: $color-black;
      }
      &--error {
        clear: both;
        position: relative;
        p {
          left: 11.65em;
          position: absolute;
          top: -2.25em;
        }
        &--hazmat {
          width: 79%;
          clear: right;
          margin-left: 21%;
        }
      }
      &--gc-message {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: bottom;
        white-space: nowrap;
        width: 12.75em;
      }
      .remove_button {
        margin-top: 1em;
      }
      .sub_line {
        display: none;
      }
      .pre-order-message-display {
        width: 100%;
        float: right;
        @include breakpoint($landscape-up) {
          width: 84%;
        }
      }
    }
  }
}
/* VIEWCART SIDEBAR TABS */

.viewcart {
  .panel--viewcart {
    @include breakpoint($medium-up) {
      border-bottom-width: 0;
    }
    margin-bottom: 0;
    display: block;
    .panel--viewcart__buttons {
      border-width: 0;
      margin: 0;
    }
    &__header {
      background-color: $color-green;
      color: $color-white;
    }
    &__title {
      color: $color-white;
    }
    .content {
      display: block;
      padding-bottom: 0;
    }
  }
  .tab-bar {
    margin-left: 0.5em;
    li {
      margin-right: 0.5em;
      float: left;
      a {
        border-width: 0;
        color: $color-black;
        background-color: $color-gray;
        display: block;
        padding: 0.5em;
        text-decoration: none;
        font-weight: bold;
        &.active {
          border-width: 0;
          color: $color-white;
          background-color: $color-green;
          font-weight: bold;
        }
      }
    }
  }
  .tab-content {
    clear: both;
  }
  .grey_background {
    background-color: $color-very-light-gray;
  }
  .checkout-header__signin {
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
    padding: 15px 0 15px 0;
    &--content {
      letter-spacing: 2px;
      &--txt {
        font-size: 12px;
      }
      &--link {
        &.signin-hdr-link {
          float: right;
          font-size: 12px;
          text-transform: uppercase;
        }
      }
    }
    &--hdr {
      font-weight: bold;
      color: $black;
      text-decoration: none;
      &--arrow {
        float: right;
        font-weight: bold;
      }
    }
  }
}
/* ONE PAGE CHECKOUT */
body#index {
  .panel--viewcart {
    border-bottom-width: 0;
    margin-bottom: 0;
    display: block;
    #checkout-header {
      background-color: $color-white;
      margin-top: 1em;
      h2 {
        display: none;
      }
    }
    .panel--viewcart__buttons {
      display: none;
    }
    .content {
      display: block;
      padding-bottom: 0;
    }
    .cart {
      &--header {
        display: none;
      }
      &--item {
        &.shaded {
          .responsive-shade-shim {
            height: 2.4em;
            clear: both;
          }
        }
        &__price {
          width: 100%;
          &__label {
            display: inline-block;
          }
        }
        &__qty {
          width: 22%;
          margin-top: 0;
          &__label {
            display: inline-block;
          }
        }
        &__total {
          text-align: right;
          width: 23%;
          float: right;
        }
        &__thumb {
          width: 20%;
        }
        &__desc {
          width: 35%;
        }
        &__error {
          p {
            position: static;
          }
        }
        &__color {
          position: absolute;
          bottom: 1em;
          left: 0;
          width: 100%;
        }
        &__swatch {
          position: absolute;
          top: 0;
          left: 0;
        }
        &__shade {
          position: absolute;
          top: 0;
          left: 20px;
        }
      }
    }
  }
}

@media (max-width: 964px) {
  //.body-container { width: 768px; }
  .panel--viewcart {
    .cart {
      &--header {
        &__products {
          width: 62%;
        }
        &__total {
          width: 15%;
          float: right;
        }
      }
      &--item {
        &__thumb {
          width: 17%;
          padding: 0;
        }
        &__total {
          width: 15%;
          float: right;
        }
      }
    }
  }
}

@include breakpoint($medium-down) {
  body#viewcart header.viewcart-header {
    background-color: $color-green;
    color: $color-white;
    font-weight: bold;
    text-align: center;
    padding: 0.8em 0;
  }
  .panel--viewcart {
    &__item-count {
      font-size: 1.1em;
      padding: 0;
      margin-left: 1em;
    }
    .panel--viewcart {
      /* &__title, &__item-count {
        display: none;
      } */
      &__buttons {
        .paypal-checkout {
          display: inline-block;
        }
        span.or {
          display: inline-block;
        }
      }
    }
    &__buttons {
      .paypal-checkout {
        display: none;
      }
      span.or {
        display: none;
      }
      .choose-samples-and {
        text-align: center;
      }
    }
  }
}

@media (max-width: 530px) {
  .panel--viewcart__buttons {
    .or,
    #viewcart-panel .paypal-checkout {
      display: inline-block;
    }
  }
  .panel--viewcart {
    .cart {
      &-header {
        display: none;
      }
      &--item {
        border-bottom: 1px dashed $color-gray;
        margin-bottom: 1em;
        padding-bottom: 1em;
        &:last-child {
          border-bottom-width: 0;
        }
        &__qty {
          text-transform: uppercase;
          width: 35%;
          .sample & {
            width: 20%;
            margin-top: 0;
          }
          &__label {
            display: inline-block;
          }
          form {
            display: inline-block;
          }
          select {
            display: inline-block;
            margin-top: -0.2em;
            font-size: 0.9em;
            padding: 0;
          }
          body#index & {
            margin-top: 0;
          }
        }
        .responsive-shade-shim {
          height: 1.5em;
          clear: both;
        }
        &__desc,
        body#index &_desc {
          width: 75%;
          /* .shaded & {
            width: 75%;
          } */
        }
        &__color {
          position: absolute;
          bottom: 1em;
          left: 0;
          .shaded & {
            width: 100%;
          }
        }
        &__swatch {
          position: absolute;
          top: 0;
          left: 0;
        }
        &__shade {
          position: absolute;
          top: 0;
          left: 20px;
          &__label {
            display: none;
          }
        }
        .error p {
          position: static;
        }
        &__thumb,
        body#index &_thumb {
          width: 25%;
          padding: 0;
        }
        &__price,
        body#index &_price {
          width: 40%;
          display: block;
          text-align: left;
          .sample & {
            width: 40%;
          }
          &__label {
            display: inline;
          }
        }
        &__total,
        body#index &_total {
          width: 15%;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .panel--viewcart {
    .cart {
      &--item {
        &__thumb {
          width: 20%;
        }
        &__color {
          width: 100%;
        }
        &__desc {
          width: 80%;
          padding-right: 60px; /* padding added to account for remove button -- may need to be adjusted for brand styles */
        }
        &__price {
          width: 20%;
          padding-left: 0;
        }
        &__qty {
          width: 40%;
          padding-right: 0;
        }
        &__total {
          width: 20%;
        }
      }
    }
  }
}

.checkout-single-page {
  .cart-items {
    &__item {
      &--total {
        width: 16%;
        margin-top: 0;
      }
    }
  }
}

.checkout-panel {
  &--bottom-viewcart-buttons {
    padding: 0;
    margin: 0;
    display: none;
    @include breakpoint($medium-up) {
      padding-bottom: 40px;
      margin-bottom: 20px;
      display: block;
    }
  }
  &--favorites,
  &--offer-code,
  &--links,
  &--payment,
  &--promo-bca {
    .checkout-panel__heading {
      display: block;
      cursor: pointer;
      .toggle-icon {
        float: right;
        margin-top: 5px;
      }
      .icon--plus.icon--minus {
        @include icon('minus');
        margin-top: 0px;
      }
    }
    .invalid_marker,
    .valid_marker {
      display: none;
    }
  }
  &--payment {
    .checkout-panel__heading {
      display: inline-block;
    }
    .payment-giftcard {
      .checkout-panel__heading {
        display: block;
      }
    }
  }
  &--viewcart {
    .icon--plus {
      margin-top: 5px;
    }
    .icon--plus.icon--minus {
      @include icon('minus');
      margin-top: 0px;
    }
    .messages {
      #error_cart\.empty {
        color: $color-black;
      }
    }
  }
  &--promo {
    .messages {
      margin-top: 15px;
    }
  }
  &__expand {
    padding-top: 8px;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
  }
  .giftwrap-panel {
    &__image {
      @include swap_direction(margin, 0 1em 1em 0);
      max-width: 90px;
      height: 80px;
      float: #{$ldirection};
      width: 100%;
      @include breakpoint($medium-up) {
        @include swap_direction(margin, 1em 2em 1em 1em);
      }
    }
    &__form {
      width: 100%;
      float: none;
      line-height: 1;
      margin-top: 1em;
      @include breakpoint($medium-up) {
        float: #{$ldirection};
        width: 70%;
      }
    }
    &__label {
      margin-bottom: 10px;
      display: inline-block;
      position: relative;
      top: 14px;
      line-height: 1;
      padding-#{$ldirection}: 50px;
      &::before {
        content: '';
        #{$ldirection}: 1px;
        background-color: $color-light-grey;
        border-radius: 7px;
        height: 14px;
        top: 5px;
        width: 34px;
        position: absolute;
      }
      &::after {
        content: '';
        border: 1px solid $color-lighter-grey;
        box-shadow: none;
        top: 0;
        #{$ldirection}: 0;
        background-color: $color-white;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        position: absolute;
        transition: all 0.3s ease;
      }
      .text-yes {
        display: none;
      }
    }
    &__checkbox {
      display: none;
      &:checked {
        & ~ label {
          &::before {
            background-color: $color-dark-green;
            content: '';
          }
          &::after {
            #{$ldirection}: 17px;
            box-shadow: 0 1px 5px $color-lighter-gray;
          }
          .text-yes {
            display: block;
          }
          .text-no {
            display: none;
          }
        }
        .gift-message__container {
          display: block;
        }
      }
    }
    &__disclaimer-text {
      color: $color-medium-gray;
      margin-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 1em;
      }
    }
    &__question {
      line-height: 1.5;
    }
  }
  &.giftwrap-panel {
    border-top: none;
    border-bottom: 1px solid $color-light-gray;
    .gift-message {
      &__container {
        .card-message {
          overflow: hidden;
          margin-bottom: 20px;
          .chars-remaining {
            font-size: 12px;
            line-height: 2;
          }
        }
        .giftwrap-message-field {
          width: 100%;
        }
        .button {
          width: 100%;
          @include breakpoint($medium-up) {
            width: auto;
          }
        }
      }
      &__text {
        line-height: 1.5;
      }
    }
    .gift-wrap {
      &__success {
        float: #{$ldirection};
        line-height: 1.5;
        @include breakpoint($medium-up) {
          margin: 20px 0;
          float: none;
        }
      }
      &__success--container {
        display: flex;
        margin-bottom: 20px;
      }
      &__success--text {
        margin-#{$ldirection}: 20px;
      }
      &__success--divider {
        background-color: $color-lightest-grey;
        height: 20px;
        margin: 2px 8px 0;
        width: 1px;
      }
      &__success--edit {
        text-decoration: underline;
        border-bottom: none;
      }
    }
  }
}

.checkout {
  &__sidebar {
    .cart-items {
      &__item--group-desc-remove {
        width: 49%;
        @include breakpoint($medium-up) {
          width: 59%;
        }
        .desc {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
      &__item--thumb {
        @include breakpoint($medium-up) {
          width: 15%;
          margin-right: 1%;
        }
      }
      &__item--total {
        width: 25%;
        text-align: right;
        display: block;
      }
      &__item--group-replenish {
        width: 76%;
        float: right;
        margin: 0;
        @include breakpoint($medium-up) {
          width: 84%;
        }
      }
      &__item--qty {
        @include breakpoint($medium-up) {
          display: block;
          width: auto;
          float: none;
        }
        &__label {
          padding: 0;
          @include breakpoint($medium-up) {
            clear: left;
            display: inline;
            float: none;
          }
        }
      }
    }
    .checkout-panel {
      &--viewcart {
        .cart-items {
          &__item {
            &--replenishment,
            &--short-desc {
              width: 100%;
              margin-top: 10px;
            }
            &--total {
              bottom: auto;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
}

.sticky-checkout-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  padding: 15px;
  background: $color-white;
  box-shadow: 0 -3px 6px $color-lightest-grey;
  .btn {
    width: 100%;
    height: 45px;
    text-align: center;
    font-size: 15px;
    padding-top: 13px;
  }
  .a {
    height: 50px;
    line-height: 50px;
    vertical-align: middle;
    margin: 10px 0;
    padding: 0;
    border: 0;
  }
  .apple-pay-button-container {
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0;
    height: 47px;
    border: 1px solid $color-black;
    .checkout-text-applepay {
      position: relative;
      left: 15px;
      font-weight: bold;
      color: black;
      top: 10px;
    }
    .apple-pay-button-logo {
      width: auto;
      height: 45px;
      margin-top: 0;
      padding: 0;
      border: 0;
    }
  }
  .or {
    display: none;
  }
  .choose-samples-and {
    display: none;
  }
  .go-shopping {
    display: none;
    .hide-go-shopping {
      display: block;
    }
  }
  .paypal-checkout-button {
    background: none;
    border: 1px solid $color-gray;
    line-height: 45px;
    img {
      vertical-align: middle;
    }
  }
  .paypal-checkout {
    width: 100%;
    height: 47px;
    text-align: center;
    display: inline-block;
    padding: 5px;
    background: $color-white;
    border: 1px solid $color-black;
    text-decoration: none;
    line-height: 2;
    img {
      vertical-align: middle;
      height: 30px;
    }
    .checkout-text-paypal {
      font-size: 15px;
      font-weight: bold;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .gwp-afterpay-checkout {
    img {
      height: 20px;
    }
  }
  .continue-checkout {
    width: 100%;
    height: 47px;
    font-size: 15px;
    margin-bottom: 10px;
  }
  .checkout-continue-btn .hide-checkout {
    display: none;
  }
}

.overlay-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-black;
  opacity: 0.5;
  z-index: 1001;
}

.continue-buttons {
  text-align: center;
  width: 100%;
  .btn {
    width: 228px;
    height: 60px;
    line-height: 60px;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.viewcart-shortcuts-panel {
  padding: 15px 0;
  border-bottom: 1px solid $color-light-gray;
  .shortcuts-content__list__offer {
    float: right;
    min-height: 17px;
  }
  .shortcuts-content__list__samples {
    text-align: right;
    float: left;
    min-height: 17px;
  }
  .shortcuts-content__list--link {
    text-transform: uppercase;
    font-weight: bold;
  }
  .shortcuts-content {
    padding-top: 15px;
  }
}

.account-login-button {
  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;
  margin: 10px 0 0;
  .arrow {
    float: right;
    opacity: 0.5;
    display: none;
  }
}

.checkout-login {
  background: $color-light-gray;
  &__content {
    font-size: 15px;
    padding: 10px 24px;
  }
  &__signout {
    text-decoration: underline;
    float: right;
    text-transform: uppercase;
    font-size: 14px;
  }
  &__signin {
    font-size: 18px;
    margin: 0;
    padding: 0;
    display: block;
    border: 0;
    padding: 10px 24px;
    background-position: 92% -503px;
  }
}

.viewcart-samples-overlay {
  @include viewcart_overlay();
  left: 0;
  ul.product-list {
    .product {
      .details {
        float: none;
        width: 100%;
        .button {
          width: 65%;
        }
      }
    }
  }
  .header-items {
    border-bottom: 1px solid $color-gray;
  }
  .samples-panel {
    padding: 20px;
  }
}

.signin-overlay {
  display: block;
  @include viewcart_overlay();
  margin: 0;
  .sign-in-panel {
    overflow: hidden;
    margin-bottom: 0;
  }
  .header-items {
    border-bottom: 1px solid $color-gray;
  }
}

.gwp-overlay {
  @include viewcart_overlay();
  margin: 0;
  padding: 0;
  .header-items {
    border-bottom: 1px solid $color-gray;
  }
  .samples-panel {
    padding: 20px;
  }
}

.checkout-page-title {
  text-align: center;
  font-size: 20px;
  line-height: 36px;
  text-transform: uppercase;
  padding: 10px;
}

.viewcart-samples-overlay__link,
.mobile-overlay-close {
  font-size: 20px;
  padding: 10px;
  display: inline-block;
  position: relative;
  float: left;
}

.mobile-overlay-logo {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  width: 90%;
  text-align: center;
  a {
    text-decoration: none;
  }
}

.focus_pointer {
  height: 100%;
  overflow: auto;
  body {
    width: 100%;
    overflow: auto;
    .viewcart {
      position: relative;
      margin-top: 0;
    }
  }
}

.auto_replenishment {
  display: none;
}

html.prevent_scroll {
  -webkit-overflow-scrolling: auto;
}

// Alipay
@if $alipay-enabled {
  .sticky-checkout-button {
    .apple-pay-button-container {
      .checkout-text-applepay {
        @include breakpoint($medium-down) {
          position: inherit;
          text-align: right;
          display: inline-block;
          vertical-align: top;
          padding-top: 12px;
        }
      }
    }
    .paypal-checkout {
      margin-bottom: 10px;
    }
  }
}

// Custom Kit
.bundle_kit_item {
  .cart-items {
    &__item--info {
      .cart--item__product-name {
        font-weight: bold;
      }
      .cart-items__item--remove {
        .remove_link.kit {
          display: none;
        }
      }
      &.product--info {
        .cart-items__item--group-desc-remove {
          margin-bottom: 0;
        }
      }
    }
    &__item--thumb {
      .checkout-panel--viewcart & {
        height: auto;
      }
      .cart--item__thumb-image {
        margin-bottom: -32px;
        @include breakpoint($medium-portrait-only) {
          margin-bottom: -62px;
        }
      }
    }
    &__item--qty {
      display: none;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
    }
    &__item--sub {
      @include breakpoint($landscape-up) {
        margin-#{$ldirection}: 16%;
      }
      .cart-items__item--group-desc-remove {
        margin: 0;
        width: 100%;
        .cart-items__item--desc {
          @include swap_direction(margin, 0 0 0 24%);
          padding: 15px 0;
          border-bottom: 1px solid $color-lightest-grey;
          @include breakpoint($landscape-up) {
            width: 30%;
            margin: 0;
          }
          .checkout__sidebar & {
            width: 100%;
          }
        }
        .cart-items__item--remove {
          display: none;
        }
        &.priceBundle {
          margin-#{$ldirection}: 24%;
          width: 76%;
          padding-top: 10px;
          @include breakpoint($landscape-up) {
            display: none;
          }
        }
      }
      .cart-items__kit-remove {
        @include breakpoint($landscape-up) {
          padding-top: 10px;
          float: $ldirection;
        }
        .remove-icon {
          position: absolute;
          #{$rdirection}: 0;
          border-bottom: none;
          font-size: 15px;
        }
      }
      .cart-items__kit-edit {
        margin-#{$ldirection}: 24%;
        margin-top: 10px;
        float: $ldirection;
        @include breakpoint($landscape-up) {
          margin-#{$ldirection}: 20px;
        }
      }
    }
  }
}

.custom-kit-page {
  #lamer_sticky_pc {
    display: none;
  }
}

//Hide Engraving
.engraving-button-container {
  display: none;
}

.active-panel-cart {
  section {
    .checkout-panel {
      &--viewcart {
        @include breakpoint($landscape-up) {
          min-height: 400px;
        }
      }
    }
  }
}

@if $enable-restrict-ingredient-error == true {
  .restricted-ingredient {
    color: $color-dark-maroon;
    background: url('#{$base-theme-path}/img/icons/src/red-triangle.svg') no-repeat;
    background-size: 20px;
    margin-top: 40px;
  }
  .restricted-ingredient-error {
    font-size: 14px;
    margin-#{$ldirection}: 25px;
    width: 190px;
  }
}
