.viewcart {
  .apple-pay-button-container {
    .apple-pay-button {
      -apple-pay-button-type: check-out;
    }
  }
  .giftwrap-content {
    .giftwrap-panel {
      &__form {
        @include breakpoint($medium-up) {
          width: 80%;
        }
      }
      &__checkbox-container {
        float: $ldirection;
        margin-bottom: 10px;
        width: 65%;
        @include breakpoint($medium-up) {
          float: none;
          width: 100%;
        }
        .label-content {
          line-height: 1.5;
        }
      }
    }
  }
}

.viewcart-buttons-panel {
  .apple-pay-button-container {
    height: 40px;
    .apple-pay-button {
      border-radius: 10px;
    }
  }
}
.sticky-checkout-button {
  .apple-pay-button-container {
    border: 0;
    .apple-pay-button {
      border-radius: 0;
      width: 100%;
    }
  }
}
