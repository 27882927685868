#cookie_popover_container {
  bottom: 0;
  right: 0;
  border: 10px solid $color-dark-gray;
  background-color: $color-white;
  color: $color-dark-gray;
  font-size: 13px;
  height: auto;
  padding: 25px 30px 25px 22px;
  position: fixed;
  right: 0;
  width: 100%;
  word-wrap: break-word;
  z-index: 100;
  @include breakpoint($medium-up) {
    width: 390px;
  }
  .cookie_legal-language {
    color: $color-dark-gray;
    .cookie_legal-close_box {
      position: absolute;
      top: 5px;
      right: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
