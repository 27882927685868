$sticky-height: 100px;
$sticky-padding: 25px;

.sticky-mobile-hidden {
  display: none;
  @include breakpoint($sticky-breakpoint) {
    display: block;
  }
}

.sticky-pc-hidden {
  @include breakpoint($sticky-breakpoint) {
    display: none;
  }
  .product-add-to-favorites {
    display: none;
    .link {
      @include button--secondary;
      width: 90%;
      margin-bottom: 10px;
    }
  }
}

.sticky-add-to-bag {
  @include clearfix;
  background: transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  text-align: center;
  z-index: 100;
  width: 100%;
  background: $color-white;
  @include breakpoint($landscape-up) {
    bottom: auto;
    left: auto;
    position: relative;
    text-align: left;
    z-index: auto;
    box-shadow: 0 -1px 5px $color-lighter-gray;
  }
  .product-full--black & {
    color: $color-black;
    a {
      color: $color-black;
    }
    .product-add-to-cart {
      .button {
        color: $color-white;
      }
    }
  }
  &--inner {
    //margin: 0 auto;
    //max-width: $max-width;
    //padding: 10px $sticky-padding 13px $sticky-padding;
    @include breakpoint($sticky-breakpoint) {
      min-height: $sticky-height;
      padding: 0 0 $sticky-padding 0;
    }
    .button {
      width: 90%;
      border: 1px solid $color-white;
      // border-bottom: 1px solid $color-black;
      border-radius: 6px;
      margin-bottom: 10px;
      @include breakpoint($sticky-breakpoint) {
        border: 1px solid $color-black;
        width: auto;
        border-radius: 4px;
        &.button--disabled {
          border-color: $color-lightest-grey;
        }
      }
    }
    .preorder-button-disabled {
      border: 1px solid $color-gray;
    }
  }
  &__container {
    @include breakpoint($sticky-breakpoint) {
      @include clearfix;
      text-align: center;
    }
  }
  &__live-chat {
    @include breakpoint($sticky-breakpoint) {
      position: relative;
      float: left;
      margin-top: 3px;
      margin-right: 20px;
      &__disc {
        position: absolute;
        top: 10px;
        left: 20px;
        color: $color-salmon;
        font-size: 45px;
        height: 13px;
        line-height: 13px;
      }
    }
  }
  &__description__container {
    @include clearfix;
    float: left;
    width: 48%;
    padding-top: 20px;
    @include breakpoint($sticky-breakpoint) {
      width: 40%;
    }
    @include breakpoint($extra-large-up) {
      width: 39%;
    }
    @include breakpoint($extra-extra-large-up) {
      width: 44%;
    }
  }
  &__description {
    @include breakpoint($sticky-breakpoint) {
      float: left;
      text-align: left;
      margin: 0;
      width: 64%;
    }
    @include breakpoint($extra-large-up) {
      width: 95%;
      margin-right: 10px;
    }
    .product-full__name {
      float: none;
      padding-top: 0;
      width: auto;
      margin: 0;
      font-size: 21px;
      line-height: 25px;
    }
    .product-full__price {
      .product-sku-price {
        //font-size: 14px;
      }
    }
  }
  &__ndd_timer {
    @include breakpoint($sticky-breakpoint) {
      float: #{$rdirection};
      clear: #{$rdirection};
    }
    .product-ndd-countdown {
      display: none;
      @include swap_direction(padding, 10px);
      .product-full--black & {
        color: $color-white;
      }
      p {
        display: inline;
      }
      br {
        @include breakpoint($sticky-breakpoint) {
          display: none;
        }
      }
      .hours,
      .minutes {
        font-weight: bold;
      }
    }
  }
  &__section {
    @include breakpoint($sticky-breakpoint) {
      float: right;
      text-align: left;
      height: $sticky-height - $sticky-padding;
      padding-top: 20px;
      .product-add-to-favorites {
        float: left;
        margin: 0 5px 0 0;
        max-width: 162px;
        text-align: center;
        padding-left: 0;
      }
      .sku-menu__container {
        float: left;
      }
      .product-add-to-cart,
      .product-add-to-waitlist {
        float: left;
        margin: 0 0 0 10px;
      }
      .product-inventory-status {
        &__item {
          text-align: right;
          padding: 5px 12px 0 0;
          clear: both;
        }
      }
      .pre-order-container {
        @include breakpoint($landscape-up) {
          .pre-order {
            &_active_msg,
            &_sold_msg,
            &_tos_msg {
              margin-right: 5px;
              padding-right: 0px;
            }
          }
          .preorder_details_link,
          .preorder_message_shipment_info {
            display: none;
          }
        }
      }
    }
    .product-add-to-favorites {
      @include breakpoint($extra-large-up) {
        text-align: left;
        margin: 7px 22px 0 0;
        max-width: 100%;
      }
    }
  }
  &__sku-select-menu {
    display: inline;
  }
  .sticky-add-to-bag__image-single {
    display: block;
    float: left;
    img {
      height: $sticky-height;
    }
  }
  &.sticky {
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    .sticky-add-to-bag--inner {
      @include breakpoint($sticky-breakpoint) {
        padding: 0 20px 5px 20px;
      }
    }
  }
  &:not(.sticky-add-to-bag-v2) {
    @if not $cr22 {
      .sticky-add-to-bag__section.multi-size {
        @include breakpoint($extra-extra-large-up) {
          width: 100%;
          max-width: 36.9%;
        }
      }
    }
  }
}
