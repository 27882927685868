%content-block--split-width--default {
  margin: 0 auto;
  text-align: center;
  .content-block-large__picture {
    @include breakpoint($medium-up) {
      margin-top: -25px;
    }
  }
  .default-alignment {
    justify-content: center;
    align-items: center;
    @include breakpoint($medium-up) {
      margin-top: 75px;
    }
  }
  .content-block-large__content-text {
    @include breakpoint($medium-up) {
      padding-top: 30px;
    }
  }
  .content-block-large__content-text__eyebrow {
    @include text--2;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      margin-bottom: 20px;
    }
  }
  .content-block-large__content-text__header {
    @include heading--2;
  }
  .content-block-large__content-text__subheader {
    @include heading--4;
    margin-top: 10px;
  }
  .content-block-large__content-text__content {
    @include text--1;
    margin-top: 10px;
  }
  .product-brief__header {
    padding: 0 5%;
  }
}

.content-block--split-width--default {
  @extend %content-block--split-width--default;
}

.content-block--split-width--kh-lotion {
  @extend %content-block--split-width--default;
  .content-block-large__picture {
    flex-basis: 490px;
  }
}

.content-block--split-width--60-40-image-text {
  @extend %content-block--split-width--default;
  .content-block--split-width .content-block-large__picture {
    @include breakpoint($medium-up) {
      max-width: 100%;
      flex-basis: 60%;
    }
  }
  .content-block--split-width .content-block-large__content-text {
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .content-block--split-width .content-block-large__content {
    padding: 0;
    @include breakpoint($medium-up) {
      flex-basis: 40%;
    }
  }
}

.content-block--split-width {
  @extend %content-block--split-width--default;
  @include breakpoint($medium-up) {
    margin: 60px auto;
    .content-block-formatter &,
    .content-block-collapsible--active &,
    .basic-carousel & {
      margin: 20px auto;
    }
    .tabbed-block__content & {
      margin-top: 0;
    }
  }
  &.video-active {
    .content-block-large__picture {
      display: none;
    }
    .content-block-large__content {
      flex-basis: 100%;
      margin: 0 auto;
    }
    .content-block-large__content-video {
      width: 100%;
    }
    .content-block-large__content-text {
      display: none;
    }
  }
  &.order-text-image {
    .content-block-large__picture {
      @include breakpoint($medium-up) {
        order: 1;
      }
    }
    .content-block-large__image-animated {
      right: 0;
      left: auto;
    }
    .content-block-large__product {
      .product-grid-wrapper {
        @include breakpoint($medium-up) {
          float: right;
          transform: translateX(25%);
        }
        @include breakpoint($landscape-up) {
          transform: translateX(50%);
        }
      }
      .content-block-large__product-smoosh {
        @include breakpoint($medium-up) {
          float: right;
          margin-bottom: 20px;
          transform: translateX(25%);
        }
        @include breakpoint($landscape-up) {
          transform: translateX(50%);
        }
      }
    }
  }
  .content-block-large__inner {
    @include breakpoint($medium-up) {
      padding: 18px 0;
      display: flex;
      text-align: center;
      width: 100%;
      justify-content: space-evenly;
    }
    .spp_reviews {
      display: flex;
      flex-direction: column;
    }
    .disable_center_review {
      .pr-review-snapshot-block-container {
        display: block;
        .pr-review-snapshot-block-pros {
          display: none;
        }
        .pr-review-snapshot-block-histogram {
          float: left;
        }
        .pr-review-snapshot-block-cons {
          float: right;
        }
      }
    }
  }
  .content-block-large__picture,
  .content-block-large__content {
    @include breakpoint($medium-up) {
      flex: 0 0 50%;
    }
  }
  .content-block-large__video {
    video {
      width: 100%;
      position: relative;
      top: 0;
      #{$ldirection}: 0;
      display: none;
      @include breakpoint($landscape-up) {
        @include centerer(false, true);
      }
    }
    &.has-video {
      video {
        display: block;
      }
    }
    .content-block-large__image-animated {
      display: none;
    }
  }
  .content-block-large__picture {
    position: relative;
    display: block;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 15px;
    @include breakpoint($medium-up) {
      max-width: 75%;
      padding-bottom: 0;
    }
    @include breakpoint($landscape-up) {
      max-width: 100%;
      width: auto;
    }
    video {
      display: none;
    }
    .content-block-large__image-animated {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      @include breakpoint($medium-up) {
        &.width-95 {
          max-width: 95%;
        }
        &.width-90 {
          max-width: 90%;
        }
        &.width-85 {
          max-width: 85%;
        }
        &.width-80 {
          max-width: 80%;
        }
        &.width-75 {
          max-width: 75%;
        }
        &.width-70 {
          max-width: 70%;
        }
        &.width-65 {
          max-width: 65%;
        }
        &.width-60 {
          max-width: 60%;
        }
        &.width-55 {
          max-width: 55%;
        }
        &.width-50 {
          max-width: 50%;
        }
      }
    }
    .content-block-large__image-animated--1 {
      z-index: 5;
      display: block;
    }
    .content-block-large__image-animated--2 {
      z-index: 4;
    }
    .content-block-large__image-animated--3 {
      z-index: 3;
    }
    .content-block-large__image-animated--4 {
      z-index: 2;
    }
    .content-block-large__image-animated--5 {
      position: relative;
      z-index: 1;
      text-align: center;
    }
  }
  .content-block-large__content-thumbnail {
    margin: 10px auto;
  }
  .content-block-large__content {
    @include breakpoint($medium-up) {
      position: static;
      padding: 0;
    }
    @include breakpoint($landscape-up) {
      padding: 0;
    }
  }
  .content-block-large__content-video {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      width: 90px;
      margin: 0 auto;
      text-align: right;
      .icon {
        font-size: 14px;
        &--close {
          color: $black;
        }
      }
    }
  }

  // Horizontal Alignment
  .horizontal-align-left {
    text-align: left;
  }
  .horizontal-align-center {
    text-align: center;
  }
  .horizontal-align-right {
    text-align: right;
  }

  // Vertical Alignment
  .justify-start {
    align-self: flex-start;
  }
  .justify-center {
    align-self: center;
  }
  .justify-end {
    align-self: flex-end;
  }
  .content-block-large__product {
    align-self: flex-start;
    width: 100%;
    .product-grid-wrapper {
      max-width: 190px;
      position: relative;
      z-index: 15;
      margin: auto;
      @include breakpoint($medium-up) {
        float: left;
        transform: translateX(-25%);
      }
      @include breakpoint($landscape-up) {
        transform: translateX(-50%);
      }
    }
    .content-block-large__product-smoosh {
      position: relative;
      z-index: 15;
      margin-top: 20px;
      max-width: 190px;
      @include breakpoint($medium-up) {
        float: left;
        margin: auto;
        transform: translateX(-25%);
      }
      @include breakpoint($landscape-up) {
        transform: translateX(-50%);
      }
    }
    .product-grid-wrapper + .content-block-large__product-smoosh {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
        margin: auto;
        float: right;
        transform: none;
      }
    }
    .product-grid__item {
      float: none;
      margin: 0 auto;
    }
  }
}

.content-block--split-width--products {
  .content-block-large__content {
    .product-grid--add-all-bag .product-grid__content {
      padding-bottom: 60px;
    }
    .product-grid--product .product-brief__image {
      padding: 0;
      margin-bottom: 0;
    }
    .content-block-large__products {
      width: 100%;
    }
    &.horizontal-align-#{$ldirection} {
      @include breakpoint($small-down) {
        .product-brief__info {
          @include swap_direction(padding, 0 20px 0 0);
        }
      }
    }
    &.horizontal-align-#{$rdirection} {
      @include breakpoint($small-down) {
        .product-brief__info {
          @include swap_direction(padding, 0 0 0 20px);
        }
      }
    }
  }
  .content-block-large__image-caption-label {
    color: $color-gray;
    margin: 20px auto 0;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
    .icon {
      display: inline-block;
      margin-top: -5px;
      margin-right: 5px;
    }
  }
  .content-block-large__image-caption {
    max-width: 260px;
    margin: 20px auto;
    a {
      text-decoration: underline;
    }
  }
}
