/* Engraving Styles Overlay */
.product-full__engraving-button {
  text-transform: none;
  margin-bottom: 5px;
  .monogramming {
    display: none;
  }
}

.engraving-info-icon {
  position: relative;
  #{$ldirection}: 6px;
  cursor: pointer;
}

.engraving-help-popup .engraving-popup-info {
  text-align: center;
  h1 {
    @include swap_direction(margin, 22px 8px 0 0);
    position: static;
  }
  p {
    @include swap_direction(margin, 3px 0 8px 37px);
    font-size: 14px;
    width: 86%;
  }
  .engraving-details {
    @include swap_direction(margin, 3px 0 0 77px);
    width: 75%;
    li {
      list-style: none;
      list-style-position: inside;
      margin-bottom: 10px;
      font-size: 11px;
      line-height: 15px;
    }
  }
}
//Placeholder mixin for Engraving Overlay
.engraving-preview-popup {
  #cboxContent {
    #cboxLoadedContent {
      padding: 0;
      background: $color-mpp-light-gray;
    }
  }
  #cboxClose,
  #cboxClose:hover {
    #{$rdirection}: 2px !important;
    top: 3px;
  }
}

.spp-engraving {
  .monogramming {
    display: none;
  }
  .error_messages_display {
    color: $color-red;
    text-align: center;
    width: 75%;
    margin: 0 auto;
  }
  &__background--image {
    height: 463px;
    width: 712px;
    background-size: 100% 100%;
  }
  &__background--content {
    float: $ldirection;
  }
  &__engraving--forms {
    @include swap_direction(margin, 71px 0 0 9px);
    text-align: center;
    width: 365px;
  }
  &__engraving--form {
    text-align: $ldirection;
  }
  &__engraving--form-heading {
    > h1 {
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      text-align: center;
      position: relative;
      #{$rdirection}: 3px;
    }
    p {
      @include swap_direction(margin, 4px 0 8px 36px);
      font-family: $font--subheading;
      font-weight: bold;
      font-size: 13px;
    }
    .engraving-form-count {
      @include swap_direction(margin, 4px 0 5px 60px);
      width: 75%;
      color: $color-green;
      font-family: $font--text;
      font-size: 14px;
      .personalize-content {
        text-transform: uppercase;
        font-style: normal;
      }
    }
  }
  &__engraving--form-font-choice-btn {
    display: none;
  }
  &__engraving--form-line {
    @include swap_direction(margin, 5px 0 15px 52px);
    position: relative;
    padding-#{$ldirection}: 3px;
    width: 74%;
    text-align: center;
    .char-counter {
      display: none;
    }
    .engraving-message {
      background: none;
      display: block;
      height: 35px;
      font-size: 12px;
      line-height: 35px;
      padding: 0;
      text-transform: none;
      width: 91%;
      box-shadow: none;
      text-align: center;
    }
  }
  &__engraving--form-preview {
    .overlay-left-arrow-nav,
    .spp-engraving__engraving--form-heading {
      display: none;
    }
    .engrave-placement {
      position: absolute;
      #{$rdirection}: 0;
      width: 340px;
      text-align: center;
      .canvas-orig {
        background: none;
        border: none;
        height: 100%;
        width: 100%;
        color: $color-gold-light-engrave;
        font-size: 25px;
        letter-spacing: normal;
        padding: 0;
        text-align: center;
        white-space: nowrap;
        opacity: 0;
        &.preview-copy {
          opacity: 1;
          &.engrave-choice-optima {
            font-family: $font--engrave;
            font-size: 13px;
            &.large-font {
              font-size: 8px;
            }
          }
        }
      }
      form.engraving-form-choice-31 & {
        #{$rdirection}: 246px;
        top: 250px;
        width: 130px;
        bottom: auto;
        -webkit-transform: rotate(-49deg);
        -moz-transform: rotate(-49deg);
        -ms-transform: rotate(-49deg);
        -o-transform: rotate(-49deg);
        transform: rotate(-49deg);
      }
      form.engraving-form-choice-37 & {
        #{$rdirection}: 254px;
        top: 256px;
        width: 80px;
        bottom: auto;
        -webkit-transform: rotate(-40deg);
        -moz-transform: rotate(-40deg);
        -ms-transform: rotate(-40deg);
        -o-transform: rotate(-40deg);
        transform: rotate(-40deg);
        @include breakpoint($medium-portrait-only) {
          #{$rdirection}: 280px;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$rdirection}: 230px;
        }
      }
      form.engraving-form-choice-38 & {
        #{$rdirection}: 236px;
        top: 234px;
        width: 94px;
        bottom: auto;
        -webkit-transform: rotate(-40deg);
        -moz-transform: rotate(-40deg);
        -ms-transform: rotate(-40deg);
        -o-transform: rotate(-40deg);
        transform: rotate(-40deg);
        @include breakpoint($medium-portrait-only) {
          #{$rdirection}: 270px;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$rdirection}: 220px;
        }
      }
      form.engraving-form-choice-39 & {
        #{$rdirection}: 218px;
        top: 218px;
        width: 84px;
        bottom: auto;
        -webkit-transform: rotate(-32deg);
        -moz-transform: rotate(-32deg);
        -ms-transform: rotate(-32deg);
        -o-transform: rotate(-32deg);
        transform: rotate(-32deg);
        @include breakpoint($medium-portrait-only) {
          #{$rdirection}: 250px;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$rdirection}: 200px;
        }
      }
      form.engraving-form-choice-35 & {
        #{$rdirection}: 246px;
        top: 260px;
        width: 80px;
        bottom: auto;
        -webkit-transform: rotate(-20deg);
        -moz-transform: rotate(-20deg);
        -ms-transform: rotate(-20deg);
        -o-transform: rotate(-20deg);
        transform: rotate(-20deg);
        .canvas-orig {
          color: $color-gold-light-engrave;
        }
        @include breakpoint($medium-portrait-only) {
          #{$rdirection}: 266px;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$rdirection}: 216px;
        }
      }
    }
  }
  &__engraving--form-element-subtotaling {
    margin-#{$ldirection}: 15%;
    li {
      display: flex;
      .name {
        width: 65%;
      }
    }
  }
  &__engraving--form-element-preview {
    display: none;
  }
  &__engraving--form-element-footer {
    margin-#{$ldirection}: 52px;
    .engraving-cancel {
      display: none;
    }
    .engraving-save {
      @include swap_direction(margin, 0 0 0 2px);
      width: 77%;
      height: 34px;
      line-height: 0;
      font-family: $font--text;
    }
    .engraving-msg {
      font-size: 13px;
      line-height: 16px;
      clear: both;
      width: 410px;
      padding-#{$ldirection}: 3px;
      letter-spacing: 0.05em;
      position: absolute;
      bottom: 28px;
      display: none;
    }
  }
  .engraving-form-custom-msg {
    &.engraving {
      display: none;
    }
  }
  .engraving-info {
    @include swap_direction(margin, 17px 0 0 3px);
    width: 250px;
    text-align: center;
    display: none;
    li {
      @include swap_direction(margin, 0 0 9px 0);
      font-family: $font--text;
      font-size: 11px;
      line-height: 14px;
      list-style: none;
      list-style-position: inside;
    }
  }
}

.checkout__sidebar {
  .engraving {
    &-wrapper {
      display: none;
    }
    &-view {
      &__message,
      &__message-disclaimer {
        display: none;
      }
    }
  }
  .cart-items {
    &__engraving-sku-item {
      .cart-item {
        border: none;
        padding-top: 0;
      }
    }
  }
  .engraving-button-container {
    clear: both;
    width: 84%;
    float: $rdirection;
    margin-bottom: 10px;
    display: block;
  }
}
/* Order History */
#order_detail,
#orders {
  .engraving-product-message {
    margin-top: 3px;
    .engraved__text {
      font-family: $font--engrave;
      text-transform: none;
      font-weight: normal;
    }
  }
}
/* End of Engraving Styles Overlay */
/* Viewcart Page */
.viewcart {
  .engraving-button-container {
    clear: both;
    display: block;
  }
  .engraving-wrapper {
    background: $gray;
    height: 60px;
    line-height: 60px;
  }
  .engraving-view {
    &__title-wrap {
      margin-#{$ldirection}: 16%;
      float: $ldirection;
    }
    &__price {
      position: absolute;
      #{$rdirection}: 4%;
      bottom: 35%;
    }
    &__message {
      float: $ldirection;
      margin-#{$ldirection}: 1%;
      width: 50%;
      &-content {
        font-family: $font--engrave;
        float: $ldirection;
        width: 50%;
        text-align: center;
        word-break: break-word;
        line-height: 1;
        padding-top: 23px;
        @include breakpoint($medium-portrait-only) {
          font-size: 12px;
          width: 56%;
          padding-top: 24px;
        }
      }
      &-delete {
        margin-#{$ldirection}: 10px;
      }
      &-disclaimer {
        margin: 10px 0;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  .viewcart-engraving__engraving--form {
    float: $ldirection;
    margin-#{$ldirection}: 1%;
  }
  .cart-items {
    &__engraving-sku-item {
      border: none;
      padding: 0;
      .cart-items {
        &__item {
          padding-bottom: 0;
        }
      }
    }
  }
}
/* End of Viewcart Page */

/* GNAV */
.gnav-util {
  .cart-item {
    &__engraving {
      border-top: solid 1px $color-light-gray;
      border-bottom: 0;
      margin-#{$ldirection}: 16.5%;
      padding: 10px 0 0;
      &-detail {
        margin-bottom: 0;
      }
      &-preview {
        background: none;
        padding: 0;
        margin-bottom: 20px;
        word-break: break-word;
        font-family: $font--engrave;
      }
    }
  }
}

.cart-confirm__engrave-container {
  display: none;
}
/* End of GNAV */
