.anchored-menu-reference {
  ul {
    max-width: $max-width;
    margin: 0 auto;
    padding: 20px;
    background-color: $color-white;
    display: block;
    text-align: center;
  }
  li {
    text-align: center;
    padding: 0 20px;
    display: inline-block;
  }
  a {
    color: $color-black;
    text-decoration: none;
    &.active {
      text-decoration: underline;
    }
  }
}
