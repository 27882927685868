///
/// @file base/_typography--helpers.scss
///
/// \brief Reusable, generic typographic styles used throughout the site.
///
/// Guidelines:
///
/// Generic classes we can use sitewide for display elements.
/// WARNING do not change values unless you know why you are here
/// these classes are used in mustache for touts
/// @setup new brand - apply consistently to match your designs

/// colors only
.font-color--dark {
  color: $color-dark-gray;
}

.font-color--light-gray {
  color: $color-light-gray;
}

.font-color--black {
  color: $color-black;
}

.font-color--neutral-black {
  color: $color-neutral-black;
}

.font-color--neutral-dark-gray {
  color: $color-neutral-dark-gray;
}

.font-color--neutral-gray {
  color: $color-neutral-gray;
}

.font-color--neutral-light-gray {
  color: $color-neutral-light-gray;
}

.font-color--neutral-silver {
  color: $color-neutral-silver;
}

.font-color--light {
  @include font-smoothing(true);
  color: $color-white;

  // White text appears on white background on content blocks
  .content-block & {
    @include breakpoint($landscape-down) {
      color: $color-black;
    }
  }
}

.font-color--white {
  @include font-smoothing(true);
  color: $color-white;

  // White text appears white in all breakpoints
  .content-block & {
    @include breakpoint($landscape-down) {
      color: $color-white;
    }
  }
}

///
/// Classes used in cremedelamer2_custom_text_format
///

// wysiwyg automatically inserts <p> in rich_text mode
.heading {
  > p {
    line-height: inherit;
    margin: 0;
  }
}

%typography-helpers {
  // Headings
  .heading--1 {
    @include heading--1;
  }
  .heading--2 {
    @include heading--2;
  }
  .heading--3 {
    @include heading--3;
  }
  .heading--4 {
    @include heading--4;
  }
  .heading--5 {
    @include heading--5;
  }

  // Text
  .text--1 {
    @include text--1;
  }
  .text--2 {
    @include text--2;
  }

  // Styles
  .style--review {
    @include style--review;
  }
  .style--name {
    @include style--name;
  }

  // 2021 new font styles
  .typestyle--h1 {
    @include typestyle--h1;
  }
  .typestyle--h2 {
    @include typestyle--h2;
  }
  .typestyle--h3 {
    @include typestyle--h3;
  }
  .typestyle--h4 {
    @include typestyle--h4;
  }
  .typestyle--h5 {
    @include typestyle--h5;
  }
  .typestyle--h6 {
    @include typestyle--h6;
  }
  .typestyle--h7 {
    @include typestyle--h7;
  }
  .typestyle--h8 {
    @include typestyle--h8;
  }
  .typestyle--h9 {
    @include typestyle--h9;
  }
  .typestyle--b1 {
    @include typestyle--b1;
  }
  .typestyle--b2 {
    @include typestyle--b2;
  }
  .typestyle--b3 {
    @include typestyle--b3;
  }
}

body {
  @extend %typography-helpers;

  // Content Block Preset Overrides
  .content-block .content-block__typography {
    @extend %typography-helpers;
  }
}
