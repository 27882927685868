.checkout {
  @include clearfix;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  max-width: $max-width;
  z-index: 1;
  padding-top: 20px;
  @include breakpoint($medium-up) {
    padding-top: 50px;
  }
  &.viewcart,
  &.samples-page,
  &.checkout-single-page {
    overflow: visible;
    @include breakpoint($landscape-up) {
      overflow: hidden;
    }
  }
  &-viewcart,
  &-samples,
  &-index {
    .site-footer {
      &__secure-checkout,
      &__needhelp {
        display: block;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      &__country-chooser-wrapper,
      &-pc,
      .menu-ref,
      .site-email-signup,
      .social-media-links {
        display: none;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
      &__bottom {
        &-links {
          display: none;
          @include breakpoint($landscape-up) {
            display: block;
          }
        }
      }
      &-pc,
      &__bottom {
        display: none;
      }
      &__bottom-condensed {
        display: block;
      }
    }
    .mobile-checkout-back {
      margin-left: 15px;
      display: block;
      top: 12px;
      position: relative;
      cursor: pointer;
      .icon {
        height: 27px;
        width: 16.8px;
      }
    }
    .site-header__utility-left > .site-header__utility-item-nav {
      display: none;
      @include breakpoint($landscape-up) {
        display: inline-block;
      }
    }
  }
  &-viewcart {
    .mobile-checkout-back {
      display: none;
    }
    .site-header__utility-left > .site-header__utility-item-nav {
      display: inline-block;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    .checkout {
      &__sidebar {
        @include breakpoint($medium-down) {
          border-top: none;
        }
      }
    }
    .site-footer {
      &__bottom-condensed {
        margin-bottom: 75px;
        @include breakpoint($landscape-up) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__header {
    position: relative;
    padding: 1em;
    width: 100%;
    text-align: center;
  }
  &__heading {
    display: inline-block;
    margin: 0;
    margin-bottom: 5px;
    padding: 0;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 36px;
    @include breakpoint($medium-up) {
      margin-bottom: 1em;
      font-size: 36px;
    }
  }
  &__content {
    @include breakpoint($medium-up) {
      float: left;
      width: 66.6666666667%;
      padding-right: 10px;
    }
    @include breakpoint($tablet-only) {
      width: 59.3333333333%;
    }
    fieldset {
      width: auto;
      max-width: none;
      margin-right: 0;
      &.fs {
        max-width: 45em;
        width: 100%;
      }
      .pc_email_promo_container {
        margin-bottom: 0;
      }
    }
    .address-options {
      width: 50%;
      float: left;
      padding: 10px;
      @include breakpoint($large-up) {
        width: 33.3333333%;
      }
      &.billing-address-options {
        height: 220px;
        margin-bottom: 0;
      }
      &__address-container {
        height: 160px;
        margin-bottom: 10px;
        text-transform: capitalize;
        @include breakpoint($medium-up) {
          height: 140px;
        }
      }
      &__links {
        padding-bottom: 10px;
      }
      &__select-this-address {
        // Hide --selected span at first
        .select-label--selected {
          display: none;
        }

        // If it's the the selected address, show --selected and hide the normal span
        &.selected {
          .select-label {
            display: none;
            &--selected {
              display: inline-block;
            }
          }
        }
      } // &__select-this-address
    } // .address-options
    .checkout-panel {
      &:last-child {
        @include breakpoint($medium-down) {
          border-bottom-width: 0;
        }
      }
    }
  } // &__content

  &__sidebar {
    border-top: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      float: left;
      width: 33.3333333333%;
      border-top: none;
      margin-left: -1px;
      background: $gray;
      padding: 10px 14px 50px;
    }
    @include breakpoint($tablet-only) {
      width: 39.3333333333%;
    }
    .edit {
      font-size: 11px;
      font-family: $font--neue-haas-unica-pro;
    }
    .checkout {
      &-panel {
        padding: 1em 0;
        margin: 0 1em;
        &--viewcart {
          padding-bottom: 0;
        }
      }
    }
    .checkout-panel--viewcart {
      .cart-items {
        &__item {
          &--qty__label {
            @include breakpoint($medium-up) {
              padding-left: 0;
            }
          }
        }
      }
      .error {
        width: 100%;
        font-size: 12px;
        margin: 0;
        p {
          margin-bottom: 0;
        }
      }
      .icon {
        padding-left: 10px;
      }
    }
  }
  &__footer {
    @include clearfix;
    clear: both;
  }
  &.checkout-single-page {
    .registration-content {
      .accepted-privacy-policy {
        margin-top: 20px;
      }
    }
  }
} // .checkout

.checkout-panel {
  &--past-purchases {
    display: none;
  }
  &--viewcart {
    .icon {
      float: right;
      padding-bottom: 5px;
      cursor: pointer;
    }
    .error {
      color: $color-error;
      width: 71%;
      float: right;
      margin: 18px 5% 0 0;
      @include breakpoint($large-up) {
        width: 50%;
        margin: 18px 34% 0 0;
      }
    }
  }
  &--sign-in-main {
    &.active {
      border: 0;
    }
    > .messages {
      @include breakpoint($large-up) {
        width: 50%;
        margin: auto;
      }
    }
  }
  &--promo {
    padding-left: 0;
  }
  @include clearfix;
  position: relative;
  padding: 1em;
  border-bottom: 1px solid $color-light-gray;
  &:last-child {
    border-bottom-width: 0;
  }
  &:first-child {
    border-top-width: 0;
  }
  &--shipping,
  &--payment {
    &.active {
      border-bottom-width: 0;
    }
  }
  &--email-and-sms-promotions {
    margin-bottom: 20px;
    padding-left: 0;
    border-bottom: 0;
    .checkout-panel {
      &__header,
      &__heading {
        padding: 0;
      }
    }
  }
  &__samples-heading {
    font-size: 20px;
  }
  &__sample-heading {
    font-size: 36px;
    line-height: 36px;
  }
  &__header {
    position: relative;
    margin: 0.5em 0;
    padding: 0;
    width: 100%;
    .cart-block {
      &__item-count {
        display: inline-block;
        float: none;
        padding: 0;
        font-size: 15px;
      }
    }
    .edit {
      float: right;
      margin-top: -2px;
      text-transform: uppercase;
      font-family: $font--neue-haas-unica-pro;
    }
  }
  &--review {
    .submit {
      input {
        width: 90%;
        border: 1px solid $color-white;
      }
      @include breakpoint($landscape-up) {
        input {
          width: 0;
          border: none;
        }
      }
      width: 100%;
      &.mobile-sticky-bottom {
        @include breakpoint($landscape-down) {
          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  &--new-account {
    text-align: center;
    padding: 0;
    border-bottom: none;
    .new-account-content {
      width: 100%;
      margin: auto;
      text-align: left;
      @include breakpoint($landscape-up) {
        width: 53%;
      }
    }
    .email-address,
    .submit {
      input {
        width: 100%;
      }
    }
    .email-address {
      input {
        &.checkedEmpty {
          border: 1px solid $color-error;
        }
      }
    }
    .account-present,
    .sign-in-btn {
      text-align: center;
    }
    .new-account-content {
      .form-item {
        margin-bottom: 20px;
      }
      .email-promotions {
        label {
          display: block;
        }
      }
      .sign-in-btn {
        font-weight: bold;
        font-size: 16px;
        font-family: $font--neue-haas-unica-pro;
        margin-bottom: 15%;
        margin-top: 20px;
      }
      .account-signin {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  &--return-user {
    padding: 0;
    border-bottom: none;
    .return-user-content {
      width: 100%;
      margin: auto;
      text-align: left;
      @include breakpoint($landscape-up) {
        width: 53%;
      }
    }
    text-align: center;
    .form-item {
      margin-bottom: 10px;
    }
    .valid_marker,
    .invalid_marker {
      display: none;
    }
    .email-address,
    .password {
      input {
        &.checkedEmpty {
          border: 1px solid $color-error;
        }
      }
    }
    .email-address,
    .password,
    .submit {
      input {
        width: 100%;
      }
    }
    .forgot-link {
      .link {
        font-size: 15px;
        text-transform: inherit;
      }
      .error {
        color: $color-error;
        list-style: none;
      }
    }
    .guest-btn {
      font-weight: bold;
      font-size: 16px;
      font-family: 'Neue Haas Unica Pro';
      margin-bottom: 15%;
      text-align: center;
      margin-top: 20px;
    }
    .privacy-policy {
      input {
        &[type='checkbox'] ~ label::before {
          content: '';
        }
      }
    }
    .account-signin {
      text-decoration: underline;
      cursor: pointer;
    }
    .show-password,
    .privacy-policy,
    .email-promotions {
      label {
        display: block;
      }
    }
  }
  .promo-bca-content {
    &__cart {
      .donation-amounts {
        display: flex;
        margin-bottom: 20px;
        li:nth-child(n) {
          margin-left: 20px;
        }
        li:first-child {
          margin-left: 0;
        }
      }
    }
    &__button {
      margin: 0 10px 20px 0;
      width: 100%;
      float: left;
      @include breakpoint($medium-up) {
        width: 0;
      }
    }
    &__note {
      float: left;
      line-height: 33px;
    }
  }
  &__heading {
    display: inline-block;
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0;
    font-size: 17px;
    line-height: 36px;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      font-size: 20px;
    }
  }
  &--shipping-edit-address {
    padding-bottom: 30px;
    border-bottom: 1px solid $color-lightest-grey;
  }
  &__content {
    margin: 0;
    padding: 0;
    .promo-bca-content {
      &__container {
        &--image {
          margin-right: 10px;
        }
        &--image,
        &--description {
          float: left;
        }
      }
    }
    .terms-conditions-content {
      margin-bottom: 20px;
    }
    .continue-shopping {
      margin-top: 20px;
      @include breakpoint($medium-up) {
        float: left;
      }
    }
    &.select-address {
      border-bottom: 1px solid $color-light-gray;
      .show {
        &_more,
        &_less {
          &_address {
            clear: both;
          }
        }
      }
      .new-address {
        margin-bottom: 20px;
      }
    }
    .gift-options-content {
      margin-bottom: 20px;
      .checkout-panel__expand + .checkout-panel__content {
        margin-bottom: 2em;
      }
      &__container {
        .gift-message__container {
          input[data-error],
          textarea[data-error] {
            border: solid 1px $color-error;
          }
          margin-top: 20px;
          .card-messages,
          .card-message {
            width: 100%;
            overflow: auto;
            padding-top: 5px;
            margin-bottom: 10px;
            .giftmessage_count {
              width: 100%;
              @include breakpoint($large-up) {
                width: 49%;
              }
            }
            .label-content {
              display: none;
            }
          }
          .card-message-to,
          .card-message-from {
            width: 49%;
            margin-bottom: 20px;
            input {
              width: 100%;
            }
          }
          .card-message-to {
            float: left;
          }
          .card-message-from {
            float: right;
          }
        }
      }
    }
    .pre-order-message-display {
      display: inline-block;
      margin: 10px 0 10px 0;
    }
  } // &__content

  .continue-button-wrapper {
    text-align: left;
    width: 100%;
    input {
      width: 90%;
      border: 1px solid $color-white;
    }
    @include breakpoint($landscape-up) {
      input {
        width: 0;
        border: none;
      }
    }
    &.mobile-sticky-bottom {
      @include breakpoint($landscape-down) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  .messages {
    padding: 0 1em;
    color: $color-error;
    background-color: transparent;
    border-width: 0;
    &.error a {
      color: $color-error;
    }
  }
  .single-message {
    color: $color-error-msg;
    &:first-child {
      padding-top: 20px;
    }
    a {
      color: $color-error-msg;
    }
    &[id^='error_offer_criteria_met'] {
      color: $color-success-msg;
      a {
        color: $color-success-msg;
      }
    }
    &[id^='error_offer_do_not_defer'],
    &.threshold-messages {
      color: $color-alert-msg;
      a {
        color: $color-alert-msg;
      }
    }
  }
  .warning-message {
    margin-bottom: 1em;
    color: $color-error;
    font-size: 1.2em;
    font-weight: bold;
  }

  // Panel loading animation
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: white url(/media/images/global/ajax-loading.gif) no-repeat center center;
    text-align: center;
    opacity: 0;
    filter: alpha(opacity=00);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=00)';
    -webkit-transition: opacity 3s ease-in-out;
    -moz-transition: opacity 3s ease-in-out;
    -ms-transition: opacity 3s ease-in-out;
    -o-transition: opacity 3s ease-in-out;
    transition: opacity 3s ease-in-out;
    &.fade-in {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    }
    &:before {
      content: '';
      display: inline-block;
      margin-right: -0.25em;
      height: 100%;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      padding-top: 50px;
      width: 100px;
      vertical-align: middle;
    }
    // End of panel loading animation
  }
  &--offer-code {
    .offer-code-content {
      &__input,
      &__btn {
        margin-bottom: 0;
        width: 100%;
        input {
          width: 100%;
          height: 47px;
          min-width: 100%;
        }
      }
      &__input {
        float: left;
        margin-bottom: 10px;
        @include breakpoint($landscape-up) {
          width: 60%;
          margin-bottom: 0;
        }
      }
      &__btn {
        float: right;
        @include breakpoint($landscape-up) {
          width: 35%;
        }
      }
      &__view-all {
        text-align: left;
      }
      &__one-offer {
        margin-bottom: 5px;
      }
    }
  }
} // .checkout-panel

.checkout-buttons-content {
  &__container {
    text-align: center;
    &.mobile-sticky-bottom {
      @include breakpoint($landscape-down) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        margin-bottom: 10px;
      }
    }
    @include breakpoint($medium-up) {
      text-align: right;
      float: right;
    }
    @include breakpoint($large-up) {
      width: 40%;
    }
  }
  &__item {
    display: inline-block;
    @include breakpoint($small-up) {
      margin-top: 0;
    }
    &--choose-samples {
      display: none;
    }
  }
  span.or-text {
    margin: 0 3%;
    font-size: 15px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      display: inline;
      margin-top: 25px;
      width: 50px;
    }
  }
  .paypal-checkout {
    display: inline-block;
    padding: 5px;
    width: 90%;
    height: 40%;
    background: $color-white;
    margin-bottom: 5px;
    border: 1px solid $color-light-gray;
    @include breakpoint($medium-up) {
      width: 25%;
      padding: 8px 16px;
      margin-top: 12px;
      margin-bottom: 0;
    }
    img {
      vertical-align: middle;
      height: 35px;
      @include breakpoint($medium-up) {
        vertical-align: baseline;
        height: auto;
      }
    }
  } // .paypal-checkout

  .continue-checkout {
    display: inline-block;
    float: none;
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    padding: 15px;
    border: 1px solid $color-white;
    &:hover {
      text-decoration: none;
    }
    @include breakpoint($medium-up) {
      float: right;
      width: 140px;
      margin-top: 12px;
      padding: 13px 15px 14px;
      font-size: 11px;
      border: none;
    }
  } // .continue-checkout
} // .checkout-buttons-content

// Quick links
.overlay-addclass-generic-helplinks-overlay {
  #cboxContent {
    .cs-page {
      &__header {
        display: none;
      }
      &__navigation {
        display: none;
      }
      &__content {
        padding-left: 17px;
        padding-right: 17px;
        width: 100%;
      }
    }
    .sidebar-page {
      border-bottom: 0px;
      &__content {
        .expando-block {
          margin: 0;
        }
      }
    }
  }
  .page-footer {
    display: none;
  }
  .page-wrapper {
    padding-top: 0px;
  }
}

.samples-content {
  &__form,
  &__button-group--top,
  &__button-group--bottom {
    margin-bottom: 20px;
    &.mobile-sticky-bottom {
      @include breakpoint($landscape-down) {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  &__form,
  &__button-group--bottom {
    @include breakpoint($landscape-up) {
      padding-bottom: 40px;
    }
  }
  &__form {
    &__subheader {
      margin-bottom: 20px;
      .checkout-panel__subheading {
        font-family: $font--sans;
      }
    }
  }
  &__button-group--top {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__button--return {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    display: none;
    @include breakpoint($landscape-up) {
      width: auto;
      margin-bottom: 0;
      display: block;
    }
    a {
      text-transform: uppercase;
      width: 100%;
      font-size: 11px;
    }
  }
  &__button--checkout {
    float: right;
    width: 100%;
    text-align: center;
    @include breakpoint($landscape-up) {
      width: auto;
    }
    input {
      width: 100%;
    }
    a {
      text-transform: uppercase;
      width: 90%;
      font-size: 15px;
      border: 1px solid $color-white;
      @include breakpoint($landscape-up) {
        font-size: 11px;
        border: none;
        width: auto;
      }
    }
  }
}

@include breakpoint($landscape-down) {
  .checkout-panel--samples-header-active {
    .checkout__header {
      padding: 1em 0;
    }
    .samples-content__button-group--top {
      display: block;
      margin-bottom: 0;
      .samples-content__button {
        display: none;
        width: auto;
        &--return {
          display: block;
        }
      }
    }
    .samples-content__samples {
      & > .offer:first-child {
        .offer_title {
          padding-top: 10px;
        }
      }
    }
  }
}

.delete {
  &-confirm {
    &-overlay {
      .buttons {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}

$adaptive-placeholder-textarea: (
  height: 6em,
  margin: 1em,
  textarea: true
);

.adpl {
  overflow: visible;
  textarea {
    @include adaptive-placeholder(
      (
        height: map-get($adaptive-placeholder-textarea, height),
        margin: map-get($adaptive-placeholder-textarea, margin),
        textarea: map-get($adaptive-placeholder-textarea, textarea)
      )
    );
    border-color: $border-color-normal;
  }
}

.active-panel {
  &-signin,
  &-payment,
  &-shipping,
  &-review,
  &-samples {
    .site-header__menu,
    .site-header__search,
    .site-header__utility-left,
    .site-header__utility-right,
    .checkout__header {
      display: none;
    }
    .site-header__utility-left.pc-hidden {
      display: block;
      .site-header__utility-item {
        display: none;
      }
    }
    .site-header.sticky {
      .site-header__utility-left.pc-hidden {
        @include breakpoint($medium-up) {
          display: none;
        }
      }
    }
  }
  &-samples,
  &-review {
    .checkout__header {
      display: block;
    }
  }
  &-signin,
  &-shipping,
  &-payment {
    .checkout__sidebar {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

// Alipay
@if $alipay-enabled {
  .checkout__sidebar {
    .viewcart-buttons-panel {
      border-bottom: 1px solid $color-light-gray;
      margin: 0 1em;
      padding: 1em 0;
    }
  }
  .viewcart {
    .sticky-checkout-button,
    .viewcart-buttons-panel {
      .btn,
      .continue-checkout {
        align-items: center;
        display: flex;
        font-size: 15px;
        justify-content: center;
        height: 55px;
        margin: 0 auto;
        padding: 0;
        text-decoration: none;
        width: 100%;
        @include breakpoint ($medium-up) {
          width: 328px;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
      .btn-primary,
      .continue-checkout {
        background-color: $color-dark-gray;
        color: $color-white;
        height: 63px;
        &::before {
          background: url('/media/images/lock-icon-white.png') no-repeat 100% / 100%;
          content: '';
          height: 16px;
          margin-#{$rdirection}: 10px;
          width: 12px;
        }
      }
      .apple-pay-button-container {
        padding: 6px;
        .apple-pay-button {
          -apple-pay-button-type: check-out;
          -apple-pay-button-type: plain;
          -apple-pay-button-style: white;
          border-radius: 0;
        }
      }
      .apple-pay-button-container,
      .gwp-paypal-checkout,
      .paypal-checkout {
        background-color: $color-white;
        border: 1px solid $color-dark-gray;
        height: 55px;
      }
      .gwp-paypal-checkout,
      .paypal-checkout {
        &::before {
          background: url('/media/images/logos/paypal.gif') no-repeat 100% / 100%;
          content: '';
          height: 20px;
          width: 80px;
        }
      }
      .paypal-smart-button {
        column-gap: 12px;
        display: flex;
        margin-bottom: 12px;
        &.paypal-loading {
          background: $color-white url(/media/images/global/ajax-loading.gif) no-repeat center center;
          position: relative;
          text-align: center;
        }
        .paypal-payment-spinner {
          align-items: center;
          background: $color-white;
          display: flex;
          height: 100%;
          justify-content: center;
          &__icon {
            background: url(/media/images/global/ajax-loading.gif) no-repeat left center;
            display: inline-block;
            height: 31px;
            margin-#{$rdirection}: 8px;
            width: 31px;
          }
        }
      }
      .payment-icons {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 16px auto 0;
        padding-top: 10px;
        width: 100%;
        @include breakpoint ($medium-up) {
          width: 328px;
        }
        &__item {
          &--master {
            background: url('/media/images/payment_icons/master_payment.png') no-repeat center;
          }
          &--visa {
            background: url('/media/images/payment_icons/visa_payment.png') no-repeat center;
          }
          &--amex {
            background: url('/media/images/payment_icons/amex_payment.png') no-repeat center;
          }
          &--discover {
            background: url('/media/images/payment_icons/discover_payment.png') no-repeat center;
          }
          &--alipay {
            background: url('/media/images/payment_icons/ali_payment.png') no-repeat center;
          }
          &--diner {
            background: url('/media/images/payment_icons/diners_payment.png') no-repeat center;
          }
          &--union {
            background: url('/media/images/payment_icons/union_payment.png') no-repeat center;
            margin-right: 0;
          }
          &--master,
          &--visa,
          &--amex,
          &--discover,
          &--alipay,
          &--diner,
          &--union {
            background-size: cover;
            height: 24px;
            width: 32px;
          }
        }
      }
    }
  }
  .sticky-checkout-button {
    .alipay-checkout {
      margin-top: 10px;
    }
  }
  .checkout-buttons-content {
    .alipay-checkout {
      margin-top: 10px;
    }
  }
  .checkout-single-page {
    .bundle_kit_item {
      .cart-items__kit-remove,
      .cart-items__kit-edit {
        display: none;
      }
      .priceBundle {
        float: $rdirection;
        font-weight: bold;
      }
    }
  }
  .bespoke-set-info {
    color: $color-black;
  }
}
