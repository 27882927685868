///
/// content-block-collapsible
/// A content block that becomes click-expandable with a title
///

.content-block-collapsible {
  @include content-container;
  position: relative;
  &-bg {
    &-accordion {
      &__paragraph {
        display: none;
        p {
          margin: 0;
          font-size: 16px;
          line-height: 20px;
          padding: 11px 0px;
        }
      }
      header {
        background-repeat: no-repeat;
        color: $color-white;
        padding: 70px 0 50px;
        margin: 0;
        @include breakpoint($medium-up) {
          margin: 0 25px;
          padding: 105px 0 90px;
        }
        button {
          background: none;
          border: none;
          padding: 5px;
          margin-top: 8px;
          font-size: 0;
          min-width: auto;
          color: $color-white;
          outline: none;
          @include breakpoint($medium-up) {
            margin-top: 22px;
            color: $color-dark-gray;
            background: $color-white;
            min-width: 124px;
            padding: 15px 10px;
            font-size: 11px;
          }
          span {
            display: inline-block;
            position: static;
            @include breakpoint($medium-up) {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              right: 18px;
            }
            svg {
              fill: currentColor;
              width: 21px;
              height: 21px;
              @include breakpoint($medium-up) {
                width: 9px;
                height: 11px;
              }
            }
          }
        }
      }
      &.active {
        .content-block-collapsible {
          &__description {
            display: block;
          }
          &__header {
            button {
              display: none;
            }
          }
          &--bg-accordion {
            &__paragraph {
              display: block;
            }
          }
        }
      }
      .content-block-collapsible {
        &__close {
          button {
            span {
              display: inline-block;
              position: static;
              @include breakpoint($medium-up) {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 15px;
              }
              svg {
                fill: currentColor;
                padding: 0;
                width: 21px;
                height: 21px;
                @include breakpoint($medium-up) {
                  width: 8px;
                  height: 5px;
                }
              }
            }
          }
        }
        &__description {
          display: none;
          .heading {
            font-size: 16px;
            line-height: 22px;
            padding-top: 26px;
            font-style: normal;
            font-weight: normal;
          }
        }
      }
    }
  }
  &__header {
    text-align: center;
    margin: 0 25px;
    padding: 20px 0;
    border-top: 1px solid $color-border;
    @include breakpoint($landscape-up) {
      margin: 0 72px;
      padding: 40px 0;
    }
  }
  &__eyebrow {
    @include text--2;
    margin: 0 0 15px 0;
    opacity: 0.8;
    @include breakpoint($medium-up) {
      opacity: 1;
      margin-bottom: 18px;
    }
  }
  &__headline {
    @include heading--2;
    position: relative;
    padding: 0 6%;
    @include breakpoint($landscape-up) {
      padding: 0 12%;
    }
  }
  &__cta {
    text-align: center;
    margin: 20px 0 0 0;
    @include breakpoint($medium-up) {
      margin-top: 40px;
    }
  }
  .content-block-collapsible__icon {
    @include icon('plus');
    font-size: 16px;
    transition: 0.3s all ease-in-out;
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%) rotate(180deg);
    cursor: pointer;
    @include breakpoint($medium-up) {
      right: 35px;
      font-size: 26px;
      padding: 10px;
    }
  }
  &.active {
    .content-block-collapsible__icon {
      @include icon('minus');
      top: 33%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  &__content {
    position: relative;
    top: -10px;
    padding: 0 25px;
    @include breakpoint($medium-up) {
      top: -25px;
      padding: 0;
    }
    @include breakpoint($landscape-up) {
      max-width: $content-block-max-width;
      margin: 0 auto;
    }
    &--full-width {
      @include breakpoint($medium-up) {
        max-width: 100%;
      }
    }
    &-body {
      @include text--1;
      text-align: center;
      margin: 0 0 10px 0;
      @include breakpoint($medium-up) {
        margin-bottom: 20px;
        padding: 0 10%;
      }
    }
  }
  .basic-carousel-formatter {
    @include breakpoint($medium-up) {
      padding: 0 10%;
    }
    @include breakpoint($extra-large-up) {
      padding: 0;
    }
    .slick-prev {
      left: -8px;
      @include breakpoint($medium-up) {
        left: 10px;
      }
      @include breakpoint($extra-large-up) {
        left: -40px;
      }
    }
    .slick-next {
      right: -8px;
      @include breakpoint($medium-up) {
        right: 10px;
      }
      @include breakpoint($extra-large-up) {
        right: -40px;
      }
    }
  }
  &__close {
    text-align: center;
    padding-bottom: 46px;
    button {
      min-width: 124px;
      @include breakpoint($small-down) {
        background: none;
        border: none;
        min-width: auto;
        padding: 0;
        font-size: 0;
        color: $color-dark-gray;
      }
      &:hover {
        @include breakpoint($small-down) {
          color: $color-dark-gray;
        }
      }
      .icon {
        &:before {
          font-size: 5px;
          line-height: 10px;
          @include breakpoint($small-down) {
            font-size: 30px;
            line-height: 36px;
          }
        }
      }
    }
    .icon {
      position: absolute;
      right: 15px;
      padding: 0;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 0;
      transition: none;
      @include breakpoint($small-down) {
        position: static;
        padding: 10px;
        transform: none;
      }
      &:before {
        font-size: 8px;
        line-height: 16px;
        font-weight: bold;
        padding: 0;
        @include breakpoint($small-down) {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
  }
  &__button {
    position: relative;
    outline: none;
    &:hover,
    &:active,
    &:focus {
      @include breakpoint($small-down) {
        background: none;
      }
    }
  }
  &.align-#{$ldirection}, &.align-#{$rdirection} {
    .content-block-collapsible__headline {
      @include swap_direction(padding, 0 8% 0 0);
    }
  }
  &.align-#{$ldirection} {
    .content-block-collapsible__header,
    .content-block-collapsible__content-body {
      text-align: $ldirection;
    }
    .content-block-collapsible__content {
      .content-block-large__content,
      .content-block-large__content-text {
        padding-#{$ldirection}: 0;
      }
    }
    .content-block--split-width .content-block-large__picture {
      margin-#{$ldirection}: 0;
    }
  }
  &.align-#{$rdirection} {
    .content-block-collapsible__header,
    .content-block-collapsible__content-body {
      text-align: $rdirection;
    }
    .content-block-collapsible__content {
      .content-block-large__content,
      .content-block-large__content-text {
        padding-#{$rdirection}: 0;
      }
    }
    .content-block--split-width .content-block-large__picture {
      margin-#{$rdirection}: 0;
    }
  }
}

.content-block-collapsible--active {
  .basic-carousel .content-block--split-width {
    padding: 0;
  }
  .basic-carousel-formatter {
    margin-bottom: 0;
  }
  .product-grid--carousel {
    .slick-prev,
    .slick-next {
      top: 28%;
      @include breakpoint($large-up) {
        top: 0;
        bottom: 0;
        height: auto;
        max-height: 287px;
        margin-top: 15px;
      }
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
  }
}
