.welcome15-overlay {
  #cboxLoadedContent {
    @include swap_direction(padding, 0);
    background-color: $color-dark-blue;
    .welcome-offer-popup-wrapper {
      text-align: center;
      background-image: url(/media/export/cms/loyalty/welcome_popup_banner_mobile.png);
      background-size: 100% 100%;
      @include breakpoint($landscape-up) {
        background-image: url(/media/export/cms/loyalty/welcome_popup_banner_pc.png);
        background-repeat: no-repeat;
      }
      &__header {
        visibility: hidden;
        height: 325px;
        @include breakpoint($landscape-up) {
          height: 240px;
        }
      }
      &__subheader {
        @include swap_direction(padding, 0 20px);
        font-size: 24px;
        color: $color-white;
        font-family: $font--subheading;
        font-style: italic;
        line-height: 1.25;
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 0 10%);
        }
      }
      &__button {
        @include swap_direction(margin, 20px 0);
        padding-#{$ldirection}: 50px;
        padding-#{$rdirection}: 50px;
        a {
          text-decoration: none;
          font-size: 11px;
        }
        &:hover {
          color: $color-white;
          a {
            color: $color-white;
          }
        }
      }
      &__footer {
        color: $color-white;
        padding-bottom: 36px;
        font-family: $font--text;
        font-size: 13px;
        a {
          color: $color-white;
          font-size: 13px;
          font-family: $font--text;
          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            text-transform: initial;
          }
        }
      }
    }
    .free-shipping-welcome-popup {
      text-align: center;
      background-color: $color-white;
      padding: 10px 0 0;
      @include breakpoint($landscape-up) {
        padding: 20px 0;
      }
      .welcome-offer-popup-wrapper {
        &__subheader {
          font-size: 22px;
          color: $color-dark-gray;
          @include breakpoint($landscape-up) {
            font-size: 24px;
          }
        }
        &__button {
          a {
            color: $color-white;
          }
        }
      }
    }
  }
}
