///
/// @file components/prouducts/_product_grid.scss
///
/// \brief Styles for multi-product grids
///
///

.product-grid-wrapper {
  clear: both;
}

.product-grid {
  position: relative;
  max-width: $max-width;
  margin: 0 auto;
  .mpp-container & {
    background-color: $color-mpp-light-gray;
    @include breakpoint($medium-up) {
      background-color: $color-white;
    }
  }
  .product-brief {
    display: flex;
    flex-direction: column;
    width: 100%;
    .product__caption {
      font-size: 13px;
      @include breakpoint($medium-up) {
        @include typestyle--h7;
      }
    }
  }
  &--product-no-flag {
    .product-brief__flag {
      display: none;
    }
  }
  &--peeking {
    margin: 0 -25px;
    @include breakpoint($medium-up) {
      margin: 0 auto;
    }
    .product-grid__content {
      padding: 0;
      .slick-list {
        @include breakpoint($medium-down) {
          @include swap_direction(padding, 0 28% 0 0);
        }
      }
    }
    .slick-slide {
      margin-#{$rdirection}: 20px;
      @include breakpoint($medium-up) {
        margin-#{$rdirection}: 0;
      }
      .product-brief {
        @include breakpoint($medium-down) {
          &__info {
            padding: 0;
            .product-brief__image {
              margin-bottom: 20px;
            }
          }
          &__header {
            padding-top: 0;
          }
          &__footer {
            .product-brief__cta {
              margin-#{$ldirection}: 0;
              margin-#{$rdirection}: 0;
            }
          }
        }
      }
      &.slick-active {
        .product-brief {
          @include breakpoint($medium-down) {
            &__header {
              padding-#{$ldirection}: 20px;
              padding-#{$rdirection}: 20px;
            }
            &__cta {
              margin-#{$ldirection}: 20px;
              margin-#{$rdirection}: 20px;
            }
            &__footer {
              @if $cr22 {
                padding-#{$ldirection}: 20px;
                padding-#{$rdirection}: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.product-grid__content {
  //@include pie-clearfix;
  display: flex;
  flex-wrap: wrap;
  @include breakpoint($medium-up) {
    margin: 0 0 20px 0;
    .content-block & {
      margin: auto;
    }
  }
  @include breakpoint($landscape-up) {
    margin: 0 0 40px 0;
  }
}

// Do not add any spacing/gutter here. See _product_brief.scss. This is due to the design requirements of the quickshop hover panel.
.product-grid__item {
  position: relative;
  display: flex;
  .no-touchevents .product-grid--theme-white & {
    display: block;
  }
}

// initially hide items only if mixitup filters is enabled
.mpp-container--has-filters {
  .mixitup-initialized {
    .product-grid__item {
      display: none;
    }
  }
}

@include breakpoint($small-down) {
  .product-grid__item {
    width: 50%;
    border: 1px solid $color-white;
  }
  .product-grid--small-cols-1 {
    .product-grid__content {
      display: block;
    }
    .product-grid__item {
      width: 100%;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-width: 5px;
    }
  }
  .product-grid--small-cols-2 {
    .product-grid__item {
      width: 50%;
    }
  }
  .product-grid--small-cols-3 {
    .product-grid__item {
      width: 33.33333%;
    }
  }
  .product-grid--small-cols-4 {
    .product-grid__item {
      width: 25%;
    }
  }
  .product-grid__item--tout {
    width: 100% !important; // all inline touts are 100% on mobile regardless of colspan
    max-width: 100% !important;
    margin-bottom: 30px;
  }
}

@include breakpoint($medium-up) {
  .product-grid--medium-cols-1 {
    .product-grid__item {
      width: 100%;
    }
  }
  .product-grid--medium-cols-2 {
    .product-grid__item {
      width: 50%;
      &--colspan-2,
      &--colspan-3,
      &--colspan-4 {
        width: 100%;
      }
    }
  }
  .product-grid--medium-cols-3 {
    .product-grid__item {
      width: 33.33333%;
      &--colspan-2 {
        width: 66.66667%;
      }
      &--colspan-3,
      &--colspan-4 {
        width: 100%;
      }
    }
  }
  .product-grid--medium-cols-4 {
    .product-grid__item {
      width: 25%;
      &--colspan-2 {
        width: 50%;
      }
      &--colspan-3 {
        width: 75%;
      }
      &--colspan-4 {
        width: 100%;
      }
    }
  }
}

.product-grid__add_all_to_bag {
  text-align: center;
  padding: 0 20px;
  margin: 20px 0 30px;
  .button {
    width: 100%;
    @include breakpoint($medium-up) {
      width: auto;
    }
  }
}

//
// Product Carousel
//
.product-grid--carousel {
  .block-template-product-grid-v1 & {
    .slick-prev,
    .slick-next {
      @include breakpoint($large-up) {
        top: 0;
        bottom: 0;
        height: auto;
        max-height: 287px;
        margin-top: 15px;
      }
    }
  }
  .product-carousel {
    display: none; // Prevent FOUC
    &.slick-initialized {
      display: block;
    }
    &:has(.product-brief--show-shade-size) {
      padding-inline-start: 19px;
    }
  }
  // mimic "Center" mode
  .slick-list {
    @include breakpoint($medium-up) {
      padding: 10px 8%;
    }
  }
  .slick-slide {
    // place empty div above inactive slides so they can't be clicked on
    @include breakpoint($medium-up) {
      opacity: 1;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        height: 100%;
        width: 100%;
      }
    }
    &:has(.product-brief--show-shade-size) {
      @include breakpoint($medium-up) {
        &::after {
          display: none;
        }
      }
    }
  }
  .slick-active {
    @include breakpoint($medium-up) {
      &:after {
        display: none;
      }
    }
  }
  .slick-prev,
  .slick-next {
    top: 30%;
    @include breakpoint($medium-up) {
      top: 35%;
    }
  }
  .slick-dots {
    margin: 0 0 20px 0;
  }
}

.product-grid--carousel--medium-4 {
  .slick-prev,
  .slick-next {
    @include breakpoint($medium-up) {
      top: 29%;
    }
  }
}

.product-grid--content-block {
  .product-grid__content {
    margin-bottom: 0;
  }
  .product-image {
    max-width: 75%;
    margin: auto;
  }
  .product {
    &-name,
    &__subline {
      font-size: 14px;
      line-height: 1.4;
    }
  }
}

.product-grid--category {
  .product-brief__image {
    margin-bottom: 20px;
  }
  .product-grid__item {
    .content-block--tout & {
      width: 100%;
    }
    .content-block-large__products & {
      width: 25%;
    }
    .content-block-large__products--inside & {
      width: 50%;
    }
  }
  .product-brief__category-cta {
    margin-top: 15px;
  }
}
