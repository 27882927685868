@import 'overrides/overrides';
@import '../../cremedelamer2_base/scss/cremedelamer2-base-pc';
@import 'liveperson';
@import 'engraving_pc';
@import '../../cremedelamer2_base/scss/sections/one_trust_cookie/cookie_settings_ot';
@import 'sign_in';
@import 'sections/checkout/checkout-viewcart';

.session-idle-popup__content {
  text-align: center;
}

.session-idle-popup__content--header {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.session-idle-popup__content--desc {
  margin-bottom: 45px;
}

.customer-service {
  .cs-contact-info {
    width: 24%;
    &__TEXT .cs-contact-info {
      &__description {
        .link {
          line-height: 50px;
          color: $color-green;
          border-bottom: none;
          cursor: initial;
        }
      }
      &__image {
        width: 19px;
      }
      &__title {
        margin-top: 12px;
      }
    }
  }
  &__migrated-user-txt {
    margin: 10px 0 5px 0;
  }
  &__migrated-user-agree-txt {
    margin: 5px 0 10px 0;
  }
  &__agree-migrate {
    margin: 20px 0 20px 0;
  }
}

.sticky {
  &__live-chat {
    font-weight: bold;
    border: 1px solid $color-lighter-gray;
    border-radius: 4px;
    width: 165px;
    height: 60px;
    background: $color-white url('/media/export/cms/liveChat_hp.png') no-repeat;
    background-position: 20px;
    &:hover,
    &:active {
      color: $color-link-hover;
      border: 1px solid $color-lighter-gray;
      background: $color-white url('/media/export/cms/liveChat_hp_hover.png') no-repeat;
      background-position: 20px;
    }
    &--text {
      width: 50%;
      margin-left: 40%;
      margin-top: 4px;
      float: left;
      line-height: 13px;
    }
  }
}

.checkout-panel__content {
  overflow: hidden;
}

.site-footer-formatter {
  &__term-link {
    vertical-align: middle;
  }
  &__accessibility {
    a {
      line-height: get-line-height(14px, 35px);
    }
  }
}
