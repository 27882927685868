//////////////////////////////////////////////////////////////
/// CONTENT BLOCK FEATURED TEXT + IMAGES
//////////////////////////////////////////////////////////////
.content-block--featured-text-images {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  color: $color-dark-gray;
  &.content-container {
    max-width: 1000px;
  }
  .content-block__content {
    flex: 1;
    padding: 20px 0 40px;
    max-width: 80%;
    margin: auto;
    @include breakpoint($medium-up) {
      max-width: 50%;
    }
  }
  .content-block__header {
    @include style--review;
    margin-bottom: 30px;
    padding: 0 10px;
  }
  .content-block__content {
    @include style--name;
  }
  .content-block__image--left,
  .content-block__image--right {
    display: none;
    img {
      display: block;
      max-width: 356px;
      height: auto;
    }
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}
