/* CONFIRMATION PANEL */
.checkout {
  &--confirmation-page {
    .checkout-panel {
      &__heading {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
}

#confirmation-page {
  #guarantee-panel {
    margin: 2em 0;
  }
  .confirmation-panel {
    &__actions {
      margin-left: 2em;
      margin-bottom: 2em;
      .button {
        background: none repeat scroll 0 0 #666666 !important;
        border-color: rgb(204, 204, 204);
        border-radius: 3px;
        color: $color-white !important;
        display: inline-block !important;
        height: 2.2em;
        font-size: 14px !important;
        line-height: 25px !important;
        padding: 4px 10px !important;
        text-transform: uppercase !important;
      }
      .button:hover {
        color: $color-black !important;
        text-decoration: none;
      }
    }
    h3 {
      margin-bottom: 1em;
    }
    p {
      margin-bottom: 1em;
    }
  }
}

.registration-panel {
  &__content {
    .checkout {
      &__button {
        background: none repeat scroll 0 0 #666666 !important;
        color: $color-white !important;
        display: inline-block !important;
        font-size: 14px !important;
        font-weight: bold;
        line-height: 25px !important;
        padding: 4px 10px !important;
        text-transform: uppercase !important;
      }
      &__button:hover {
        color: $color-black !important;
      }
    }
  }
  &__pre {
    white-space: pre-wrap;
  }
  &__registration-form-container {
    .show-password input {
      vertical-align: middle;
    }
  }
}

//new code from scratch for order-confirmation page
.registration-content {
  padding: 0;
  &__email {
    display: none;
  }
  &__create-account {
    width: 90%;
    @include breakpoint($medium-up) {
      width: 95%;
    }
    margin: auto;
    ul {
      list-style-type: disc;
      margin-top: 10px;
    }
  }
  &__heading {
    margin-bottom: 10px;
  }
  &__pre {
    li {
      margin-bottom: 5px;
    }
  }
  .checkout-registration {
    text-align: left;
    margin-top: 20px;
    .valid_marker,
    .invalid_marker {
      display: none;
    }
    .back-to-home {
      a {
        width: 100%;
      }
    }
    &__email-value {
      text-align: left;
      .example-user-email {
        font-weight: bold;
      }
    }
    &__password {
      text-align: left;
      input {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
      .note {
        display: none;
      }
    }
    &__show-password {
      text-align: left;
      margin-bottom: 0;
    }
    &--password {
      margin-top: 20px;
    }
    &__policy,
    &__passowrd {
      input {
        &.checkedEmpty,
        &.error {
          color: $color-error;
          border: 1px solid $color-error;
          @include input-placeholder {
            color: $color-error;
          }
          & ~ label {
            color: $color-error;
          }
        }
      }
    }
  }
  .accepted-privacy-policy {
    margin-bottom: 20px;
    text-align: left;
  }
  ul.error_messages {
    text-align: center;
  }
}

.checkout-panel {
  &--confirmation {
    width: 360px;
    margin: auto;
    @include breakpoint($medium-up) {
      width: 500px;
    }
    @include breakpoint($landscape-up) {
      width: 100%;
    }
    .checkout-panel__header {
      text-align: center;
    }
    .confirmation-content {
      text-align: center;
      &__print-buttons {
        width: 100%;
        margin-bottom: 10px;
        @include breakpoint($landscape-up) {
          width: 48%;
        }
        float: left;
        a {
          width: 100%;
        }
      }
      &__cancel-buttons {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 48%;
        }
        float: right;
        a {
          width: 100%;
          @include breakpoint($landscape-up) {
            padding: 14px;
          }
        }
      }
      &__email-coming {
        text-align: left;
      }
    }
    .order-number {
      width: 100%;
      background-color: $color-white-smoke;
      padding: 5px;
      @include breakpoint($medium-up) {
        padding: 15px;
      }
      margin: auto;
      margin-bottom: 20px;
      font-weight: bold;
      .link {
        color: $color-green;
        border-bottom: none;
      }
    }
  }
  &__fields {
    margin-bottom: 10px;
  }
  &--email-and-sms-promotions {
    .section-sms-promotions {
      display: none;
    }
  }
}

.order-cancel {
  ul.error_messages {
    padding: 8px 0;
    li {
      color: $color-error;
    }
  }
}

.checkout-panel {
  &--confirmation {
    padding-bottom: 30px;
    padding-top: 0;
    width: 100%;
  }
}

.checkout--confirmation-page {
  .order-header {
    padding-bottom: 0;
  }
  .order-heading {
    font-size: 21px;
  }
  .checkout-panel--sign-in-confirmation {
    &--header {
      border-top: 1px solid $color-white-smoke;
      border-bottom: 1px solid $color-white-smoke;
      padding: 20px 0 5px 0;
    }
    .email-address {
      .email {
        font-weight: bold;
      }
    }
    .password,
    .submit {
      width: 49%;
      input {
        width: 100%;
      }
    }
    .forgot-pw-note {
      a {
        text-decoration: underline;
      }
      margin: 0 0 5% 0;
    }
  }
  .checkout-panel--registration {
    margin: auto;
    .registration_header {
      margin: auto;
      padding: 15px 0 0 0;
      border-top: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-light-gray;
      .checkout-panel__heading {
        width: 350px;
      }
    }
  }
  .registration-content {
    .continue-button-wrapper {
      text-align: left;
      margin-top: 20px;
      input {
        width: 100%;
        @include breakpoint($landscape-up) {
          width: 50%;
        }
      }
    }
  }
}

.return-policy-overlay {
  .customer-service {
    &__navigation {
      display: none;
    }
    &__content {
      width: 100%;
      .collapsible-block {
        &__title {
          span {
            width: 90%;
          }
        }
      }
    }
  }
}

.checkout-panel--registration {
  &.finished {
    .button.edit {
      @include breakpoint($medium-down) {
        float: none;
      }
    }
  }
}
