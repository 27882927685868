///
/// @file components/prouducts/_mpp.scss
///
/// \brief Styles for multi-product pages
///
///

.mpp-container {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0;
  position: relative;
  padding: 30px 0 0 0;
  @include breakpoint($medium-up) {
    padding: 60px 0;
  }
  &--has-filters {
    @include pie-clearfix;
    .product-grid-wrapper,
    .filter-no-matches {
      @include breakpoint($large-up) {
        clear: none;
        float: right;
        width: 85.54688%;
        margin: 0 0.46875% 0 0;
      }
    }
  }
  &--has-sort {
    padding-top: 0;
  }
}

.mpp-sort {
  padding: 20px 5px 20px 5px;
  text-align: center;
  @include breakpoint($large-up) {
    float: right;
    padding-right: 10px;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
  }
  .mpp-container--has-filters & {
    padding-right: 30px;
    float: right;
    width: 50%;
    text-align: left;
    @include breakpoint($large-up) {
      width: auto;
    }
  }
  select {
    width: 120px;
  }
  &__label {
    font-size: 11px;
    letter-spacing: 0.05em;
    padding: 0 7px 0 0;
  }
  &__menu {
    height: 35px;
    line-height: 35px;
  }
}

.mpp-sort-menu,
.mpp-sort-menu-selectBox-dropdown-menu {
  text-transform: uppercase;
}

.mpp-filter {
  padding: 20px 5px 20px 30px;
  float: left;
  width: 50%;
  text-align: right;
  @include breakpoint($large-up) {
    text-align: left;
    clear: right;
    float: left;
    padding: 0;
    margin: 5px 0 0 0.70313%;
    width: 11.71875%;
  }
}

.mpp-hero {
  text-align: center;
  padding: 20px 0;
  @include breakpoint($large-up) {
    position: absolute;
    width: 100%;
    left: 0;
    top: 20px;
    padding: 0;
  }
}

.mpp-hero__title {
  @include heading--1;
  text-transform: uppercase;
  margin: 0;
}
