///
/// @file template_api/_gnav-util.scss
///
/// \brief Styles for gnav utility links and expandable content wrappers
///
///
///

.gnav-util-formatter__item {
  display: inline-block;
  float: left;
  padding: 0 30px;
  width: 30px;
}

.gnav-util__link {
  display: inline-block;
  padding: 0 1em;
}

.gnav-util__icon {
  color: $black;
  top: 4px;
  position: relative;
  .icon:before {
    font-size: 16px;
  }
  &__cart-count {
    width: 20px;
    height: 20px;
  }
  &--cart {
    display: block;
  }
}

.site-header__utility {
  a:hover {
    color: initial;
  }
  .gnav-util__content {
    position: fixed;
    top: $header-height + 1px;
    right: 0;
    background: $white;
    border: 1px solid $color-black;
    width: 100%;
    z-index: 3;
    &-close {
      background: transparent;
      position: absolute;
      z-index: 2;
      right: 1em;
      top: 1em;
      text-decoration: none;
      width: 22px;
      height: 22px;
      .icon {
        height: 16px;
        width: 17px;
        @include breakpoint($medium-up) {
          height: 20px;
          margin: 0;
          padding: 0;
          width: 22px;
        }
      }
    }
    &-inner {
      padding: 1em 40px 1em 1em;
      ul.error-messages {
        color: $color-error;
        padding: 3px;
      }
    }
    // Email Signup Overlay
    &--signup {
      position: fixed;
      min-height: 250px;
      background-color: $color-white;
      border: solid 1px $color-light-gray;
      left: 125px;
      right: auto;
      text-align: center;
      top: 37px;
      text-transform: uppercase;
      width: 382px;
      .gnav-util__content-close {
        border: 0;
        top: 2em;
      }
      .site-email-signup {
        margin: auto;
        padding: 20px;
        &__title {
          display: block;
          margin-bottom: 10px;
          font-size: 15px;
        }
        &__subtitle {
          font-size: 13px;
          font-style: normal;
          text-transform: none;
          width: 90%;
          margin: auto;
          margin-bottom: 20px;
        }
        &__submit {
          display: block;
          text-align: center;
          margin: 0 auto;
        }
        &__fields {
          margin-bottom: 10px;
          text-transform: none;
          &--email,
          &--sms {
            width: 80%;
            border: solid 1px $color-light-gray;
            margin: 13px auto;
          }
        }
        &__messages {
          text-align: center;
          text-transform: none;
        }
        &__success {
          margin-top: 30%;
        }
      }
      input[type='text'].site-email-signup__field {
        width: 100%;
        text-align: center;
        height: 22px;
        line-height: 22px;
        &::placeholder {
          font-size: 10px;
        }
      }
    }
  }

  // Account Sign Overlay
  .gnav-util__content--account {
    background-color: $color-white;
    border: solid 1px $color-light-gray;
    left: auto;
    right: 74px;
    max-width: 400px;
    top: 37px;
    -webkit-overflow-scrolling: touch;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      width: 350px;
    }
    @include breakpoint($medium-only) {
      width: 350px;
    }
    .gnav-util__content-close {
      border: 0;
      top: 2em;
    }
    .login__overlay-forgot-password {
      cursor: pointer;
    }
  }

  // CART Overlay
  .gnav-util__content--cart {
    background-color: $color-white;
    border: solid 1px $color-light-gray;
    max-width: 350px;
    top: 37px;
    right: 10px;
    left: auto;
    margin: auto;
    -webkit-overflow-scrolling: touch;
    text-transform: uppercase;
    @include breakpoint($landscape-up) {
      right: 19px;
    }
    .gnav-util__carrot {
      position: absolute;
      top: -8px;
      right: 20px;
    }
    .gnav-util__content-edit {
      float: left;
      position: relative;
      top: 20px;
      left: 20px;
      border-bottom: solid 1px $color-black;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .gnav-util__content-close {
      border: 0;
      top: 2em;
    }
    .cart-block {
      width: 100%;
      padding: 0;
      &__items {
        &--remove-form {
          a {
            text-transform: lowercase;
          }
        }
        &--all {
          overflow-y: auto;
        }
        .cart-item {
          border-bottom: solid 1px $color-light-gray;
          padding-top: 10px;
          padding-bottom: 10px;
          width: 100%;
          .cart-block__products & {
            &__cart-details {
              width: 22.25%;
            }
            &__info__header {
              margin-bottom: 15px;
            }
          }
          .cart-item__size {
            display: none;
          }
        }
        .cart-item--kit {
          .cart-item {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  &-item__link {
    @include breakpoint($large-up) {
      height: 1.7em;
    }
  }
  &-logo &-item__link {
    @include breakpoint($large-up) {
      height: auto;
      top: -7px;
    }
  }
}

.gnav-util--cart {
  .gnav-util__content--cart {
    display: none;
    &:hover,
    &.visible {
      display: block;
    }
  }
  &:hover .gnav-util__content {
    &--cart,
    &--account {
      display: block;
    }
  }
}

.gnav-util--account {
  .site-header__utility-item__link {
    padding-bottom: 3px;
  }
  .gnav-util__content-inner {
    padding: 2em;
  }
  .social-login__divider {
    margin: 20px 0;
  }
  .sign-in {
    text-transform: none;
  }
  input,
  .button {
    margin-top: 10px;
    width: 100%;
    &:focus {
      border-color: $color-lighter-gray;
      @include outline-none;
    }
    &:focus-visible {
      @include outline;
    }
  }
  .button {
    color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
  .login {
    &__forgot-password {
      text-transform: none;
      text-decoration: underline;
      margin-top: 8px;
    }
    &__header,
    &__no-account {
      text-transform: none;
    }
    &__registration-alt {
      margin-top: 20px;
    }
  }
  .registration {
    &__show-pass-text,
    &__terms-text,
    &__email-list-text {
      margin-top: 10px;
      display: inline-block;
    }
    &__signin-alt {
      margin-top: 20px;
    }
  }
}

img.gnav-util__avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
/* GDPR Tool tip */
.gnav-util {
  .registration__container {
    .tooltip {
      .tooltiptext-over {
        &::before {
          left: 48%;
        }
      }
    }
  }
}

.tooltip--email_signup {
  .tooltiptext-over {
    &.tooltip-contact,
    &.tooltip-footer,
    &.tooltip-offers {
      font-size: 12px;
    }
    &.tooltip-offers {
      max-width: 530px;
    }
  }
  &.tooltip {
    .tooltiptext-over {
      bottom: 100%;
      border-radius: 0;
    }
    .tooltiptext-under {
      border-radius: 0;
    }
  }
}

.site-header__utility .gnav-util__content--cart {
  .bundle-kit-container {
    .cart-subitem {
      margin-#{$ldirection}: 16.5%;
      .cart-item {
        padding: 0;
        border-bottom: none;
      }
      .cart-item__img-container {
        display: none;
      }
      .cart-item__sku-label {
        display: none;
      }
      .cart-item__info__header {
        min-height: 0;
      }
      .cart-item__qty {
        display: none;
      }
      .cart-item__size {
        display: block;
      }
    }
    border-bottom: 1px solid $color-light-gray;
    margin-bottom: 10px;
  }
}

.giftguide--menu {
  .giftguide {
    &--listitem {
      margin: 0 24px;
      display: inline-block;
      &:nth-child(1) {
        margin-left: 0;
        position: relative;
        top: -10px;
      }
    }
    &--link {
      text-decoration: none;
      line-height: 1.2;
      display: inline-block;
    }
  }
  .giftlist {
    &--one {
      font-style: italic;
    }
  }
}
