.samples-overlay {
  .content {
    height: auto;
  }
  footer {
    display: none;
    visibility: hidden;
  }
}
/* SAMPLES PANEL */
.samples-panel {
  h3 {
    margin-bottom: 1em;
  }
  ul {
    &.product-list {
      .product {
        border-spacing: 0.4em;
        border-bottom: 1px dotted $color-gray;
        display: block;
        &:last-child {
          border: none;
        }
      }
      > li {
        &.deactivate {
          color: $color-light-gray;
        }
        h4 {
          margin-top: 1em;
        }
      }
    }
    &.skus {
      margin-top: 1em;
      li {
        margin-bottom: 0.5em;
      }
    }
  }
  .swatch,
  .samples-panel input.sample-select {
    float: left;
    margin-right: 0.3em;
  }
  .add-button,
  .no-thanks,
  .close-container {
    display: none;
  }
  .samples-pane & {
    .samples-buttons.bottom {
      display: none;
    }
  }
  .product .details input {
    padding-top: 0;
    height: auto;
  }
}

.samples-overlay {
  padding: 0 0 2em 0;
  .close-container {
    display: block;
    position: absolute;
    top: 0.2em;
    right: 1em;
    float: none;
    z-index: 999;
  }
  .samples-panel {
    border-width: 0;
    .product-list > li {
      float: left;
      clear: none;
      border-width: 0;
      width: 25%;
      &:nth-child(4n + 1) {
        clear: left;
      }
    }
    .add-button {
      display: inline-block;
    }
  }
  .product {
    .details {
      float: none;
      clear: left;
      margin-left: 0;
      padding-right: 2em;
    }
    img {
      float: none;
      width: 100%;
      margin-bottom: 1em;
    }
  }
  .return-link {
    display: none;
  }
  .add-button {
    display: block;
  }
}

.overlay-container .samples-panel {
  .no-thanks {
    display: inline-block;
  }
  .add-button,
  .view-larger {
    display: none;
  }
  .add-button {
    float: right;
    padding-right: 2em;
  }
}

#samples-panel .samples-buttons.top {
  display: none;
}
/* SAMPLES PANEL - LARGE IMAGE */
.samples-view-larger-overlay {
  padding: 0;
  .close-container {
    position: absolute;
    top: 0.2em;
    right: 1em;
    float: none;
  }
}

#sample-large-panel {
  .large-image {
    float: left;
  }
  .center {
    clear: both;
    text-align: center;
  }
  .swatch {
    width: 60px;
    height: 60px;
    overflow: hidden;
  }
  .shade {
    float: left;
    padding-right: 1em;
    padding-top: 1em;
  }
  h4.product-name {
    margin-top: 60px;
  }
}
/* SAMPLES PAGE */
.samples-page {
  .return-link {
    display: block;
    width: 50%;
    float: left;
  }
  .product-img {
    float: left;
    width: 30%;
    @include breakpoint($medium-up) {
      float: none;
      width: 100%;
    }
    a.view-larger {
      display: none;
    }
  }
  .details {
    float: left;
    width: 65%;
    padding: 10px 0 0 10px;
    min-height: 120px;
    @include breakpoint($medium-up) {
      padding: 10px 14% 0;
      float: none;
      width: 100%;
    }
    .product {
      &-brief__title,
      &-brief__subtitle,
      &-size {
        padding-bottom: 7px;
      }
      &-brief__title {
        font-weight: bold;
        @include breakpoint($medium-up) {
          font-weight: normal;
        }
      }
      &-brief__subtitle {
        display: none;
      }
    }
    .swatch,
    .shade {
      display: none;
    }
  }
  ul.product-list .product {
    width: 100%;
    float: left;
    text-align: left;
    position: relative;
    border-bottom: 1px solid $color-light-gray;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      width: 33.33%;
      border: 5px solid $color-white;
      padding-bottom: 10px;
      background-color: $color-white;
      margin-bottom: 40px;
      text-align: center;
      .grid__item--left {
        clear: left;
      }
      &:hover {
        &:after {
          content: '';
          position: absolute;
          top: -5px;
          right: -5px;
          bottom: -5px;
          left: -5px;
          box-shadow: 0 0 0 1px $color-border;
          z-index: 1;
        }
      }
    }
  }
  .checkout__header {
    border-bottom: 0;
  }
  .checkout-panel {
    &:first-child {
      border: 0;
    }
  }
  .samples-content__samples {
    @include breakpoint($landscape-up) {
      padding: 0 15%;
    }
    .disclaimer {
      clear: both;
    }
    .offer_title {
      text-align: center;
      padding: 30px 0;
      font-size: 26px;
      text-transform: uppercase;
      display: inline-block;
      width: 100%;
      @include breakpoint($landscape-up) {
        padding: 30px;
      }
    }
  }
}

#samples {
  #samples-panel {
    .return-link {
      display: block;
    }
    .add-button {
      display: block;
      text-align: right;
    }
  }
  .viewcart-buttons-panel {
    border-width: 0;
  }
  .panel {
    border: none;
    header {
      background-color: transparent;
    }
  }
  .samples-pager {
    display: none;
  }
  .prev-next {
    display: none;
  }
}

.samples-overlay {
  .samples-pager {
    display: none;
  }
  .prev-next {
    display: none;
  }
}

.samples-content {
  &__samples--button {
    position: relative;
    width: 100%;
    z-index: 10;
    margin-bottom: 10px;
    @include breakpoint($medium-up) {
      position: absolute;
      max-width: 140px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 10px auto;
    }
    &-container {
      @include breakpoint($medium-up) {
        padding-top: 40px;
      }
    }
    .icon--check {
      padding-left: 5px;
    }
  }
}

.samples-page {
  .checkout {
    &__header {
      .checkout__heading {
        display: none;
      }
    }
  }
}
