.video-steps {
  padding: 15px 0;
  @include breakpoint($medium-up) {
    display: flex;
    padding: 20px 0 0;
    flex-wrap: wrap;
  }
  &.video-steps--no-padding {
    padding: 0;
    margin-top: -5px;
    @include breakpoint($medium-up) {
      padding: 0;
      margin-top: -10px;
    }
  }
  &__step {
    margin-bottom: 20px;
    @include breakpoint($medium-up) {
      margin-bottom: 10px;
      flex: 0 0 33%;
      .video-steps--2 & {
        flex-basis: 50%;
        text-align: center;
      }
      .video-steps--4 & {
        flex-basis: 25%;
        .heading--2 {
          font-size: 24px;
        }
      }
    }
  }
  &__step-heading {
    text-align: center;
  }
  &__step-description {
    margin-top: 10px;
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: left;
    }
  }
  &__step-plus {
    margin-bottom: 40px;
    .icon--plus {
      display: block;
      font-size: 35px;
      font-weight: bold;
      text-align: center;
    }
  }
  &__product {
    margin: 0 7px 40px;
    .video-steps__product {
      &-image {
        margin-bottom: 0;
        padding: 0 11px;
        position: relative;
      }
      &-img {
        display: block;
        margin: auto;
      }
      &-name {
        font-family: $font--subheading;
        font-size: 25px;
        line-height: 30px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 95%;
        z-index: 10;
      }
      &-desc {
        position: relative;
        margin-top: 10px;
      }
      &-subline {
        font-family: $font--subheading;
        font-size: 14px;
        line-height: 20px;
        margin: 0 auto 15px auto;
        text-align: center;
        width: 95%;
      }
    }
  }
}
