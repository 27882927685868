.change-password {
  .profile__change-password-overlay {
    .profile__reset-password {
      .error_messages {
        li {
          color: $color-red;
          font-size: 13px;
        }
      }
      .profile-info {
        &__header {
          text-align: center;
          text-transform: uppercase;
          font-size: 20px;
        }
        &__item {
          @include swap_direction(margin, 15px 0);
          input {
            width: 100%;
          }
          .label {
            text-transform: uppercase;
            font-size: 14px;
          }
          .button {
            width: 48%;
          }
          .form-cancel {
            float: #{$ldirection};
            margin-#{$rdirection}: 4%;
          }
          .form-submit {
            padding-#{$ldirection}: 0;
            padding-#{$rdirection}: 0;
            &:disabled,
            &.disabled {
              color: $color-light-gray;
              background-color: $color-white;
              border-color: $color-light-gray;
              cursor: not-allowed;
            }
          }
        }
        &__rules {
          @include swap_direction(margin, 10px 0);
          li {
            &:before {
              @include swap_direction(margin, 0 6px 0 0);
              content: $cross;
              display: inline-block;
              color: $color-red;
              font-size: 16px;
              line-height: 0;
            }
            &.pass {
              color: $color-green;
              &:before {
                content: $tick;
                color: $color-green;
              }
            }
            &:last-child {
              &:after {
                content: ' ';
              }
            }
          }
        }
        &__meter-status {
          font-weight: bold;
        }
        &__meter {
          height: 8px;
          border-radius: 4px;
          &--container {
            margin-top: 6px;
            background-color: $color-gray;
          }
          &--progress {
            background-color: $color-gray;
            width: 0;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            transition: all 0.3s linear;
          }
        }
        &__meter-wrapper {
          .profile-info {
            &__strength-list {
              display: inline-block;
              li {
                display: none;
                font-style: italic;
              }
            }
          }
          &.weak,
          &.no-score {
            .profile-info {
              &__meter {
                &--progress {
                  width: 0;
                }
              }
              &__strength-list {
                .no-score {
                  display: inline-block;
                }
              }
            }
          }
          &.bad {
            .profile-info {
              &__meter {
                &--progress {
                  width: 25%;
                  background-color: $color-red;
                }
              }
              &__strength-list {
                .bad {
                  display: inline-block;
                }
              }
            }
          }
          &.average {
            .profile-info {
              &__meter {
                &--progress {
                  width: 50%;
                  background-color: $color-average;
                }
              }
              &__strength-list {
                .average {
                  display: inline-block;
                }
              }
            }
          }
          &.good {
            .profile-info {
              &__meter {
                &--progress {
                  width: 75%;
                  background-color: $color-good;
                }
              }
              &__strength-list {
                .good {
                  display: inline-block;
                }
              }
            }
          }
          &.strong {
            .profile-info {
              &__meter {
                &--progress {
                  width: 100%;
                  background-color: $color-green;
                }
              }
              &__strength-list {
                .strong {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
  fieldset {
    overflow: visible;
  }
  .password-reset-page {
    .password-reset {
      &__fieldset {
        .form-item {
          float: none;
          width: auto;
          padding-#{$rdirection}: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  .password-field {
    position: relative;
    &__info {
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-top: 5px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$ldirection}: 100%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -o-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      @include breakpoint($medium-up) {
        position: absolute;
        #{$rdirection}: 103%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 200px;
        border: 1px solid $color-gray;
      }
      @include breakpoint($medium-portrait-only) {
        #{$ldirection}: 0;
        top: -75px;
        &:before {
          top: 116px;
          #{$ldirection}: 50%;
          -ms-transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
      &-reset {
        bottom: auto;
        top: 40%;
        #{$ldirection}: 102%;
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 75%;
        }
        &:before {
          #{$ldirection}: 0;
          top: 45%;
          transform: translate(-110%, -50%);
        }
      }
      &-checkout {
        @include swap_direction(padding, 10px 0);
        @include breakpoint($medium-up) {
          width: 200px;
          &:before {
            -ms-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
            #{$ldirection}: 80px;
            top: 100%;
          }
        }
        width: 100%;
        #{$ldirection}: 0;
        top: -75px;
      }
      &-gnav {
        position: relative;
        left: 0;
        top: 30px;
        width: 100%;
        border: 0;
        &:before {
          display: none;
        }
      }
    }
    &__rules {
      column-count: 2;
      @include breakpoint($medium-up) {
        column-count: 1;
      }
      padding: 10px;
      font-size: 12px;
      list-style: none;
      .special_character {
        text-align: #{$ldirection};
        width: 130px;
      }
      li {
        @include swap_direction(padding, 3px 0);
        display: flex;
        align-items: center;
        color: $color-gray;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
        white-space: nowrap;
        &:before {
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 0;
          margin-#{$rdirection}: 6px;
          -webkit-transition: all 0.2s linear;
          -moz-transition: all 0.2s linear;
          -o-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
        &.pass {
          color: $color--success;
          &:before {
            color: $color--success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
      &--popup,
      &--gnav {
        column-count: 2;
        padding: 0;
      }
    }
  }
}
