body#index {
  html.no-js .panel--sign-in #forgot-pw-note {
    display: none;
  }
  /* SIGN IN PANEL */
  #sign-in-paypal-panel {
    header {
      #return-account-h,
      #new-account-h {
        background: none;
      }
    }
  }
  .panel--sign-in {
    &.paypal.finished {
      display: none;
    }
    .forgot-pass {
      margin-top: 1em;
    }
    .label-content {
      display: none;
    }
    &__return-user,
    &__new-account {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 50%;
        *width: 49%;
      }
      &__header {
        background: none;
      }
    }
    &__return-user {
      border-right-width: 0;
      &__submit {
        margin-bottom: 10px;
      }
    }
    input {
      width: 100%;
    }
    img.img_mail {
      float: left;
      margin-right: 0.3em;
    }
    .new-or-returning-radios {
      margin-bottom: 1em;
      input {
        display: inline-block;
        width: auto;
        padding: 0;
        margin-top: -0.3em;
      }
      label {
        display: inline-block;
        width: auto;
        padding: 0;
      }
      fieldset {
        float: left;
        width: 50%;
      }
    }
  }
  #billing-address-display,
  #shipping-address-display,
  #gift-options-display {
    .column {
      width: 50%;
      *width: 49%;
    }
  }
  /* REGISTRATION PANEL */

  #registration-panel {
    .how-will-we-use {
      clear: right;
      float: right;
      width: 24em;
      border: 1px solid $color-black;
      padding: 1em;
      h3 {
        margin: 0 0 1em;
      }
      p {
        margin: 0;
      }
    }
    .button-wrapper {
      text-align: center;
    }
  }
  .why-create-account-overlay {
    width: 300;
    height: 100;
  }
  @media (max-width: 480px) {
    .sign-in-panel > .column {
      float: none;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      border-right-width: 0;
      border-bottom: 1px solid #ccc;
    }
  }
}

.checkout {
  &__content {
    .checkout-panel {
      &--new-account {
        .email-address {
          margin-bottom: 20px;
          .invalid_marker,
          .valid_marker {
            display: none;
          }
        }
      }
      &--return-user {
        #checkout_signin {
          .email-address,
          .password,
          .forgot-link {
            margin-bottom: 20px;
          }
        }
      }
      ul.error_messages {
        li {
          color: $color-error;
        }
        margin-bottom: 10px;
      }
    }
  }
}
