.filterable-products-block {
  &--text {
    @include breakpoint($large-up) {
      text-align: center;
      display: inline;
    }
  }
  &--copy-over-header {
    display: none;
    @include breakpoint($medium-up) {
      display: block;
      margin-top: 40px;
      font-size: 13px;
    }
  }
  &--header {
    margin-top: 20px;
    font-family: $font--heading;
    font-size: 24px;
    padding: 0 16%;
    text-align: center;
    @include breakpoint($medium-up) {
      font-size: 36px;
      padding: 0;
    }
  }
  &--subheader {
    font-size: 16px;
    margin-top: 25px;
    text-align: center;
    padding: 0 12%;
    @include breakpoint($medium-up) {
      margin: 0 auto;
      padding: 0 25%;
    }
  }
}

.filterable-products {
  &__grid {
    opacity: 0;
    display: flex;
    overflow: hidden;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 auto;
    &.isotope-initialized {
      opacity: 1;
    }
    &-sizer {
      width: 20%;
    }
    &-item {
      flex-direction: column;
      .filter-dependent {
        cursor: default;
        .filterable-products__grid--filtered & {
          cursor: pointer;
        }
      }
      &--content {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        height: auto;
        width: auto;
        @include breakpoint($extra-large-up) {
          &:hover {
            .filterable-products__grid-item--image img {
              transform: scale(1.2);
            }
          }
        }
      }
      &--image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: auto;
          height: 207px;
          @include breakpoint($medium-up) {
            height: 100%;
          }
        }
      }
      &.rectangle-hero {
        width: 40%;
        &:before {
          padding-top: $rectangle-aspect-ratio-percent;
        }
        img {
          width: 100%;
          height: auto;
          margin-top: $rectangle-aspect-ratio-percent-position;
        }
      }
      &--details,
      &--cta {
        display: none;
      }
      @include breakpoint($medium-up) {
        width: 266px;
        height: 266px;
      }
    }
    &--unfiltered,
    &--filtered {
      .filterable-products__grid-item {
        &.grid-columns--1 {
          width: 100%;
        }
        &.grid-columns--2 {
          width: 50%;
          height: 207px;
          &.square-hero,
          &.rectangle-hero {
            width: 50%;
          }
        }
        &.grid-columns--3 {
          width: 33.33%;
          &.square-hero,
          &.rectangle-hero {
            width: 66.66%;
          }
        }
        &.grid-columns--4 {
          width: 25%;
          &.square-hero,
          &.rectangle-hero {
            width: 50%;
          }
        }
        &.grid-columns--5 {
          width: 25%;
          &.square-hero,
          &.rectangle-hero {
            width: 40%;
          }
        }
        &.grid-columns--7 {
          width: 14.28%;
          &.square-hero,
          &.rectangle-hero {
            width: 28.56%;
          }
        }
      }
    }
    &--filtered {
      .filterable-products__grid-item {
        &--details {
          position: absolute;
          top: 8%;
          color: $color-white;
          width: 50%;
          .filterable-products__grid-item-item--shade-name {
            padding-#{$ldirection}: 15px;
          }
        }
        &--cta {
          position: absolute;
          cursor: pointer;
          a {
            text-transform: lowercase;
            color: $color-white;
            border-bottom: 1px solid $color-white;
          }
        }
        &.grid-columns--1,
        &.grid-columns--2,
        &.grid-columns--3 {
          .filterable-products__grid-item--details {
            display: block;
            font-size: 13px;
            line-height: 1.4;
            @include breakpoint($medium-up) {
              font-size: 25px;
              line-height: 1.2;
            }
            @include breakpoint($extra-large-up) {
              &:hover {
                .filterable-products__grid-item--details {
                  top: 20%;
                  font-size: 30px;
                  line-height: 1.2;
                }
                .filterable-products__grid-item--cta {
                  font-size: 15px;
                  position: absolute;
                  bottom: 15px;
                  #{$ldirection}: 15px;
                }
              }
            }
          }
          .filterable-products__grid-item--cta {
            display: block;
            font-size: 10px;
            bottom: 10px;
            #{$ldirection}: 5px;
            @include breakpoint($medium-up) {
              font-size: 15px;
              bottom: 15px;
              #{$ldirection}: 15px;
            }
          }
        }
        &.grid-columns--4,
        &.grid-columns--5 {
          .filterable-products__grid-item--details {
            display: block;
            font-size: 10px;
            line-height: 1.4;
          }
          .filterable-products__grid-item--cta {
            display: block;
            font-size: 10px;
            bottom: 10px;
            #{$ldirection}: 5px;
          }
        }
        &.grid-columns--4,
        &.grid-columns--5,
        &.grid-columns--7 {
          @include breakpoint($extra-large-up) {
            &:hover {
              .filterable-products__grid-item--details {
                top: 30px;
                font-size: 25px;
                line-height: 1.2;
                span {
                  .ie & {
                    line-height: 0.65;
                  }
                  line-height: 0.72;
                  box-shadow: -15px 0 0 0 $color-black, 0.3em 0 0 0 $color-black;
                  padding: 6px 0 1px;
                }
              }
              .filterable-products__grid-item--cta {
                font-size: 12px;
                position: absolute;
                bottom: 15px;
                #{$ldirection}: 15px;
              }
            }
          }
        }
        &.grid-columns--1,
        &.grid-columns--2,
        &.grid-columns--3,
        &.grid-columns--4,
        &.grid-columns--5,
        &.grid-columns--7 {
          .filterable-products__grid-item--details {
            display: block;
            @include breakpoint($extra-large-up) {
              display: none;
            }
            .filterable-products-block--has-results & {
              display: none;
            }
          }

          .filterable-products__grid-item--cta {
            display: block;
            @include breakpoint($extra-large-up) {
              display: none;
            }
            .filterable-products-block--has-results & {
              font-size: 10px;
              @include breakpoint($extra-large-up) {
                width: calc(100% - 30px);
                height: calc(100% - 30px);
                #{$ldirection}: 15px;
                bottom: 15px;
                font-size: 12px;
                align-items: center;
              }
            }
            a {
              .filterable-products-block--has-results & {
                margin: 0 auto;
                padding-bottom: 2px;
              }
            }
          }

          &:hover {
            .filterable-products__grid-item--details {
              @include breakpoint($extra-large-up) {
                display: block;
              }
              .filterable-products-block--has-results & {
                @include breakpoint($extra-large-up) {
                  display: none;
                }
              }
            }
            .filterable-products__grid-item--cta {
              @include breakpoint($extra-large-up) {
                display: block;
              }
              .filterable-products-block--has-results & {
                @include breakpoint($extra-large-up) {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
    @include breakpoint($medium-up) {
      width: 1064px;
      margin-bottom: 10%;
    }
  }
}
