/// Recommended products panel
body#viewcart #recommended-products-panel .recommended-product-items .recommended-item {
  display: table;
  width: 33%;
  float: left;
  height: 170px;
  a {
    float: left;
  }
  .description {
    float: left;
    clear: left;
    width: 105px;
  }
  .addtobag,
  .quickshop {
    clear: left;
  }
}
