$filters-body-width: $filter-block-width;
.filterable-products {
  &-block {
    max-width: $filter-block-width;
    margin: 0 auto;
    &--filters {
      max-width: $filters-body-width;
      margin: 30px auto 5px;
    }
  }
  &__filter-arrow {
    &:before {
      content: '';
      border: solid $color-white;
      border-width: 0 2.4px 2.4px 0;
      height: 5px;
      width: 5px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      bottom: 22px;
      #{$rdirection}: 20px;
      font-size: 5px;
      position: absolute;
      @include breakpoint($extra-large-up) {
        display: none;
      }
    }
    &.open {
      &:before {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
    }
  }
  &__filter-set {
    background: $color-white;
    width: 100%;
    display: flex;
    @include breakpoint($extra-large-up) {
      padding-top: 0;
    }
    &.filtered {
      .filterable-products__filter-set-footer {
        width: 105px;
        float: #{$rdirection};
        @include breakpoint($extra-large-up) {
          width: auto;
          #{$rdirection}: 0;
          float: none;
          padding: 0;
        }
        .reset-link,
        .filterable-products_product-count {
          display: inline-block;
        }
        .filterable-products_product-count span:first-child {
          margin-#{$ldirection}: 5px;
          &::before {
            content: '(';
          }
        }
        .filterable-products_product-count span:last-child {
          &::after {
            content: ')';
          }
        }
      }
    }
    &-label {
      text-transform: uppercase;
      font-size: 18px;
      width: calc(100% - 20px);
      position: absolute;
      #{$rdirection}: 10px;
      top: 10px;
      height: 50px;
      background-color: $color-black;
      text-align: center;
      padding-top: 10px;
      padding-#{$rdirection}: 10px;
      color: $color-white;
      @include breakpoint($extra-large-up) {
        background: none;
        color: $color-black;
        height: auto;
        min-width: 100px;
        padding: 0;
        position: static;
        width: auto;
      }
      .filters_container--inner.has-sorting & {
        width: 45%;
        margin-#{ldirection}: 6px;
        @include breakpoint($extra-large-up) {
          width: auto;
        }
        :not(.has-image-toggle) & {
          @include breakpoint($extra-large-up) {
            min-width: unset;
          }
        }
      }
    }
    &-container {
      width: 100%;
      position: relative;
      &--inner {
        .filters-position-center & {
          @include breakpoint($extra-large-up) {
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
    &-body {
      position: relative;
    }
    &-footer {
      width: 100%;
      padding: 5px 20px;
      min-height: 28px;
      text-align: #{$rdirection};
      @include breakpoint($extra-large-up) {
        padding: 10px 0 0;
        bottom: 0;
        position: absolute;
      }
      .reset-link {
        display: none;
        color: $color-light-grey;
        text-transform: inherit;
      }
    }
    &-section {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      height: auto;
      .filters-position-center & {
        margin-#{$ldirection}: 20px;
      }
      &.first {
        @include breakpoint($extra-large-up) {
          justify-content: flex-start;
        }
        .filters-position-center & {
          margin-#{$ldirection}: 0;
        }
      }
      &.last {
        @include breakpoint($extra-large-up) {
          justify-content: flex-end;
        }
      }
      &.current {
        color: $color-black;
        border-bottom: 2px solid $color-black;
        @include breakpoint($extra-large-up) {
          border: none;
        }
        .filterable-products-filter-set {
          &__filters {
            display: flex;
            position: absolute;
            justify-content: space-evenly;
            bottom: 0;
            #{$ldirection}: 0;
            min-width: 100%;
            &.draggable {
              transition: transform 500ms ease 0s;
              touch-action: pan-y;
              .filterable-products-filter-set__button:last-of-type {
                margin-#{$rdirection}: 10px;
              }
            }
            @include breakpoint($extra-large-up) {
              position: static;
              width: auto;
              justify-content: flex-start;
            }
          }
          &__filters-wrapper {
            position: absolute;
            bottom: 0;
            .mpp-custom-override-layout & {
              bottom: -35px;
            }
            #{$ldirection}: 0;
            width: 100%;
            height: 45px;
            @include breakpoint($extra-large-up) {
              position: static;
              height: auto;
              margin-#{$ldirection}: 10px;
            }
            .expanded & {
              @include breakpoint($extra-large-up) {
                margin-#{$ldirection}: 0;
                padding-top: 10px;
              }
            }
          }
        }
      }
      .filterable-products-filter-set {
        &__filters {
          display: flex;
          justify-content: space-around;
          &-wrapper {
            height: 0;
            width: 100%;
            overflow: hidden;
            transition: opacity 1s ease-in-out;
            opacity: 0;
            &.current {
              height: auto;
              overflow: visible;
              opacity: 1;
            }
          }
          @include breakpoint($medium-up) {
            justify-content: center;
          }
        }
        &__button {
          @include font--text;
          text-transform: uppercase;
          padding: 10px;
          margin-bottom: 20px;
          color: $color-gray;
          font-size: 13px;
          outline: none;
          cursor: pointer;
          &:last-child {
            margin-#{$rdirection}: 0;
          }
          &:not(.is_selected):hover {
            color: $color-black;
          }
          &.is_selected {
            color: $color-dark-gray;
          }
          &.active {
            font-weight: bold;
            color: $color-black;
          }
        }
        &__title {
          padding: 0 3px;
          text-transform: uppercase;
          margin: 0 auto;
          display: none;
          @include breakpoint($extra-large-up) {
            display: block;
            margin: 0;
            color: $color-black;
            margin-#{$ldirection}: 8px;
            padding-bottom: 5px;
          }
          &--mobile {
            display: block;
            @include breakpoint($medium-up) {
              font-size: 23px;
            }
            @include breakpoint($extra-large-up) {
              display: none;
            }
          }
          @include breakpoint($extra-large-up) {
            margin-#{$ldirection}: 0;
            padding-bottom: 0;
          }
        }
      }
    }
    &-choose-your-match,
    &-select-skin-tone {
      text-align: center;
      font-size: 11px;
      padding-bottom: 10px;
    }
  }
  &__tabs {
    margin-bottom: 20px;
    text-align: center;
    @include breakpoint($medium-up) {
      margin-bottom: 15px;
    }
  }
  &__tab {
    @include swap_direction(margin, 0 0 0 -4px);
    border-bottom: 1px solid $gray;
    color: $color-light-gray;
    cursor: pointer;
    display: inline-block;
    font-family: $font--subheading;
    font-size: 16px;
    text-transform: uppercase;
    padding: 10px 20px 2px;
    transition: 0.3s all ease-in-out;
    &:not(.current):hover {
      color: $tabbed-block-inactive-green;
      border-bottom-color: $tabbed-block-inactive-green;
    }
    &.current {
      border-bottom-color: $color-dark-gray;
      color: $color-dark-gray;
      font-weight: bold;
    }
    @include breakpoint($medium-up) {
      padding: 10px 24px 6px;
    }
  }
}
.filters_container {
  display: flex;
  width: 100%;
  padding-top: 20px;
  position: relative;
  flex-direction: column;
  .mpp-custom-override-layout & {
    @include breakpoint($extra-large-up) {
      padding-top: 53px;
    }
  }
  @include breakpoint($extra-large-up) {
    flex-direction: row;
  }
  &--inner {
    display: flex;
    width: 100%;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    @include breakpoint($extra-large-up) {
      flex-direction: row;
    }
    &.has-sorting,
    &.has-filters {
      min-height: 65px;
    }
    .filters-position-center & {
      display: flex;
      justify-content: space-around;
    }
  }
  .mpp-sort-wrapper {
    @include breakpoint($extra-large-up) {
      width: 25%;
    }
  }
  &.expanded {
    min-height: 200px;
    .filterable-products__wrapper {
      @include breakpoint($extra-large-up) {
        z-index: 2;
        height: auto;
      }
    }
    .filterable-products__filter-set-section {
      .filterable-products-filter-set__filters-wrapper {
        display: none;
      }
      &.current {
        .filterable-products-filter-set__filters-wrapper {
          display: block;
        }
      }
    }
  }
  &.fixed {
    @include breakpoint($extra-large-up) {
      position: fixed;
      height: 90px;
      border-bottom: 1px solid $color-light-grey;
      background-color: $color-white;
      #{$ldirection}: 0;
      width: 100%;
      z-index: 999;
    }
    .mpp-custom-override-layout & {
      padding-top: 20px;
      @include breakpoint($extra-large-up) {
        padding-top: 30px;
      }
    }
    &.closed {
      .filterable-products__wrapper {
        .filterable-products__filter-set-container--inner {
          .filterable-products__filter-set-footer {
            bottom: 28px;
          }
        }
      }
    }
  }
  &.closed {
    .filterable-products__wrapper {
      height: auto;
    }
    .filterable-products-filter-set__filters-wrapper {
      display: none;
    }
  }
  &.filtered {
    .filterable-products__wrapper {
      .filterable-products__filter-set-footer {
        .reset-link,
        .filterable-products_product-count {
          display: block;
          float: #{$ldirection};
        }
        .filterable-products_product-count span:first-child {
          margin-#{$ldirection}: 5px;
          &::before {
            content: '(';
          }
        }
        .filterable-products_product-count span:last-child {
          &::after {
            content: ')';
          }
        }
      }
    }
    .mpp-model-images__container {
      margin: 20px auto 25px;
      @include breakpoint($extra-large-up) {
        @include swap_direction(margin, 0 0 20px 0);
      }
    }
  }
  .mpp-model-images {
    &__container {
      color: $color-light-grey;
      display: flex;
      font-size: 19px;
      height: 60px;
      justify-content: flex-start;
      margin: 20px auto 0;
      position: relative;
      max-width: 1440px;
      width: 350px;
      order: 2;
      @include breakpoint($medium-up) {
        font-size: 20px;
        width: 95%;
      }
      @include breakpoint($extra-large-up) {
        @include swap_direction(margin, 0 0 20px 0);
        justify-content: flex-start;
        order: 0;
        width: 25%;
        font-size: 18px;
      }
    }
    &__label {
      display: none;
      @include breakpoint($extra-large-up) {
        @include swap_direction(padding, 0 10px 0);
        color: $color-black;
        display: block;
        text-transform: uppercase;
      }
      @include breakpoint($extra-large-up) {
        @include swap_direction(padding, 0 10px 0 0);
      }
    }
    &__option {
      display: flex;
      padding: 0 3px;
      color: $color-light-grey;
      border-bottom: 1.5px solid $color-light-grey;
      font-size: 20px;
      flex-grow: 1;
      flex-basis: auto;
      margin-bottom: 10px;
      padding-bottom: 15px;
      height: 35px;
      text-transform: uppercase;
      @include breakpoint($extra-large-up) {
        cursor: pointer;
        padding: 0 10px 15px;
        font-size: 18px;
        flex-grow: 0;
      }
      &:hover,
      &.selected {
        color: $color-black;
        border-color: $color-black;
      }
      span {
        margin: 0 auto;
      }
    }
  }

  .filterable-products__wrapper {
    width: 100%;
    min-height: 80px;
    @include breakpoint($extra-large-up) {
      height: auto;
      max-width: 40%;
      width: auto;
      margin: 0 auto;
    }
    .contextual-links-region {
      position: static;
    }
    .filterable-products {
      &__filter-set {
        width: 100%;
        &-container {
          position: static;
          @include breakpoint($extra-large-up) {
            width: 100%;
            height: auto;
          }
        }
        &-section {
          display: none;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-bottom: 0;
          border-bottom: none;
          margin-top: 75px;
          @include breakpoint($extra-large-up) {
            display: flex;
            padding-#{$rdirection}: 18px;
            margin-top: 0;
            margin-#{$rdirection}: 0;
            margin-#{$ldirection}: 0;
          }
          &.closed {
            display: none;
          }
          &.open {
            display: flex;
          }
          .filterable-products-filter-set {
            &__button {
              padding: 0 15px;
              @include breakpoint($extra-large-up) {
                width: auto;
                min-width: unset;
              }
              &.active,
              &.hover {
                color: $color-black;
              }
              &-wrapper {
                &-smoosh {
                  width: 75px;
                  height: 85px;
                  margin: 0 auto;
                }
                &-label {
                  text-align: center;
                  margin-bottom: 20px;
                  @include breakpoint($extra-large-up) {
                    margin-bottom: 0;
                  }
                }
                &-label:only-child {
                  margin-top: 10px;
                  @include breakpoint($extra-large-up) {
                    margin-top: 50px;
                    padding: 0 15px;
                  }
                  .has-smooshes & {
                    margin-top: 45px;
                    margin-bottom: 60px;
                    @include breakpoint($extra-large-up) {
                      margin-top: 45px;
                      margin-bottom: 40px;
                    }
                  }
                }
              }
              border-bottom: none;
              color: $color-light-grey;
              text-transform: lowercase;
              font-size: 15px;
              outline: none;
              &:not(.disabled) {
                .mpp-custom-override-layout & {
                  color: $color-black;
                }
              }
            }
            &__title {
              color: $color-light-grey;
              cursor: pointer;
              font-size: 18px;
              #{$ldirection}: auto;
              display: block;
              height: 40px;
              &.filterable-products-filter-set--has-image {
                .has-image-toggle & {
                  display: none;
                }
                &.show {
                  .has-image-toggle & {
                    @include breakpoint($extra-large-up) {
                      display: block;
                    }
                  }
                }
              }
              &.selected {
                color: $color-black;
              }
              &--mobile {
                display: none;
                &.filterable-products-filter-set--has-image {
                  &.show {
                    display: block;
                    .has-image-toggle & {
                      @include breakpoint($extra-large-up) {
                        display: none;
                      }
                    }
                  }
                }
              }
              &.filtered {
                color: $color-black;
              }
            }
            &__filters {
              margin-bottom: 10px;
              @include breakpoint($extra-large-up) {
                position: absolute;
                width: 100%;
                margin-#{$ldirection}: 0;
              }
              &-wrapper {
                display: none;
              }
              .has-smooshes & {
                @include breakpoint($extra-large-up) {
                  padding-top: 5px;
                }
              }
            }
          }
          .vertical-line {
            #{$ldirection}: 0;
            border-#{$ldirection}: 1px solid $color-light-grey;
            border-#{$rdirection}: none;
            padding-#{$rdirection}: 18px;
            height: 15px;
            margin-top: 6px;
            &.filterable-products-filter-set--has-image {
              .has-image-toggle & {
                display: none;
              }
              &.show {
                .has-image-toggle & {
                  display: block;
                }
              }
            }
          }
          &.last {
            border-#{$rdirection}: none;
          }
          &.first {
            padding-#{$ldirection}: 18px;
            .vertical-line {
              display: none;
              &.filterable-products-filter-set--has-image {
                &.show {
                  .has-image-toggle & {
                    display: none;
                  }
                }
              }
            }
          }

          &.current {
            .filterable-products-filter-set {
              &__filters {
                bottom: auto;
                justify-content: space-evenly;
              }
            }
          }
        }
        &-body {
          min-height: 80px;
          @include breakpoint($extra-large-up) {
            height: auto;
            min-height: unset;
          }
        }
        &-footer {
          font-size: 13px;
          padding: 0;
          z-index: 9999;
          position: absolute;
          bottom: 0;
          width: auto;
          #{$rdirection}: 10px;
          @include breakpoint($extra-large-up) {
            bottom: 0;
            #{$rdirection}: 0;
          }
          .reset-link {
            float: #{$ldirection};
            display: none;
            color: $color-black;
            border-bottom-color: $color-black;
            &.busy {
              opacity: 0.5;
              cursor: wait;
            }
          }
          .filterable-products_product-count {
            display: none;
          }
        }
      }
    }
  }
}
