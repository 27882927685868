///////////////////////////////
/// CONTENT BLOCK COLUMNS
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////

// Presets content block styles
.variation-cream-hero {
  margin: 0;
  @include breakpoint($medium-up) {
    margin: 0 72px;
  }
  .slick-slider {
    padding: 0;
    .slick-list {
      padding: 0 8%;
    }
    .slick-slide {
      margin: 0 5px;
    }
  }
}

// Content Block Default + Editor Options
.content-block-columns {
  display: flex;
  position: relative;
  width: 100%;
  background-color: $color-white;
  z-index: 0;
  &.full-height {
    height: 100vh;
  }
  &.no-image {
    .content-block-w-column__content-leadin {
      @include breakpoint($medium-up) {
        position: static;
      }
    }
    .content-block-w-column__content {
      @include breakpoint($medium-up) {
        position: relative;
        height: auto;
      }
    }
  }
  .icon {
    color: inherit;
    font-size: 90px;
    cursor: pointer;
  }
  &__inner {
    width: 100%;
    position: relative;
  }
  picture img {
    width: 100%;
    z-index: 1;
  }
  &__content {
    width: 100%;
    align-items: center;
    flex-direction: row;
    @include breakpoint($medium-portrait-only) {
      display: flex;
    }
    @include breakpoint($landscape-up) {
      display: flex;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  &__content-text {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    text-align: center;
    @include breakpoint($medium-portrait-only) {
      padding: 4px;
      text-align: inherit;
    }
    @include breakpoint($landscape-up) {
      padding: 0px;
      margin-bottom: 0px;
      text-align: inherit;
    }
    &__header {
      margin-top: 30px;
      margin-bottom: 10px;
      @include breakpoint($medium-up) {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
  }
  &__content-button {
    text-align: center;
    margin-top: 10px;
    @include breakpoint($medium-up) {
      text-align: inherit;
      margin-top: 20px;
    }
  }
  .text-alignment {
    @include breakpoint($landscape-down) {
      text-align: center;
    }
  }
  .items-direction {
    &--row {
      flex-direction: row;
      justify-content: center;
      &.horizontal-align-left {
        justify-content: flex-start;
      }
      &.horizontal-align-right {
        justify-content: flex-end;
      }
      &.vertical-align-start {
        align-items: flex-start;
      }
      &.vertical-align-end {
        align-items: flex-end;
      }
    }
    &--column {
      flex-direction: column;
      align-items: center;
      &.vertical-align-start {
        justify-content: flex-start;
      }
      &.vertical-align-end {
        justify-content: flex-end;
      }
      &.horizontal-align-left {
        align-items: flex-start;
      }
      &.horizontal-align-right {
        align-items: flex-end;
      }
    }
  }
  // Maximum Width for Inner Items
  @include breakpoint($landscape-up) {
    .max-width-15 {
      max-width: 15%;
    }
    .max-width-16 {
      max-width: 16%;
    }
    .max-width-30 {
      max-width: 30%;
    }
    .max-width-35 {
      max-width: 35%;
    }
    .max-width-40 {
      max-width: 40%;
    }
    .max-width-50 {
      max-width: 50%;
    }
    .max-width-60 {
      max-width: 60%;
    }
    .max-width-70 {
      max-width: 70%;
    }
    .max-width-80 {
      max-width: 80%;
    }
    .max-width-90 {
      max-width: 90%;
    }
    .max-width-100 {
      max-width: 100%;
    }
  }

  // Vertical Alignment paddings
  .vertical-align-end {
    @include breakpoint($landscape-up) {
      &.padding--1 {
        padding-bottom: 1%;
      }
      &.padding--2 {
        padding-bottom: 2%;
      }
      &.padding--3 {
        padding-bottom: 3%;
      }
      &.padding--4 {
        padding-bottom: 4%;
      }
      &.padding--5 {
        padding-bottom: 5%;
      }
      &.padding--10 {
        padding-bottom: 10%;
      }
      &.padding--15 {
        padding-bottom: 15%;
      }
      &.padding--20 {
        padding-bottom: 20%;
      }
      &.padding--25 {
        padding-bottom: 25%;
      }
      &.padding--30 {
        padding-bottom: 30%;
      }
      &.padding--35 {
        padding-bottom: 35%;
      }
    }
  }
  .vertical-align-start {
    @include breakpoint($landscape-up) {
      &.padding--1 {
        padding-top: 1%;
      }
      &.padding--2 {
        padding-top: 2%;
      }
      &.padding--3 {
        padding-top: 3%;
      }
      &.padding--4 {
        padding-top: 4%;
      }
      &.padding--5 {
        padding-top: 5%;
      }
      &.padding--10 {
        padding-top: 10%;
      }
      &.padding--15 {
        padding-top: 15%;
      }
      &.padding--20 {
        padding-top: 20%;
      }
      &.padding--25 {
        padding-top: 25%;
      }
      &.padding--30 {
        padding-top: 30%;
      }
      &.padding--35 {
        padding-top: 35%;
      }
    }
  }
}
