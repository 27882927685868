// Hide the VTO-button by request. NAMAINT-1618
.product-quickshop {
  .cta-vto {
    display: none;
  }
}

.product-foundation-vto {
  & &__mobile-ctrl {
    .container {
      .product-foundation-vto__info-container {
        .product-name {
          font-size: 14px;
          width: 90%;
        }
      }
    }
  }
}
