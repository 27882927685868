///////////////////////////////
/// CONTENT BLOCK LARGE
/// bg image dictates container height
/// uses generic font and size classes
///////////////////////////////

// Presets expand default content block styles
.content-block-large--kate-hudson {
  .content-block-large__content {
    text-align: center;
    justify-content: center;
    align-items: flex-end;
    @include breakpoint($landscape-up) {
      text-align: left;
    }
  }
  .content-block-large__content-text {
    color: $color-black;
    @include breakpoint($landscape-up) {
      color: $color-white;
      max-width: 40%;
    }
  }
  .content-block-large__content-text__header,
  .content-block-large__content-text__subheader {
    @include heading--1;
  }
  .content-block-large__content-text__content {
    @include text--1;
  }
  .content-block-large__content-buttons {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  .content-block-large__content-button {
    @include breakpoint($landscape-up) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

// Content Block Default + Editor Options
.content-block-large {
  display: flex;
  position: relative;
  width: 100%;
  background-color: $color-white;
  z-index: 0;
  &.has-video {
    video {
      display: block;
    }
  }
  &.full-height {
    height: 100vh;
  }
  &.no-image {
    .content-block-large__content-leadin {
      @include breakpoint($medium-up) {
        position: static;
      }
    }
    .content-block-large__content {
      @include breakpoint($medium-up) {
        position: relative;
        height: auto;
      }
    }
    &.has-video {
      .content-block-large__content {
        @include breakpoint($medium-up) {
          position: absolute;
          height: 100%;
        }
      }
    }
  }
  .icon {
    color: inherit;
    font-size: 90px;
    cursor: pointer;
  }
  &__inner {
    width: 100%;
    position: relative;
  }
  picture img {
    width: 100%;
    z-index: 1;
  }
  video {
    width: 100%;
    display: none;
  }
  &__content {
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding: 15px 10px;
    @include breakpoint($medium-up) {
      padding: 20px;
    }
    @include breakpoint($landscape-up) {
      display: flex;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      padding: 25px;
    }
    &.text-alignment.align-#{$ldirection} {
      text-align: $ldirection;
    }
    &.text-alignment.align-#{$rdirection} {
      text-align: $rdirection;
    }
  }
  &__content-text {
    width: 100%;
    padding: 10px;
    @include breakpoint($medium-up) {
      padding: 20px;
    }
  }
  &__content-buttons {
    text-align: center;
    margin-top: 10px;
    @include breakpoint($medium-up) {
      text-align: inherit;
      margin-top: 20px;
    }
  }
  &__content-button-container {
    &.button--#{$rdirection} {
      text-align: $rdirection;
    }
    &.button--#{$ldirection} {
      text-align: $ldirection;
    }
    @include breakpoint($medium-up) {
      display: inline;
    }
  }
  &__content-leadin {
    margin-top: auto;
    text-align: center;
    width: 100%;
    color: $white;
    font-family: $font--text;
    letter-spacing: 1px;
    font-size: 11px;
    margin-top: 30px;
    @include breakpoint($landscape-up) {
      position: absolute;
      bottom: 0;
      left: 0;
      bottom: 33px;
      margin-top: 0;
    }
    a {
      display: inline-block;
      text-decoration: none;
      @include breakpoint($landscape-up) {
        color: inherit;
      }
    }
    .icon {
      display: block;
      font-size: 32px;
      @include breakpoint($medium-up) {
        margin-top: 16px;
      }
      @include breakpoint($landscape-up) {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .text-alignment {
    @include breakpoint($landscape-down) {
      text-align: center;
    }
  }
  // Horizontal Alignment
  .horizontal-align-left {
    align-items: flex-start;
    @include breakpoint($landscape-up) {
      .margin--5 {
        margin-left: 5%;
      }
      .margin--10 {
        margin-left: 10%;
      }
      .margin--15 {
        margin-left: 15%;
      }
      .margin--20 {
        margin-left: 20%;
      }
      .margin--25 {
        margin-left: 25%;
      }
      .margin--30 {
        margin-left: 30%;
      }
      .margin--35 {
        margin-left: 35%;
      }
      .margin--40 {
        margin-left: 40%;
      }
      .margin--45 {
        margin-left: 45%;
      }
      .margin--50 {
        margin-left: 50%;
      }
    }
  }
  .horizontal-align-center {
    align-items: center;
  }
  .horizontal-align-right {
    align-items: flex-end;
    @include breakpoint($landscape-up) {
      .margin--5 {
        margin-right: 5%;
      }
      .margin--10 {
        margin-right: 10%;
      }
      .margin--15 {
        margin-right: 15%;
      }
      .margin--20 {
        margin-right: 20%;
      }
      .margin--25 {
        margin-right: 25%;
      }
      .margin--30 {
        margin-right: 30%;
      }
      .margin--35 {
        margin-right: 35%;
      }
      .margin--40 {
        margin-right: 40%;
      }
      .margin--45 {
        margin-right: 45%;
      }
      .margin--50 {
        margin-right: 50%;
      }
    }
  }

  // Vertical Alignment
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  @include breakpoint($landscape-up) {
    .max-width-30 {
      max-width: 30%;
    }
    .max-width-40 {
      max-width: 40%;
    }
    .max-width-50 {
      max-width: 50%;
    }
    .max-width-60 {
      max-width: 60%;
    }
    .max-width-70 {
      max-width: 70%;
    }
    .max-width-80 {
      max-width: 80%;
    }
    .max-width-90 {
      max-width: 90%;
    }
    .max-width-100 {
      max-width: 100%;
    }
  }
}
